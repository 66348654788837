import { APIEndpoints } from "src/types/apiTypes";
import useFetch from "../useFetch";
import { ProductRequestProps } from "./types";


const useDrugNameListing = () => {
  const { data: productNameListing } = useFetch<ProductRequestProps[]>(APIEndpoints.GET_PRODUCTS);

  return { productNameListing };
};

export default useDrugNameListing;
