import { FC } from "react";


import { RestoreCaseSessionModalProps } from "../addCase/types";



const RerunRestoreCaseSessionModal: FC<RestoreCaseSessionModalProps> = ({ isModalVisible, restoringCaseData, removeCurrentData }): JSX.Element => {
  return (
    <div
      className={`modal fade ${isModalVisible ? 'show show-modal' : ''}`}
      id="exampleModal"
      tabIndex={-1}
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '600px'}}>
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">Warning</h1>
          </div>

          <div className="modal-body">
            <p className="m-0">
              It seems that you were already enrolling a patient but did not finish it.
              Would you like to continue with the existing enrollment?
            </p>
          </div>

          <div className="modal-footer justify-content-center">
            <button type="button" className="btn btn-danger" onClick={removeCurrentData}>No, discard the previous enrollment and let me continue cloning this current one</button>
            <button type="button" className="btn btn-secondary" onClick={restoringCaseData}>Yes, let me continue with my previous enrollment</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RerunRestoreCaseSessionModal;
