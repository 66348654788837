import { FC } from "react";
import { PatientInformationProps } from "./types";
import { formatPhoneNumber, formatSSN } from "src/helpers";

const PatientInformation: FC<PatientInformationProps> = ({ caseObject }): JSX.Element => {
  const { patient_information } = caseObject;

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="m-0">Patient Information</h4>
      </div>

      <div className="card-body">
        <div className="row">

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Patient Title:</p>
              </div>
              <div className="col-6">
                <p>
                  {patient_information ? patient_information.patient_title : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Gender:</p>
              </div>
              <div className="col-6">
                <p>
                  {patient_information ? patient_information.gender : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>First Name:</p>
              </div>
              <div className="col-6">
                <p>
                  {patient_information ? patient_information.first_name : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Middle Name:</p>
              </div>
              <div className="col-6">
                <p>
                  {patient_information ? patient_information.middle_name : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Last Name:</p>
              </div>
              <div className="col-6">
                <p>
                  {patient_information ? patient_information.last_name : "N/A"}
                </p>
              </div>
            </div>
          </div>

          {/*<div className="col-12 col-md-6">*/}
          {/*  <div className="row">*/}
          {/*    <div className="col-6 text-end px-3">*/}
          {/*      <p>Email Address:</p>*/}
          {/*    </div>*/}
          {/*    <div className="col-6">*/}
          {/*      <p>*/}
          {/*        {patient_information &&*/}
          {/*          (patient_information.email && typeof patient_information.email !== "string") ?*/}
          {/*            patient_information.email.email :*/}
          {/*            "N/A"*/}
          {/*        }*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Date of Birth:</p>
              </div>
              <div className="col-6">
                <p>
                  {patient_information ? patient_information.dob : "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>SSN:</p>
              </div>
              <div className="col-6">
                <p>
                  {patient_information ? formatSSN(patient_information.ssn) : "N/A"}
                </p>
              </div>
            </div>
          </div>


        {patient_information && patient_information.patient_phone_numbers && patient_information.patient_phone_numbers.length > 0 ?
          patient_information.patient_phone_numbers.map((item, index) => (
            <div className="row" key={index}>
              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>{item.phone_type ? `${item.phone_type} Phone Number:` : "Phone Number:"}</p>
                  </div>
                  <div className="col-6">
                    <p>{item && item.phone_number ? formatPhoneNumber(item.phone_number) : 'N/A'}</p>
                  </div>
                </div>
              </div>
            </div>
          ))
          : null
        }

      </div>
    </div>
  );
};

export default PatientInformation;
