import { useContext } from "react";
import { AuthContext } from "src/context/authContext";
import { FooterContext } from "src/context/footerContext";


const Footer = () => {
  const { iovera_practice, isManufacturer } = useContext(AuthContext);
  const { isAccordianShown, changeAccordianVisibility } = useContext(FooterContext);


  return (
    <footer className="footer-policy">
      {/*{isUserAuthorized && <div className="container overflow-auto" style={{ backgroundColor: '#808285', maxHeight: '40px', maxWidth: '4000px' }}>*/}
      {/*  <div className="links" style={{ backgroundColor: '#808285', maxHeight: '100px', maxWidth: '4000px' }}>*/}
      {/*    <p className="text-primary-grey" style={{ backgroundColor: '#808285', textEmphasisColor: '#808285' }} >Home</p>*/}
      {/*  </div>*/}
      {/*</div>}*/}
      {!isManufacturer ? (
        <div className="accordion" id="accordionExample">
          <div className="accordion-header">
            <button onClick={changeAccordianVisibility}
              className="accordion-button collapsed text-uppercase fw-bold rounded-0 text-white"
              type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
            >PLEASE SEE IMPORTANT SAFETY INFORMATION AND FULL PRESCRIBING INFORMATION
              {isAccordianShown ? (
                <i className="bi bi-chevron-down ps-1"></i>
              ) : (
                <i className="bi bi-chevron-up ps-1"></i>
              )}
            </button>
          </div>
          <div id="collapseOne" className={`pt-3 accordion-collapse collapse ${isAccordianShown ? 'show' : ''}`}
            data-bs-parent="#accordionExample">
            <h2 className="text-primary">INDICATION AND IMPORTANT SAFETY INFORMATION</h2>
            <h2 className="text-primary">Indication</h2>
            <p className="text-black">ZILRETTA&reg; (triamcinolone acetonide extended release injectable suspension) is indicated as an intra-articular injection for the management of osteoarthritis pain of the knee.</p>
            <p className="text-black">Limitation of Use: The efficacy and safety of repeat administration of ZILRETTA have not been demonstrated.</p>

            <h2 className="text-primary">Contraindication</h2>
            <p className="text-black">ZILRETTA is contraindicated in patients who are hypersensitive to triamcinolone acetonide, corticosteroids, or any components of the product.</p>

            <h2 className="text-primary">Warnings and Precautions</h2>
            <ul className="text-black">
              <li>Intra-articular Use Only: ZILRETTA has not been evaluated and should not be administered by epidural, intrathecal, intravenous, intraocular, intramuscular, intradermal, or subcutaneous routes. Serious events have been reported with epidural and intrathecal administration of corticosteroids and none are approved for this use. ZILRETTA should not be considered safe for epidural or intrathecal administration.</li>
              <li>Hypersensitivity Reactions: Rare instances of anaphylaxis, including serious cases, have occurred in patients with hypersensitivity to corticosteroids.</li>
              <li>Joint Infection and Damage: A marked increase in pain accompanied by local swelling, restriction of joint motion, fever, and malaise are suggestive of septic arthritis. Examine joint fluid to exclude a septic process. If diagnosis is confirmed, institute appropriate antimicrobial therapy. Avoid injecting corticosteroids into a previously infected or unstable joint. Intra-articular administration may result in damage to joint tissues.</li>
              <li>Increased Risk of Infections: Infection with any pathogen in any location of the body may be associated with corticosteroid use. Corticosteroids may increase the susceptibility to new infection and decrease resistance and the ability to localize infection.</li>
              <li>Alterations in Endocrine Function: Corticosteroids can produce reversible hypothalamic-pituitary-adrenal axis suppression, with potential for adrenal insufficiency after withdrawal of treatment, which may persist for months. In situations of stress during that period, institute corticosteroid replacement therapy.</li>
              <li>Cardiovascular and Renal Effects: Corticosteroids can cause blood pressure elevation, salt and water retention, and increased potassium excretion. Monitor patients with congestive heart failure, hypertension, and renal insufficiency for edema, weight gain, and electrolyte imbalance. Dietary salt restriction and potassium supplementation may be needed.</li>
              <li>Increased Intraocular Pressure: Corticosteroid use may be associated with increased intraocular pressure. Monitor patients with elevated intraocular pressure for potential treatment adjustment.</li>
              <li>Gastrointestinal Perforation: Corticosteroid administration may increase risk of gastrointestinal perforation in patients with certain GI disorders and fresh intestinal anastomoses. Avoid corticosteroids in these patients.</li>
              <li>Alterations in Bone Density: Corticosteroids decrease bone formation and increase bone resorption. Special consideration should be given to patients with or at increased risk of osteoporosis prior to treatment.</li>
              <li>Behavior and Mood Disturbances: Corticosteroids may cause adverse psychiatric reactions. Prior to treatment, special consideration should be given to patients with previous or current emotional instability or psychiatric illness. Advise patients to immediately report any behavior or mood disturbances.</li>
            </ul>
            <h2 className="text-primary">Adverse Reactions</h2>
            <p className="text-black">The most commonly reported adverse reactions (incidence ≥1%) in clinical studies included sinusitis, cough, and contusions.</p>
            <h3 style={{ color: '#808285' }}>Please see <a href="https://www.zilrettalabel.com/PI" target="_blank">full Prescribing Information</a></h3>
            <h3 style={{ color: '#808285' }}>
              Please see Indications for Use and Important Safety Information at <a style={{ color: '#13B5EA' }} href="https://www.ioverapro.com" target="_blank">www.ioverapro.com</a>
            </h3>
            <p className="text-black">
              &copy;2025 Pacira Pharmaceuticals, Inc., a wholly owned subsidiary of Pacira BioSciences,
              Inc. ZILRETTA and FlexForward are registered trademarks of Pacira Therapeutics, Inc., and
              iovera° is a registered trademark of Pacira CryoTech, Inc., both of which are wholly owned
              subsidiaries of Pacira BioSciences, Inc. PP-MP-US-0325 February 2025
            </p>

          </div>
        </div>
      ) : (
        <div style={{ backgroundColor: "#808285", minHeight: "50px" }}></div>
      )}
    </footer>
  );
};

export default Footer;
