import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { APIEndpoints } from "src/types/apiTypes";
import useFetch from "../useFetch";
import { ToastContext } from "src/context/toastContext";
import { ToastTypes } from "src/types";

const useAppealButtonFunc = () => {
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();

  const { loading: apiInPorgress, mutate: appealForCase, data: appealCaseResponse } =
    useFetch<{}>(APIEndpoints.APPEAL_CASE);

  useEffect(() => {
    if (appealCaseResponse) {
      showToast(["Appeal Submitted!"], ToastTypes.INFO);
      if (window.location.pathname === "/cases") navigate(0);
      else navigate(`/cases`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appealCaseResponse]);

  const submiAppealForCase = (caseId: number, precertId: number) => {
    const pathParams = {
      caseId,
      precertId,
    }
    appealForCase({}, pathParams)
  }

  return { submiAppealForCase, apiInPorgress };
};

export default useAppealButtonFunc;
