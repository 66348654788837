import { FC } from "react";
import { PrescriberInformationProps } from "./types";
import { formatPhoneNumber } from "src/helpers";

const PrescriberInformation: FC<PrescriberInformationProps> = ({ caseObject }): JSX.Element => {
  const { prescriber_information } = caseObject;

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="m-0">Prescriber Information</h4>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>First name:</p>
              </div>
              <div className="col-6">
                <p>
                  {prescriber_information ? prescriber_information.first_name : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Middle Name:</p>
              </div>
              <div className="col-6">
                <p>
                  {prescriber_information ? prescriber_information.middle_name : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Last Name:</p>
              </div>
              <div className="col-6">
                <p>
                  {prescriber_information ? prescriber_information.last_name : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Speciality:</p>
              </div>
              <div className="col-6">
                <p>
                  {prescriber_information ? prescriber_information.spaciality : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>NPI:</p>
              </div>
              <div className="col-6">
                <p>{prescriber_information ? prescriber_information.npi : "N/A"}</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>DEA:</p>
              </div>
              <div className="col-6">
                <p>
                  {prescriber_information ? prescriber_information.dea : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Street:</p>
              </div>
              <div className="col-6">
                <p>
                  {prescriber_information ? prescriber_information.street : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>City:</p>
              </div>
              <div className="col-6">
                <p>
                  {prescriber_information ? prescriber_information.city : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>State:</p>
              </div>
              <div className="col-6">
                <p>
                  {prescriber_information ? prescriber_information.state : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Zip:</p>
              </div>
              <div className="col-6">
                <p>
                  {prescriber_information ? prescriber_information.zip : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Phone Number:</p>
              </div>
              <div className="col-6">
                <p>
                  {prescriber_information && prescriber_information.phone_number ? formatPhoneNumber(prescriber_information.phone_number) : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Fax Number:</p>
              </div>
              <div className="col-6">
                <p>
                  {prescriber_information && prescriber_information.fax_number ? formatPhoneNumber(prescriber_information.fax_number) : "N/A"}
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PrescriberInformation;
