import { FC, createContext, useEffect, useMemo, useReducer } from "react";

import { AuthProviderProps, AuthStateActions, AuthStateProps, UserContextProps } from "./types";
import { HCPUserSubHeaderProps, ManufacturerSubHeaderProps, UserType } from "../../types/users";
import { authContextReducer, initialState } from "./reducer";
import { shutDownAndEndChat } from "src/helpers/tawkto";
import { USER_TYPES } from "src/constants";

export const AuthContext = createContext<UserContextProps>({
  isUserAuthorized: false,
  isManufacturer: false,
  authToken: '',
  userData: null,
  isRestoreCalled: false,
  sub_header: [],
  iovera_practice:false,
  createSession: (userData: UserType, sub_header: HCPUserSubHeaderProps[] | ManufacturerSubHeaderProps[] , authToken: string,iovera_practice:boolean) => {},
  destroySession: () => {},
  restoreSession: () => false,
  updateUserToken: (userToken: string) => {},
  updateUserData: (userData: UserType) => {},
});

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(authContextReducer, initialState);
  const authChannel = useMemo(() => new BroadcastChannel("urogen_auth"), []);

  const createSession = (userData: UserType, sub_header: HCPUserSubHeaderProps[] | ManufacturerSubHeaderProps[], authToken: string,iovera_practice:boolean) => {
    const data: AuthStateProps = {
      authToken,
      userData,
      sub_header,
      isUserAuthorized: true,
      isRestoreCalled: false,
      iovera_practice:iovera_practice,
      isManufacturer: USER_TYPES.MANUFACTURER.includes(userData.ref_type)
    };
    dispatch({
      type: AuthStateActions.SET_SESSION,
      payload: data,
    });

    localStorage.setItem('token', authToken);
    localStorage.setItem('data', JSON.stringify({userData, sub_header,iovera_practice}));
    authChannel.postMessage(userData);
  };

  const destroySession = () => {
    //emptyCacheAndCookies()
    localStorage.clear();
    authChannel.postMessage(null);
    dispatch({ type: AuthStateActions.CLEAR_SESSION });
  };

  const updateUserData = (userData: UserType) => {
    dispatch({
      type: AuthStateActions.UPDATE_USER_DATA,
      payload: userData,
    });
    localStorage.setItem('data', JSON.stringify({ userData, sub_header: state.sub_header, iovera_practice: state.iovera_practice }));
  };

  const updateUserToken = (userToken: string) => {
    dispatch({
      type: AuthStateActions.UPDATE_USER_TOKEN,
      payload: userToken
    });
    localStorage.removeItem("token")
    localStorage.setItem("token", userToken);
  };

  const restoreSession = (): boolean => {
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('data');
    const data = {} as AuthStateProps;

    if (token && userData) {
      data.isUserAuthorized = true;
      data.authToken = token;
      const dataInLC = JSON.parse(userData);
      data.userData = dataInLC.userData;
      data.sub_header = dataInLC.sub_header;
      data.iovera_practice = dataInLC.iovera_practice;
      data.isManufacturer = USER_TYPES.MANUFACTURER.includes(dataInLC.userData.ref_type);
      data.isRestoreCalled = true;
      dispatch({
        type: AuthStateActions.SET_SESSION,
        payload: data,
      });
      return true;
    } else {
      dispatch({
        type: AuthStateActions.SET_SESSION,
        payload: {
          ...initialState,
          isRestoreCalled: true,
        },
      });
      return false;
    }
  };

  useEffect(() => {
    authChannel.onmessage = (_isAuthorized) => {
      if (_isAuthorized.data === null) {
        localStorage.clear();
        dispatch({ type: AuthStateActions.CLEAR_SESSION });
        shutDownAndEndChat();
      }
      else {
        restoreSession();
      }
    }
  }, [authChannel]);

  authChannel.onmessage = (_isAuthorized) => {
    if (_isAuthorized.data === null) {
      localStorage.clear();
      dispatch({ type: AuthStateActions.CLEAR_SESSION });
      shutDownAndEndChat();
    }
    else {
      restoreSession();
    }
  }

  return (
    <AuthContext.Provider value={{
      isUserAuthorized: state.isUserAuthorized,
      isManufacturer: state.isManufacturer,
      authToken: state.authToken,
      userData: state.userData,
      isRestoreCalled: state.isRestoreCalled,
      sub_header: state.sub_header,
      iovera_practice: state.iovera_practice,
      createSession,
      destroySession,
      restoreSession,
      updateUserData,
      updateUserToken,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
