import { FC } from "react";
import Select, { GroupBase, OptionsOrGroups, PropsValue } from "react-select";
import DatePicker from "react-datepicker";

import { QueryStateActions } from "src/pages/dashboard/types";
import { CaseFilterProps } from "./types";
import useDrugNameListing from "src/hooks/useDrugNameListing";
import { format } from "date-fns";

const TableFilters: FC<CaseFilterProps> = ({ state, dropdownData, inputPlaceholder, dispatch, handleSearchWithQueryParam, onClearButtonClicked, onStatusChange }): JSX.Element => {
  const { productNameListing } = useDrugNameListing();

  const handleDateFromChange = (date: Date | null): void => {
    dispatch({
      type: QueryStateActions.CHANGE_FROM_DATE,
      payload: date ? format(date, "MM/dd/yyyy") : "",
    });
  };

  const handleDateToChange = (date: Date | null) => {
    dispatch({
      type: QueryStateActions.CHANGE_TO_DATE,
      payload: date ? format(date, "MM/dd/yyyy") : "",
    });
  };

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: QueryStateActions.CHANGE_SEARCH_QUERY,
      payload: e.target.value
    });
  };

  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchWithQueryParam()
    }
  }

  const handleDrugChange = (val: { label: string; value: string }) => {
    dispatch({
      type: QueryStateActions.CHANGE_DRUG,
      payload: val.value,
    });
  };

  return (
    <div className="row g-2" data-tour="third-step">
      <div className="col-12 col-sm-12 col-md-12 col-lg-7">
        <div className="row g-2">
          <div className="col-12 col-sm-12 col-md-3">
            <Select
              options={dropdownData as unknown as OptionsOrGroups<string | boolean, GroupBase<string | boolean>>}
              noOptionsMessage={() => ""}
              onChange={(val) => {
                const selectedValue = val as unknown as { label: string; value: string | boolean };
                (typeof selectedValue.value === "boolean") ?
                  dispatch({
                    type: QueryStateActions.CHANGE_CASE_STATUS,
                    payload: selectedValue.value,
                  }) :
                  dispatch({
                    type: QueryStateActions.CHANGE_CATEGORY_TYPE,
                    payload: selectedValue.value,
                  });
                onStatusChange(selectedValue.label);
              }}
              isSearchable={false}
              value={((openValue: string | boolean) => {
                const data = dropdownData.find(singleStatus =>
                  singleStatus.value === openValue
                );
                if (data) return data as unknown as PropsValue<string | boolean>;
              })(typeof state.open === "boolean" ? state.open : state.category_type)}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-3">
            <Select
              options={
                [
                  { value: "", label: "Select Product" },
                  ...(productNameListing
                    ? productNameListing
                      .map((item) => ({
                        value: item.name.toLowerCase(),
                        label:
                          item.name.toLowerCase() === "zilretta"
                            ? item.name.toUpperCase()
                            : item.name,
                      }))
                      .map((procedure) => ({
                        value: procedure.value,
                        label: procedure.label,
                      }))
                    : []),
                ] as unknown as OptionsOrGroups<string, GroupBase<string>>
              }
              noOptionsMessage={() => ""}
              onChange={(val) => {
                const selectedValue = val as unknown as { label: string; value: string };
                handleDrugChange(selectedValue);
              }}
              isSearchable={false}
              value={((drug_name: string) => {
                if (drug_name === "") return null;
                const new_drug =
                  productNameListing &&
                  productNameListing
                    .map((item) => ({
                      value: item.name.toLowerCase(),
                      label:
                        item.name.toLowerCase() === "zilretta"
                          ? item.name.toUpperCase()
                          : item.name,
                    }))
                    .find((option) => option.value === drug_name);

                return new_drug as unknown as PropsValue<string>;
              })(state.drug_name)}
              placeholder="Select Product..."
            />
          </div>
          <div className="col-6 col-sm-6 col-md-3">
            {/* <DatePicker
              value={state.date_from} onChange={handleDateFromChange}
              className="form-control rounded-1" wrapperClassName="w-100"
              placeholderText="mm/dd/yyyy"
              maxDate={state.date_to ? new Date(state.date_to) : undefined}
              isClearable // Allows clearing the field
            /> */}
            <DatePicker
              selected={state.date_from ? new Date(state.date_from) : null} // Ensure correct date format
              onChange={(date) => handleDateFromChange(date)} // Pass Date | null directly
              className="form-control rounded-1"
              wrapperClassName="w-100"
              placeholderText="mm/dd/yyyy"
              maxDate={state.date_to ? new Date(state.date_to) : undefined}
              isClearable // Allows clearing the field
            />
          </div>
          <div className="col-6 col-sm-6 col-md-3">
            {/* <DatePicker
              value={state.date_to} onChange={handleDateToChange}
              className="form-control rounded-1" wrapperClassName="w-100"
              placeholderText="mm/dd/yyyy"
              minDate={state.date_from ? new Date(state.date_from) : undefined}
              isClearable // Allows clearing the field
            /> */}
            <DatePicker
              selected={state.date_to ? new Date(state.date_to) : null} // Use selected instead of value
              onChange={(date) => handleDateToChange(date)} // Pass Date | null directly
              className="form-control rounded-1"
              wrapperClassName="w-100"
              placeholderText="mm/dd/yyyy"
              minDate={state.date_from ? new Date(state.date_from) : undefined}
              isClearable // Allows clearing the field
            />
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-12 col-md-12 col-lg-5">
        <div className="row g-2">
          <div className="col-12 col-sm-12 col-md-7 col-lg-8">
            <input type="text" onChange={handleSearchQueryChange} value={state.search_query}
              placeholder={inputPlaceholder} className="form-control rounded-1" onKeyDown={onEnterPress} />
          </div>

          <div className="col-12 col-sm-12 col-md-5 col-lg-4">
            <div className="row g-2">
              <div className="col-6">
                <button type="button" onClick={onClearButtonClicked} className="btn btn-outline-secondary w-100">
                  Clear
                </button>
              </div>
              <div className="col-6">
                <button type="button" onClick={() => {
                  dispatch({ type: QueryStateActions.CHANGE_PAGE, payload: 1, });
                  handleSearchWithQueryParam({ ...state, page: 1 });
                }} className="btn btn-primary w-100">
                  <i className="bi bi-search me-2"></i> Find
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableFilters;
