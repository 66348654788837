import { FC, useEffect, useContext, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { format } from "date-fns";


import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { ToastContext } from "src/context/toastContext";
import { LoaderContext } from "src/context/loaderContext";
import { ToastTypes } from "src/types";

import FooterText from "src/components/footerText";
import CaseFilter from "src/components/manufacturer/statistics";
import { StatisticsSearchProps } from "src/components/manufacturer/statistics/types";
import { AuthContext } from "../../context/authContext";
import AuthMaunfacturerSubHeader from "src/components/manufacturer/authManufacturerSubHeader";
import CustomTopPayorDetail from "src/components/manufacturer/customTopPayorDetail";
import CustomComponentAreaCaseBySubmission from "src/components/manufacturer/customComponentAreaCaseBySubmission";
import CaseVolumeColumnChart from "src/components/manufacturer/caseVolumeColumnChart";
import { StatisticsApiResponse } from "../manufacturer/types";


const PracticeStats: FC = (): JSX.Element => {
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const { userData } = useContext(AuthContext);
  const [searchParams, updateSearchParams] = useSearchParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const insuranceName:string | null = params.get('insurance_name');
  const [queryParams, changeQueryParams] = useState({
    start_date:
      searchParams.get("start_date") ||
      format(new Date().setDate(new Date().getDate() - 30), "yyyy-MM-dd"),
    end_date:
      searchParams.get("end_date") ||
      format(new Date().setDate(new Date().getDate() + 1), "yyyy-MM-dd"),
    period: searchParams.get('period') || "",
    filter_type: searchParams.get("filter") || "",
    // time: searchParams.get("time") || "",
    type: "territories",
    role: userData ? userData.ref_type.toLowerCase() : searchParams.get("role") || "",
    soc_value: searchParams.get("soc_value") || "",
    drug_id: searchParams.get("drug_id") || "",
    id: searchParams.get("userId") || (userData && userData.ref_type === "rep" ? userData.id.toString() : "") || "",
    insurance_name:insuranceName

  });

  const { data: statisticsData, mutate: getStatsData } =
    useFetch<StatisticsApiResponse>(APIEndpoints.MANUFACTURE_STATISTICS, queryParams);

  useEffect(() => {
    if (statisticsData) {
      hideLoader();
      if (statisticsData.code === 200) {
        showToast([statisticsData.message], ToastTypes.SUCCESS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statisticsData]);



  const updateQueryParamsAndCallAPI = (
    updatedQueries: StatisticsSearchProps,
    isReset: boolean = false
  ): void => {
    if (isReset) {
      updateSearchParams({});
      changeQueryParams({
        start_date: format(
          new Date().setDate(new Date().getDate() - 30),
          "yyyy-MM-dd"
        ),
        end_date: format(
          new Date().setDate(new Date().getDate()),
          "yyyy-MM-dd"
        ),
        period: "",
        filter_type: "",
        // time: "",
        type: "territories",
        role: userData ? userData.ref_type.toLowerCase() : searchParams.get("role") || "",
        soc_value: "",
        drug_id: "",
        id: "",
        insurance_name:''

      });
    } else {
      changeQueryParams(updatedQueries);
    }

    setLoader();
    getStatsData({}, {}, updatedQueries);
  };

  const getStatisticsData = (period: string) => {
    const updatedQueries = {
      ...queryParams,
      period,
    }
    changeQueryParams(updatedQueries)
    getStatsData({}, {}, updatedQueries);
  }

  return (
      <div className="container-fluid overflow-auto bg-primary-sky">
        <AuthMaunfacturerSubHeader/>
        {statisticsData && (
            <CaseFilter
                defaultQueryParams={queryParams}
                updateQueryParams={updateQueryParamsAndCallAPI}
            />
        )}
        {statisticsData && (
            <CaseVolumeColumnChart StatisticsObjectType={statisticsData.data} getStatisticsData={getStatisticsData}
                                   buttonShows={false}/>
        )}
        {statisticsData && (
            <CustomTopPayorDetail startDate={queryParams.start_date} endDate={queryParams.end_date} StatisticsObjectType={statisticsData.data}/>
        )}
        <div className="row">
          <div className="col p-2">
            <div className="card mt-3">
              <div className="card-header">
                <h5 className="m-0 text-center text-primary">Transfers
                </h5>
              </div>
              <div className="card-body">
                <h6 className="text-center">
                  18.143% (14.460%)
                </h6>
              </div>
            </div>
          </div>
        </div>

        {statisticsData && (
            <CustomComponentAreaCaseBySubmission startDate={queryParams.start_date} endDate={queryParams.end_date} StatisticsObjectType={statisticsData.data} title={"Doctors"}/>
        )}

        <FooterText footerType="black"/>
      </div>
  );
};

export default PracticeStats;
