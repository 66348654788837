import { FC, useEffect, useContext, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { format } from "date-fns";


import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { ToastContext } from "src/context/toastContext";
import { LoaderContext } from "src/context/loaderContext";
import { ToastTypes } from "src/types";

import CaseFilter from "src/components/manufacturer/statistics";
import { StatisticsSearchProps } from "src/components/manufacturer/statistics/types";
import {AuthContext} from "../../context/authContext";
import AuthMaunfacturerSubHeader from "src/components/manufacturer/authManufacturerSubHeader";
import CustomComponentAreaCaseBySubmission from "src/components/manufacturer/customComponentAreaCaseBySubmission";
import { GeographicalStatisticsObject, StatisticsApiResponse } from "../manufacturer/types";
import GeographicalStatistics from "src/components/manufacturer/geographicalStatistics";
import CustomPieChart from "src/components/manufacturer/customPieChart";
import CaseVolume from "src/components/manufacturer/caseVolumeLineChart";
import CustomComponentDoctor from "src/components/manufacturer/customDoctor";


const Territory: FC = (): JSX.Element => {
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const { userData } = useContext(AuthContext);
  const [searchParams, updateSearchParams] = useSearchParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const insuranceName:string | null = params.get('insurance_name');
  const [queryParams, changeQueryParams] = useState({
    start_date:
      searchParams.get("start_date") ||
      format(new Date().setDate(new Date().getDate() - 30), "yyyy-MM-dd"),
    end_date:
      searchParams.get("end_date") ||
      format(new Date().setDate(new Date().getDate()+1), "yyyy-MM-dd"),
    period: searchParams.get('period') || "day",
    filter_type: searchParams.get("filter") || "",
    // time: searchParams.get("time") || "",
    type: "territories",
    role: searchParams.get("role") || (userData ? userData.ref_type.toLowerCase() : "rep"),
    soc_value:searchParams.get("soc_value") || "",
    drug_id:searchParams.get("drug_id") || "",
    id: searchParams.get("userId") || (userData && userData.ref_type === "rep" ? userData.id.toString() : "") || "",
    insurance_name: insuranceName
  });

  const { data: statisticsData, mutate: getStatsData } =
    useFetch<StatisticsApiResponse>(APIEndpoints.MANUFACTURE_STATISTICS, queryParams);

  useEffect(() => {
    if (statisticsData) {
      hideLoader();
      if (statisticsData.code === 200) {
        showToast([statisticsData.message], ToastTypes.SUCCESS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statisticsData]);



  const updateQueryParamsAndCallAPI = (
    updatedQueries: StatisticsSearchProps,
    isReset: boolean = false
  ): void => {
    if (isReset) {
      updateSearchParams({});
      changeQueryParams({
        start_date: format(
          new Date().setDate(new Date().getDate() - 30),
          "yyyy-MM-dd"
        ),
        end_date: format(
          new Date().setDate(new Date().getDate()),
          "yyyy-MM-dd"
        ),
        period: "",
        filter_type: "",
        // time: "",
        type: "index",
        role: userData ? userData.ref_type.toLowerCase() : searchParams.get("role") || "rep",
        soc_value:"",
        drug_id:"",
        id: "",
        insurance_name:''

      });
    } else {
      changeQueryParams(updatedQueries);
    }

    setLoader();
    getStatsData({}, {}, updatedQueries);
  };

  const getStatisticsData = () => {
    const updatedQueries = {
      ...queryParams,

    }
    changeQueryParams(updatedQueries)
    getStatsData({}, {}, updatedQueries);
  }

  return (
      <div className={"container-fluid body-bg overflow-y-auto"}>
        <div className="container">
          <AuthMaunfacturerSubHeader/>
          {statisticsData && (
              <CaseFilter
                  defaultQueryParams={queryParams}
                  updateQueryParams={updateQueryParamsAndCallAPI}
              />
          )}
          {statisticsData && (
              <CaseVolume startDate={queryParams.start_date} endDate={queryParams.end_date} StatisticsObjectType={statisticsData.data} getStatisticsData={getStatisticsData}
                          buttonShows={false}/>
          )}
          <div>
            {statisticsData &&
                <CustomPieChart pieChartObject={statisticsData.data.top_payors} title={'Top 10 Payors'}/>}
          </div>
          {statisticsData && (
            <CustomComponentDoctor startDate={queryParams.start_date} endDate={queryParams.end_date} StatisticsObjectType={statisticsData.data} title={'Doctors'}/>          )}

          {statisticsData?.data && (
              <GeographicalStatistics
                  GeographicalStatisticsListType={
                    statisticsData?.data
                        .geographical_stats as unknown as GeographicalStatisticsObject[]
                  }
              />
          )}
        </div>
      </div>
  );
};

        export default Territory;
