import { FC, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FooterText from "../../components/footerText";
import HubInputField from "../../components/inputType";

import useFetch from "../../hooks/useFetch";
import { ToastContext } from "../../context/toastContext";
import { AuthContext } from "../../context/authContext";

import { createUserAPIResponseType } from "./types";
import { APIEndpoints } from "../../types/apiTypes";
import { ToastTypes } from "../../types";
import { UpdateUserSchema } from "src/validation/updateUser";
import AuthMaunfacturerSubHeader from "../../components/manufacturer/authManufacturerSubHeader";
import AuthSubHeader from "../../components/authSubHeader";
import BAAComponent from "../../components/baaComponent";



const HubUpdateUser: FC = (): JSX.Element => {
  const { userData, updateUserData,isManufacturer } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);

  const { control, setValue, handleSubmit } = useForm({
    mode: 'all',
    resolver: yupResolver(UpdateUserSchema),
    defaultValues: {
      retreatment_reminders: false,
    }

  });

  useEffect(() => {
    if (userData) {
      setValue("firstname", userData.firstname);
      setValue("middlename", userData.middlename || "");
      setValue("lastname", userData.lastname);
      setValue("email", userData.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);





  const { loading: userLoading, data: updateUserApiResponse, mutate: addNewUser, error, removeErrorAndData } =
    useFetch<createUserAPIResponseType>(APIEndpoints.USER_UPDATE, {}, { userId: userData?.id });

  useEffect(() => {
    if (updateUserApiResponse) {
      const { code, message, data } = updateUserApiResponse.status;

      if (code === 200) {
        if (data) {
          const { user, navbar } = data;

          if (user) {
            showToast([message], ToastTypes.SUCCESS);

            //:- Runs if user has setup a new password and needs to login again
            updateUserData({
              ...userData,
              ...user,
              ...(navbar && {
                reset_demo_cases: navbar.reset_demo_cases,
                stats_view: navbar.stats_view,
                demo_user_view: navbar.demo_user_view,
              })
            });
          }
        }
      } else if (code === 500) {
        showToast([message], ToastTypes.ERROR);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserApiResponse]);

  const submitForm = async (values: unknown) => {
    if ((error && error.length > 0) || updateUserApiResponse) removeErrorAndData();
    addNewUser({ user: values });
  };





  return (
      <div className="container-fluid d-flex flex-fill flex-column body-bg overflow-auto pt-2">
        <div className="container">
          {isManufacturer ? <AuthMaunfacturerSubHeader /> : <AuthSubHeader />}
          <div className="d-flex flex-column">
            <div className="flex-fill">
              <div className="card mt-4">
                <div className="card-header">
                  <h4 className="mb-0">
                    Edit Account
                  </h4>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit(submitForm)}>

                    <p className="fs-6">
                      You may edit your user account information here.
                    </p>

                    <div>
                      <Controller
                          name="firstname"
                          control={control}
                          defaultValue=""
                          render={({field, fieldState: {error}}) => (
                              <HubInputField
                                  inputId="firstname"
                                  isRowBased={true}
                                  isRequired={false}
                                  field={field}
                                  error={error}
                                  inputLabel="First Name"
                                  inputType="firstName"
                              />
                          )}
                      />
                    </div>

                    <div>
                      <Controller
                          name="middlename"
                          control={control}
                          defaultValue=""
                          render={({field, fieldState: {error}}) => (
                              <HubInputField
                                  inputId="middlename"
                                  isRowBased={true}
                                  isRequired={false}
                                  field={field}
                                  error={error}
                                  inputLabel="Middle Name"
                                  inputType="middleName"
                              />
                          )}
                      />
                    </div>

                    <div>
                      <Controller
                          name="lastname"
                          control={control}
                          defaultValue=""
                          render={({field, fieldState: {error}}) => (
                              <HubInputField
                                  inputId="lastname"
                                  isRowBased={true}
                                  isRequired={false}
                                  field={field}
                                  error={error}
                                  inputLabel="Last Name"
                                  inputType="lastName"
                              />
                          )}
                      />
                    </div>

                    <div>
                      <Controller
                          name="email"
                          control={control}
                          defaultValue=""
                          render={({field, fieldState: {error}}) => (
                              <HubInputField
                                  inputId="email"
                                  isRowBased={true}
                                  isRequired={true}
                                  field={field}
                                  error={error}
                                  inputLabel="Email"
                                  inputType="email"
                              />
                          )}
                      />
                    </div>

                    {/* <div className="card mb-3">
                      <div className="card-header">
                        <h4 className="text-primary mb-0">Account Settings</h4>
                      </div>
                      <div className="card-body">
                        <Controller
                            name="retreatment_reminders"
                            control={control}
                            defaultValue={false}
                            render={({field: {value, onChange}}) => (
                                <div className="form-check">
                                  <input
                                      className="form-check-input" type="checkbox"
                                      id="isSpecialityPharmacyOnlyCheckbox"
                                      name="prescription_attributes.is_specialty_pharmacy_only"
                                      checked={value}
                                      onChange={(e) => onChange(e.target.checked)}
                                  />
                                  <label className="form-check-label" htmlFor="isSpecialityPharmacyOnlyCheckbox">
                                    Allow patient to receive Retreatment Reminders
                                  </label>
                                </div>
                            )}
                        />
                      </div>
                    </div> */}

                    <div className="card mb-3">
                      <div className="card-header">
                        <h4 className="mb-0">Password</h4>
                      </div>
                      <div className="card-body">
                        <p>Password must be at least 10 characters long (no spaces) and include at least one uppercase
                          letter, at least three lowercase letters, one special character, and a number.</p>
                        <div>
                          <Controller
                              name="password"
                              control={control}
                              defaultValue=""
                              render={({field, fieldState: {error}}) => (
                                  <HubInputField
                                      inputId="password"
                                      isRowBased={true}
                                      isRequired={false}
                                      field={field}
                                      error={error}
                                      inputLabel="Password"
                                      inputType="password"
                                  />
                              )}
                          />
                        </div>
                        <div className="">
                          <Controller
                              name="password_confirmation"
                              control={control}
                              defaultValue=""
                              render={({field, fieldState: {error}}) => (
                                  <HubInputField
                                      inputId="password_confirmation"
                                      isRowBased={true}
                                      isRequired={false}
                                      field={field}
                                      error={error}
                                      inputLabel="Confirm Password"
                                      inputType="password"
                                  />
                              )}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <p>By logging in, you agree to the terms of the Business Associates Agreement below.</p>
                    </div>
                    {
                    <div className="card">
                      <div className="card-header">
                        <h4 className="mb-0">
                          Business Associates Agreement
                        </h4>
                      </div>
                      <div className="card-body" style={{maxHeight: '400px', overflowY: 'auto'}}>
                        <BAAComponent/>
                      </div>

                    </div>
                }
                    <button disabled={userLoading} className="btn btn-primary d-flex mt-4" type="submit">
                      {userLoading ? (
                          <i className="spinner-border text-white me-2" style={{width: `24px`, height: `24px`}}></i>
                      ) : null}
                      <p className="col-auto mb-0">Save</p>
                    </button>
                  </form>

                </div>
              </div>
            </div>
            <div className="mt-3"><FooterText footerType="black"/></div>
          </div>
        </div>
      </div>
  );
};

export default HubUpdateUser;


