import { FC } from "react"

import CustomLineChart from "../customLineChart";
import CustomButtonRight from "../customButtonRight";

import { StatisticsObjectType } from "./types";



const CaseVolume: FC<StatisticsObjectType> = ({ StatisticsObjectType, buttonShows, startDate, endDate }): JSX.Element => {
  return (
    <div className="row mt-3">
      <div className={` ${buttonShows ? "col-12 col-sm-12 col-md-9" : "col"}`} style={{maxHeight:550}}>
        <CustomLineChart title="Case Volume" LineChartObject={StatisticsObjectType.case_volume} />
      </div>
      {buttonShows &&
        <div className="col-12 col-sm-12 col-md-3 mt-4 mt-sm-4 mt-md-0" style={{maxHeight:550}}>
          <CustomButtonRight startDate={startDate} endDate={endDate} StatisticsObjectType={StatisticsObjectType} />
        </div>
      }
    </div>
  )
}

export default CaseVolume;
