import { FC, useEffect } from "react";
import { Controller } from "react-hook-form";
import { GroupBase, OptionsOrGroups } from "react-select";

import useFetch from "src/hooks/useFetch";
import HubInputField from "src/components/inputType";
import IOveraComps from "../addCase/ioveraComps";
import ZilrettaComp from "../addCase/zilrettaComps";

import { APIEndpoints } from "src/types/apiTypes";
import { CaseStepOneProps } from "./types";
import { ProductRequestProps } from "../manufacturer/statistics/types";



const CaseStepOne: FC<CaseStepOneProps> = ({ control, isVisible, watch, setValue, getValues }): JSX.Element => {
  const drug_name = watch("prescription_attributes.drug_name");


  const { data: productData } = useFetch<ProductRequestProps[]>(APIEndpoints.GET_PRODUCTS);

  useEffect(() => {
    if (productData && productData.length > 0 && drug_name === "") {
      const firstDrug = productData.find((item) => item.id === 1);
      if (firstDrug)
        setValue("prescription_attributes.drug_name", firstDrug.name.toLowerCase().replace(/°/g, ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData]);



  return (
    <div className={`card mb-3 ${!isVisible ? 'd-none' : ''}`}>
      <div className="card-header">
        <h4 className="m-0">Getting Started</h4>
      </div>

      <div className="card-body">
        <p>
          In order to process your request for benefit investigation, the following information must be completed.
          All information is kept confidential according to HIPAA guidelines. Please fill out the fields below as
          completely as possible. Missing fields may result in a delay to processing patient benefits in a timely manner.
        </p>

        <div className="row">
          <div className='col-lg-6'>
            <Controller
              name="prescription_attributes.drug_name"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId="prescription_attributes.drug_name"
                  inputLabel="Product"
                  isRequired={true}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  field={field}
                  error={error}
                  isDropdown={true}
                  isSearchable={false}
                  inputPlaceholder="Select a Product ..."
                  dropdownData={
                    (productData ?
                      productData.map((item) => ({ value: item.name.toLowerCase(), label: item.name.toLowerCase() === "zilretta" ? item.name.toUpperCase() : item.name.replace(/°/g, "") })).map(
                        (procedure) => ({ value: procedure.value, label: procedure.label })
                      ) : []
                    ) as unknown as OptionsOrGroups<string, GroupBase<string>>
                  }
                />
              )}
            />
          </div>
        </div>

        {drug_name === "iovera°" || drug_name === "iovera"  ?
          <IOveraComps control={control} setValue={setValue} watch={watch} getValues={getValues} /> :
          <ZilrettaComp control={control} watch={watch} setValue={setValue} />
        }

        <Controller
          name="prescription_attributes.drug_name"
          control={control}
          render={() => <></>}
        />
      </div>
    </div>
  )
};

export default CaseStepOne;
