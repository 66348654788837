import { FC, useContext, useEffect } from "react";
import { format, parse } from "date-fns";
import Select, { GroupBase, MultiValue, OptionsOrGroups } from "react-select";
import { Controller, useWatch } from "react-hook-form";

import HubInputField from "src/components/inputType";
import SiteOfCareComp from "../siteOfCare";

import { CPT_CODE_TO_HIDE_NERVES, NERVE_NAMES, TREATMENT_AREA } from "src/constants/cases";

import { IOveraCompProps } from "./types";
import AddCaseProcedureSelect from "../addCaseProcedureSelect";
import AddCaseDiagnosisCode from "../addCaseDiagnosisCode";
import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { SiteOfCareResponseType } from "../siteOfCare/types";
import { AuthContext } from "src/context/authContext";
import DatePicker from "react-datepicker";
import ReactInputMask from "react-input-mask";



const IOveraComps: FC<IOveraCompProps> = ({ control, watch, setValue, getValues }): JSX.Element => {
  const { userData } = useContext(AuthContext);
  const cptCode = watch("procedure_attributes.cpt_code");
  const debounced = () => { }
  const selectedValue = useWatch({
    control,
    name: "prescription_attributes.injection_site",
  });





  const { data: siteOfCareApiResponse } =
    useFetch<SiteOfCareResponseType>(APIEndpoints.GET_SITE_OF_CARE_DROPDOWN);

  useEffect(() => {
    if (siteOfCareApiResponse && siteOfCareApiResponse.data && siteOfCareApiResponse.data.length > 0 && userData) {
      if (!getValues("shipments_attributes.0.practice_id")) {
        const userPractice = siteOfCareApiResponse.data.find((soc) => soc.name === userData.practice_name)
        if (userPractice) {
          setValue("shipments_attributes.0.practice_id", userPractice.id)
          setValue("shipments_attributes.0.practice_name", userPractice.name)
          setValue("shipments_attributes.0.practice_type", userPractice.practice_type || "");
          setValue("shipments_attributes.0.ship_to_street", userPractice.address.street)
          setValue("shipments_attributes.0.ship_to_suite", userPractice.address.suite || "")
          setValue("shipments_attributes.0.ship_to_city", userPractice.address.city)
          setValue("shipments_attributes.0.ship_to_state", userPractice.address.state)
          setValue("shipments_attributes.0.ship_to_zip", userPractice.address.zip)
          !(typeof userPractice.phone === "string" && userPractice.phone !== "N/A") &&
            setValue("shipments_attributes.0.phone", (userPractice.phone as number).toString())
          !(typeof userPractice.fax === "string" && userPractice.fax !== "N/A") &&
            setValue("shipments_attributes.0.fax", userPractice.fax.toString())
          userPractice.email !== "N/A" && setValue("shipments_attributes.0.email", userPractice.email)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteOfCareApiResponse]);
  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <AddCaseProcedureSelect control={control} isIOvera={true} />
        </div>

        <div className="col-lg-6">
          <Controller
            name="prescription_attributes.surgical_date"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <div className="row align-items-center mb-2">
                <label htmlFor="prescription_attributes.surgical_date" className="col-lg-4 col-md-4 col-12 form-label text-end mb-0">
                  Surgical Date
                </label>
                <div className="col-lg-8 col-md-8 col-12">
                  <DatePicker
                    selected={
                      field.value
                        ? parse(field.value, "yyyy-MM-dd", new Date()) // Parse default yyyy-MM-dd to a Date
                        : null
                    }
                    onChange={(date) => {
                      field.onChange(date ? format(date, "yyyy-MM-dd") : ""); // Convert back to yyyy-MM-dd for consistency
                    }}
                    id="prescription_attributes.surgical_date"
                    dateFormat="MM/dd/yyyy" // User-facing format
                    className={`form-control ${error?.message ? "is-invalid" : ""}`}
                    placeholderText="mm/dd/yyyy"
                    customInput={
                      <ReactInputMask mask="99/99/9999">
                        {(inputProps: {}) => <input {...inputProps} />}
                      </ReactInputMask>
                    }
                    minDate={new Date()} // Prevent past dates
                    wrapperClassName="w-100"
                  />
                  {/* <DatePicker
                    selected={field.value ? new Date(field.value) : null} // Convert string to Date
                    onChange={(date) => field.onChange(date?.toISOString().split("T")[0])} // Convert Date to YYYY-MM-DD string
                    className={`form-control ${error?.message ? 'is-invalid' : ''}`}
                    id="prescription_attributes.surgical_date"
                    minDate={new Date()} // Prevent past dates
                    placeholderText="mm/dd/yyyy"
                    dateFormat="MM/dd/yyyy"
                    wrapperClassName="w-100"
                    customInput={<ReactInputMask mask="99/99/9999">{(inputProps: {}) =>
                      <input {...inputProps} />}</ReactInputMask>} /> */}
                  {error && error.message ? (
                    <div dangerouslySetInnerHTML={{ __html: error.message }} className="text-danger text-small mt-2"></div>
                  ) : null}
                </div>
              </div>
            )}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <Controller
            name="prescription_attributes.nerves_qty"
            control={control}
            // defaultValue={0}
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                inputId="prescription_attributes.nerves_qty"
                isRowBased={true}
                isRowInsideAnother={true}
                isRequired={false}
                field={field}
                error={error}
                inputLabel="Nerve Qty"
                inputPlaceholder="Up to 5 nerves"
              />
            )}
          />
        </div>

        <div className="col-lg-6">
          <Controller
            name="prescription_attributes.last_surgical_date"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <div className="row mb-2">
                <label htmlFor="prescription_attributes.last_surgical_date" className="form-label col-lg-4 col-md-4 col-12">
                  Last Surgical Date
                </label>
                <div className="col-lg-8 col-md-8 col-12">
                  {/* <input type="date" className={`form-control ${error && error.message ? 'is-invalid' : ''}`}
                    id="prescription_attributes.last_surgical_date" {...field}
                    max={new Date().toISOString().substring(0, 10)}
                    onKeyDown={(e) => e.preventDefault()} // Prevent manual input

                  /> */}
                  {/* <DatePicker
                    selected={field.value ? new Date(field.value) : null} // Convert string to Date
                    onChange={(date) => field.onChange(date?.toISOString().split("T")[0])} // Convert Date to YYYY-MM-DD string
                    className={`form-control ${error?.message ? 'is-invalid' : ''}`}
                    id="prescription_attributes.last_surgical_date"
                    maxDate={new Date()} // Prevent future dates
                    placeholderText="mm/dd/yyyy"
                    dateFormat="MM/dd/yyyy"
                    wrapperClassName="w-100"
                    customInput={<ReactInputMask mask="99/99/9999">{(inputProps: {}) =>
                      <input {...inputProps} />}</ReactInputMask>} /> */}
                  <DatePicker
                    selected={
                      field.value
                        ? parse(field.value, "yyyy-MM-dd", new Date()) // Parse default yyyy-MM-dd to a Date
                        : null
                    }
                    onChange={(date) => {
                      field.onChange(date ? format(date, "yyyy-MM-dd") : ""); // Convert back to yyyy-MM-dd for consistency
                    }}
                    id="prescription_attributes.last_surgical_date"
                    dateFormat="MM/dd/yyyy" // User-facing format
                    className={`form-control ${error?.message ? "is-invalid" : ""}`}
                    placeholderText="mm/dd/yyyy"
                    customInput={
                      <ReactInputMask mask="99/99/9999">
                        {(inputProps: {}) => <input {...inputProps} />}
                      </ReactInputMask>
                    }
                    maxDate={new Date()} // Prevent future dates
                    wrapperClassName="w-100"
                  />

                  {error && error.message ? (
                    <div dangerouslySetInnerHTML={{ __html: error.message }} className="text-danger text-small mt-2 "></div>
                  ) : null}
                </div>
              </div>
            )}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          {cptCode !== CPT_CODE_TO_HIDE_NERVES && (
            <div className="row">
              <label htmlFor="prescription_attributes.nerves" className="form-label col-lg-4 col-md-4 col-12">
                Nerve Names
              </label>
              <div className="col-lg-8 col-md-8 col-12">
                <Controller
                  name="prescription_attributes.nerves"
                  control={control}
                  defaultValue={[]}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      {/* <Select
                        options={
                          (
                            NERVE_NAMES.map((option) => ({ value: option.value, label: option.label }))
                          ) as unknown as OptionsOrGroups<string, GroupBase<string>>
                        }
                        id="prescription_attributes.nerves"
                        isSearchable={true}
                        isMulti={true}
                        placeholder="Select Nerves (Max 5)..."
                        {...field}
                        components={{
                          // ClearIndicator: undefined,
                          // DropdownIndicator: () => null,
                          MultiValueLabel: (props) => (
                            <div {...props}
                              className="p-1" style={{ fontSize: 12 }}
                              title={NERVE_NAMES.find((item) => item.label === props.data.label)?.label}
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                            >
                              {props.data.label}
                            </div>
                          )
                        }}
                        value={field && field.value &&
                          field.value.map((item) => ({
                            value: item,
                            label: item,
                          })) as unknown as MultiValue<unknown>
                        }
                        onChange={(val) => {
                          if (val.length <= 5) {
                            field.onChange(val.map((item: unknown) => (item as { label: string }).label));
                          }
                        }}
                        onInputChange={debounced}
                        styles={{
                          multiValueRemove: (base) => ({
                            ...base,
                            display: 'block', // Ensure you can remove individual selected values
                          }),
                        }}
                      /> */}
                      <Select
                        options={NERVE_NAMES
                          .filter(option => !(field.value || []).includes(option.label)) // Exclude selected nerves
                          .map(option => ({ value: option.value, label: option.label }))
                        }
                        id="prescription_attributes.nerves"
                        isSearchable={true}
                        isMulti={true}
                        placeholder="Select Nerves (Max 5)..."
                        {...field}
                        components={{
                          MultiValueLabel: (props) => (
                            <div {...props}
                              className="p-1" style={{ fontSize: 12 }}
                              title={NERVE_NAMES.find((item) => item.label === props.data.label)?.label}
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                            >
                              {props.data.label}
                            </div>
                          )
                        }}
                        value={field.value?.map((item) => ({
                          value: item,
                          label: item,
                        })) as unknown as MultiValue<unknown>}
                        onChange={(val) => {
                          field.onChange(val.map((item: unknown) => (item as { label: string }).label))
                        }}
                        onInputChange={debounced}
                        styles={{
                          multiValueRemove: (base) => ({
                            ...base,
                            display: 'block', // Ensure you can remove individual selected values
                          }),
                        }}
                      />


                      {error && error.message ? (
                        <div className="text-danger text-small mt-2">{error.message}</div>
                      ) : null}
                    </>
                  )}
                />
              </div>
            </div>
          )}
        </div>

        <div className="col-lg-6">
          <Controller
            name="prescription_attributes.treatment_date"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              // <HubInputField
              //   inputId="prescription_attributes.treatment_date"
              //   isRowBased={true}
              //   isRowInsideAnother={true}
              //   isRequired={false}
              //   inputLabel="Treatment Date"
              //   inputType="date"
              //   field={field}
              //   error={error}
              // />
              <div className="row mb-2">
                <label htmlFor="prescription_attributes.treatment_date" className="form-label col-lg-4 col-md-4 col-12">
                  Treatment Date
                </label>
                <div className="col-lg-8 col-md-8 col-12">
                  {/* <input type="date" className={`form-control ${error && error.message ? 'is-invalid' : ''}`}
              id="prescription_attributes.treatment_date" 
              {...field}
              max={new Date().toISOString().substring(0, 10)}
              onKeyDown={(e) => e.preventDefault()} // Prevent manual input
            /> */}
                  {/* <DatePicker
                    selected={field.value ? new Date(field.value) : null}
                    onChange={(date) => field.onChange(date)}
                    className={`form-control ${error?.message ? 'is-invalid' : ''}`}
                    id="prescription_attributes.treatment_date"
                    maxDate={new Date()} // Prevent future dates
                    placeholderText="mm/dd/yyyy"
                    dateFormat="MM/dd/yyyy"
                    wrapperClassName="w-100"
                    customInput={<ReactInputMask mask="99/99/9999">{(inputProps: {}) =>
                      <input {...inputProps} />}</ReactInputMask>} /> */}

                  <DatePicker
                    selected={
                      field.value
                        ? parse(field.value, "yyyy-MM-dd", new Date()) // Parse default yyyy-MM-dd to a Date
                        : null
                    }
                    onChange={(date) => {
                      field.onChange(date ? format(date, "yyyy-MM-dd") : ""); // Convert back to yyyy-MM-dd for consistency
                    }}
                    id="prescription_attributes.treatment_date"
                    dateFormat="MM/dd/yyyy" // User-facing format
                    className={`form-control ${error?.message ? "is-invalid" : ""}`}
                    placeholderText="mm/dd/yyyy"
                    customInput={
                      <ReactInputMask mask="99/99/9999">
                        {(inputProps: {}) => <input {...inputProps} />}
                      </ReactInputMask>
                    }
                    maxDate={new Date()} // Prevent future dates
                    wrapperClassName="w-100"
                  />

                  {error && error.message ? (
                    <div dangerouslySetInnerHTML={{ __html: error.message }} className="text-danger text-small mt-2 "></div>
                  ) : null}
                </div>
              </div>
            )}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          {cptCode !== CPT_CODE_TO_HIDE_NERVES && (
            <Controller
              name="prescription_attributes.other_nerves"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId="prescription_attributes.other_nerves"
                  isRowBased={true}
                  isRowInsideAnother={true}
                  isRequired={false}
                  field={field}
                  inputLabel="Additional Nerve(s)"
                  inputPlaceholder="Specify Additional Nerves"
                  error={error}
                />
              )}
            />
          )}
        </div>

        <div className="col-lg-6">
          <Controller
            name="prescription_attributes.injection_site"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                inputId="prescription_attributes.injection_site"
                isRowBased={true}
                isRowInsideAnother={true}
                isRequired={true}
                inputLabel="Treatment Area"
                inputPlaceholder="Specify Area..."
                field={field}
                error={error}
                isDropdown={true}
                isSearchable={false}
                dropdownData={TREATMENT_AREA as unknown as OptionsOrGroups<string, GroupBase<string>>}
              />
            )}
          />


        </div>
        <div className="col-lg-6"></div>
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12">
              <label htmlFor="hidden_label" className="form-label invisible">
                Hidden Label
              </label>
            </div>
            <div className="col-lg-8 col-md-8 col-12 float-end">
              {selectedValue === "other" && (
                <Controller
                  name="prescription_attributes.note"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <HubInputField
                      inputId="prescription_attributes.note"
                      isRowBased={true}
                      isRequired={false}
                      field={field}
                      inputPlaceholder="Specify Other Treatment site ..."
                      error={error}
                    />
                  )}
                />
              )}
            </div>
          </div>

        </div>


      </div>

      <div className="row">
        <div className="col-lg-6">
          <AddCaseDiagnosisCode control={control} watch={watch} setValue={setValue} />
          <div className="row mb-2">
            <div className={"col-lg-4 col-md-4 col-12"}></div>
            <div className={"col-lg-8 col-md-8 fw-bold"}>
              NOTE: Please ensure at least one pain related diagnosis is selected.
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <Controller
            name="prescription_attributes.direction"
            control={control}
            defaultValue="Administer as a single intra-articular injection for extended release"
            render={({ field: { value } }) => (
              <div className="row mb-3">
                <div className="col-lg-4 col-md-4 col-12">
                  <label className={"form-label"}>
                    Indications for Use
                  </label>
                </div>
                <div className="col-lg-8 col-md-8 col-12 overflow-y-auto" style={{ maxHeight: '90px' }}><p>The iovera° system is used to destroy tissue during surgical procedures by applying freezing cold. It can also be used to produce lesions in peripheral nervous
                  tissue by the application of cold to the selected site for the blocking of pain. It is also indicated for the relief of pain and symptoms associated with osteoarthritis of the knee for
                  up to 90 days. The iovera° system is not indicated for treatment of central nervous system tissue.</p>
                  <p>When stimulation compatible components are used, the iovera° system can also facilitate target nerve location by conducting electrical nerve stimulation from a compatible
                    third-party nerve stimulator.</p></div>
              </div>
            )}
          />


        </div>


      </div>
      <div className="row">
        <div className="col-lg-6">
          <Controller
            name="shipments_attributes.0.practice_id"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                inputId="shipments_attributes.0.practice_id"
                inputLabel="Site of Care"
                isRequired={true}
                isRowBased={true}
                isRowInsideAnother={true}
                error={error}
                field={field}
                isDropdown={true}
                isSearchable={false}
                dropdownData={
                  (siteOfCareApiResponse && siteOfCareApiResponse.data && siteOfCareApiResponse.data.length > 0 ?
                    [{ value: 0, label: 'New...' }, ...siteOfCareApiResponse.data.map((siteOfCare) => ({ value: siteOfCare.id, label: siteOfCare.name }))]
                    : [{ value: 0, label: 'New...' }]) as unknown as OptionsOrGroups<string, GroupBase<string>>
                }
              />
            )}
          />
        </div>
        <div className="col-lg-6">

        </div>
      </div>

      <SiteOfCareComp control={control} watch={watch} setValue={setValue} getValues={getValues} socListing={siteOfCareApiResponse} />

      <div className="row">
        <Controller
          name="prescription_attributes.is_daw"
          control={control}
          defaultValue={format(new Date(), 'MM/dd/yyyy')}
          render={({ field: { value } }) => (
            <div className="form-check mt-3">
              <input
                className="form-check-input" type="checkbox"
                id="flexCheckIndeterminateDisabled"
                disabled checked
              />
              <label className="form-check-label" htmlFor="flexCheckIndeterminateDisabled">
                Dispense as Written (written date will be {value})
              </label>
            </div>
          )}
        />
      </div>
    </>
  )
}

export default IOveraComps;
