import { FC, useCallback, useEffect, useState } from "react";
import { SeriesMapDataOptions } from "highcharts";

import HighchartsMapComponent from "src/components/highChartMapComponent";

import { MAP_CHART } from "src/constants/highCharts";
import { wrapperFunction } from "src/helpers";
import { GeographicalStatisticsPropsType } from "./types";
const mapData = require("@highcharts/map-collection/countries/us/us-all.geo.json");



const GeographicalStatistics: FC<GeographicalStatisticsPropsType> = ({
  GeographicalStatisticsListType,
}): JSX.Element => {
  const [chartOptions, setChartOptions] = useState(MAP_CHART);

  const getMinAndMax = () => {
    if (GeographicalStatisticsListType) {
      let max = 1;
      GeographicalStatisticsListType.forEach((item) => {
        if (max < item.case_count) max = item.case_count;
      });
      return [0, max];
    }

    return [0, 1];
  };

  const populateGeographicalChart = useCallback(() => {
    const [min, max] = getMinAndMax();

    const mapOptions = { ...chartOptions };
    mapOptions.series = [
      {
        type: "map",
        name: "Case Volume",
        color: "#beb697",
        dataLabels: {
          enabled: false,
          format: "{point.name}",
        },
        tooltip: { pointFormat: "Cases: {point.value}" },

        data: wrapperFunction(
          GeographicalStatisticsListType
        ) as unknown as SeriesMapDataOptions[],
      },
    ];
    mapOptions.colorAxis = {
      min,
      max: Math.ceil(max / 10) * 10,
      minColor: "#EEEEFF",
      maxColor: "#000022",
      stops: [
        [0, "#EFEFFF"],
        [(min + max / 2) / max, "#4444FF"],
        [1, "#000022"],
      ],
    };
    setChartOptions(mapOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GeographicalStatisticsListType]);

  const splitArray = (GeographicalStatisticsListType: any[], index: number) => {
    const numberOfChunks = Math.ceil(
      GeographicalStatisticsListType.length / 29
    );

    const splitArray = [...Array(numberOfChunks)].map((value, index) => {
      return GeographicalStatisticsListType.slice(index * 29, (index + 1) * 29);
    });
    return splitArray[index];
  };

  // const {destroySession} = useContext(AuthContext)
  // const { showToast } = useContext(ToastContext);
  // const { setLoader, hideLoader } = useContext(LoaderContext);
  // const navigate = useNavigate();

  // const {
  //   data: downloadCaseData, error: downloadCaseError, mutate: downloadRequestForm, removeErrorAndData: clearRequestFileData
  // } = useFetch<DownloadPDFResponseTypeApi>(APIEndpoints.MANUFACTURE_STATISTICS_PDF_DOWNLOAD);

  // useEffect(() => {
  //   if (downloadCaseError) {
  //     if (typeof downloadCaseError === 'string') {
  //       showToast([downloadCaseError], ToastTypes.ERROR);
  //       hideLoader();
  //     } else {
  //       const { code, error } = downloadCaseError as unknown as DownloadZipErrorType;
  //       hideLoader();
  //       if (code === 401) {
  //         destroySession();
  //         showToast([`You need to sign up or sign in to continue`], ToastTypes.ERROR);
  //         navigate(`/users/sign_in`);
  //       }
  //       showToast([error], ToastTypes.ERROR);
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [downloadCaseError]);

  // useEffect(() => {
  //   if (downloadCaseData) {
  //     const { data } = downloadCaseData;
  //     downloadPdfFile(data, `stats_request_reports.pdf`);
  //     hideLoader();
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [downloadCaseData]);

  // const downloadFileByMixingID = (value:string) => {
  //   // console.log("downloadFileByMixingID",value)
  //   // if (downloadCaseData || downloadCaseError) clearRequestFileData();
  //   setLoader();
  //   downloadRequestForm({}, {Id:1034},{type:'request',ref_type:'Case' });
  // };

  useEffect(() => {
    populateGeographicalChart();
  }, [populateGeographicalChart]);

  return (
    <div className="table-responsive card p-0">
      <div className="card-header">
        <h4 className="text-center m-0">Geographical Statistics</h4>
      </div>
      <div className="card-body p-0">
        <div className="container-fluid">
          <div className="row">
            <div style={{ maxHeight: 400 }} className="col-12 col-sm-12 col-md-4 overflow-auto px-0">
              <div className="row p-0 g-0">
                <div className="col-6">
                  <table className="table text-center table-sm">
                    <thead className="table-light sticky-top">
                      <tr>
                        <th className="py-2" scope="col">
                          State
                        </th>
                        <th className="py-2" scope="col">
                          Cases
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {GeographicalStatisticsListType &&
                        splitArray(GeographicalStatisticsListType, 0).map(
                          (item, index) => (
                            <tr key={index}>
                              <td>
                                <a className="fs-6 "  >
                                  {item?.state}{" "}
                                </a>
                              </td>
                              <td>
                                {item.case_count.toString() === "NaN%"
                                  ? 0
                                  : item.case_count}
                                {/* {item.case_percentage === "NaN%"
                                  ? " (0.0%)"
                                  : ` (${item.case_percentage})`} */}
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
                <div className="col-6">
                  <table className="table text-center table-sm ">
                    <thead className="table-light sticky-top">
                      <tr>
                        <th className="py-2" scope="col">
                          State
                        </th>
                        <th className="py-2" scope="col">
                          Cases
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {GeographicalStatisticsListType &&
                        splitArray(GeographicalStatisticsListType, 1).map(
                          (item, index) => (
                            <tr key={index}>
                              <td>
                                <a className="fs-6" >
                                  {item?.state}
                                </a>
                              </td>
                              <td>
                                {item.case_count.toString() === "NaN%"
                                  ? 0
                                  : item.case_count}
                                {/* {item.case_percentage === "NaN%"
                                  ? " (0.0%)"
                                  : ` (${item.case_percentage})`} */}
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-8"><HighchartsMapComponent mapData={mapData} options={chartOptions}/></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeographicalStatistics;
