import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select,{ GroupBase, OptionsOrGroups, PropsValue, SingleValue } from "react-select";

import FooterText from "../../components/footerText";
import AuthSubHeader from "src/components/authSubHeader";

import useFetch from "src/hooks/useFetch";

import { APIEndpoints } from "src/types/apiTypes";
import { PrecertAPIResponse } from "../precert/types";

const STATE_UPDATE_PA = [
  {
    value: 'approved',
    label: 'Approved',
    change_note: 'Precert Marked Approved by office.',
  }, 
  {
    value: 'denied',
    label: 'Denied',
    change_note: 'Precert Marked Denied by office.',
  },
   {
    value: 'cancelled',
    label: 'Cancelled',
    change_note: 'Precert Cancelled by office.',
  }
];



const UpdatePrecert: FC = (): JSX.Element => {
  const [cancelReason, setCancelReason] = useState<string>('approved');

  const { caseId, precertsId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search); // Parse query params





  const { mutate: fetchPrecertMutate, data: fetchPrecertResponse } =
    useFetch<PrecertAPIResponse>(APIEndpoints.UPDATE_PRECERT, {}, {}, false);

  useEffect(() => {
    if (fetchPrecertResponse) navigate(`/cases`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPrecertResponse]);

  const UpdatePrecertButton = () => {
    const pathParams = {
      case_id: caseId,
      id: precertsId
    }
    const queryParams = {
      status: cancelReason,
      case_id: caseId,
      change_note: STATE_UPDATE_PA.find((item) => item.value === cancelReason)?.change_note,
    }

    fetchPrecertMutate({}, pathParams, queryParams)
  }




  const onChangeSelect = (value: SingleValue<string>) => {
    setCancelReason((value as unknown as { value: string; label: string; }).value);
  };

  const getValue = () => {
    const data = STATE_UPDATE_PA.find((option) => option.value === cancelReason);
    if (data) return data as unknown as PropsValue<string>;
  };

  return (
    <div className={"container-fluid body-bg overflow-auto d-flex flex-column flex-fill"}>
      <div className="container">
        <AuthSubHeader/>
        <div className="card">
          <div className="card-header">
            <p className="fs-2 text-white m-0">
              Update PA Status for Case {caseId} {'(' + queryParams.get('patient') + ")"}<p>(Insurance Name: {queryParams.get('insuranceName')})</p>
            </p>
          </div>

          <div className="card-body">
            <p className="text-center"> if you've received updated information or wish to cancel your PA, select a
              status from the dropdown below and the system will update this case for you. </p>

            <div className="row align-items-center">
              <div className="col-12 col-md-10">
                <Select
                  options={STATE_UPDATE_PA as unknown as OptionsOrGroups<string, GroupBase<string>>}
                  id="updatePA"
                  isSearchable={false}
                  noOptionsMessage={() => "Select Option..."}
                  value={getValue()}
                  onChange={(value) => onChangeSelect(value)}
                />
              </div>

              <div className="col-12 col-md-2">
                <button
                  type="button"
                  className="btn btn-success rounded-0 px-4 mt-2 mt-md-0"
                  onClick={UpdatePrecertButton}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3"><FooterText footerType="black"/></div>
      </div>
    </div>
  );
};

export default UpdatePrecert;
