import Highcharts from "highcharts";
const mapData = require("@highcharts/map-collection/countries/us/custom/us-all-territories.topo.json");
 
export const BAR_CHART: Highcharts.Options = {
  chart: {
    type: "bar",
    height: 150,
  },

  title: {
    text: "",
    align: "left",
  },

  xAxis: {
    title: {
      text: null,
    },
    categories: ["Pending Cases", "Investigated Cases"],
    gridLineWidth: 0,
    lineWidth: 1,
  },

  yAxis: {
    min: 0,
    title: {
      text: "",
    },
    labels: {
      enabled: false,
    },
    gridLineWidth: 1,
  },

  plotOptions: {
    bar: {
      borderRadius: "0",
      dataLabels: {
        enabled: false,
      },
      groupPadding: 0.1,
    },
  },

  colors: ["rgb(124, 181, 236)"],

  credits: {
    enabled: false,
  },

  legend: {
    enabled: false,
  },

  accessibility: {
    enabled: false,
  },
};
export const BAR_CHART_MEDICAL_AUTHS: Highcharts.Options = {
  chart: {
    type: "bar",
    height: 150,
  },

  title: {
    text: "",
    align: "left",
  },

  xAxis: {
    title: {
      text: null,
    },
    gridLineWidth: 0,
    lineWidth: 1,
  },

  yAxis: {
    min: 0,
    title: {
      text: "",
    },
    labels: {
      enabled: false,
    },
    gridLineWidth: 1,
  },

  plotOptions: {
    bar: {
      borderRadius: "0",
      dataLabels: {
        enabled: false,
      },
      groupPadding: 0.1,
    },
  },

  colors: ["rgb(124, 181, 236)"],

  credits: {
    enabled: false,
  },

  legend: {
    enabled: false,
  },

  accessibility: {
    enabled: false,
  },
};
export const BAR_CHART_PRIOR_AUTHS: Highcharts.Options = {
  chart: {
    type: "bar",
    height: 150,
  },

  title: {
    text: "",
    align: "left",
  },

  xAxis: {
    // categories: ["Submitted to Plan", "Pending Transcription"," ","Approved","Denied"],
    title: {
      text: null,
    },
    gridLineWidth: 0,
    lineWidth: 1,
  },

  yAxis: {
    min: 0,
    title: {
      text: "",
    },
    labels: {
      enabled: false,
    },
    gridLineWidth: 1,
  },

  plotOptions: {
    bar: {
      borderRadius: "0",
      dataLabels: {
        enabled: false,
      },
      groupPadding: 0.1,
    },
  },

  colors: ["rgb(124, 181, 236)"],

  credits: {
    enabled: false,
  },

  legend: {
    enabled: false,
  },

  accessibility: {
    enabled: false,
  },
};
export const BAR_CHART_PATIENT_DIRECT_PURCHASES: Highcharts.Options = {
  chart: {
    type: "bar",
    height: 150,
  },

  title: {
    text: "",
    align: "left",
  },

  xAxis: {
    // categories: ["pdps"],
    title: {
      text: null,
    },
    gridLineWidth: 0,
    lineWidth: 1,
  },

  yAxis: {
    min: 0,
    title: {
      text: "",
    },
    max:50,
    labels: {
      enabled: false,
    },
    gridLineWidth: 1,
  },

  plotOptions: {
    bar: {
      borderRadius: "0",
      dataLabels: {
        enabled: false,
      },
      groupPadding: 0.1,
    },
  },

  colors: ["rgb(124, 181, 236)"],

  credits: {
    enabled: false,
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
    x: -40,
    y: 80,
    floating: true,
    borderWidth: 1,
    backgroundColor:
       '#FFFFFF',
    shadow: true
  },
  accessibility: {
    enabled: false,
  },
};

export const LINE_CHART: Highcharts.Options = {
  title: {
    text: "",
    align: "left",
  },
  chart:{
    type:'area'
  },
  yAxis: {
    title: {
      text: "",
    },
    accessibility:{
      rangeDescription:"Range:  22 Jan to 13 Feb"
    }
  },
  tooltip: {
    shared: false,
    split: true,
    // shared: true,
    // useHTML: true,
    // formatter: function () {
    //   let tooltipContent = `<b>${this.x}</b><br>`;
      
    //   // Loop through all the points in the tooltip and display their values
    //   this.points?.forEach(point => {
    //     tooltipContent += `${point.series.name}: ${point.y}<br>`;
    //   });

    //   // Check if the values of the two series are the same at this x value
    //   const points = this.points;
    //   if (points && points.length > 0) {
    //     if (points[0].y === points[1].y) {
    //       tooltipContent += `<b>Both lines have the same value: ${points[0].y}</b><br>`;
    //     }
    //   }

    //   return tooltipContent;
    // },
    // xDateFormat: '%A, %b %e, %Y', // e.g., "Friday, Dec 13, 2024",
  },
  xAxis: {
    // minRange: 2,
    //     type: 'datetime',
    //     labels: {
    //         formatter: function () {
    //             // Use Highcharts.dateFormat to customize the date format
    //             return Highcharts.dateFormat('%e %b ',Number(this.value)); // e.g., "13 Dec 2024"
    //         }
    //     }
  },
  // tooltip: {
  //     xDateFormat: '%A, %b %e, %Y' // e.g., "Friday, Dec 13, 2024"
  // },
  credits: {
    enabled: false
  },
  legend: {
    layout: 'horizontal',
    align: "center",
    verticalAlign: 'bottom',
  },
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      pointStart: 0,
    },
  },
  series: [],
  responsive: {
    rules: [{
      condition: {
        maxWidth: 500,
      },
      chartOptions: {
        legend: {
          layout: "horizontal",
          align: "center",
          verticalAlign: "bottom",
        },
      },
    }],
  },
  accessibility: {
    enabled: false,
  },
};

export const COLUMN_CHART: Highcharts.Options = {
  // Highcharts.chart('container', {

  title: {
    text: "",
    align: "left",
  },
  chart:{
    type:'column'
  },

  // subtitle: {
  //   text: 'By Job Category. Source: <a href="https://irecusa.org/programs/solar-jobs-census/" target="_blank">IREC</a>.',
  //   align: "left",
  // },

  yAxis: {
    title: {
      text: "",
    },
    accessibility:{
        rangeDescription:"Range:  22Jan to 13Feb"
    }
  },

  credits: {
    enabled: false
  },

  legend: {
    layout: 'horizontal',
    align: "center",
    verticalAlign: 'bottom',
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      pointStart: 0,
    },
  },
  series: 
  [
    {
      type: "line",
      name: "Received",
      data: [
        43934, 48656, 65165, 81827, 112143, 142383, 171533, 165174, 155157,
        161454, 154610,
      ],
    },
    {
      type: "line",
      name: "Completed",
      data: [
        24916, 37941, 29742, 29851, 32490, 30282, 38121, 36885, 33726, 34243,
        31050,
      ],
    },
    {
      type: "line",
      name: "Sameday Completed",
      data: [
        11744, 30000, 16005, 19771, 20185, 24377, 32147, 30912, 29243, 29213,
        25663,
      ],
    },
    {
      type: "line",
      name: "PA Required",
      data: [
        11211,
        11299,
        11800,
        13456,
        14534,
        16785,
        90009,
        12323,
        11164,
        11218,
        10077,
      ],
    }
  ],

  responsive: {
    rules: [{
      condition: {
        maxWidth: 500,
      },
      chartOptions: {
        legend: {
          layout: "horizontal",
          align: "center",
          verticalAlign: "bottom",
        },
      },
    }],
  },

  accessibility: {
    enabled: false,
  },

  //});
};
export const PIE_CHART_OPTIONS: Highcharts.Options = {
  chart: {
    type: "pie",
  },
  title:{
    text:""
  },
  tooltip: {
    valueSuffix: "",
  },
  credits: {
    enabled: false
  },
  // plotOptions: {
  //   pie: {
  //     allowPointSelect: true,
  //     cursor: "pointer",
  //     showInLegend: true,
  //     dataLabels: {
  //       enabled: false,
  //       format: '<b>{point.name}</b>: {point.percentage:.1f}%',
  //       style: {
  //         fontWeight: 'normal',
  //         textOverflow: 'clip'
  //       },
  //       connectorPadding: 1,
  //       connectorWidth: 1,
  //       distance: 20,
  //     },
  //   },
  // },
  plotOptions: {
    series: {
        allowPointSelect: true,
        showInLegend:false,
        cursor: 'pointer',
        dataLabels: [{
            enabled: true,
            format: "<b>{point.title}: {point.percentage:.1f}%</b>",

            // distance: 20
        },
         {
            enabled: true,
            // distance: -40,
          format: "<b>{point.title}: {point.percentage:.1f}%</b>",
            //format: "<b>{point.percentage:.1f}%</b>",

            style: {
                fontSize: '1.2em',
                textOutline: 'none',
                opacity: 0.7
            },
            filter: {
                operator: '<',
                property: 'percentage',
                value: 0.00001
            }
        }
      ]
    }
  },
  series: [{
    type: "pie",
    name: "Percentage",
    // colorByPoint: true,
    data: [
      {
        "name": "",
        "y": 597
      },
      {
        "name": "MOBILE",
        "y": 10
      },
      {
        "name": "JUBILLE",
        "y": 10
      },
      {
        "name": "PERX1",
        "y": 10
      },
      {
        "name": "ABC TEST",
        "y": 8
      },
      {
        "name": "PAPER",
        "y": 7
      },
      {
        "name": "BLUE CROSS",
        "y": 6
      },
      {
        "name": "MELVIN SHAW",
        "y": 5
      },
      {
        "name": "MED-INS1",
        "y": 5
      },
      {
        "name": "Hea CS",
        "y": 5
      }
    ]
  }],
  accessibility: {
    enabled: false,
  },
};
export const MAP_CHART: Highcharts.Options = {
  chart: {
    map: mapData,
  },

  title: {
    text: "",
  },

  subtitle: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  mapNavigation: {
    enabled: false,
    buttonOptions: {
      verticalAlign: "bottom",
    },
  },

  colorAxis: {
    min: 0,
    max: 30,
    //type: 'logarithmic',
    minColor: "#EEEEFF",
    maxColor: "#000022",
    stops: [
      [0, "#EFEFFF"],
      [0.67, "#4444FF"],
      [1, "#000022"],
    ],
  },
  accessibility: {
    enabled: false,
  },
};
