import { FC, useContext, useEffect, useState } from "react";

import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { ToastTypes } from "src/types";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "src/context/toastContext";
import { RequestDateOfTreatmentProps, RequestTreatmentNotTreatingApiResponse } from "../requestDateOfTreatment/types";
import Select, { GroupBase, OptionsOrGroups, PropsValue, SingleValue } from "react-select";
import { STATUS_REASON } from "src/constants/cases";
import { PrecertAPIResponse } from "src/pages/precert/types";
import { format } from "date-fns";



const TreatmentDecision: FC<RequestDateOfTreatmentProps> = ({ isModalVisible, caseId, hideModal, prescriptionId, refreshListing, kase }): JSX.Element => {
  const [treatmentDate, changeTreatmentDate] = useState(format(new Date().setDate(new Date().getDate()), "yyyy-MM-dd"),);
  const [isInvalid, setInvalidStatus] = useState<boolean>(false);
  const [cancelReason, setCancelReason] = useState<string>('');
  const [cancelInputReason, setCancelinputReason] = useState<string>('');
  const [charLimitExceeded, setCharLimitExceeded] = useState<boolean>(false);

  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();



  const resetAndhideModal = () => {

    changeTreatmentDate("");
    setCancelReason('')
    setCancelinputReason('')
    hideModal();
  };

  const saveTreatmentReason = () => {
    if (cancelReason !== '') {
      const requestBody = {
        prescription: {
          bentype: "non",
          status_reason: cancelReason === 'Other' ? cancelInputReason : cancelReason
        }
      };
      const queryParam = {
        buy_and_bill:false
      }
      updateRequestTreatment(requestBody, { caseId, prescriptionId }, queryParam);
    } else {
      showToast(['Kindly Select Reason First'], ToastTypes.WARNING)
    }
  };

  const buttonTransferRXClicked = () => {
    const confirmDeny = window.confirm("Are you sure you want to transfer this prescription  to the speciality pharmacy for precessing?");
    if (confirmDeny) {
      transferRxByCaseId({}, { case_id: caseId })
    }
  }

  const { loading: transferRxLoading, data: transferRxData, mutate: transferRxByCaseId } =
    useFetch(APIEndpoints.TRANSFER_RX, {}, { case_id: caseId });

  useEffect(() => {
    if (transferRxData) {
      const { message } = transferRxData as unknown as { code: number; message: string; };
      showToast([message], ToastTypes.SUCCESS);
      hideModal();
      refreshListing()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferRxData]);

  useEffect(() => {
    if (transferRxLoading)
      console.log("")

  }, [transferRxLoading])

  const { mutate: updateCaseMutate, data: updateCaseResponse } =
    useFetch<PrecertAPIResponse>(APIEndpoints.UPDATE_CASES, {}, {}, false);


  const buyAndBillButton = (caseID: number) => {
    const queryParam = {
      buy_and_bill:true,
      investigated_at:treatmentDate,
    }
    updateRequestTreatment({}, { caseId, prescriptionId }, queryParam);
  }

  useEffect(() => {
    if (updateCaseResponse) {
      navigate(`/cases/detail/${caseId}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCaseResponse]);


  const { data: requestTreatmentData, mutate: updateRequestTreatment, error: treatmentUpdateError } =
    useFetch<RequestTreatmentNotTreatingApiResponse>(APIEndpoints.REQUEST_TREATMENT_NOT_TREATING);

  useEffect(() => {
    if (treatmentUpdateError) {
      resetAndhideModal()
    }
    if (requestTreatmentData) {
      const { code } = requestTreatmentData;
      if (code === 200) {
        showToast([`Request Treatment Scheduled Successfully`], ToastTypes.SUCCESS);
        hideModal()
        setCancelReason('')
        setCancelinputReason('')
        refreshListing()
        resetAndhideModal()

      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestTreatmentData, treatmentUpdateError]);

  const onChangeSelect = (value: SingleValue<string>) => {
    setCancelReason((value as unknown as { value: string; label: string; }).value);
  };
  const getValue = () => {
    if (!cancelReason) return null; // Ensure the select resets when empty
    const data = STATUS_REASON.find((option) => option.value === cancelReason);
    return data ? (data as unknown as PropsValue<string>) : null;
  };



  return (
    <div
      className={`modal fade ${isModalVisible ? 'show show-modal' : ''}`}
      id="exampleModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
      onClick={resetAndhideModal} // Close modal when clicking outside
    >
      <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title fs-3 text-primary-grey">
              <i>Treatment Decision for Case {caseId}</i>
            </p>
            <button type="button" className="btn-close" aria-label="Close" onClick={resetAndhideModal}></button>
          </div>

          <div className="modal-body">
            <p className="text-center mb-3"> Please specify a treatment date and select an option... </p>

            <div className="row mx-3 align-items-center flex-wrap">
              <div className="col-12 col-md-7 pb-2">
                <input
                  type="date"
                  value={treatmentDate}
                  onChange={(e) => {
                    changeTreatmentDate(e.target.value)
                    if (e.target.value.length > 0) setInvalidStatus(false);
                  }}
                  name="treatmentDate"
                  id="treatmentDate"
                  className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
                />
                {isInvalid ? (
                  <div className="invalid-feedback">
                    Please select a date first
                  </div>
                ) : null}
              </div>
              <div className="col-12 col-md-5 d-flex justify-content-end gap-1">
                {kase && (kase.drug_name !== "iovera°")
                  &&
                  <button
                    className="btn btn-success"
                    onClick={() =>
                      buttonTransferRXClicked()
                    }
                  >
                    <i className="fas fa-prescription me-2"></i> Xfer to SP
                  </button>
                }
                <button
                  className="btn btn-success"
                  onClick={() => buyAndBillButton(caseId)}
                >
                  <i className="far fa-hospital me-2"></i> Buy and Bill
                </button>
              </div>
            </div>
            <p className="text-center mb-3 mt-1"><b>OR</b> Please specify reason for non-treatment ... </p>

            <div className="row mx-3 align-items-center">
             
              {/* Show Dropdown only when 'Other' is NOT selected OR drug is NOT 'iovera' */}
              {!(cancelReason === "Other") && 
              (
                <>
                  <div className="col-4 col-md-2 text-end p-0">
                    <label htmlFor="treatmentDate"> Status Reason </label>
                  </div>
                  <div className="col-8 col-md-7">
                    <Select
                      options={STATUS_REASON as unknown as OptionsOrGroups<string, GroupBase<string>>}
                      id="updatePA"
                      isSearchable={false}
                      noOptionsMessage={() => "Select a reason ..."}
                      value={getValue()}
                      onChange={(value) => onChangeSelect(value)}
                      isClearable={true}
                    />
                  </div>
                </>
              )}

              {/* Show Input Field ONLY when 'Other' is selected AND drug is 'iovera' */}
              {cancelReason === "Other" && (
                <>
                  <div className="col-3 text-end p-0 mt-1">
                    <label htmlFor="treatmentDate"> Enter Other Reason </label>
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      className={`form-control mt-2 ${charLimitExceeded ? 'is-invalid' : ''}`}
                      placeholder="Enter custom reason"
                      value={cancelInputReason}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 100) {
                          setCancelinputReason(value);
                          setCharLimitExceeded(false); // Reset error if within limit
                        } else {
                          setCharLimitExceeded(true);
                        }
                      }}
                      maxLength={101} // Prevents typing more than 101 characters
                    />
                    {charLimitExceeded && (
                      <div className="invalid-feedback">
                        Character limit exceeded! Maximum 100 characters allowed.
                      </div>
                    )}
                  </div>
                </>
              )}
              <div className="col-12 col-md-3 text-end mt-2 mt-md-0">
                <button
                  className="btn btn-primary m-1"
                  onClick={() => saveTreatmentReason()
                  }
                >
                  Not Treating
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatmentDecision;
