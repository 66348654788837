import * as yup from "yup";

import { EMAIL_REGEX } from "../constants";

const spaceCheckYupValidation = (val?: string) => val ? (!/^\s*$/.test(val)) : false

export const NewEnrollmentSchema = yup.object({
  npi: yup
    .string()
    .when({
      is: (val:string) => val && val.length > 0,
      then: (schema) =>
        schema
          .test("containAllDigits", "NPI should only contain digits", (val) => val ? (/^\d+$/.test(val)) : false)
          .min(10, "Invalid NPI")
          .max(10, "Invalid NPI")
          // .test('range', 'NPI Invalid', (val) => {
          //   const numberedVal = Number(val)
          //   if (!isNaN(numberedVal)) {
          //     if (numberedVal >= 1000000000 && numberedVal <= 9999999999) return true;
          //   }
          //   return false
          // })
          ,
    }),

  enrollment_practices_attributes: yup
    .array()
    .of(
      yup.object({
        practice_name: yup
          .string()
          .required(`Practice Name is required`)
          .max(120, `Practice Name should only be 120 characters long`),
        npi: yup
          .string()
          .required(`NPI is required`)
          .test("containAllDigits", "NPI should only contain digits", (val) => val ? (/^\d+$/.test(val)) : false)
          .min(10, `NPI Invalid`)
          .max(10, `NPI Invalid`)
          // .test('range', 'NPI Invalid', (val) => {
          //   const numberedVal = Number(val)
          //   if (!isNaN(numberedVal)) {
          //     if (numberedVal >= 1000000000 && numberedVal <= 9999999999) return true;
          //   }
          //   return false
          // }),
          ,
          office_contact: yup
          .string(),
          
          //.max(120, `Practice Name should only be 120 characters long`),
        tax_id: yup
          .string()
          .max(9, `Tax id Invalid`),
        phone_number: yup
          .string()
          .required(`Phone Number is required`)
          .min(10, `Phone Number Invalid`)
          .max(10, `Phone Number Invalid`)
          .test('range', 'Phone Number Invalid', (val) => {
            const numberedVal = Number(val)
            if (!isNaN(numberedVal)) {
              if (numberedVal > 2000000000 && numberedVal < 9999999999) return true;
            }
            return false;
          })
          ,
        fax_number: yup
          .string()
          .required(`Fax Number is required`)
          .min(10, `Fax Number Invalid`)
          .max(10, `Fax Number Invalid`)
          .test('range', 'Fax Number Invalid', (val) => {
            const numberedVal = Number(val)
            if (!isNaN(numberedVal)) {
              if (numberedVal > 2000000000 && numberedVal < 9999999999) return true;
            }
            return false;
          }),
        street: yup
          .string()
          .required(`Street is required`)
          .max(255, 'Street should not be longer than 255 characters')
          .test("whiteSpaceRemoval", "Street should not contain only spaces", (val) => spaceCheckYupValidation(val)),
        suite: yup
          .string()
          .max(255, 'Suite should not be longer than 255 characters')
          .when({
            is: (val?: string) => val && val.length > 0,
            then: (schema) =>
              schema
                .test("whiteSpaceRemoval", "Suite should not contain only spaces", (val) => spaceCheckYupValidation(val)),
          }),
        city: yup
          .string()
          .required(`City is required`)
          .max(255, 'City should not be longer than 255 characters'),
        state: yup
          .string()
          .required(`State is required`),
        zip: yup
          .string()
          .required(`Zip code is required`)
          .max(10, `Zip code Invalid`)
          .test("containAllDigits", "Zip code should only contain digits", (val) => val ? (/^\d+$/.test(val)) : false),
        timezone: yup.string().required('Timezone is required'),
      }),
    )
    .required(`Practice data is required`),

  enrollment_contacts_attributes: yup
    .array().of(
      yup.object({
        firstname: yup
          .string()
          .required(`First Name is required`)
          .max(35, `First name should not be longer than 35 characters`),
        lastname: yup
          .string()
          .required(`Last Name is required`)
          .max(35, `Middle name should not be longer than 35 characters`),
        middlename: yup
          .string()
          .max(35, `Last name should not be longer than 35 characters`),
        email: yup
          .string()
          .required(`Email is required`)
          .max(150, 'Email should not be longer than 150 characters')
          .test('EmailVerification', `Email Invalid`, (val) => (EMAIL_REGEX.test(val))),
      })
    )
    .required(`User data is required`),

  enrollment_doctors_attributes: yup
    .array()
    .of(
      yup.object({
        npi: yup
          .string()
          .required(`NPI is required`)
          .test("containAllDigits", "NPI should only contain digits", (val) => val ? (/^\d+$/.test(val)) : false)
          .min(10, `Npi Invalid`)
          .max(10, `Npi Invalid`),
        tax_id: yup
          .string()
          .max(9, `tax ID Invalid`),
        firstname: yup
          .string()
          .required(`First Name is required`)
          .max(35, `Name should only be 35 characters long`),
        lastname: yup
          .string()
          .required(`Last Name is required`)
          .max(35, `Name should only be 35 characters long`),
        middlename: yup
          .string()
          .max(35, `Name should only be 35 characters long`),
        ref_taxonomy_id: yup
          .string()
          .required(`Taxonomy of our specific Provider is required`),
        phone_number: yup
          .string()
          .required(`Phone Number is required`)
          .min(10, `Phone Number Invalid`)
          .max(10, `Phone Number Invalid`)
          .test('range', 'Phone Number Invalid', (val) => {
            const numberedVal = Number(val)
            if (!isNaN(numberedVal)) {
              if (numberedVal > 2000000000 && numberedVal < 9999999999) return true;
            }
            return false;
          }),
        fax_number: yup
          .string()
          .required(`Fax Number is required`)
          .min(10, `Fax Number Invalid`)
          .max(10, `Fax Number Invalid`)
          .test('range', 'Fax Number Invalid', (val) => {
            const numberedVal = Number(val)
            if (!isNaN(numberedVal)) {
              if (numberedVal > 2000000000 && numberedVal < 9999999999) return true;
            }
            return false;
          }),
        dea: yup
          .string()
          .when({
            is: (val:string) => val && val.length > 0,
            then: (schema) =>
              schema
                .min(9, "DEA Invalid")
                .max(9, "DEA Invalid")
                .test('CheckForInvalidChars', 'DEA Invalid', (val) => (val ? /^[a-z0-9]+$/i.test(val) : true)),
          }),
        sln: yup
          .string()
          .when({
            is: (val?: string) => val && val.length > 0,
            then: (schema) =>
              schema
                .test("whiteSpaceRemoval", "SLN should not contain only spaces", (val) => spaceCheckYupValidation(val)),
          }),
        ptan: yup
          .string()
          .when({
            is: (val:string) => val && val.length > 0,
            then: (schema) =>
              schema
                .test('CheckForInvalidChars', 'PTAN Invalid', (val) => (val ? /^[a-z0-9]+$/i.test(val) : true)),
          }),
        email: yup
          .string()
          .max(150, 'Email address can not be longer than 150 characters')
          .test('EmailVerification', `Provided value should be a valid email`, (val) => (val ? EMAIL_REGEX.test(val) : true)),
      }),
    )
    .required(`Prescriber data is required`),
});
