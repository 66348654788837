import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";

import ContactAndSafetyInfo from "../contactAndSafetyInfo";
import { TextAlignProps } from "../contactAndSafetyInfo/types";
import { AuthContext } from "src/context/authContext";

import { HCPUserSubHeaderProps } from "src/types/users";
import { sub } from "date-fns";



const AuthSubHeader: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { sub_header, isUserAuthorized ,isManufacturer} = useContext(AuthContext);
  // console.log("Sub header......", sub_header)
  return (
    <div className="row pt-2">
      {isUserAuthorized ?
        <>

          {/* <div className="col-12 col-md-4">
            <p className="mb-0 fw-bold">
              Sales Rep:{' '} {sub_header && sub_header.length > 0 ? (sub_header as HCPUserSubHeaderProps[])[0].Rep : ''}
            </p>
            <p className="mb-0">
              <span>Email:{' '}</span>
              {sub_header ? (
                <a href={`mailto:${(sub_header as HCPUserSubHeaderProps[])[0].Email}`}
                >
                  {(sub_header as HCPUserSubHeaderProps[])[0].Email}
                </a>
              ) : ""}
            </p>
          </div> */}
          <div className="col-12 col-md-5">
          <p className="mb-0 fw-bold">Sales Rep: </p>
              {sub_header &&
                  sub_header.length > 0 &&
                  (sub_header as HCPUserSubHeaderProps[]).map((rep, index) => (
                      <div key={index} className="mb-2">
                          <p className="mb-0">
                              <span>{rep.Rep} - </span>
                              <a href={`mailto:${rep.Email}`}>{rep.Email}</a>
                              <span> - ({Array.isArray(rep.Drugs) ? rep.Drugs.join(", ") : rep.Drugs})</span>
                          </p>
                      </div>
                  ))}
          </div>


          <div className="col-12 col-md-3 py-2">
            <button className="btn btn-primary" onClick={() => navigate(`/rep_visits/new`)}>
              <i className="bi-calendar-week-fill pe-1 text-light" style={{ color: 'white' }}></i>  Schedule a Sales Rep
              Visit
            </button>
          </div>
        </>
        :
        <>
          <div className="col-12 col-md-4">
            {/* <p className="mb-0 fw-bold">
              Sales Rep:{' '} {sub_header ? (sub_header as HCPUserSubHeaderProps).Rep : ''}
            </p>
            <p className="mb-0">
                <span>Email:{' '}</span>
                {sub_header ? (
                  <a href={`mailto:${(sub_header as HCPUserSubHeaderProps).Email}`}
                  >
                      {(sub_header as HCPUserSubHeaderProps).Email}
                  </a>
                ) : ""}
            </p> */}
          </div>
          <div className="col-12 col-md-3 py-2">
            {/* <button className="btn btn-primary" onClick={() => navigate(`/rep_visits/new`)}>
              <i className="bi-calendar-week-fill pe-1 text-light" style={{color: 'white'}}></i>  Schedule a Sales Rep
                Visit
            </button> */}
          </div>
        </>}
      <div className="col-12 col-md-4">
        <ContactAndSafetyInfo textColor="black" alignText={TextAlignProps.TEXT_END} />
      </div>
    </div>
  );
};

export default AuthSubHeader;
