import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useFetch from "src/hooks/useFetch";
// import { ToastContext } from "src/context/toastContext";

// import { ToastTypes } from "src/types";
import { APIEndpoints } from "src/types/apiTypes";
import { CaseListingType, RenderNextStepProps, statetype } from "./types";
import { PrecertAPIResponse } from "../precert/types";
// import { LoaderContext } from "src/context/loaderContext";
import useAppealButtonFunc from "src/hooks/useAppealButtonFunc";
// import { TreatmentListErrorResponse, TreatmentStatusUpdateApiResponse } from "../treatmentListing/types";



const RenderNextStep: FC<RenderNextStepProps> = ({ kase, showHideModalForTreatment, setDataForTreatmentDecisionModal, getCaseListing }): JSX.Element => {
  // const { showToast } = useContext(ToastContext);
  // const { setLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const [precertPathParameters, setPrecertPathParameters] = useState<statetype>()
  const { submiAppealForCase, apiInPorgress } = useAppealButtonFunc();

  //let caseAction;
  let precertButtons;
  let updateButtons;
  let reviewButtons;
  let rx_pa_buttons;
  let review_pa_button;
  let update_pa_button;
  let appeal_button;


  // const buyAndBillButton = (caseID:number) => {
  //   updateCaseMutate({case:{status_name:"Pulled From Stock",color_idx:3}},{caseId:caseID},{})
  //   getCaseListing()
  // }

  // const { mutate: updateCaseMutate, data: updateCaseResponse } =
  //   useFetch<PrecertAPIResponse>(APIEndpoints.UPDATE_CASES, {}, {}, false);

  // useEffect(() => {
  //   if (updateCaseResponse) {
  //     navigate(`/cases`);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [updateCaseResponse]);

  // const onTreatedOrNotTreatedButtonClicked = (value: string, treatment_id: number) => {
  //   const requestBody = { status: 0 };

  //   if (value === "Not-Treated") {
  //     requestBody.status = 31;
  //   }
  //   // else {
  //   //   requestBody.status = 22;
  //   // }
  //   setLoader()
  //   updatetreatment(requestBody, { treatmentId: treatment_id });
  // };

  // const { data: treatmentUpdateData, mutate: updatetreatment, error: treatmentUpdateError } =
  //   useFetch<TreatmentStatusUpdateApiResponse>(APIEndpoints.TREATMENT_STATUS_UPDATE, {}, {}, false);

  // useEffect(() => {
  //   if (treatmentUpdateError) {
  //     hideLoader();
  //     const { code, error } = treatmentUpdateError as unknown as TreatmentListErrorResponse;
  //     showToast([error || `Treatment can't update now. Please try again in a while.`], ToastTypes.ERROR);
  //     if (code === 401) {
  //       //destroySession();
  //       navigate(`/users/sign_in`);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [treatmentUpdateError]);

  // useEffect(() => {
  //   const hasUpdateData = treatmentUpdateData?.code;

  //   if (hasUpdateData || treatmentUpdateError) {
  //     if (hasUpdateData) {
  //       // getCaseListing(null, null, initialState);
  //     }
  //     hideLoader();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [treatmentUpdateError, treatmentUpdateData]);

  const { mutate: fetchPrecertMutate, data: fetchPrecertResponse } =
    useFetch<PrecertAPIResponse>(APIEndpoints.FETCH_PRECERT, {}, {}, false);

  useEffect(() => {
    if (fetchPrecertResponse) {

      navigate(`/cases/${precertPathParameters && precertPathParameters.case_id}/precerts/${precertPathParameters && precertPathParameters.id}/edit?insuranceName=${precertPathParameters && precertPathParameters.insurance_name}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPrecertResponse]);



  const navigateToPrecertForm = (caseId: number, precertId: number ,insuranceName:string) => {
    const pathParams = {
      case_id: caseId,
      id: precertId
    }
    setPrecertPathParameters(pathParams)
    fetchPrecertMutate({}, pathParams)
    let pathParam = {insurance_name:insuranceName, ...pathParams}
    setPrecertPathParameters(pathParam)
  }


  const navigateToUpdatePrecertForm = (caseId: number, precertId: number, patient: string,insurance_name: string) => {
    // const pathParams = {
    //   case_id: caseId,
    //   id: precertId
    // }
    // setPrecertPathParameters(pathParams)
    // fetchPrecertMutate({}, pathParams)
    navigate(`/cases/${caseId}/precert/${precertId}/edit?patient=${patient}&insuranceName=${insurance_name}`);

  }

  const navigateToReviewPrecertForm = (caseId: number, precertId: number, title: string, patient: string,insurance_name: string) => {

   // console.log(caseId, "    ", precertId, "   ", title)
    navigate(`/cases/${caseId}/precerts/${precertId}/review?title=${title + " " + caseId}&precert_id=${precertId}&patient=${patient}&insuranceName=${insurance_name}`,
      //   {
      //   state: { title: title + " " + caseId ,precert_id:precertId,patient:patient}
      // }
    );
  }

  const navigateToReviewRXPrecertForm = (caseId: number, precertId: number, title: string, patient: string,insurance_name: string) => {
    //Update to RXPA// Prior Authorization RXPA
    //console.log(caseId, "    ", precertId, "   ", title) //update RX PA 
    navigate(`/cases/${caseId}/prior_authorizations/${precertId}?title=${title + " " + caseId}&precert_id=${precertId}&patient=${patient}&insuranceName=${insurance_name}`, {
      state: { title: title + " " + caseId, precert_id: precertId, patient: patient }
    });
  }


  const naviagteToRX_PA = (kase: CaseListingType,precert_id:number,insurance_name:string) => {

    navigate(`/cases/${kase.id}/documents/new?precert_id=${precert_id}&insuranceName=${insurance_name}`)
  }
  const naviagteToUpdateRX_PA = (kase: CaseListingType,precert_id:number,insurance_name:string) => {
    //priorAuthorization
    navigate(`/cases/${kase.id}/prior_authorizations/${precert_id}/edit?precert_id=${precert_id}&patient=${kase.patient.firstlastname}&insuranceName=${insurance_name}`, {
      state: { precert_id: kase.precert_id, patient: kase.patient.firstlastname }
    })

  }

  // const { loading: transferRxLoading, data: transferRxData, mutate: transferRxByCaseId } =
  //   useFetch(APIEndpoints.TRANSFER_RX, {}, { case_id: kase.id });

  // useEffect(() => {
  //   if (transferRxData) {
  //     const { message } = transferRxData as unknown as { code: number; message: string; };
  //     showToast([message], ToastTypes.SUCCESS);
  //     getCaseListing();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [transferRxData]);

  // useEffect(() => {
  //   if (transferRxLoading) setLoader();
  //   else hideLoader();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [transferRxLoading]);

  // const ConfirmAlert = (title: string,buttonName:string,caseId:number ) => {

  //   const result = window.confirm(title);
  //   if (result) {
  //     if(buttonName === 'xFerToSP'){
  //       transferRxByCaseId()
  //     }else if (buttonName === "patientPurchase"){
  //       transferRxByCaseId({case_id:caseId,pdp:1},{case_id:caseId,pdp:1})
  //     }
  //   }
  // };

  const caseAction = [];

  if (kase && kase.precert_buttons.length > 0) {
    precertButtons = kase.precert_buttons.map((btn) =>
      btn.precert === true &&
      <button
        className="btn btn-warning m-1 text-white fw-normal fs-6 link-offset-0-hover link-underline-opacity-75-hover"
        key={`review-${btn.key}`}
        data-bs-toggle="tooltip"
        data-bs-placement="right"
        title={`${btn.ins_name}`}
        onClick={() => { btn.precert_id && navigateToPrecertForm(kase.id, btn.precert_id,btn.ins_name) }}
      >
        <i className="fas fa-clipboard-list me-2"></i> Precert
      </button>
    );
  }
  if (kase && kase.update_buttons.length > 0) {
    updateButtons = kase.update_buttons.map((btn) =>
      btn.update === true &&
      <button
        className="btn btn-warning m-1 text-white fw-normal fs-6 link-offset-0-hover link-underline-opacity-75-hover"
        key={`review-${btn.key}`}
        data-bs-toggle="tooltip"
        data-bs-placement="right"
        title={`${btn.ins_name}`}
        disabled={apiInPorgress}
        onClick={() => { btn.precert_status === 'submitted' ? btn?.precert_id && navigateToUpdatePrecertForm(kase.id, btn.precert_id, kase.patient.firstlastname,btn.ins_name) : btn?.precert_id && navigateToPrecertForm(kase.id, btn.precert_id,btn.ins_name) }}
      >
        <i className="fas fa-clipboard-list me-2 col-1"></i> Update PA
         
      </button>

    );
  }
  if (kase && kase.review_buttons.length > 0) {

    reviewButtons = kase.review_buttons.map((btn) =>
      btn.review === true &&
      <button
        className="btn btn-warning text-white m-1 fw-normal fs-6 link-offset-0-hover link-underline-opacity-75-hover"
        key={`review-${btn.key}`}
        data-bs-toggle="tooltip"
        data-bs-placement="right"
        title={`${btn.ins_name}`}
        onClick={() => { btn.precert_id && navigateToReviewPrecertForm(kase.id, btn.precert_id, "Review Med PA For Case", kase.patient.firstlastname,btn.ins_name) }}
      //onClick={() => { true && navigateToReviewPrecertForm(kase.id, 1965,"Review Med PA For Case") }}

      >
        <i className="fas fa-clipboard-list me-2"></i> Review PA
      </button>

    );

  }
  if (kase && kase.rx_pa_buttons.length > 0) {
    rx_pa_buttons = kase.rx_pa_buttons.map((btn) =>
      btn.rx_pa === true &&
      <button className="btn btn-warning m-1 text-white fw-normal fs-6 link-offset-0-hover link-underline-opacity-75-hover"
        disabled={apiInPorgress}
        key={`review-${btn.key}`}
        data-bs-toggle="tooltip"
        data-bs-placement="right"
        title={`${btn.ins_name}`}
        onClick={() => naviagteToRX_PA(kase,btn.prior_authorization_id,btn.ins_name)}
      >
        <i className="fas fa-clipboard-list me-2 col-1"></i> RX PA
          
      </button>
    );
  }
  if (kase && kase.review_pa_buttons.length > 0) {
    review_pa_button = kase.review_pa_buttons.map((btn) =>
      btn.review_pa === true &&
      <button className="btn btn-warning text-white m-1 fw-normal fs-6 link-offset-0-hover link-underline-opacity-75-hover"
      key={`review-${btn.key}`}
      data-bs-toggle="tooltip"
      data-bs-placement="right"
      title={`${btn.ins_name}`}
        disabled={apiInPorgress}
        onClick={() => btn.prior_authorization_id && navigateToReviewRXPrecertForm(kase.id, btn.prior_authorization_id, "Review RX PA For Case", kase.patient.firstlastname,btn.ins_name)}
      >
        <i className="fas fa-clipboard-list me-2 col-1"></i> Review RX PA
         
      </button>
    );
  }
  if (kase && kase.update_pa_buttons.length > 0) {
    update_pa_button = kase.update_pa_buttons.map((btn) =>
      btn.update_pa === true &&
      <button className="btn btn-warning m-1 text-white fw-normal fs-6 link-offset-0-hover link-underline-opacity-75-hover"
        disabled={apiInPorgress}
        key={`review-${btn.key}`}
        data-bs-toggle="tooltip"
        data-bs-placement="right"
        title={`${btn.ins_name}`}
        onClick={() => naviagteToUpdateRX_PA(kase,btn.prior_authorization_id,btn.ins_name)}
      >
        <i className="fas fa-clipboard-list me-2 col-1"></i> Update RX PA
         
      </button>
    );
  }
  
  // if (kase && kase.appeal_buttons.length > 0) {
  //   appeal_button = kase.appeal_buttons.map((btn) =>
  //     btn.appeal === true &&
  //      <button className="btn btn-success m-1 text-white fw-normal fs-6 link-offset-0-hover link-underline-opacity-75-hover"
  //      style={{ backgroundColor: '#28A745', borderBlock: '#28A745' }}
  //      disabled={apiInPorgress}
  //      key={`review-${btn.key}`}
  //      data-bs-toggle="tooltip"
  //      data-bs-placement="right"
  //      title={`${btn.ins_name}`}
  //      onClick={() => { btn.precert_id && submiAppealForCase(kase.id, btn.precert_id) }}
  //    >
  //      <i className="fas fa-clipboard-list me-2 col-1"></i> Appeal
        
  //    </button>
  //   );
  // }

  // if (kase.precert) {

  //   caseAction.push(
  //     <button
  //       className="btn btn-warning m-1 text-white"
  //       onClick={() => { kase?.precert_id && navigateToPrecertForm(kase.id, kase.precert_id) }}
  //     >
  //       <i className="fas fa-clipboard-list me-2"></i> Precert
  //     </button>
  //     )
  // }
  //update_buttons
  // if (kase.update_pa) {
  //   caseAction.push(
  //     <button className="btn btn-warning m-1 text-white"
  //       disabled={apiInPorgress}
  //       onClick={() => { kase.precert_status === 'submitted' ? kase?.precert_id && navigateToUpdatePrecertForm(kase.id, kase.precert_id, kase.patient.firstlastname) : kase?.precert_id && navigateToPrecertForm(kase.id, kase.precert_id) }}

  //     >
  //       <i className="fas fa-clipboard-list me-2 col-1"></i>
  //       Update PA
  //     </button>
  //   )
  //   //review_buttons
  // } if (kase.review_pa) {
  //   caseAction.push(
  //     <button
  //       className="btn btn-warning text-white m-1"
  //       onClick={() => { kase.precert_id && navigateToReviewPrecertForm(kase.id, kase.precert_id, "Review Med PA For Case", kase.patient.firstlastname) }}
  //     //onClick={() => { true && navigateToReviewPrecertForm(kase.id, 1965,"Review Med PA For Case") }}

  //     >
  //       <i className="fas fa-clipboard-list me-2"></i> Review PA
  //     </button>)
  // }
  ///okay
  ///rx_pa_buttons 
  // if (kase.rx_pa) {
  //   caseAction.push(
  //     <button className="btn btn-warning m-1 text-white"
  //       disabled={apiInPorgress}
  //       onClick={() => naviagteToRX_PA(kase)}
  //     >
  //       <i className="fas fa-clipboard-list me-2 col-1"></i>
  //       RX PA
  //     </button>
  //   )
  // }
  //review_pa_buttons
  // if (kase.review_rx_pa) {
  //   caseAction.push(
  //     <button className="btn btn-warning text-white m-1 "
  //       disabled={apiInPorgress}
  //       onClick={() => kase.prior_auth_id && navigateToReviewRXPrecertForm(kase.id, kase.prior_auth_id, "Review RX PA For Case", kase.patient.firstlastname)}
  //     >
  //       <i className="fas fa-clipboard-list me-2 col-1"></i>
  //       Review RX PA
  //     </button>
  //   )
  // }
  // /// Okay 
  // //update_pa_buttons
  // if (kase.update_rx_pa) {
  //   caseAction.push(
  //     <button className="btn btn-warning m-1 text-white"
  //       disabled={apiInPorgress}
  //       onClick={() => naviagteToUpdateRX_PA(kase)}
  //     >
  //       <i className="fas fa-clipboard-list me-2 col-1"></i>
  //       Update RX PA
  //     </button>
  //   )
  // }




  ///////////////////////////////////////////////////////////////////////////
  if (kase.status_name.toLowerCase() !== "cancelled") {

    // if (kase.appeal) {
    //   // if (true) {
    //   caseAction.push(
    //     <button className="btn btn-success m-1" style={{ backgroundColor: '#28A745', borderBlock: '#28A745' }}
    //       disabled={apiInPorgress}
    //       onClick={() => { kase?.precert_id && submiAppealForCase(kase.id, kase.precert_id) }}
    //     >
    //       <i className="fas fa-clipboard-list me-2 col-1"></i>
    //       Appeal
    //     </button>
    //   )
    // }
    if (kase.date_of_treatment && kase.no_action_required) {
      // if(true)
      // {
      caseAction.push(
        <button
          className="btn btn-success m-1"
          onClick={() => showHideModalForTreatment({ isModalVisible: true, caseId: kase.id, prescriptionId: kase.prescription_id })}
        >
          <i className="bi bi-clipboard-data me-2"></i>
          Date of Treatment
        </button>)
    }
    if (kase.treatment_decision) {
      // if(true)
      // {
      caseAction.push(
        <button className="btn btn-primary m-1 "
          disabled={apiInPorgress}
          onClick={() => setDataForTreatmentDecisionModal({ isModalVisible: true, caseId: kase.id, prescriptionId: kase.prescription_id, prior_authorization_id: kase.prior_auth_id ? kase.prior_auth_id : 0, prior_auth_status: '',kase:kase })}
        >
          <span title="Click here to transfer to pharmacy or indicate treatment decision" data-bs-toggle="tooltip"
            data-bs-placement="right"
          >
            <i className="fa fa-times me-2"></i>
            Treatment Decision
          </span>
        </button>
      )
    }
    if(kase.no_action_required){
      caseAction.push(<p className="fs-6 mb-0">No Action Required</p>);
    }

    // else if (caseAction.length < 1) {
    //   caseAction.push(<p className="fs-6 mb-0">No Action Required</p>);
    // }
  } 
  // else {
  //   caseAction.push(<p className="fs-6 mb-0">No Action Required</p>);
  // }

  return (
    <>
      {caseAction}
      {precertButtons}
      {reviewButtons}
      {updateButtons}
      {rx_pa_buttons}
      {update_pa_button}
      {review_pa_button}
      {appeal_button}
    </>
  );
};

export default RenderNextStep;
