import { FC, useEffect, useState } from "react";
import { InsuranceInformationType, InsuranceInformationProps } from "./type";
import { APIEndpoints } from "src/types/apiTypes";
import { PrecertAPIResponse } from "src/pages/precert/types";
import useFetch from "src/hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "src/helpers";

const InsuranceInformation: FC<InsuranceInformationProps> = ({ insuranceInformation, insuranceNumber ,drugName}): JSX.Element => {
  const [precertPathParameters, setPrecertPathParameters] = useState<{ caseId: number; precertId: number }>({ caseId: 0, precertId: 0 })

  const navigate = useNavigate();

  const { mutate: fetchPrecertMutate, data: fetchPrecertResponse } =
    useFetch<PrecertAPIResponse>(APIEndpoints.FETCH_PRECERT, {}, {}, false);

  useEffect(() => {
    if (fetchPrecertResponse)
      navigate(`/cases/${precertPathParameters && precertPathParameters.caseId}/precerts/${precertPathParameters && precertPathParameters.precertId}/edit`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPrecertResponse]);



  const navigateToPrecertForm = (caseId: number, precertId: number) => {
    const pathParams = {
      case_id: caseId,
      id: precertId
    }
    setPrecertPathParameters({ caseId, precertId })
    fetchPrecertMutate({}, pathParams)
  }


  const precertCheck = (item: InsuranceInformationType) => {
    //console.log(item.precert_id, item.case_id, item.precert_status);
    if (item.precert_clickable) {
    //  console.log("button part");
      return (
        <button
          className="btn btn-warning m-1 text-white"
          onClick={() => { item.precert_id && navigateToPrecertForm(item.case_id, item.precert_id) }}
        >
          <i className="fas fa-clipboard-list me-2"></i> {item.precert_status}
        </button>
      )
    } else {
      return `${item.precert_status}`
    }
  }

  return (
    <div className="card my-4">
      <div className="card-header">
        <p className="p-2 mb-0 fs-4 text-center text-white">
          Insurance # {insuranceNumber}: {insuranceInformation ? insuranceInformation.insurance_priority : "N/A"}
        </p>
      </div>

      <div className="card-body">
        <div className="row">

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Relation to Patient:</p>
              </div>
              <div className="col-6">
                <p>
                  {insuranceInformation ? insuranceInformation.relation_to_patient : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            {insuranceInformation && insuranceInformation.relation_to_patient !== 'Self' && (
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>Relation First Name:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation ? insuranceInformation.relation_first_name : "N/A"}
                    </p>
                  </div>
                </div>
            )}
          </div>

          {insuranceInformation && insuranceInformation.relation_to_patient !== 'Self' && (
              <>
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-6 text-end px-3">
                      <p>Relation Middle Name:</p>
                    </div>
                    <div className="col-6">
                      <p>
                        {insuranceInformation ? insuranceInformation.relation_middle_name : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-6 text-end px-3">
                      <p>Relation Last Name:</p>
                    </div>
                    <div className="col-6">
                      <p>
                        {insuranceInformation ? insuranceInformation.relation_last_name : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </>
          )}

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Plan Name:</p>
              </div>
              <div className="col-6">
                <p>
                  {insuranceInformation ? insuranceInformation.plan_name : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <label>Plan Type:</label>
              </div>
              <div className="col-6">
                <p>
                  {insuranceInformation ? insuranceInformation.plan_type : "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Medical Insurance */}
        <div className="card my-4">
          <div className="card-header">
            <p className="fs-4 text-black p-2 mb-0 text-white">Medical Insurance</p>
          </div>

          <div className="card-body">
            <div className="row">

              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>Member ID:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation ? insuranceInformation.member_id : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-6 text-end px-4">
                    <p>Group:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation ? insuranceInformation.group : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>Insurance Phone:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation && insuranceInformation.insurance_phone ? formatPhoneNumber(insuranceInformation.insurance_phone) : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="row align-items-center">
                  <div className="col-6 text-end px-3">
                    <p className="mb-0">Precert:</p>
                  </div>
                  <div className="col-6">
                    {precertCheck(insuranceInformation)}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

         {/*Pharmacy Insurance*/}
        { drugName !== "iovera°" && 
        <div className="card my-2">
          <div className="card-header">
            <p className="fs-4 text-black p-2 mb-0 text-white">Pharmacy Insurance</p>
          </div>

          <div className="card-body">
            <div className="row">

              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>Rx Member ID:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation ? insuranceInformation.rx_member_id : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>Rx Member Group:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation ? insuranceInformation.rx_group : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>BIN:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation ? insuranceInformation.bin : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>PCN:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation ? insuranceInformation.pcn : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-6 text-end px-3">
                    <p>Prior Authorization:</p>
                  </div>
                  <div className="col-6">
                    <p>
                      {insuranceInformation ? insuranceInformation.prior_authorization : "N/A"}
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default InsuranceInformation;
