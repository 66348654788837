import { FC, useContext, useEffect, useReducer } from "react";

import AuthSubHeader from "src/components/authSubHeader";
import TableFilters from "src/components/caseFilters";
import FooterText from "src/components/footerText";
import { LoaderContext } from "src/context/loaderContext";

import {CASE_STATUS_LISTING, RETREATMENT_TABLE_HEADER} from "src/constants/cases";

import { initialState, queryParamReducer } from "../dashboard/reducer";
import { QueryParamsState, QueryStateActions, QueryStateActionType } from "../dashboard/types";
import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { DeleteRetreatmentAPIRes, RetreatmentAPIRes } from "./types";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { ToastContext } from "src/context/toastContext";
import { ToastTypes } from "src/types";




const RetreatmentRequests: FC = (): JSX.Element => {
  const [queryParams, dispatch] = useReducer(queryParamReducer, initialState);

  const { setLoader, hideLoader } = useContext(LoaderContext);
  const { showToast } = useContext(ToastContext);



  const { error: retreatmentError, data: retreatmentListing, mutate: getRetreatmentListing, removeErrorAndData: clearRetreatmentListingState } =
    useFetch<RetreatmentAPIRes>(APIEndpoints.RETREATMENT_LISTING, queryParams);

  useEffect(() => {
    if (retreatmentListing || retreatmentError)
      hideLoader();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retreatmentListing, retreatmentError])

  const handleSearchWithQueryParam = (updatedState?: QueryParamsState) => {
    if (retreatmentError || retreatmentListing) clearRetreatmentListingState();
    setLoader();
    getRetreatmentListing(null, null, updatedState ? updatedState : queryParams);
  };

  const onClearButtonClicked = () => {
    if (retreatmentError || retreatmentListing) clearRetreatmentListingState();
    dispatch({
      type: QueryStateActions.CLEAR_STATE,
    });
    getRetreatmentListing(null, null, initialState);
  };

  const changeRecordOrderByColumn = (value: string): void => {
    const order = queryParams.direction === 'desc' ? 'asc' : 'desc';

    const action: QueryStateActionType = {
      type: QueryStateActions.CHANGE_SORT_PARAMS,
      payload: {
        direction: queryParams.sort_by === value ? order : "desc",
        sort_by: value,
      },
    };
    dispatch(action);

    const updatedState = queryParamReducer(queryParams, action);
    handleSearchWithQueryParam(updatedState);
  };

  const changePageNumber = (page: { selected: number }): void => {
    const action: QueryStateActionType = {
      type: QueryStateActions.CHANGE_PAGE,
      payload: page.selected+1,
    };
    dispatch(action);

    const updatedState = queryParamReducer(queryParams, action);
    handleSearchWithQueryParam(updatedState);
  };


  const { loading: deleteRetreatmentLoader, data: deleteRetreatmentResponse, mutate: deleteTreatementById } =
    useFetch<DeleteRetreatmentAPIRes>(APIEndpoints.DELETE_RETREATMENT);

  useEffect(() => {
    if (deleteRetreatmentResponse) {
      hideLoader();
      showToast([deleteRetreatmentResponse.message], ToastTypes.SUCCESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRetreatmentResponse])

  const removeRetreatmentRequest = (retreatmentId: number): void => {
    setLoader();
    deleteTreatementById({}, { retreatment_id: retreatmentId });
  };

  return (
      <div className="container-fluid cases-page bg-primary-sky overflow-y-auto overflow-x-hidden d-flex flex-column flex-fill">
        <div className="container">
          <AuthSubHeader/>
          <div className="card m-1 mt-4 flex-fill">
            <div className="card-header">
              <h4 className="m-0">Retreatment Requests</h4>
            </div>
            <div className="card-body">
              <p>
                The following is a list of patients who are potentially eligible for retreatment. Clicking re-run
                will allow you to reinvestigate the patients benefits. Clicking remove will remove the patient from this list.
              </p>
              <TableFilters
                  dropdownData={CASE_STATUS_LISTING}
                  inputPlaceholder="Search by Patient, Physician, ID"
                  state={queryParams}
                  dispatch={dispatch}
                  handleSearchWithQueryParam={handleSearchWithQueryParam}
                  onClearButtonClicked={onClearButtonClicked}
                  onStatusChange={() => console.log("nothing required")}
              />

            <div className="table-responsive-sm table-responsive-md table-responsive-lg">
              <table className="table table-striped table-sm mt-3">
                <thead>
                <tr className="text-center">
                  {RETREATMENT_TABLE_HEADER.map((tableHeader, index) => (
                      <th
                          key={index} className="bg-primary text-white fs-6" style={{minWidth: "115px"}}
                          onClick={tableHeader.value !== '' ? () => changeRecordOrderByColumn(tableHeader.value) : undefined}
                      >
                        <span
                            role={tableHeader.value === '' ? "textbox" : "button"}
                            className={tableHeader.value === '' ?
                                '' :
                                'btn text-white btn-link link-underline link-underline-opacity-0 p-0 fw-bold'
                            }
                        >
                          {tableHeader.label}
                          {queryParams.sort_by === tableHeader.value ? (
                              <i className={`bi ${queryParams.direction === 'desc' ? 'bi-caret-down-fill' : 'bi-caret-up-fill'}`}></i>
                          ) : null}
                        </span>
                      </th>
                  ))}
                </tr>
                </thead>

                <tbody>
                {retreatmentListing && retreatmentListing.data && retreatmentListing.data.retreatment && retreatmentListing.data.retreatment.length > 0 ?
                    retreatmentListing.data.retreatment.map((record) => (
                        <tr key={record.retreatment_id} className="align-middle text-center">
                          <td>
                            <Link
                                to={{pathname: `/cases/detail/${record.case_id}`}}
                                className="btn btn-link link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover"
                            >
                              {record.case_id}
                            </Link>
                          </td>
                          <td className="fs-6">{record ? record.submitted : ''}</td>
                          <td className="fs-6">{record ? record.patient : ''}</td>
                          <td className="fs-6">{record ? record.physician : ''}</td>
                          <td className="fs-6">{record ? record.patient_phone : ''}</td>
                          <td>
                            <button type="button" onClick={() => removeRetreatmentRequest(record.retreatment_id)}
                                    disabled={deleteRetreatmentLoader}
                                    className="btn btn-outline-secondary me-2 rounded-0">
                              X{' '}Remove
                            </button>
                            <Link to={`/cases/${record ? record.case_id : 0}/clone`}
                                  className="btn btn-success rounded-0 my-2">
                              <i className="bi bi-arrow-repeat"></i> Re-run
                            </Link>
                          </td>
                        </tr>
                    )) : null
                }
                </tbody>
              </table>
            </div>

            {retreatmentListing && retreatmentListing.data && retreatmentListing.data.total_pages ? (
                <ul className="pagination justify-content-center flex-wrap">
                  {queryParams.page !== 1 ? (
                      <li className="page-item">
                        <button
                            className="btn btn-link page-link"
                            onClick={() => changePageNumber({selected: 0})}
                        >
                          <i className="bi bi-chevron-double-left"></i> First
                        </button>
                      </li>
                  ) : null}

                  <li>
                    <ReactPaginate
                        nextLabel={<>Next <i className="bi bi-chevron-right"></i></>}
                        onPageChange={changePageNumber}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={retreatmentListing.data.total_pages}
                        previousLabel={<><i className="bi bi-chevron-left"></i> Previous</>}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                        forcePage={queryParams.page - 1}
                    />
                  </li>

                  {queryParams.page !== retreatmentListing.data.total_pages ? (
                      <li className={`page-item ${queryParams.page === retreatmentListing.data.total_pages ? 'disabled' : ''}`}>
                        <button
                            className="btn btn-link page-link rounded-start-0"
                            onClick={() => changePageNumber({selected: retreatmentListing.data.total_pages - 1})}
                        >
                          Last <i className="bi bi-chevron-double-right"></i>
                        </button>
                      </li>
                  ) : null}
                </ul>
            ) : null}
            </div>
          </div>
          <FooterText footerType="black"/>
        </div>
      </div>
    )
  }
  export default RetreatmentRequests;
