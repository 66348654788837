import { FC, useContext } from "react";

import { FooterContext } from "src/context/footerContext";

import { PaciraLogo } from "../../assets";

import { FooterTextProps } from "./types";



const FooterText: FC<FooterTextProps> = ({ footerType }): JSX.Element => {
  const { changeAccordianVisibility } =  useContext(FooterContext)

  return (
      <div className="pt-3 footer-detail">
        <p>
            For more information on iovera, visit <a className={"fw-bold"} style={{ color: '#212529' }} href="https://www.ioverapro.com/" target="_blank"> ioverapro.com </a>
            or call 1-855-793-9727. For more information on ZILRETTA, visit <a className={"fw-bold"} style={{ color: '#212529' }} href="https://zilrettapro.com/" target="_blank"> www.zilrettapro.com </a>
            or call 1-855-793-9727. You are encouraged to report negative side effects of prescription drugs to FDA; visit
            <a className={"fw-bold"} style={{ color: '#212529' }} href="http://www.fda.gov/medwatch" target="_blank"> www.fda.gov/medwatch </a>
            or call 1-800-FDA-1088.
        </p>
        <div className="row">
            <div className="col-sm-2"><img className="img-fluid pacira-logo" src={PaciraLogo} alt="Pacira Logo"/></div>
            <div className="col-sm-10">
                <p>
                    ©2025 Pacira Pharmaceuticals, Inc., a wholly owned subsidiary of Pacira BioSciences, Inc. ZILRETTA and
                    FlexForward are registered trademarks of Pacira Therapeutics, Inc., and iovera° is a registered trademark
                    of Pacira CryoTech, Inc., both of which are wholly owned subsidiaries of Pacira BioSciences, Inc.
                    PP-MP-US-0325 February 2025
                </p>
          </div>
        </div>
      </div>
  );
};

export default FooterText;
