import { FC, useCallback, useEffect, useState } from "react";
import HighCharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import {
  PIE_CHART_OPTIONS,
} from "src/constants/highCharts";
import { StatisticsObjectType } from "../caseVolumeLineChart/types";
import { wrapperFunctionPieChart } from "src/helpers";
import CustomTopPayorList from "../customTopPayorList";
import { topPayor } from "src/pages/manufacturer/types";



const CustomTopPayorDetail: FC<StatisticsObjectType> = ({ StatisticsObjectType }): JSX.Element => {
  const [data, setData] = useState({});

  const populatePieChart = useCallback(() => {
    const topPayorOptions = {
      ...PIE_CHART_OPTIONS,
      series: wrapperFunctionPieChart(StatisticsObjectType.top_payors_details as topPayor[])
    };
    setData(topPayorOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StatisticsObjectType.top_payors_details]);

  useEffect(() => {
    populatePieChart();
  }, [populatePieChart]);


  return (
      <div className="card mt-3">
        <div className="card-header">
          <h4 className="m-0 text-center">Top Payer Details</h4>
        </div>
        <div className="card-body p-0">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4" style={{maxHeight: 450}}>
              <CustomTopPayorList startDate="" endDate="" StatisticsObjectType={StatisticsObjectType}/>
            </div>
            <div className="col-12 col-sm-12 col-md-8">
              <HighchartsReact highcharts={HighCharts} options={data}/>
            </div>
          </div>
        </div>
      </div>
  );
};

export default CustomTopPayorDetail;
