import { FC } from "react";
import { Controller } from "react-hook-form";
import { GroupBase, OptionsOrGroups } from "react-select";

import HubInputField from "../../inputType";

import { PATIENT_PHONE_TYPES } from "src/constants/cases";
import { AddCasePhoneNumberProps } from "./types";
import ReactInputMask from "react-input-mask";

const AddCasePhoneNumber: FC<AddCasePhoneNumberProps> = ({ control, phoneNumberIndex, removePhoneNumber }): JSX.Element => {
  return (
      <div className="row">
          <label className="form-label col-lg-2 col-md-4 col-12">Phone Number*</label>
          <div className="col-lg-9 col-md-7 col-12">
              <div className="row">
                  <div className="col-lg-3 col-md-6 col-6">
                      <Controller
                          name={`patient_attributes.phone_numbers.${phoneNumberIndex}.phone_type`}
                          control={control}
                          defaultValue="cell"
                          render={({field, fieldState: {error}}) => (
                              <HubInputField
                                  inputId={`patient_attributes.phone_numbers.${phoneNumberIndex}.phone_type`}
                                  isRequired={true}
                                  isRowBased={true}
                                  field={field}
                                  error={error}
                                  isDropdown={true}
                                  isSearchable={false}
                                  dropdownData={PATIENT_PHONE_TYPES as unknown as OptionsOrGroups<string, GroupBase<string>>}
                              />
                          )}
                      />
                  </div>
                  <div className="col-lg-3 col-md-6 col-6">
                      <Controller
                          name={`patient_attributes.phone_numbers.${phoneNumberIndex}.number`}
                          control={control}
                          defaultValue=""
                          render={({field, fieldState: {error}}) => (
                            
                            <>
                                <ReactInputMask
                                    {...field}
                                    mask="(999) 999-9999"
                                    onChange={(e) => field.onChange(e.target.value.replace(/\D/g, ''))}
                                >
                                    {(inputProps: {}) => <input id="enrollment_practices_attributes.0.phone_number" type="text" className="form-control" placeholder="(555) 555-5555" {...inputProps} />}
                                </ReactInputMask>
                
                                {error && error.message ? (
                                    <div dangerouslySetInnerHTML={{__html: error.message}} className="text-danger text-small mt-2 "></div>
                                ) : null}
                            </>
                          )}
                      />
                  </div>
                  {/* <div className="col-lg-3 col-md-6 col-6 mb-3">
                      <Controller
                          name={`patient_attributes.phone_numbers.${phoneNumberIndex}.is_preferred`}
                          control={control}
                          defaultValue={phoneNumberIndex === 0 ? true : false}
                          render={({field: {value, onChange}}) => (
                              <div className="form-check">
                                  <input className="form-check-input" type="checkbox" value=""
                                      id={`patient_attributes.phone_numbers.${phoneNumberIndex}.is_preferred`}
                                      onChange={onChange} checked={value}
                                  />
                                  <label className="form-check-label"
                                      htmlFor={`patient_attributes.phone_numbers.${phoneNumberIndex}.is_preferred`}
                                  >
                                      Preferred number
                                  </label>
                              </div>
                          )}
                      />
                  </div> */}
                  {/* <div className="col-lg-3 col-md-6 col-6 mb-3">
                      <Controller
                          name={`patient_attributes.phone_numbers.${phoneNumberIndex}.is_ok_to_leave_message`}
                          control={control}
                          defaultValue={false}
                          render={({field: {value, onChange}}) => (
                              <div className="form-check">
                                  <input className="form-check-input" type="checkbox" checked={value} onChange={onChange}
                                      id={`patient_attributes.phone_numbers.${phoneNumberIndex}.is_ok_to_leave_message`}
                                  />
                                  <label className="form-check-label"
                                      htmlFor={`patient_attributes.phone_numbers.${phoneNumberIndex}.is_ok_to_leave_message`}
                                  >
                                      OK to leave message
                                  </label>
                              </div>
                          )}
                      />
                  </div> */}
              </div>
          </div>
          {phoneNumberIndex > 0 ? (
              <div className="col-lg-1 col-md-1">
                  <button type="button" className="btn btn-danger btn-sm d-lg-block d-md-block d-none float-end"
                          onClick={() => removePhoneNumber(phoneNumberIndex)}>
                      <i className="bi bi-x-lg"></i>
                  </button>
                  <button type="button" className="btn btn-danger btn-sm d-lg-none d-md-none d-block mb-4 float-end"
                          onClick={() => removePhoneNumber(phoneNumberIndex)}>
                      <i className="bi bi-x-lg"></i> Remove
                  </button>
              </div>
          ) : null}
      </div>
  );
};

export default AddCasePhoneNumber;
