import { FC } from "react";
import { GroupBase, OptionsOrGroups } from "react-select";
import { Controller } from "react-hook-form";

import HubInputField from "src/components/inputType";
import useFetch from "src/hooks/useFetch";

import { APIEndpoints } from "src/types/apiTypes";
import { AddCaseProcedureSelectProps, ProcedureRequestProps } from "./types";




const AddCaseProcedureSelect: FC<AddCaseProcedureSelectProps> = ({ control, isIOvera }): JSX.Element => {
  /**
   * @GetProcedureListing API to call getProcedureListing
   */
  const { data: procedureListing } = useFetch<ProcedureRequestProps>(APIEndpoints.GET_PROCEDURE_DROPDOWN);




  return (
    <Controller
      name="procedure_attributes.cpt_code"
      control={control}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <HubInputField
          inputId="procedure_attributes.cpt_code"
          inputLabel="Procedure (CPT)"
          isRequired={true}
          isRowBased={true}
          isRowInsideAnother={true}
          field={field}
          error={error}
          isDropdown={true}
          isSearchable={false}
          inputPlaceholder="Select a Procedure ..."
          dropdownData={
            (isIOvera ? 
              procedureListing && procedureListing.data && procedureListing.data.iovera 
              && procedureListing.data.iovera.length > 0 &&
              procedureListing.data.iovera.map((procedure) => (
                {
                  value: procedure.code,
                  label: `${procedure.description.replace(/\b(99999 - |99998 - )\b/g, "").trim()}`
                })
              )
              :
              procedureListing && procedureListing.data && procedureListing.data.zilretta
              && procedureListing.data.zilretta.length > 0 &&
              procedureListing.data.zilretta.map((procedure) => (
                {
                  value: procedure.code,
                  label: `${procedure.description}`
                })
              )
            ) as unknown as OptionsOrGroups<string, GroupBase<string>>
          }
        />
      )}
    />
  )
}

export default AddCaseProcedureSelect;
