import { FC } from "react";

import { PrescriberInfoProps } from "./types";
import { CASE_CATEGORY, DEFAULT_DRUG_NAME } from "src/constants/cases";
// import { AuthContext } from "src/context/authContext";

const ShowPrescriberInformation: FC<PrescriberInfoProps> = ({ getValues }): JSX.Element => {
  const { prescription_attributes, category, shipments_attributes } = getValues();
  //console.log("prescription_attributes......",prescription_attributes,"     Shipment Attributes....",shipments_attributes);
  // const {iovera_practice} = useContext(AuthContext)
  return (
    <div className="card my-3">
      <div className="card-header">
        <h4 className="m-0">Prescription Information</h4>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 mb-3">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Product:</label>
              </div>
              <div className="col-8">
                <p className={"mb-0"}>{prescription_attributes && prescription_attributes.drug_name ? prescription_attributes.drug_name : DEFAULT_DRUG_NAME}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 mb-3">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Benefits being requested:</label>
              </div>
              <div className="col-8">
                <p className={"mb-0"}>{category ? CASE_CATEGORY[category as ("rx" | "med" | "medrx")] : "N/A"}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 mb-3">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Scheduled Date of Service:</label>
              </div>
              <div className="col-8">
                <p className={"mb-0"}>{prescription_attributes && prescription_attributes.scheduled_date_of_service ? prescription_attributes.scheduled_date_of_service : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 mb-3">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Treatment Site:</label>
              </div>
              <div className="col-8">
                <p className={"mb-0"}>{prescription_attributes && prescription_attributes.injection_site ? prescription_attributes.injection_site : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 mb-3">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Quantity:</label>
              </div>
              <div className="col-8">
                <p className={"mb-0"}>{prescription_attributes && prescription_attributes.nerves_qty ? prescription_attributes.nerves_qty : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 mb-3">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Diagnosis Code (ICD):</label>
              </div>
              <div className="col-8">
                <p className={"mb-0"}>{prescription_attributes && prescription_attributes.diagnosis_code ? prescription_attributes.diagnosis_code.join(", ") : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 mb-3">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Written Date:</label>
              </div>
              <div className="col-8">
                <p className={"mb-0"}>{prescription_attributes && prescription_attributes.is_daw ? prescription_attributes.is_daw : 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 mb-3">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Site of Care:</label>
              </div>
              <div className="col-8">
                <p className={"mb-0"}>
                  {shipments_attributes && shipments_attributes[0] && shipments_attributes[0].practice_name ? 
                    shipments_attributes[0].practice_name : 'other'
                  }
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 mb-3">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Directions:</label>
              </div>
              <div className="col-8">
                <p className={"mb-0"}>{prescription_attributes && prescription_attributes.drug_name === "zilretta" ? prescription_attributes.direction : 'N/A' }</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 mb-3">
            <div className="row">
              <div className="col-4">
                <label className="form-label">Note:</label>
              </div>
              <div className="col-8">
                <p className={"mb-0"}>{prescription_attributes && prescription_attributes.note ? prescription_attributes.note : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowPrescriberInformation;
