import { FC } from "react";
import ShowPatientInformation from "../addCase/showPatientInformation";
import ShowPatientAddress from "../addCase/showPatientAddress";
import ShowPrescriberInformation from "../addCase/showPrescriberInformation";
import ShowInsuranceInformation from "../addCase/showInsuranceInformation";
import { CaseStepThreeProps } from "./types";
// import { Controller } from "react-hook-form";

const CaseStepThree: FC<CaseStepThreeProps> = ({ getValues, control }): JSX.Element => {
   const { insurances_attributes,prescription_attributes } = getValues();

  return (
    <div className="">
      <ShowPatientInformation getValues={getValues} />

      <ShowPatientAddress getValues={getValues} />

      <ShowPrescriberInformation getValues={getValues} />

      {insurances_attributes ? insurances_attributes.map((item, index) => (
        <ShowInsuranceInformation key={index} id={index+1} insurance={item} drugName = {prescription_attributes && prescription_attributes.drug_name || ""} />
      )) : null}

      <p className="alert alert-light" role="alert" style={{backgroundColor: '#fff'}}>
        FlexForward assists healthcare professionals in the determination of whether treatment could be covered by the 
        applicable third-party payer based on coverage guidelines provided by the payer and patient information provided 
        by the healthcare provider. Third-party reimbursement is affected by many factors. Therefore, FlexForward makes no 
        representation or guarantee that full or partial insurance reimbursement or any other payment will be available.
      </p>

      {/* <div className="alert alert-warning" role="alert">
        <Controller
          name="patient_attributes.retreatment_reminders"
          control={control}
          defaultValue={true}
          render={({ field: { value, onChange } }) => (
            <div className="form-check m-0">
              <input
                className="form-check-input" type="checkbox"
                id="retreatmentRemindersCheckbox"
                name="patient_attributes.retreatment_reminders"
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="retreatmentRemindersCheckbox">
                Send patient a{' '}
                <a href="/assets/retreatments.mp4" target="_blank">
                  Retreatment Reminder
                </a>
                {' '}postcard
              </label>
            </div>
          )}
        />
      </div> */}

      <p className="alert alert-warning rounded-1" role="alert">
        By submitting this form, I certify 1) I am duly licensed and authorized under applicable law to prescribe, receive, 
        and dispense the medication requested in this application to the patient listed above (the 'Patient'); 2) The 
        information provided above is complete and accurate; 3) I authorize FlexForward to forward the above prescription 
        information to the appropriate pharmacy in order to dispense product to the above named patient; 4) I understand 
        that state law may require the pharmacy to contact me to confirm the prescription information before dispensing. 5) 
        By signing this form, I represent to FlexForward that I have obtained all necessary federal and state authorizations 
        and consents from my patient to allow me to release health information to FlexForward and its contracted third 
        parties. Signature on this form also provides consent to contact this patient's insurance provider for this 
        prescription on the prescriber's behalf.
      </p>
    </div>
  );
};

export default CaseStepThree;
