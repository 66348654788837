import { FC, useCallback, useEffect, useState } from "react"
import { COLUMN_CHART } from "src/constants/highCharts";
import { StatisticsObjectType } from "./types";
import { format, parse } from "date-fns";
import CustomButtonRight from "../customButtonRight";
import CustomColumnChart from "../customColumnChart";


const CaseVolumeColumnChart: FC<StatisticsObjectType> = ({ StatisticsObjectType,getStatisticsData,buttonShows }): JSX.Element => {
  const [lineChart, setLineChart] = useState(COLUMN_CHART);


  // Populate line chart options with x-axis and series data
  const populateLineChart = useCallback(() => {
    const lineOptions = { ...lineChart };
    lineOptions.series = wrapperFunctionForLine();
    lineOptions.xAxis = {
      categories: wrapperFunctionForBaseAxis(),
    };
    setLineChart(lineOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StatisticsObjectType]);

  // Create a unified array of unique dates for the x-axis
  const wrapperFunctionForBaseAxis = () => {
    let allDates: any[] = [];
    StatisticsObjectType.case_volume.forEach((caseVolume: any) => {
      allDates = allDates.concat(Object.keys(caseVolume.data));
    });
    const updatedDates = allDates.map((item) => format(item, "MM-dd-yyyy"));
    return Array.from(new Set(updatedDates)).sort(); // Remove duplicates and sort the dates
  };

  // Map data to the line series, filling missing dates with null
  const wrapperFunctionForLine = () => {
    let lineSeriesArray: any = [];
    const xAxisDates = wrapperFunctionForBaseAxis(); // Get the unique x-axis dates

    StatisticsObjectType.case_volume.forEach((caseVolume) => {
      let seriesData: any = [];

      // Map over the xAxisDates, inserting data or null if missing
      xAxisDates.forEach((date) => {
        if (caseVolume.data) {
          const formattedDate = format(parse(date, "MM-dd-yyyy", new Date()), "yyyy-MM-dd");
          // @ts-ignore
          if (caseVolume.data[formattedDate] !== undefined) {
            // @ts-ignore
            seriesData.push(caseVolume.data[formattedDate]);
          } else {
            seriesData.push(0); // Insert null for missing dates
          }
        }
      });

      lineSeriesArray.push({
        type: "column",
        name: caseVolume.name,
        data: seriesData,
        boostThreshold: 0,
      });
    });

    return lineSeriesArray;
  };

  useEffect(() => {
    populateLineChart();
  }, [populateLineChart]);



  return (
    <div className="row mt-2">
       <div className={` ${buttonShows ? "col-9" : "col"}`} style={{maxHeight:550}}> 
        <CustomColumnChart title="Case Volume" LineChartObject={StatisticsObjectType.case_volume} />
      </div>
      {buttonShows &&<div className="col-3" style={{maxHeight:550}}>
         <CustomButtonRight startDate="" endDate="" StatisticsObjectType={StatisticsObjectType} />
      </div>
      }
    </div >

  )
}
export default CaseVolumeColumnChart;

