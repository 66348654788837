import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import HubHeader from '../components/header';
import HubFooter from "../components/footer";
// import GuestTopHeader from "src/components/guestTopHeader";
 import TawkToMessengerComp from "src/components/tawk.to";

import { AuthContext } from "../context/authContext";
import { AuthenticatedRoutes, ManufacturerRoutes, PublicRoutes } from "../routes/AppRoutes";
import { AppRoute } from "src/routes/types";
import {USER_TYPES} from "../constants";


const WithHeaderandFooter: FC<{ WrappedComponent: FC }> = ({ WrappedComponent }): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mountTawk, updateMount] = useState(false);

  const { isUserAuthorized, isManufacturer, isRestoreCalled, userData, restoreSession } = useContext(AuthContext);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const isSingleSignOn = localStorage.getItem("fromSingleSignOn")


  const navigateToTargetedRoute = useCallback((RouteListing: AppRoute[]) => {
    const indexRoute = RouteListing.find((route) => route.isIndex);
    const currentRoute = RouteListing.find((route) => {
      const firstCheck = route.route.includes(pathname);
      if (route.route.includes(":")) {
        const checkForRoute = route.route.replace(/:([a-z]+)\/?/gi, "/").replace(/\//g, "");
        const checkForPathname = pathname.replace(/[0-9]/g, "").replace(/\//g, "");
        const secondCheck = checkForRoute === checkForPathname;
        return firstCheck || secondCheck;
      } else {
        return firstCheck;
      }
    });

    if (indexRoute) {
      if (currentRoute) navigate(pathname + search);
      else navigate(indexRoute.route);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkForAuthentication = useCallback(() => {
    if (!isUserAuthorized) {
      navigateToTargetedRoute(PublicRoutes);
    } else {
      if (isSingleSignOn) {
        if (isManufacturer) {
          navigateToTargetedRoute(ManufacturerRoutes);
        } else {
          navigateToTargetedRoute(AuthenticatedRoutes);
        }
      } else {
        if (userData?.is_password_changed === false) {
          navigate("/users/createuser");
        } else if (isManufacturer) {
          navigateToTargetedRoute(ManufacturerRoutes);
        } else {
          navigateToTargetedRoute(AuthenticatedRoutes);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthorized]);

  useEffect(() => {
    if (isRestoreCalled) {
      checkForAuthentication();
    } else if (!userData) {
      restoreSession();
    } else {
      navigate(pathname + search);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkForAuthentication, isRestoreCalled]);

  useEffect(() => {
    if (isUserAuthorized && userData && !USER_TYPES.MANUFACTURER.includes(userData.ref_type)) updateMount(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthorized]);



  return (
    <>
      <header className="bg-primary-gray sticky-top">
        <HubHeader />
      </header>
      <WrappedComponent />
      {mountTawk ? <TawkToMessengerComp /> : null}

      <HubFooter />
    </>
  );
}


export default WithHeaderandFooter;
