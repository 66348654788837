import { FC } from "react";
import { StatisticsObjectType } from "../caseVolumeLineChart/types";
import { Link, useSearchParams } from "react-router-dom";
import CustomPieChart from "../customPieChart";



const CustomComponentDoctor: FC<StatisticsObjectType> = ({ StatisticsObjectType ,title}): JSX.Element => {

  const [searchParams, updateSearchParams] = useSearchParams();

  // console.log("searchparam.....",searchParams.get("userId"))
  const findPath = () => {
    if(title === "Area Manager") {
      return '/regions'
    } else if(title === "Regional Manager") {
      return '/districts'
    } else if(title === "Reps") {
      return '/territories'
    } else if(title === "Practices") {
      return '/practice_stats'
    } else if (title === "Doctors") {
      return "/doctor_stats"
    } else if (title === "Areas") {
      return "/regions"
    } else if (title === "Regions") {
      return "/districts"
    }else if (title === "Territories") {
      return "/territories"
    }
    // else if(title === "district_manager"){
    //   return '/districts'
    // }
  }
  const doctors = StatisticsObjectType.doctors || [];
  const totalCounts = doctors.reduce((sum, item) => sum + (item?.plan_type_data[0]?.count || 0), 0);
  const totalCases = doctors.reduce((sum, item) => sum + Number(item?.plan_type_data[0]?.cases_total || 0), 0);
  return (
    <div className="flex-fill row">
      <div className="col-12 col-md-6 col-sm-12 d-flex">
        <div className="flex-fill card">
          <div className="card-header">
            <h4 className="m-0 text-center">{title}</h4>
          </div>
            
          <div className="card-body p-0 overflow-y-auto" style={{maxHeight:'434px'}}>
            <table className="table table-hover table-responsive" >
              <thead className="table-light sticky-top">
                <tr className="text-center">
                  <th>Provider</th>
                  <th>Zilretta</th>
                  <th>Total Cases</th>
                </tr>
              </thead>

              <tbody>
                {StatisticsObjectType.doctors && StatisticsObjectType.doctors && StatisticsObjectType.doctors.length > 0 &&
                  StatisticsObjectType.doctors.map((item, key) =>
                    <tr className="text-center" key={key}>
                      <td>
                        <Link
                          to={{
                            pathname: '/territories',
                            search: (() => {
                              const newParams = new URLSearchParams()
                              newParams.set("userId", searchParams.get('userId') || "");
                              newParams.set("role", searchParams.get('role') || "");
                              return newParams.toString();
                            })(),
                          }}
                          reloadDocument
                          className="fs-6 fw-normal link-text"
                        >
                          {item.doctor_name}
                        </Link>
                      </td>
                      <td className="text-center text-dark">{item?.plan_type_data[0].count}</td>
                      <td className="text-center text-dark">{item?.plan_type_data[0].cases_total}</td>
                    </tr>
                  )
                }
              </tbody>

              <tfoot>
                <tr className="text-center">
                  <td className="fw-bold">Total</td>
                  <td className="fw-bold">{ totalCounts}</td>
                  <td className="fw-bold">{ totalCases}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <div className="col-12 col-md-6 col-sm-12">
        <CustomPieChart title={"Cases By Submission Mode"} pieChartObject={StatisticsObjectType.mos} />
      </div>
    </div>
  );
};

export default CustomComponentDoctor;
