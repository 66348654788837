import { FC, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import AuthSubHeader from "src/components/authSubHeader";
import FooterText from "src/components/footerText";
import CaseStepOne from "src/components/caseStepOne";
import CaseStepTwo from "src/components/caseStepTwo";
import CaseStepThree from "src/components/caseStepThree";

import { AddCaseSchema } from "src/validation/addCase";
import { AuthContext } from "src/context/authContext";
import { ToastContext } from "src/context/toastContext";
import { ToastTypes } from "src/types";
import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { CaseClonePathParams, CaseCloneResponseType } from "./types";
import { LoaderContext } from "src/context/loaderContext";
import { populatingCloneCase, populatingPrescriptionAttributes } from "src/helpers/cloneCase";
import { UserType } from "src/types/users";
import { AddCaseProps, AddCaseResponseProps } from "../addCase/types";
import RerunRestoreCaseSessionModal from "./modal";



const HubCaseClone: FC = (): JSX.Element => {
  const [step, changeStep] = useState<number>(1);
  const [isModalVisible, showHideModal] = useState<boolean>(false);
  const [isStepTwoMounted, mountingStepTwo] = useState<boolean>(false);
  const [isStepOneInvalid, setStepOneValidity] = useState<boolean>(false);
  const [isStepTwoInvalid, setStepTwoValidity] = useState<boolean>(false);

  const { userData, iovera_practice } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const { hideLoader, setLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const { caseId } = useParams();
  const validationSchema = (AddCaseSchema(iovera_practice))

  const {
    control,
    formState,
    handleSubmit,
    getValues,
    watch,
    trigger,
    reset,
    setValue,
    resetField,
  } = useForm<AddCaseProps>({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      mos: "online",
      submission_source: "provider",
      category: "medrx",
      prescription_attributes: {
        drug_id: 1,
        drug_name: iovera_practice ? "" : "zilretta",
        ...(iovera_practice && { nerves_qty: 0 }),
      },
      patient_attributes: {
        address: {
          address_name: "home",
        },
        phone_numbers: [
          {
            phone_type: "cell",
            id: null,
          },
        ],
      },
      insurances_attributes: [
        {
          global_insurance_id: null,
          is_primary_insurance: true,
          insurance_priority: 0,
        },
      ],
    } as AddCaseProps,
  });
  const patientData = watch("patient_attributes");

  useEffect(() => {
    if (patientData && patientData.global_patient_id)
      trigger(["patient_attributes", "insurances_attributes"]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData]);

  useEffect(() => {
    const data = localStorage.getItem('newCase');
    if (data) showHideModal(true);
  }, [])




  const { error: caseCloneError, data: caseCloneData, loading: caseCloneLoading } =
    useFetch<CaseCloneResponseType, {}, CaseClonePathParams>(APIEndpoints.CLONE_CASE_DETAILS, {}, { caseId: caseId ? Number(caseId) : 0 });

  useEffect(() => {
    if (caseCloneLoading === true) setLoader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseCloneLoading]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (caseCloneData || caseCloneError) {
      hideLoader();

      if (caseCloneData) {
        timeout = setTimeout(() => {
          const caseFormData = populatingCloneCase(caseCloneData, userData || {} as UserType, getValues);
          caseFormData.prescription_attributes = populatingPrescriptionAttributes(caseCloneData)
          reset(caseFormData);
          //console.log("case Form Data .....",caseFormData);
        }, 1500);
      }
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseCloneData, caseCloneError]);




  useEffect(() => {
    const { errors } = formState;

    if (Object.keys(errors).length > 0) {
      if (Object.hasOwn(errors, 'prescription_attributes') || Object.hasOwn(errors, 'procedure_attributes') 
        || Object.hasOwn(errors, 'shipments_attributes')
      )
        setStepOneValidity(true);
      else
        setStepOneValidity(false);

      if (Object.hasOwn(errors, 'patient_attributes') ||
        Object.hasOwn(errors, 'case_doctor') ||
        Object.hasOwn(errors, 'insurances_attributes')
      )
        setStepTwoValidity(true);
      else
        setStepTwoValidity(false);
    } else {
      setStepOneValidity(false);
      setStepTwoValidity(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  useEffect(() => {
    const messages = [];
    const { errors } = formState;

    if (isStepOneInvalid && isStepTwoInvalid && step >= 2) {
      messages.push(`The following sections from step 1 have errors:
     ${errors.procedure_attributes ? '<br>- case procedure<br>' : ''}
      ${errors.prescription_attributes ? '- case prescription<br>' : ''}
      ${errors.shipments_attributes ? '- case prescription shipments' : ''}
      ${errors.category ? '- case type' : ''}
      
      `);

      messages.push(`The following sections from step 2 have errors: <br>
      ${errors.doctor_attributes ? '- case doctor<br>' : ''}
      ${errors && errors.patient_attributes ? '- case patient<br>' : ''}
      ${errors.patient_attributes && errors.patient_attributes.hasOwnProperty("phone_numbers") ? '- case patient phone numbers<br>' : ''}
      ${errors.patient_attributes && errors.patient_attributes.address ? '- case patient address<br>' : ''}
      ${errors.insurances_attributes ? '- case patient insurances' : ''}
      `);
    }
    else if (isStepOneInvalid && step >= 1) {
      messages.push(`The following sections from step 1 have errors:
      ${errors.procedure_attributes ? '<br>- case procedure<br>' : ''}
      ${errors.prescription_attributes ? '- case prescription<br>' : ''}
      ${errors.shipments_attributes ? '- case prescription shipments' : ''}
      ${errors.category ? '- case type' : ''}
     
      `);
    }
    else if (isStepTwoInvalid && step >= 2) {
      messages.push(`The following sections from step 2 have errors: <br>
      ${errors.doctor_attributes ? '- case doctor<br>' : ''}
      ${errors && errors.patient_attributes ? '- case patient<br>' : ''}
      ${errors.patient_attributes && errors.patient_attributes.hasOwnProperty("phone_numbers") ? '- case patient phone numbers<br>' : ''}
      ${errors.patient_attributes && errors.patient_attributes.address ? '- case patient address<br>' : ''}
      ${errors.insurances_attributes ? '- case patient insurances' : ''}`);
    }

    showToast(messages, ToastTypes.WARNING);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStepOneInvalid, isStepTwoInvalid, step]);


  const onStepProgressionClick = (localStep: number) => {
    if (step === 1)
      trigger(["procedure_attributes", "prescription_attributes", "shipments_attributes"]);
    else if (step === 2)
      trigger(["doctor_attributes", "patient_attributes", "insurances_attributes"]);

    if (localStep >= 2)
      mountingStepTwo(true);
    else if (localStep === 3)
      trigger(["procedure_attributes", "prescription_attributes", "doctor_attributes", "patient_attributes", "insurances_attributes","shipments_attributes"]);

    changeStep(localStep);

    //:- logic to save case data in localstorage
    localStorage.setItem('newCase', JSON.stringify({ newData: getValues(), step: localStep }));
  };





  /**
   * @AddNewCase form handling for case data submission
   * @fourth case submit handler
   */
  const { loading: addCaseLoading, data: addCaseData, mutate: addNewCase } =
    useFetch< AddCaseResponseProps >(APIEndpoints.ADD_NEW_CASE);

    useEffect(() => {
      if (addCaseData && addCaseData.status === 200) {
        localStorage.removeItem('newCase');
        showToast([`Your request submitted successfully!`], ToastTypes.SUCCESS);
        if (addCaseData.case_id) {
          if (addCaseData.precert_id) {
            navigate(`/cases/${addCaseData.case_id}/precerts/${addCaseData.precert_id}/edit`);
          } else {
            navigate(`/cases/${addCaseData.case_id}/documents/new`);
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addCaseData]);

  /** @fourth */
  const caseDataSubmission = (values: AddCaseProps) => {
    const prescription_attributes_attributes = [];
    if (values.prescription_attributes.scheduled_date_of_service) {
      prescription_attributes_attributes.push({
        name: "scheduled_date_of_service",
        value: values.prescription_attributes.scheduled_date_of_service
      });
    }

    if (values.prescription_attributes.surgical_date) {
      prescription_attributes_attributes.push({
        name: "surgical_date",
        value: values.prescription_attributes.surgical_date,
      })
    }

    if (values.prescription_attributes.injection_site) {
      prescription_attributes_attributes.push({
        name: "injection_site",
        value: values.prescription_attributes.injection_site
      });
    }

    if (values.prescription_attributes.last_date_of_service || values.prescription_attributes.last_surgical_date) {
      prescription_attributes_attributes.push({
        name: values.prescription_attributes.drug_name === "iovera" || values.prescription_attributes.drug_name === "iovera°" ? "last_surgical_date" : "last_date_of_service",
        value: values.prescription_attributes.drug_name === "iovera°" || values.prescription_attributes.drug_name === "iovera" ? values.prescription_attributes.last_surgical_date : values.prescription_attributes.last_date_of_service
      });
    }

    if (values.prescription_attributes.treatment_date) {
      prescription_attributes_attributes.push({
        name: "treatment_date",
        value: values.prescription_attributes.treatment_date
      });
    }

    if (values.prescription_attributes.nerves_qty) {
      prescription_attributes_attributes.push({
        name: "nerves_qty",
        value: values.prescription_attributes.nerves_qty
      });
    }

    const nerves = [];
    if(values.prescription_attributes.nerves && values.prescription_attributes.nerves.length > 0) {
      nerves.push(
        values.prescription_attributes.other_nerves
      )
    }
    if (values.prescription_attributes.nerves) {
      const allNerves = values.prescription_attributes.nerves.concat(nerves as string[])
      prescription_attributes_attributes.push({
        name: "nerves",
        value: allNerves.join(","),
      });
    }

    const shipments_attributes = [];
    const shipments_attributes_attributes = [];
    if (values.shipments_attributes && values.shipments_attributes.length > 0) {
      shipments_attributes.push({
        ...values.shipments_attributes[0],
        ship_to: "other",
      });
    }
    if ( values.prescription_attributes.drug_name === "iovera°" || values.prescription_attributes.drug_name === "iovera") {

      if (values.shipments_attributes && values.shipments_attributes.length > 0 && values.shipments_attributes[0].ship_to_npi) {
        shipments_attributes_attributes.push({
          name: "npi",
          value: values.shipments_attributes[0].ship_to_npi,
        })
      }
    }
    
    

    const { other_nerves, injection_site, ...actualPrescription } = values.prescription_attributes;

    const newValues = {
      ...values,
      drug_name: values.prescription_attributes.drug_name,
      ...(values.procedure_attributes ? {procedures_attributes: [
        { cpt_code: values.procedure_attributes.cpt_code },
      ]} : {}),
      prescription_attributes: {
        ...actualPrescription,
        drug_name: values.prescription_attributes.drug_name,
        drug_id: values.prescription_attributes.drug_name === "iovera°" || values.prescription_attributes.drug_name === "iovera" ? 14 : 1,
        nerves,
        // injection_site: values.prescription_attributes.injection_site,
        diagnosis_code: values.prescription_attributes.diagnosis_code.join(","),
        // ndc: dataProduct.find((drug) => values.prescription_attributes.drug_name === drug.value)?.ndc || "",
        prescription_attributes_attributes,
      },
      shipments_attributes,
      shipments_attributes_attributes,
      patient_firstlastname: `${values.patient_attributes.firstname} ${values.patient_attributes.lastname}`,
      patient_fullname: `${values.patient_attributes.firstname} ${values.patient_attributes.middlename} ${values.patient_attributes.lastname}`,
      patient_dob: values.patient_attributes.dob,
      insurances_attributes: values.insurances_attributes?.map((insurance, index) => {
        insurance.insurance_priority = index;
        return insurance;
      }),
      patient_attributes: {
        ...values.patient_attributes,
        ...(values.patient_attributes.patient_email && {
          patient_email: {
            id: values.patient_attributes.patient_email_id,
            email: values.patient_attributes.patient_email
          }
        })
      },
    };

    const { procedure_attributes, ...actualCaseValues } = newValues;
     addNewCase({ case: actualCaseValues });
  };



  return (
    <div className="d-flex flex-fill body-bg overflow-auto">
      <div className="container d-flex flex-column flex-fill pt-2">
        <AuthSubHeader />

        <form className="d-flex flex-column flex-fill" onSubmit={handleSubmit(caseDataSubmission)}>
          <div className="py-3 sticky-top top-0 z-1">
            <div id="case-creation-progress-bar" className="case-progress-bar">
              <button
                type="button"
                id="progress-step-1"
                className={`btn col-4 case-creation-progress-step ${step >= 1 ? isStepOneInvalid ? 'invalid-progress-step' : 'active-progress-step' : ''}`}
                onClick={() => onStepProgressionClick(1)}
              >
                1. Getting Started <i className={`bi bi-play-fill progression-arrow-head`}></i>
              </button>

              <button
                type="button"
                id="progress-step-2"
                className={`btn col-4 case-creation-progress-step ${step >= 2 ? isStepTwoInvalid ? 'invalid-progress-step' : 'active-progress-step' : ''}`}
                onClick={() => onStepProgressionClick(2)}
              >
                2. Patient Information <i className={`bi bi-play-fill progression-arrow-head`}></i>
              </button>

              <button
                type="button"
                id="progress-step-3"
                className={`btn col-4 case-creation-progress-step ${step >= 3 ? 'active-progress-step' : ''}`}
                onClick={() => onStepProgressionClick(3)}
              >
                3. Finish
              </button>
            </div>
          </div>

          <Controller name="original_id" control={control} defaultValue={Number(caseId)} render={() => <></>}/>

          {userData ? (
            <>
              <Controller
                name="creator_name"
                control={control}
                defaultValue={userData.name}
                render={() => (<></>)}
              />

              <Controller
                name="user_id"
                control={control}
                defaultValue={userData.id}
                render={() => (<></>)}
              />
            </>
          ) : (<></>)}

          <div id="step-1" className="case-creation-step">
            {step >= 1 && caseCloneData &&
              <CaseStepOne 
                control={control} watch={watch} setValue={setValue} getValues={getValues}
                isVisible={step === 1}
              />
              }
          </div>
          <div id="step-2" className="case-creation-step">
            {isStepTwoMounted &&
              <CaseStepTwo resetField={resetField} control={control} getValues={getValues} watch={watch}
                setValue={setValue} isVisible={step === 2} trigger={trigger}
              />
            }
          </div>
          <div id="step-3" className="case-creation-step">
            {step === 3 && <CaseStepThree getValues={getValues} control={control} />}
          </div>

          <div className="d-flex mt-2">
            <button type="button" key="previousStepButton"
              className={`me-3 btn bg-white ${step === 1 ? 'd-none' : ''}`}
              onClick={() => onStepProgressionClick(step - 1)}
            >
              <i className="bi bi-chevron-double-left"></i> Previous
            </button>

            {step === 3 ? (
              <button type="submit" disabled={addCaseLoading} key="formSubmitButton"
                className="d-flex btn btn-primary"
              >
                {addCaseLoading ? (
                  <i className="text-white me-2 spinner-border" style={{width: `24px`, height: `24px`}}></i>
                ) : null}
                Submit Request
              </button>
            ) : (
              <button type="button" key="nextStepButton" className="btn bg-white"
                onClick={() => onStepProgressionClick(step + 1)}
              >
                Next <i className="bi bi-chevron-double-right"></i>
              </button>
            )}
          </div>
        </form>

        <FooterText footerType="black" />
      </div>

      <RerunRestoreCaseSessionModal
        isModalVisible={isModalVisible}
        removeCurrentData={() => {
          showHideModal(false)
          localStorage.removeItem("newCase")
        }}
        restoringCaseData={() => {
          showHideModal(false);
          navigate("/cases/new", { state: { fromClone: true } })
        }}
      />
    </div>
  );
};

export default HubCaseClone;
