import { FC, useContext, useEffect, useReducer, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

import AuthSubHeader from "src/components/authSubHeader";
import FooterText from "src/components/footerText";
import CreateOrderModal from "src/components/modals/createOrderModal";

import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { ToastContext } from "src/context/toastContext";
import { ToastTypes } from "src/types";
import { AuthContext } from "src/context/authContext";
import { LoaderContext } from "src/context/loaderContext";
import { ORDER_HEADER_LISTING } from "src/constants/cases";

import { initialState, queryParamReducer } from "./reducer";
import { QueryParamsState, QueryStateActionType, QueryStateActions, OrderListAPIResponse, OrderListErrorResponse } from "./types";
import { getDateFormat } from "src/helpers/dateTime";



const OrderListing: FC = (): JSX.Element => {
  const [queryParams, dispatch] = useReducer(queryParamReducer, initialState);
  const [orderTreatmentModal, setDataForOrderModal] = useState<{ isModalVisible: boolean; }>({
    isModalVisible: false,
  });

  const { showToast } = useContext(ToastContext);
  const { destroySession } = useContext(AuthContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();




  const { data: orderData, mutate: getOrderListing, error: orderError, removeErrorAndData: clearOrderState } =
    useFetch<OrderListAPIResponse, QueryParamsState>(APIEndpoints.ORDER_LISTING, queryParams);

  useEffect(() => {
    if (orderError) {
      hideLoader();
      const { code, error } = orderError as unknown as OrderListErrorResponse;
      showToast([error || `Can't fetch Order List right now. Please try again in a while.`], ToastTypes.ERROR);
      if (code === 401) {
        destroySession();
        showToast([`You need to sign up or sign in to continue`], ToastTypes.ERROR);
        navigate(`/users/sign_in`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderError]);

  useEffect(() => {
    if (orderData?.code || orderError) {
      hideLoader();
      if(orderData?.code){
        if (orderData.code === 500) {
          showToast(['No Orders found against provided filters'], ToastTypes.ERROR);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData, orderError]);

  // const onClearButtonClicked = () => {
  //   if (orderError || orderData) clearOrderState();
  //   dispatch({
  //     type: QueryStateActions.CLEAR_STATE,
  //   });
  //   getOrderListing(null, null, initialState);
  // };

  const handleSearchWithQueryParam = (updatedState?: QueryParamsState) => {
    if (orderError || orderData) clearOrderState();
    setLoader();
    getOrderListing(null, null, updatedState ? updatedState : queryParams);
  };





  const changeIdOrder = (value: string) => {
    const order = queryParams.direction === 'desc' ? 'asc' : 'desc';

    const action: QueryStateActionType = {
      type: QueryStateActions.CHANGE_SORT_PARAMS,
      payload: {
        direction: queryParams.sort_by === value ? order : "desc",
        sort_by: value,
      },
    };
    dispatch(action);

    const updatedState = queryParamReducer(queryParams, action);
    handleSearchWithQueryParam(updatedState);
  };

  const changePageNumber = (page: { selected: number; }) => {
    const action: QueryStateActionType = {
      type: QueryStateActions.CHANGE_PAGE,
      payload: page.selected+1,
    };
    dispatch(action);

    const updatedState = queryParamReducer(queryParams, action);
    handleSearchWithQueryParam(updatedState);
  };

  return (
    <div className="container-fluid auth-page">
      <AuthSubHeader />

      <div className="card m-1 mt-4">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col-6">
              <h3 className="m-0">Orders</h3>
            </div>

            <div className="col-6 text-end">
              {orderData && orderData.data && orderData.data.order_button ? (
                <button
                  type="button"
                  className="btn btn-success fs-6 rounded-0 me-1"
                  onClick={() => setDataForOrderModal({ isModalVisible: true })}
                >
                  <i className="bi bi-prescription"></i>{' '}
                  Order
                </button>
              ) : (
                <span className="text-black fw-bold mb-0 me-2">Please Order From Treatment Screen</span>
              )}
              <button
                disabled={true}
                type="button"
                className="btn btn-danger fs-6 rounded-0"
              >
                <i className="bi bi-x-lg"></i>{' '}
                Return
              </button>
            </div>
          </div>
        </div>

        <div className="card-body p-0">
          <div className="px-3 pt-3">
            <p>The following is a list of previously placed orders and their statuses. If an order was placed for a specific patient, the associated case will be linked below. Bulk orders will not have associated links</p>
            {/* <TableFilters
              dropdownData={ORDER_STATUS_LISTING}
              inputPlaceholder="Search by ID, Case ID"
              state={queryParams}
              dispatch={dispatch}
              handleSearchWithQueryParam={handleSearchWithQueryParam}
              onClearButtonClicked={onClearButtonClicked}
            /> */}
          </div>

          <div className="table-responsive mt-3">
            <table className="table table-striped table-sm">
              <thead>
                <tr className="text-center align-items-center">
                  {ORDER_HEADER_LISTING.map((tableHeader, index) => (
                    <th key={index} className="text-white bg-primary" scope="col" onClick={() => changeIdOrder(tableHeader.value)}>
                      <span
                        role={tableHeader.value === '' ? "textbox" : "button"}
                        className={
                          tableHeader.value === '' ?
                            'fw-bold fs-6 lh-base' :
                            'btn btn-link link-light link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover p-0 fw-bold fs-6'
                        }
                      >
                        {tableHeader.label}
                        {queryParams.sort_by === tableHeader.value ? (
                          <i className={`bi ${queryParams.direction === 'desc' ? 'bi-caret-down-fill' : 'bi-caret-up-fill'}`}></i>
                        ) : null}
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {orderData && orderData.data && orderData.data.orders && orderData.data.orders.length > 0 ?
                  orderData.data.orders.map((item) => (
                    <tr key={item.order_id} className="align-middle text-center">
                      <td className="fs-6">{item ? item.order_id : ''}</td>
                      <td>
                        {item.case_id !== 'N/A' ? (
                          <Link
                            to={{ pathname: `/cases/detail/${item.case_id}` }}
                            className="btn btn-link link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover fs-6"
                          >
                            {item.case_id}
                          </Link>
                        ) : null}
                      </td>
                      <td className="fs-6">{item.po_number !== 'N/A' ? item.po_number : ''}</td>
                      <td className="fs-6">{item.ordered_by}</td>
                      <td className="fs-6">{item.order_date !== 'N/A' ? getDateFormat(item.order_date) : ''}</td>
                      <td className="fs-6">{item.completion_date !== 'N/A' ? getDateFormat(item.completion_date) : ''}</td>
                      <td className="fs-6">{item ? item.status : ''}</td>
                    </tr>
                  ))
                : null}
              </tbody>
            </table>

            {orderData && orderData.data.total_pages ? (
              <div className="pagination justify-content-center">
              {queryParams.page !== 1 ? (
                <li className="page-item">
                  <button className="btn btn-link page-link" onClick={() => changePageNumber({ selected: 0 })}>
                    <i className="bi bi-chevron-double-left"></i> First
                  </button>
                </li>
              ) : null}
              <ReactPaginate
                nextLabel={<>Next <i className="bi bi-chevron-right"></i></>}
                onPageChange={changePageNumber}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={orderData.data.total_pages}
                previousLabel={<><i className="bi bi-chevron-left"></i> Previous</>}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link rounded-0"
                nextClassName="page-item"
                nextLinkClassName="page-link rounded-0"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
                forcePage={queryParams.page-1}
              />
              {queryParams.page !== orderData.data.total_pages ? (
                <li className={`page-item ${queryParams.page === orderData.data.total_pages ? 'disabled' : ''}`}>
                  <button className="btn btn-link page-link" onClick={() => changePageNumber({ selected: orderData.data.total_pages-1 })}>
                    Last <i className="bi bi-chevron-double-right"></i>
                  </button>
                </li>
              ) : null}
            </div>
        ) : null}

          </div>
        </div>
      </div>

      <FooterText footerType="black" />

      <CreateOrderModal
        isModalVisible={orderTreatmentModal.isModalVisible}
        hideModal={() => {
          setDataForOrderModal({
            isModalVisible: false,
          });
        }}
        orderType={orderData ? orderData.data.order_type : []}
        refreshListing = {()=> {
          getOrderListing(null, null, initialState);
        }}
      />
    </div>
  );
};

export default OrderListing;
