import React, { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'src/context/authContext';
import { ToastContext } from 'src/context/toastContext';
import { USER_TYPES } from 'src/constants';

import { UserType } from 'src/types/users';
import { SSOLoginAPIResponseType } from './types';
import { ToastTypes } from 'src/types';
import { apiConstants } from 'src/constants/api';




const AuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const { createSession } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);

  const getRedirectUri = (): string => {
    return `${window.location.origin}/auth/entra_id/callback`;
  };

  const clientId = process.env.REACT_APP_AZURE_CLIENT_ID || '';
  const backend_redirect_uri = process.env.REACT_APP_BACKEND_AUTH_URL || "";
  const tenantId = process.env.REACT_APP_AZURE_TENANT_ID || 'common';

  const reRouteRelevantUser = (userData: UserType) => {
    if (userData.ref_type === 'Manufacturer') {
      navigate("/statistics");
    } else if (userData.ref_type === 'regional_manager') {
      navigate("/regions");
    } else if (userData.ref_type === 'district_manager') {
      navigate("/districts");
    } else if (userData.ref_type === 'fam_am') {
      navigate("/fam_regions");
    } else if (userData.ref_type === 'fam_rm') {
      navigate("/fam_districts");
    } else if (userData.ref_type === 'rep') {
      navigate("/territories");
    } else if (USER_TYPES.HCP.includes(userData.ref_type)) {
      navigate("/cases");
    }
  };



  const processUserAuth = useCallback((code: string, redirectUri: string, codeVerifier: string, scopes: string) => {
    const tokenEndpoint = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;
    const params = new URLSearchParams();
    params.append('client_id', clientId);
    params.append('grant_type', 'authorization_code');
    params.append('code', code);
    params.append('redirect_uri', redirectUri);
    params.append('code_verifier', codeVerifier);
    params.append('scopes', scopes);

    // console.log('Token Exchange Request Parameters:', params.toString());
    fetch(tokenEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: params.toString(),
    })
      .then((response) => {
        if (!response.ok) return { isError: true, data: response.text()}
        return { isError: false, data: response.json()}
      })
      .then((transformmedData) => {
        if (transformmedData.isError) throw new Error(transformmedData.data.toString())
        return transformmedData.data;
      })
      .then((data) => {
        // Send the token to the backend for validation
        return fetch(backend_redirect_uri, {
          method: 'POST',
          headers: apiConstants.headers,
          body: JSON.stringify(data)
        });
      })
      .then((backendResponse) => {
        if (!backendResponse.ok) {
          console.log(backendResponse.status)
          return { isError: true, data: backendResponse.json() };
        }
        return { isError: false, data: backendResponse.json() };
      })
      .then((transformedRes) => {
        if (transformedRes.isError) {
          throw new Error((transformedRes.data as unknown as { code: number, error: string }).error);
        }
        else return transformedRes.data;
      })
      .then((backendData: SSOLoginAPIResponseType) => {
        if (backendData && backendData.status && backendData.status.code === 200) {
          console.log('SSO login successful, JWT:');
          console.log(backendData.status.data.user, backendData.status.data.sub_header, `bearer ${backendData.status.data.token}`, backendData.status.data.iovera_practice)
          const { status } = backendData;
          createSession(
            {
              ...status.data.user,
              practice_created_date: status.data.address ? status.data.address.date : "",
              practice_name: status.data.address ? status.data.address.name : "",
              address: status.data.address ? status.data.address.ref_address : "",
              reset_demo_cases: status.data.navbar.reset_demo_cases,
              stats_view: status.data.navbar.stats_view,
              demo_user_view: status.data.navbar.demo_user_view,
              
            },
            status.data.sub_header,
            `bearer ${status.data.token}`,
            true,
          );
          showToast([status.message], ToastTypes.INFO);
          localStorage.setItem("fromSingleSignOn", "true")
          reRouteRelevantUser(status.data.user);
        } else {
          console.error('SSO backend validation failed:', backendData?.status?.message || 'Unknown error');
          throw new Error(backendData?.status?.message || 'Login failed. Please try again.')
        }
      })
      .catch((error) => {
        if (typeof error === "string") {
          showToast([error], ToastTypes.ERROR)
          navigate("/users/sign_in")
        } else {
          showToast([error.toString()], ToastTypes.ERROR);
          navigate("/users/sign_in");
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
  
    const redirectUri = getRedirectUri();
  
    //const scopes = 'openid profile email User.Read';
     const scopes = process.env.REACT_APP_AZURE_SCOPES || "";
    //const scopes = 'openid profile email offline_access User.Read User.Read.All Group.Read.All Directory.Read.All';
    // console.log({ scopes })

    if (code) {
      const newUrl = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);

      const codeVerifier = localStorage.getItem('codeVerifier'); //store it to session storage instead of local storage...
      if (codeVerifier) {
        processUserAuth(code, redirectUri, codeVerifier, scopes)
      } else {
        // console.error('Code verifier not found in local storage');
        showToast(['Authorization failed. Please try logging in again.'], ToastTypes.ERROR);
        navigate('/users/login');
      }
    } else {
      showToast(['Authorization failed. Please try logging in again.'], ToastTypes.ERROR);
      navigate('/users/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <div style={{ textAlign: 'center', marginTop: '20%' }}>
      <h1>Authenticating...</h1>
      <p>Please wait while we log you in.</p>
      <span className="spinner-border spinner-border-sm" style={{ width: 30, height: 30 }} aria-hidden="true"></span>
    </div>
  );
};

export default AuthCallback;


