import { FC, useContext, useEffect, MouseEvent, useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";

import DropdownPossibleNextStep from "../dropdownPossibleNextStep";

import { AuthContext } from "src/context/authContext";
import { LoaderContext } from "src/context/loaderContext";
import { ToastContext } from "src/context/toastContext";
import useFetch from "src/hooks/useFetch";

import { LogoBrand } from "src/assets";
import { AuthenticatedHCPHeader, AuthenticatedManufactureHeader } from "src/constants/headerLinks";
import { HeaderElementType, ToastTypes } from "src/types";
import { APIEndpoints } from "src/types/apiTypes";
import { LogoutAPISuccessResponseType, ResetDemoCasesAPISuccessResponseType } from "./types";
import flexionTour from "src/constants/tour";
import DropdownStatusColorMeaning from "../dropdownStatusColorMeaning";
import { createUserAPIResponseType, resourceAnalyticsAPIResponse } from "src/pages/createUser/types";
import { redirectPath } from "src/constants";



const AuthHeader: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { userData, updateUserData, destroySession, isManufacturer, isUserAuthorized } = useContext(AuthContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const { showToast } = useContext(ToastContext);
  const isSingleSignOn = localStorage.getItem("fromSingleSignOn")


  const [openSubmenus, setOpenSubmenus] = useState<number[]>([]);

  const autoCollapseSubmenu = (event: MouseEvent<HTMLAnchorElement>, submenuIndex: number) => {
    // Check if clicked element is the dropdown toggle
    if ((event.target as HTMLAnchorElement).matches('a.dropdown-item.dropdown-toggle')) {
      event.stopPropagation(); // Prevent parent dropdown from collapsing
      setOpenSubmenus((prevState) => {
        const isAlreadyOpen = prevState.includes(submenuIndex);

        if (isAlreadyOpen) {
          // Close the submenu
          return prevState.filter((index) => index !== submenuIndex);
        } else {
          // Close all other submenus and open the clicked one
          return [submenuIndex];
        }
      });
    }
  };

  const { data: resourseAnalyticsResponse, mutate: resourseAnalyticsMutate } =
    useFetch<resourceAnalyticsAPIResponse>(APIEndpoints.RESOURCE_ANALYTICS, {}, { userId: userData?.id });

  useEffect(() => {
    if (resourseAnalyticsResponse && resourseAnalyticsResponse.code === 200) {
     
       // updateUserData({ ...userData, ...resourseAnalyticsResponse.status.data.user });
        //setTimeout(() => navigate(0), 300)
        console.log("Api Response Successful")
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourseAnalyticsResponse]);

  const clickOnResources = (product:string,resources:string) => {
    //console.log("Click On Resources.......",product,"     dfgsdfgsdgsdfgsdfgf",resources);
    const params = {
      product: product === "iovera Resources" ? 'iovera' : 'zilretta',
      resource: resources,
      clicked_at: new Date(),
      clicked_by: userData?.name,
      clicked_by_id: userData?.id
    }
    resourseAnalyticsMutate({},{},params)
  }




  const { data: updateUserApiResponse, mutate: addNewUser } =
    useFetch<createUserAPIResponseType>(APIEndpoints.USER_UPDATE, {}, { userId: userData?.id });

  useEffect(() => {
    if (updateUserApiResponse) {
      if (updateUserApiResponse.status.data && updateUserApiResponse.status.data.user) {
        updateUserData({
          ...userData,
          ...updateUserApiResponse.status.data.user,
          reset_demo_cases: updateUserApiResponse.status.data.navbar?.reset_demo_cases,
          stats_view: updateUserApiResponse.status.data.navbar?.stats_view,
          demo_user_view: updateUserApiResponse.status.data.navbar?.demo_user_view,
        });
        setTimeout(() => navigate(0), 300)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserApiResponse]);

  const submitForm = async (values: unknown) => {
    if ((error && error.length > 0) || updateUserApiResponse) removeErrorAndData();
    addNewUser({ user: values });
  };




  const { mutate, error, data, removeErrorAndData } = useFetch<LogoutAPISuccessResponseType>(APIEndpoints.USER_LOGOUT);

  useEffect(() => {
    if (data || error) {
      hideLoader();
      if (data && data.status === 200) {
        destroySession();
        showToast([data.message], ToastTypes.SUCCESS);
        navigate('/users/sign_in');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  const logoutUser = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if ((error && error.length > 0) || data) removeErrorAndData();

    setLoader();
    mutate();
  };




  const { mutate: resetDemoCasesApi, data: resetDemoCasesApiResponse, error: resetDemoCasesApiResponseError } =
    useFetch<ResetDemoCasesAPISuccessResponseType>(APIEndpoints.DEMO_RESET_CASES);

  useEffect(() => {
    if (resetDemoCasesApiResponse?.status === 'success' || resetDemoCasesApiResponseError) {
      hideLoader();
      if (resetDemoCasesApiResponse?.status === 'success') {
        showToast([resetDemoCasesApiResponse.message], ToastTypes.SUCCESS);
        if (window.location.pathname === "/cases") {
          navigate(0)
        } else {
          navigate('/cases');
        }
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetDemoCasesApiResponse, resetDemoCasesApiResponseError]);




  // const { data: retreatmentRequestCount } =
  //   useFetch<{ retreatment_count: number; }>(APIEndpoints.GET_RETREATMENT_REQUESTS_COUNT, {}, {}, !isManufacturer);




  return (
    <nav className="navbar navbar-expand-lg py-1 px-3" style={{ backgroundColor: "#808285" }}>
      <Link className="navbar-brand" to={isUserAuthorized ?
        (userData?.is_password_changed || isSingleSignOn ?
          (isManufacturer ? (userData ? redirectPath(userData) : "") : `/cases`) :
          '/users/createuser'
        ) :
        `/users/sign_in`
      }
      >
        <img className="img-fluid after-login-logo" src={LogoBrand} alt="Euflexxa logo" />
      </Link>
      <button className="navbar-toggler bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#authHeader">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="authHeader">
        <ul className="navbar-nav me-auto first-nav">
          {(userData && userData.is_password_changed) || isSingleSignOn ? (
            <>
              {isManufacturer === true ?
                AuthenticatedManufactureHeader.map((headerLink, index) => (
                  <li
                    className={`nav-item ${headerLink.actionType === HeaderElementType.DRPODOWN ? 'dropdown' : ''}`}
                    key={index}
                  >
                    {headerLink.actionType === HeaderElementType.ROUTE ? (
                      <NavLink
                        className={({ isActive }) => {
                          return ["nav-link", isActive ? "custom-active-link" : ""].join(" ")
                        }}
                        aria-current="page"
                        to={headerLink.title === "Statistics" ? (userData ? redirectPath(userData) : "") : headerLink.redirectLink}
                        end
                      >
                        {headerLink.title}
                      </NavLink>
                    ) : null}
                    {headerLink.actionType === HeaderElementType.BUTTON ? (
                      <button className="nav-link btn-localCoverage rounded-pill"
                        onClick={() => { navigate(headerLink.redirectLink) }}>
                        {headerLink.title}
                      </button>
                    ) : null}
                    {headerLink.actionType === HeaderElementType.LINK ? (
                      <a className="nav-link" href={headerLink.redirectLink} target="_blank" rel="noreferrer">
                        {headerLink.title}
                      </a>
                    ) : null}
                    {headerLink.actionType === HeaderElementType.DRPODOWN ? (
                      <>
                        <a
                          className="nav-link dropdown-toggle"
                          href="."
                          data-bs-toggle="dropdown" aria-expanded="false"
                        >
                          {headerLink.title}
                        </a>
                        <ul className="dropdown-menu dropdown-center">
                          {headerLink.dropdown && headerLink.dropdown.map((header, index) => {
                            return header.dropdown && header.dropdown.length > 0 ? (
                              <li className="submenu submenu-md dropend" key={index}>
                                <a className="dropdown-item dropdown-toggle" role="button"
                                  data-bs-toggle="dropdown" aria-expanded="false"
                                  onClick={(event) => autoCollapseSubmenu(event, index)}
                                  href="."
                                >
                                  {header.title}
                                </a>
                                <ul className={`dropdown-menu py-2 ${openSubmenus.includes(index) ? 'show' : ''}`}>
                                  {header.dropdown.map((dropdown, index) => (
                                    <li className="px-0" key={index}>
                                      <a className="dropdown-item" href={dropdown.redirectLink} target="_blank" rel="noreferrer" onClick={()=>clickOnResources(header.title.toString(),dropdown.title.toString())}>
                                        {dropdown.title}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ) : (
                              <li key={index}>
                                <a className="dropdown-item" href={header.redirectLink} target="_blank" rel="noreferrer" >
                                  {header.title}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    ) : null}
                  </li>
                ))
                :
                AuthenticatedHCPHeader.map((headerLink, index) => (
                  <li
                    className={`nav-item ${headerLink.actionType === HeaderElementType.DRPODOWN ? 'dropdown' : ''}`}
                    key={index}
                  >
                    {headerLink.actionType === HeaderElementType.ROUTE ? (
                      <NavLink
                        className={({ isActive }) => {
                          return ["nav-link", isActive ? "custom-active-link" : ""].join(" ")
                        }}
                        aria-current="page"
                        to={headerLink.redirectLink}
                        data-tour={headerLink.title === "Submit Request" ? "fifth-step" : ""}
                        end
                      >
                        {headerLink.title}{' '}
                        {/* {headerLink.redirectLink === "/retreatments" ?
                          retreatmentRequestCount ?
                            (
                              <span className="bg-primary text-white rounded-1 p-1" style={{ fontSize: 11 }}>
                                {retreatmentRequestCount.retreatment_count}
                              </span>
                            ) : */}
                        {/* (
                              <span className="placeholder-glow">
                                <span className="placeholder bg-primary" style={{ minWidth: 15 }}></span>
                              </span>
                            ) */}
                        {/* : null
                        } */}
                      </NavLink>
                    ) : null}
                    {headerLink.actionType === HeaderElementType.BUTTON ? (
                      <button className="nav-link btn-localCoverage rounded-pill"
                        onClick={() => { navigate(headerLink.redirectLink) }}>
                        {headerLink.title}
                      </button>
                    ) : null}
                    {headerLink.actionType === HeaderElementType.LINK ? (
                      <a className="nav-link" href={headerLink.redirectLink} target="_blank" rel="noreferrer">
                        {headerLink.title}
                      </a>
                    ) : null}
                    {headerLink.actionType === HeaderElementType.DRPODOWN ? (
                      <>
                        <a
                          className="nav-link dropdown-toggle"
                          href="."
                          data-bs-toggle="dropdown" aria-expanded="false"
                        >
                          {headerLink.title}
                        </a>
                        <ul className="dropdown-menu dropdown-center">
                          {headerLink.dropdown && headerLink.dropdown.map((header, index) => {
                            return header.dropdown && header.dropdown.length > 0 ? (
                              <li className="submenu submenu-md dropend" key={index}>
                                <a className="dropdown-item dropdown-toggle" role="button"
                                  data-bs-toggle="dropdown" aria-expanded="false"
                                  onClick={(event) => autoCollapseSubmenu(event, index)}
                                  href="."
                                >
                                  {header.title}
                                </a>
                                <ul className={`dropdown-menu py-2 ${openSubmenus.includes(index) ? 'show' : ''}`}>
                                  {header.dropdown.map((dropdown, index) => (
                                    <li className="px-0" key={index}>
                                      <a className="dropdown-item" href={dropdown.redirectLink} target="_blank" rel="noreferrer" onClick={()=>clickOnResources(header.title.toString(),dropdown.title.toString())}>
                                        {dropdown.title}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ) : (
                              <li key={index}>
                                <a className="dropdown-item" href={header.redirectLink} target="_blank" rel="noreferrer">
                                  {header.title}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    ) : null}
                  </li>
                ))
              }
            </>
          ) : null}
        </ul>

        <div className="text-start text-lg-end">
          <ul className="navbar-nav justify-content-end">
            {userData?.is_password_changed || isSingleSignOn ? (
              isManufacturer ?
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle" href="." data-bs-toggle="dropdown"
                    aria-expanded="false" role="button"
                  >
                    {userData?.name}
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <Link className="dropdown-item" to='/users/updateuser'>
                        Account Settings
                      </Link>
                    </li>
                    {/* i am adding this check for only Manufacture  */}
                    {userData && (userData.demo_user_view || userData.stats_view) ? (
                      <li>
                        <button className="dropdown-item" onClick={() => submitForm({ demo: true })}>
                          Demo User View
                        </button>
                      </li>
                    ) : null}
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a className="dropdown-item" href="."
                        onClick={logoutUser}
                      >Logout</a>
                    </li>
                  </ul>
                </li>
                :
                <>
                  <DropdownStatusColorMeaning />
                  <DropdownPossibleNextStep />
                  {window.location.pathname === "/cases" && (
                    <li className="nav-item dropdown">
                      <button onClick={() => flexionTour.start()} className="btn btn-success rounded-0" type="button">
                        Take a Tour
                      </button>
                    </li>
                  )}
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle" href="."
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      role="button"
                    >
                      {userData?.name}
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li><Link className="dropdown-item" to="/users/updateuser">
                        Account Settings</Link>
                      </li>
                      <li><Link className="dropdown-item" to="/users/new">Add User</Link>
                      </li>
                      <li><Link className="dropdown-item" to="/doctors/new">
                        Add Doctor
                      </Link></li>
                      <li><Link className="dropdown-item" to="/pages/baa">BAA</Link></li>
                      {userData && userData.reset_demo_cases ? (
                        <li><button className="dropdown-item" onClick={() => resetDemoCasesApi()}>Reset Demo Cases</button></li>
                      ) : null}
                      {userData && ((Object.hasOwn(userData, "stats_view") && userData.stats_view === true)) ? (
                        <>
                          {/* <li><button className="dropdown-item" onClick={() => resetDemoCasesApi()}>Reset Demo Cases</button></li> */}
                          <li><button className="dropdown-item" onClick={() => submitForm({ demo: false })}>Stats View</button></li>
                        </>
                      ) : null}
                      {/* {userData && userData.reset_demo_cases ? (
                          <li><button className="dropdown-item" onClick={() => resetDemoCasesApi()}>Reset Demo Cases</button></li>
                        ) : null}
                        {userData && userData.stats_view ? (
                          <li><button className="dropdown-item" onClick={() => submitForm({ demo: false })}>Stats View</button></li>
                        ) : null} */}
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li><a className="dropdown-item" href="." onClick={logoutUser}>
                        Logout
                      </a></li>
                    </ul>
                  </li>
                </>
            ) : (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle" href="." data-bs-toggle="dropdown"
                  aria-expanded="false" role="button"
                >
                  {userData?.name}
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li><a className="dropdown-item" href="." onClick={logoutUser}>Logout</a></li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default AuthHeader;
