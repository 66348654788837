import React, { useContext, useEffect, useState } from 'react';
import AuthMaunfacturerSubHeader from 'src/components/manufacturer/authManufacturerSubHeader';
import useFetch from 'src/hooks/useFetch';
import { APIEndpoints } from 'src/types/apiTypes';
import { LocalCoverageErrorResponse, LocalCoverageResponseType } from './types';
import { downloadPdfFile } from 'src/helpers/downloadFile';
import { DownloadReportsResponseTypeApi } from 'src/components/manufacturer/statsReports/types';
import AuthSubHeader from "../../components/authSubHeader";
import FooterText from "../../components/footerText";
import { AuthContext } from 'src/context/authContext';
import ContactAndSafetyInfo from 'src/components/contactAndSafetyInfo';
import { TextAlignProps } from 'src/components/contactAndSafetyInfo/types';

const CoverageLookup: React.FC = () => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [zipCode, setZipCode] = useState<string>('');
  const [enabled, setEnabled] = useState<boolean>(false)
  const [enabledbutton, setEnabledButton] = useState<boolean>(false)
  const { isUserAuthorized } = useContext(AuthContext);

  const { data: getLocalCoverage, error: localCoverageError, mutate: localCoverageMutate } =
    useFetch<LocalCoverageResponseType>(APIEndpoints.LOCAL_COVERAGE);

  useEffect(() => {
    if (localCoverageError) {
      // hideLoader();
      const { code } = localCoverageError as unknown as LocalCoverageErrorResponse;
      //showToast([error || `Treatment can't update now. Please try again in a while.`], ToastTypes.ERROR);
      if (code === 401) {

      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCoverageError]);

  useEffect(() => {
    const hasUpdateData = getLocalCoverage?.code;

    if (getLocalCoverage && getLocalCoverage.data && getLocalCoverage.data.formularies.length > 0) {
      setEnabled(true)
      setEnabledButton(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLocalCoverage]);

  const onSubmitData = (event: React.FormEvent) => {
    event.preventDefault(); // Prevent page refresh
    const searchValue = (document.getElementById('searchstr') as HTMLInputElement).value.trim();
    if (searchValue) {
      setZipCode(searchValue); // Update state
      localCoverageMutate({}, {}, { searchstr: searchValue }); // Trigger fetch
    } else {
      alert('Please enter a valid ZIP code.');
    }
  };
  const onclearData = () => {
    setEnabledButton(false);
    (document.getElementById('searchstr') as HTMLInputElement).value = ''; // Clear input field
      setZipCode(''); // Update state
      localCoverageMutate({}, {}, { searchstr: 0 }); // Trigger fetch
  };

  const {
    data: downloadReportsData, error: downloadReportsError, mutate: downloadRequestForm, removeErrorAndData: clearRequestFileData
  } = useFetch<DownloadReportsResponseTypeApi>(APIEndpoints.DOWNLOAD_LOCAL_REQUEST_FORM);

  useEffect(() => {
    if (downloadReportsData || downloadReportsError) {
      setEnabled(true)
      if (downloadReportsData) {
        const { response, report_name } = downloadReportsData;
        downloadPdfFile(response, `${report_name}.pdf`);
      }

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadReportsData, downloadReportsError]);


  const DownloadReport = (reportName: string) => {
    //if (getValues) {

    let params = {
      //type=formulary&ref_type=RefFormulary&ref_id=1
      'type': 'formulary',
      'ref_type': 'RefFormulary',
      'ref_id': zipCode,
      // ...(userData && userData.ref_type === "rep" ? { user_id: userData.id } : {})
    }
    if (downloadReportsData || downloadReportsError) clearRequestFileData();
    // setLoader();
     setEnabled(false)
     //setEnabledButton()
    downloadRequestForm({}, {}, params);
  }




  return (
      <div className="container-fluid d-flex flex-fill auth-page body-bg overflow-auto">
        <div className="container d-flex flex-column pt-2 p-0">
          {isUserAuthorized ? 
          <AuthMaunfacturerSubHeader/> :
          <ContactAndSafetyInfo alignText={TextAlignProps.TEXT_END} textColor='white'/> 
            }
          <div className="card local-coverage-card">
            <div className="card-header">
              <h4 className="m-0">ZILRETTA Local Coverage</h4>
            </div>
            <div className="card-body">
             {isUserAuthorized ? <Description/> : <DescriptionNotAuthorized/>}

              <form onSubmit={onSubmitData}>
                <input type="hidden" name="utf8" value="✓"/>
                <div className="row mb-2 g-2">
                  <div className="col-12 col-md-10">
                    <input type="text" name="searchstr" id="searchstr" className="form-control"
                           placeholder="Search by five digit zipcode..."
                    />
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="row g-2">
                      <div className="col-6 col-md-6">
                        <button type="button" className="btn btn-outline-secondary w-100"
                                onClick={() => {
                                  onclearData() // Clear input field
                                }}>Clear
                        </button>
                      </div>
                      <div className="col-6 col-md-6">
                        <button type="submit" className="btn btn-primary w-100">
                          <span className="bi bi-search"></span> Find
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div className="row">
                <div className="col-1 p-0 d-none d-md-block">
                  <span
                      style={{
                        float: 'right', width: 0, height: 0, borderStyle: 'solid', borderWidth: '0 0 47px 47px',
                        borderColor: 'transparent transparent #000 transparent',
                      }}
                  ></span>
                </div>
                <div className="col d-flex ps-md-0">
                  <p className="text-white fw-bold m-0 ps-3 flex-fill d-flex align-items-center py-2 py-md-0"
                     style={{fontSize: '20px', backgroundColor: '#000'}}>
                    ZILRETTA® Coverage at {(zipCode.toString())==='0' ? '[Please enter search criteria above]' : (zipCode.toString())}
                  </p>
                </div>
              </div>

              <div className="table-responsive-sm table-responsive-md table-responsive-lg" style={{border:'2px solid #697684'}}>
                <table className="table m-0">
                  <thead>
                  <tr>
                    <th style={{width: '400px'}}></th>
                    <th className="text-white" style={{backgroundColor: '#435364'}}>Channel</th>
                    <th className="text-white" style={{backgroundColor: '#435364'}}>Medical Coverage</th>
                    <th className="text-white" style={{backgroundColor: '#435364'}}>Pharmacy Coverage</th>
                  </tr>
                  <tr>
                    <td className="fw-bold text-white" style={{backgroundColor: '#0cb5b3'}}>Medicare Part B:</td>
                    <td className="text-white" style={{backgroundColor: '#0cb5b3'}}>Medicare</td>
                    <td className="text-white" style={{backgroundColor: '#0cb5b3'}}>
                      Covered Without Restrictions
                    </td>
                    <td className="text-white" style={{backgroundColor: '#0cb5b3'}}>See Medical Coverage</td>
                  </tr>
                  </thead>
                  <tbody>
                  {getLocalCoverage && getLocalCoverage.data && getLocalCoverage.data.formularies &&  getLocalCoverage.data.formularies.length > 0 ?
                      getLocalCoverage.data.formularies.map(kase => (
                          <tr>
                            <td>{kase.payer_name}</td>
                            <td>{kase.channel}</td>
                            <td>{kase.medical_coverage}</td>
                            <td>{kase.pharmacy_coverage}</td>
                          </tr>
                      )) : null
                  }
                  </tbody>
                </table>
              </div>
              <p className="fw-bold m-0 p-1 text-center text-white" style={{backgroundColor: '#435364'}}>
                QUESTIONS? CONTACT YOUR FIELD REIMBURSEMENT MANAGER:  {getLocalCoverage &&  getLocalCoverage.data && getLocalCoverage.data.name +" "+ getLocalCoverage.data.email}
              </p>

              <SafetyInfo/>
              {enabledbutton && <div className="row">
                <div className="col-12 px-0 pt-2 text-end">
                  <button className="btn btn-sm btn-primary" onClick={() => DownloadReport("")} disabled={!enabled}>
                    <span className="fa fa-file-pdf"></span> Download
                  </button>
                </div>
              </div>}
            </div>
          </div>
          <div className="mt-3"><FooterText footerType="black"/></div>
        </div>
      </div>
  );
};
const SafetyInfo: React.FC = () => (
    <div className="overflow-hidden" style={{backgroundColor: '#eef1f2'}}>
      <p className="fw-bold m-0 px-3 py-1">
        <span style={{fontSize: '18px'}}>
          Please see Important Safety Information and full Prescribing Information available at{' '}
          <a href="https://www.zilrettapro.com/safety-data/" style={{color: '#0cb5b3'}}>
            www.zilrettapro.com/safety-data/
          </a>
          .
        </span>
        <br/>
        <span style={{color: '#435364'}}>
          The information provided represents no statement, promise, or guarantee by Pacira BioSciences, Inc., concerning
          levels of reimbursement, payment, or charge.
          <br/>
          Please follow your standard procedure for conducting a benefit investigation for ZILRETTA.
          <br/>
          Consult your payer organization with regard to local or actual coverage,
          reimbursement policies, and determination processes. Information is subject to change without notice. Nothing
          herein may be construed as an endorsement, approval, recommendation, representation, or warranty of any kind
          by any plan or insurer referenced herein. This communication is solely the responsibility of Pacira
          BioSciences, Inc.
        </span>
      </p>
      <span style={{
        float: 'right', width: 0, height: 0, borderStyle: 'solid', borderWidth: '47px 47px 0 0',
        borderColor: '#eef1f2 #FFF transparent transparent',
      }}></span>
    </div>
);
const Description: React.FC = () => (
    <p className="fw-bold" style={{fontSize: '18px'}}>
      Search for formulary coverage Zip code below. Remember to submit a new request to conduct a full benefits
      investigation to determine patient-specific coverage details. Payers listed by the number of covered lives.
    </p>
);
const DescriptionNotAuthorized: React.FC = () => (
  <p className="fw-bold" style={{fontSize: '18px'}}>
    Search for formulary coverage Zip code below. 
        Remember to 
         <a href="/users/sign_in"> sign in </a> or 
        <a href="/enrollments/new"> enroll </a> and conduct a full benefits 
        investigation to determine patient-specific coverage details. 
      Payers listed by the number of covered lives.
  </p>
);


export default CoverageLookup;
