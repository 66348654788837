import { FC, useContext, useEffect, useState, } from "react";
import { useParams } from "react-router-dom";

import CaseInformation from "src/components/caseInformation";
import CaseNotes from "src/components/caseNotes";
import DocumentUpload from "src/components/documentUpload";
import FooterText from "src/components/footerText";
import { CaseNotesType } from "src/components/caseNotes/types";
import InsuranceInformation from "src/components/insuranceInformation";
import PatientInformation from "src/components/patientInformation";
import PrescriberInformation from "src/components/prescriberInformation";

import useFetch from "src/hooks/useFetch";
import { LoaderContext } from "src/context/loaderContext";

import { CaseDetailType } from '../../../src/types/caseDetail';
import { CaseDetailAPIResponseType } from "./types";
import { APIEndpoints } from "src/types/apiTypes";
import AuthSubHeader from "../../components/authSubHeader";


const HubCaseDetail: FC = (): JSX.Element => {
  const { caseId } = useParams();
  const { hideLoader } = useContext(LoaderContext);
  const [caseNotes, setCaseNotes] = useState<CaseNotesType[]>([]);
  const [caseDetail, setCaseDetail] = useState<CaseDetailType>();


  const { data: caseDetailObject } =
    useFetch<CaseDetailAPIResponseType>(APIEndpoints.CASE_DETAIL, {}, { caseId });

  useEffect(() => {
    if (caseDetailObject) {
      hideLoader();
      setCaseNotes(caseDetailObject?.data.case_notes)
      setCaseDetail(caseDetailObject?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseDetailObject]);





  return (
    <div className="body-bg overflow-auto flex-fill">
      <div className="container pt-2">
        <AuthSubHeader/>

        <div className="mt-4 d-flex align-items-center justify-content-between mb-2 flex-wrap">
          <h2 className="text-primary-grey m-0 flex-grow-1 mb-2">Case# {caseId}</h2>
          <h2
            className={`m-0 text-center text-white py-2 flex-grow-1 background-color-${caseDetail && caseDetail.case_information ? caseDetail.case_information.color : 1}`}>
            {caseDetail && caseDetail.case_information ? caseDetail.case_information.status : ''}
          </h2>
        </div>

        {caseDetail ? (
          <>
            {/* Case Information */}
            <div className="">
              <CaseInformation caseObject={caseDetail} />
            </div>
            {/* Patient Information */}
            <div className="my-4">
              <PatientInformation caseObject={caseDetail}  />
            </div>
            {/* Prescriber Information */}
            <div className="my-4">
              <PrescriberInformation caseObject={caseDetail}  />
            </div>
            {/* Insurance Information */}
            <div className="card">
              <div className="card-header">
                <h4 className="m-0">Insurance Information</h4>
              </div>

              <div className="card-body">
                {caseDetail.insurance_information.length > 0 ?
                    (
                        <>
                          {caseDetail.insurance_information.map((item, index) => (
                              <InsuranceInformation drugName={caseDetail.case_information.product} key={index} insuranceNumber={index + 1}
                                                    insuranceInformation={item} />
                          ))}
                        </>
                    )
                    : null}
              </div>
            </div>
            {/* Documents */}
            <div className="my-4">
              <DocumentUpload
                  setNotes={setCaseNotes}
                  caseDocuments={caseDetail.case_documents ? caseDetail.case_documents : []}
                  caseId={caseDetail.case_information ? caseDetail.case_information.case_id : 0}
              />
            </div>
            {/* Case Notes */}
            <div className="my-4">
              <CaseNotes caseNotes={caseNotes} setNotes={setCaseNotes} caseObject={caseDetail} />
            </div>
          </>
        ) : null}

        <div className="mt-3"><FooterText footerType="black" /></div>
      </div>
    </div>
  );
};

export default HubCaseDetail;
