import {FC, useContext} from "react";
import {AuthContext} from "../../context/authContext";
import {format} from "date-fns";

const BAAComponent: FC = (): JSX.Element => {
    const { userData } = useContext(AuthContext);

    return (
        <>
            <div className="panel panel-default p-4">
                <div className={"panel-body"}>
                    <p>
                        THIS BUSINESS ASSOCIATE AGREEMENT (this “Agreement”) is entered into as of 10/17/2024 (the “Effective
                        Date”),
                        by and between the currently logged in business (“Covered Entity”) and Copilot Provider Support
                        Services(“Business Associate”) with an address of 1981 Marcus Avenue, New Hyde Park, NY 11042.
                    </p>
                    <p>
                        RECITALS
                    </p>

                    <p>
                        WHEREAS, Business Associate performs services (“Services”) on behalf of Covered Entity pursuant one or
                        more
                        underlying agreements (each, an “Underlying Agreement(s)”), which Services involve the Use and/or
                        Disclosure
                        of Protected Health Information (defined below); and
                    </p>
                    <p>
                        WHEREAS, the parties desire to enter into this Agreement in order to comply with the Health Insurance
                        Portability and Accountability Act of 1996 (“HIPAA”) and its implementing regulations, as amended and in
                        effect.
                    </p>
                    <p>
                        NOW, THEREFORE, the parties do hereby agree as follows:
                    </p>
                    <ol type="1">
                        <li>Definitions.  Capitalized terms not otherwise defined in this Agreement shall have the same
                            meaning as those terms in the Privacy Rule and the Security Rule (defined below).
                        </li>
                        <ul style={{listStyle: "none"}}>
                            <li>a) “Breach” when capitalized, “Breach” shall have the meaning set forth in 45 CFR §
                                164.402 (including all of its subsections); with respect to all other uses of the word
                                “breach” in this Agreement, the word shall have its ordinary contract meaning.
                            </li>
                            <li>b) “Electronic Protected Health Information” or “EPHI” shall have the same meaning as
                                the term “electronic protected health information” in 45 CFR § 160.103, limited to
                                information that Business Associate creates, accesses or receives on behalf of Covered Entity.
                            </li>
                            <li>c) “Protected Health Information” or “PHI” shall have the meaning set forth in the
                                Privacy Rule, limited to information that Business Associate creates, accesses or
                                receives on behalf of Covered Entity.  PHI includes EPHI.
                            </li>
                            <li>d) “Privacy Rule” means the Standards for Privacy of Individually Identifiable Health
                                Information, codified at 45 CFR parts 160 and 164, Subparts A, D, and E, as currently in effect.
                            </li>
                            <li>e) “Security Rule” means the Standards for Security for the Protection of Electronic
                                Protected Health Information, codified at 45 CFR parts 160 and 164, Subpart C.
                            </li>
                            <li>f) “Unsecured Protected Health Information” shall have the same meaning as the term
                                “unsecured protected health information” in 45 CFR § 164.402, limited to the information
                                created or received by Business Associate from or on behalf of Covered Entity.
                            </li>
                        </ul>

                        <li>Business Associate Obligations.  Business Associate acknowledges and agrees that it is
                            considered a “Business Associate” as defined by HIPAA.  As a Business Associate of Covered
                            Entity, Business Associate shall, in addition to complying with the terms of this Agreement,
                            comply with the following and any state provisions that are more restrictive:
                        </li>
                        <ol type="a">
                            <li>Uses and Disclosures. Business Associate shall not Use or further Disclose PHI other than as
                                permitted or
                                required by this Agreement, to perform Services under the Underlying Agreement or as Required By Law,
                                provided that:
                            </li>
                            <ol type="i">
                                <li>Such use or disclosure would not violate HIPAA if done by Covered Entity; and</li>
                                <li>Such Use or Disclosure shall be limited to the minimum necessary to accomplish the permissible
                                    purpose(s) of the Use or Disclosure.
                                </li>
                            </ol>
                            <li>Uses and Disclosures Permitted By Law. As permitted by the Privacy Rule, Business Associate may Use
                                or
                                Disclose PHI:
                            </li>
                            <ol type="i">
                                <li>as is necessary for the proper management and administration of Business Associate’s organization,
                                </li>
                                <li> to provide data aggregation services relating to the health care services of the Covered Entity;
                                    and
                                </li>
                                <li>to carry out the legal responsibilities of Business Associate; provided, however, that any
                                    permitted
                                    Disclosure of PHI to a third party must be either Required By Law or subject to reasonable
                                    assurances
                                    obtained by Business Associate from the third party that PHI will be held confidentially, and
                                    securely,
                                    and Used or Disclosed only as Required By Law or for the purposes for which it was disclosed to such
                                    third
                                    party, and that any breaches of confidentiality of PHI which become known to such third party will
                                    be
                                    immediately reported to Business Associate.
                                </li>
                            </ol>
                            <li>Privacy Rule. To the extent Business Associate carries out one or more of Covered Entity’s
                                obligations
                                under the Privacy Rule, Business Associate shall comply with the requirements of HIPAA that apply to
                                Covered
                                Entity in the performance of such obligation(s).
                            </li>
                            <li>Safeguards. Business Associate shall use safeguards that are appropriate and sufficient to prevent
                                Use or
                                Disclosure of PHI other than the Uses and Disclosures permitted or required by this Agreement.
                                Business
                                Associate shall comply with the Security Rule with respect to EPHI, including implementing
                                Administrative
                                Safeguards, Physical Safeguards, and Technical Safeguards that reasonably and appropriately protect
                                the
                                Confidentiality, Integrity and Availability of EPHI.
                            </li>
                            <li>Reporting. Business Associate shall report to Covered Entity any Use or Disclosure of PHI not
                                permitted
                                or required by this Agreement and any Security Incident of which it becomes aware.
                            </li>
                            <li>Agents and Subcontractors. Business Associate shall ensure that any and all subcontractors that
                                create,
                                receive, maintain or transmit PHI on behalf of Business Associate agree, in writing prior to the
                                subcontractors’ receipt of such PHI, to the same terms and conditions of this Agreement with respect
                                to PHI.
                                Each subcontract agreement must contain the same restrictions and conditions that apply to Business
                                Associate
                                with respect to PHI, including without limitation the provisions of this Agreement. Business Associate
                                shall make such agreements with its subcontractors available to Covered Entity upon Covered Entity’s
                                request.
                            </li>
                            <li>Patient Rights.</li>
                            <ol type="i">
                                <li>Patient Right to Access. Business Associate shall make PHI in a Designated Records Set that it
                                    maintains
                                    available to Covered Entity within ten (10) days or, as directed by Covered Entity, to the subject
                                    of the
                                    PHI, in compliance with the requirements of 45 C.F.R. §164.524. If any Individual requests access to
                                    his
                                    or her own PHI from Business Associate, Business Associate shall, within two (2) business days,
                                    notify
                                    Covered Entity of the details of such request.
                                </li>
                                <li>Patient Right to Amend. Business Associate shall incorporate amendment(s) to PHI in a Designated
                                    Records Set that it maintains within ten (10) days of receipt of Covered Entity’s request and in
                                    compliance with 45 C.F.R. §164.526. If any Individual submits to Business Associate a request to
                                    amend
                                    his or her own PHI, Business Associate shall, within two (2) business days, notify Covered Entity of
                                    the
                                    details of such request.
                                </li>
                                <li>Patient Right to Request Accounting. Business Associate shall document and make available to
                                    Covered
                                    Entity the information required to provide an accounting of disclosures within ten (10) days of
                                    receipt
                                    of Covered Entity’s request or, as directed by Covered Entity, to the subject of the PHI, in
                                    compliance
                                    with the requirements of 45 C.F.R. §164.528. If any Individual requests an accounting from Business
                                    Associate, Business Associate shall, within two (2) business days, notify Covered Entity of the
                                    details
                                    of such request.
                                </li>
                            </ol>
                            <ol type="1">
                                <li>Business Associate agrees to implement an appropriate record keeping process to enable it to
                                    comply
                                    with the requirements of this Section.
                                </li>
                                <li>Business Associate agrees to provide PHI it maintains electronically in a Designated Record Set in
                                    an
                                    electronic form at the request of Covered Entity or an Individual.
                                </li>
                            </ol>
                            <li>Audit. Business Associate shall make its internal practices, books, and records relating to the Use
                                and
                                Disclosure of PHI received from, or created or received by Business Associate on behalf of Covered
                                Entity
                                available to the Secretary of Health and Human Services, upon request, for purposes of determining and
                                facilitating Covered Entity’s compliance with HIPAA.
                            </li>
                            <li>Mitigation. Business Associate shall mitigate promptly, to the extent practicable, any harmful
                                effect
                                that is known to Business Associate of a Use or Disclosure of PHI by Business Associate in violation
                                of this
                                Agreement, the Privacy Rule, the Security Rule, or other applicable federal or state law.
                            </li>
                            <li>Breach. If Business Associate has knowledge or a reasonable belief that a Breach or potential Breach
                                of
                                Unsecured Protected Health Information has occurred or may have occurred, Business Associate shall
                                promptly
                                (no later than five (5) days after it has knowledge that a Breach or reasonable belief that a Breach
                                has or
                                may have occurred) notify the Covered Entity in accordance with the requirements of 45 CFR § 164.410.
                                Such
                                notification shall include, to the extent possible, the identification of each Individual whose PHI
                                has been
                                or is reasonably believed to have been accessed, acquired, Used or Disclosed during the Breach, along
                                with
                                any other information that the Covered Entity will be required to include in its notification to the
                                Individual, the media and/or the Secretary, as applicable, including, without limitation, a
                                description of
                                the Breach, the date of the Breach and its discovery, the types of Unsecured Protected Health
                                Information
                                involved and a description of the Business Associate’s investigation, mitigation, and prevention
                                efforts.
                            </li>
                        </ol>

                        <li>Covered Entity Obligations</li>
                        <ol type="a">
                            <li>Notice of Privacy Practices. Covered Entity shall notify Business Associate of limitation(s) in its
                                notice of privacy practices, to the extent such limitation affects Business Associate’s permitted Uses
                                or
                                Disclosures.
                            </li>
                            <li> Individual Permission. Covered Entity shall notify Business Associate of changes in, or revocation
                                of,
                                permission by an Individual to Use or Disclose PHI, to the extent such changes affects Business
                                Associate’s
                                permitted Uses or Disclosures.
                            </li>
                            <li>Restrictions. Covered Entity shall notify Business Associate of restriction(s) in the Use or
                                Disclosure
                                of PHI that Covered Entity has agreed to, to the extent such restriction affects Business Associate’s
                                permitted Uses or Disclosures.
                            </li>
                        </ol>
                        <li>Term & Termination.</li>
                        <ol type="a">
                            <li>Term. The Term of this Agreement shall begin on the Effective Date, and shall terminate when all PHI
                                provided by Covered Entity to Business Associate is destroyed or returned to Covered Entity, or if it
                                is
                                infeasible to return or destroy all PHI, protections are extended to such information in accordance
                                with the
                                termination provisions in this Section.
                            </li>
                            <li>Termination.</li>
                            <ol type="i">
                                <li>By Covered Entity. Upon Covered Entity’s knowledge of a material breach by Business Associate of
                                    this
                                    Agreement, Covered Entity shall:
                                </li>
                                <ol type="1">
                                    <li>Terminate this Agreement and the Underlying Agreement upon thirty (30) days’ notice if Covered
                                        Entity
                                        determines that Business Associate has violated a material term of this Agreement if, following
                                        Covered
                                        Entity’s notification to Business Associate of the material breach, Business Associate is unable
                                        or
                                        unwilling to take steps to cure the breach within such thirty (30) day period. In the event of
                                        such a
                                        cure, this Agreement shall remain in full force and effect; or
                                    </li>
                                    <li>Immediately terminate this Agreement and the Underlying Agreement if Business Associate has
                                        breached
                                        a material term of this Agreement and cure is not possible.
                                    </li>
                                </ol>
                                <li>By Business Associate. Upon Business Associate’s knowledge of a material breach by Covered Entity
                                    of
                                    this Agreement, Business Associate shall:
                                </li>
                                <ol type="1">
                                    <li>Terminate this Agreement and the Underlying Agreement upon thirty (30) days’ notice if Business
                                        Associate determines that Covered Entity has violated a material term of this Agreement if,
                                        following
                                        Business Associate’s notification to Covered Entity of the material breach, Covered Entity is
                                        unable or
                                        unwilling to take steps to cure the breach within such thirty (30) day period. In the event of
                                        such a
                                        cure, this Agreement shall remain in full force and effect; or
                                    </li>
                                    <li>Immediately terminate this Agreement and the Underlying Agreement if Covered Entity has breached
                                        a
                                        material term of this Agreement and cure is not possible.
                                    </li>
                                </ol>

                            </ol>
                            <li>Return on Termination. At termination of the Agreement, to the extent feasible, Business Associate
                                shall
                                return or destroy all PHI that Business Associate still maintains in any form and retain no copies of
                                PHI.
                                If the return or destruction of such PHI is not feasible, Business Associate shall extend the
                                protections of
                                this Agreement to the remaining information and limit further Uses and Disclosures of PHI to those
                                purposes
                                that make the return or destruction of PHI infeasible.
                            </li>
                            <li>Survival. The terms of this Section shall survive the termination or expiration of this Agreement.
                            </li>
                        </ol>
                        <li>Required Disclosure. If Business Associate is confronted with legal action to disclose any PHI,
                            Business
                            Associate shall promptly notify and assist Covered Entity in obtaining a protective order or other
                            similar
                            order, and shall thereafter disclose only the minimum amount of PHI that is required to be disclosed in
                            order
                            to comply with the legal action, whether or not a protective order or other order has been obtained.
                        </li>
                        <li>Indemnification. Business Associate agrees to indemnify, defend, and hold harmless Covered Entity and
                            its
                            directors, officers, affiliates, employees, agents, and permitted successors from and against any and
                            all
                            claims, losses, liabilities, damages, costs, and expenses (including reasonable attorneys’ fees) arising
                            out
                            of or related to Business Associate’s breach of its obligations under this Agreement, including, but not
                            limited to a Breach of Unsecured Protected Health Information by Business Associate.
                        </li>
                        <li> Compliance with Laws. Business Associate shall comply with all applicable federal, state and local
                            laws,
                            rules and regulations, including, without limitation, the requirements of HIPAA.
                        </li>
                        <li>Underlying Agreement. Except as specifically required to implement the purposes of this Agreement, and
                            except to the extent inconsistent with this Agreement, all terms of the Underlying Agreement shall
                            remain
                            in full force and effect. In the event of a conflict between the terms of the Underlying Agreement and
                            this
                            Agreement, this Agreement shall control.
                        </li>
                        <li>No Third-Party Beneficiaries. Nothing express or implied in this Agreement is intended to confer, nor
                            shall
                            anything herein confer, upon any person other than the Covered Entity, Business Associate and their
                            respective
                            successors or assigns, any rights, remedies, obligations or liabilities whatsoever.
                        </li>
                        <li>Ownership. Covered Entity shall be and remain the sole and exclusive owner of its PHI.</li>
                        <li> Amendment. The parties shall amend this Agreement from time to time by mutual written agreement in
                            order
                            to keep this Agreement consistent with any changes made to the HIPAA laws or regulations in effect as of
                            the
                            Effective Date and with any new regulations promulgated under HIPAA. Covered Entity may terminate the
                            Agreement in whole or in part if the parties are unable to agree to such changes by the compliance date
                            for
                            such new or revised HIPAA laws or regulations.
                        </li>
                        <li>Counterparts. This Agreement may be executed in two or more counterparts, each of which shall be an
                            original, but all of which taken together shall constitute one and the same agreement.
                        </li>
                    </ol>
                </div>
            </div>
        </>
    );
};

export default BAAComponent;
