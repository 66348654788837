/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { GroupBase, OptionsOrGroups } from "react-select";
import { Controller, useForm } from "react-hook-form";

import HubInputField from "src/components/inputType";
import StatsReports from "../statsReports";

import { StatisticsPropsType, StatisticsSearchProps } from "./types";
import DatePicker from "react-datepicker";
import ReactInputMask from "react-input-mask";




const CaseFilter: FC<StatisticsPropsType> = ({
  updateQueryParams,
  defaultQueryParams,
  title
}): JSX.Element => {

  const { control, handleSubmit, getValues, } = useForm<StatisticsSearchProps>({
    mode: "all",
    defaultValues: defaultQueryParams,
  });
  const timeData = [
    { value: "", label: "Group Report By..." },
    { value: "day", label: "Group by Day" },
    { value: "week", label: "Group by Week" },
    { value: "month", label: "Group by Month" },
    { value: "quarter", label: "Group by Quarter" },
  ];
  const comparison = [
    { value: "", label: "Compare With..." },
    { value: "p-1", label: "Compare with previous Period" },
    { value: "y-1", label: "Compare with Previous Year" },
  ]

  const searchForStats = (values: StatisticsSearchProps) => {
    const updatedValues = {
      ...values,
      period: values.period
        ? typeof values.period === "string"
          ? values.period
          : (values.period as unknown as { value: string; label: string }).value
        : "",
      soc_value:
        values.soc_value ?
          (typeof values.soc_value === "string" ? values.soc_value : (values.soc_value as unknown as { value: string; name: string; }).value)
          : "",
      drug_id:
        values.drug_id ?
          (typeof values.drug_id === "string" ? values.drug_id : (values.drug_id as unknown as { value: string; name: string; }).value)
          : "",
      filter_type: values.filter_type
        ? typeof values.filter_type === "string"
          ? values.filter_type
          : (values.filter_type as unknown as { value: string; label: string }).value
        : "",
      id:
        values.id ?
          (typeof values.id === "string" ? values.id : (values.id as unknown as { value: string; name: string; }).value)
          : "",
    };

    updateQueryParams(updatedValues, false);
  };



  return (
    <form onSubmit={handleSubmit(searchForStats)}>
      <div className="card mt-4">
        <div className="card-header"><h4 className="m-0">{title ? title : 'Statistics'}</h4></div>
        <div className="card-body pt-3">
          <div className="row g-3 statistics-filter mb-3">
            <div className="col">
              <Controller
                name="start_date"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  // <HubInputField
                  //     inputId="start_date"
                  //     isRowBased={true}
                  //     isRequired={false}
                  //     field={field}
                  //     inputType="date"
                  //     inputLabel="Start"
                  //     isRowInsideAnother={true}
                  // />

                 
                  <DatePicker
                    selected={field.value ? new Date(field.value) : null} // Ensure correct date format
                    onChange={(date) => field.onChange(date?.toISOString().split("T")[0])} // Convert to YYYY-MM-DD for storing
                    dateFormat="MM/dd/yyyy"
                    className="form-control"
                    icon = "bi bi-calendar"
                    customInput={<ReactInputMask mask="99/99/9999">{(inputProps: {}) =>
                      <input {...inputProps} />}</ReactInputMask>}
                    wrapperClassName="w-100"
                  />
                  
                )}
              />
            </div>
            <div className="col">
              <Controller
                name="end_date"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  // <HubInputField
                  //   inputId="end_date"
                  //   isRowBased={true}
                  //   isRequired={false}
                  //   field={field}
                  //   inputType="date"
                  //   inputLabel="End"
                  //   isRowInsideAnother={true}
                  // />
                 
                 <DatePicker
                    selected={field.value ? new Date(field.value) : null} // Convert string to Date
                    onChange={(date) => field.onChange(date?.toISOString().split("T")[0])} // Convert Date to YYYY-MM-DD string
                    className="form-control"
                    id="end_date"
                    icon = "bi bi-calendar"

                    placeholderText="Select end date"
                    dateFormat="MM/dd/yyyy"
                    customInput={<ReactInputMask mask="99/99/9999">{(inputProps: {}) =>
                      <input {...inputProps} />}</ReactInputMask>}
                    wrapperClassName="w-100"
                  />
                 
                )}
              />
            </div>
            <div className="col min-col">
              <Controller
                name="period"
                control={control}
                // defaultValue={timeData[0].value}
                render={({ field }) => (
                  <HubInputField
                    field={field}
                    inputId="period"
                    isRequired={false}
                    isRowBased={true}
                    isDropdown={true}
                    inputPlaceholder="Group Report By..."
                    isSearchable={false}
                    dropdownData={
                      (timeData && timeData.length > 0
                        ? timeData.map((item) => ({
                          value: item.value,
                          label: item.label,
                        }))
                        : []) as unknown as OptionsOrGroups<
                          string,
                          GroupBase<string>
                        >
                    }
                  />
                )}
              />
            </div>
            <div className="col">
              <Controller
                name="filter_type"
                control={control}
                defaultValue={comparison[0].value}
                render={({ field }) => (
                  <HubInputField
                    field={field}
                    inputId="period"
                    isRequired={false}
                    isRowBased={false}
                    isDropdown={true}
                    inputPlaceholder="Compare With ..."
                    isSearchable={false}
                    dropdownData={
                      (comparison && comparison.length > 0
                        ? comparison.map((item) => ({
                          value: item.value,
                          label: item.label,
                        }))
                        : []) as unknown as OptionsOrGroups<
                          string,
                          GroupBase<string>
                        >
                    }

                  />
                )}
              />
            </div>
            <div className="col">
              <button type="submit" className="btn btn-primary w-100">
                <i className="fas fa-sync-alt"></i> Refresh
              </button>
            </div>
          </div>
          <StatsReports getValues={getValues} />
        </div>
      </div>
    </form>
  );
};

export default CaseFilter;
