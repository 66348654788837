import { UseFormGetValues } from "react-hook-form";

import { UserType } from "src/types/users";
import {
  AddCaseProps,
  CaseDoctorProps,
  InsuranceAttributesProps,
  PatientAddress,
  PatientAttributes,
  PatientPhoneNumber,
  PrescriptionAttributesType,
  ProcedureProps,
} from "src/pages/addCase/types";
import { CaseCloneResponseType } from "src/pages/cloneCase/types";



export function populatingCloneCase(
  { data }: CaseCloneResponseType,
  userData: UserType,
  getValues: UseFormGetValues<AddCaseProps>
): AddCaseProps {
  const caseFormData: AddCaseProps = {} as AddCaseProps;

  if (data.case) {
    caseFormData.mos = "online";
    caseFormData.user_id = userData ? userData.id : 0;
    caseFormData.creator_name = userData ? userData.name : "";
    caseFormData.submission_source = data.case.submission_source || "provider";
    caseFormData.category =  "medrx";

    caseFormData.prescription_attributes = {} as PrescriptionAttributesType;
  }

  if (data.procedure && data.procedure.length > 0) {
    caseFormData.procedure_attributes = {} as ProcedureProps;
    caseFormData.procedure_attributes.cpt_code = data.procedure[0].cpt_code;
  }
  const drug_name =  data.prescription.product

  if (drug_name === 'iovera' || drug_name === "iovera°") {
    caseFormData.shipments_attributes = [{}];

    if (data.shipments && data.shipments.length > 0) {
      caseFormData.shipments_attributes[0].practice_id =
        data.shipments[0].practice_id;
      caseFormData.shipments_attributes[0].ship_to_street =
        data.shipments[0].ship_to_street;
      caseFormData.shipments_attributes[0].ship_to_suite =
        data.shipments[0].ship_to_suite;
      caseFormData.shipments_attributes[0].ship_to_city =
        data.shipments[0].ship_to_city;
      caseFormData.shipments_attributes[0].ship_to_state =
        data.shipments[0].ship_to_state;
      caseFormData.shipments_attributes[0].ship_to_zip =
        data.shipments[0].ship_to_zip;
      caseFormData.shipments_attributes[0].practice_type =
        data.shipments[0].practice_type;
      caseFormData.shipments_attributes[0].practice_name =
        data.shipments[0].practice_name;
      caseFormData.shipments_attributes[0].email = data.shipments[0].email;
      caseFormData.shipments_attributes[0].fax =  data.shipments[0].fax?.toString();
      caseFormData.shipments_attributes[0].phone =
        data.shipments[0].phone?.toString();
    }
    if ( data.shipment_attributes && data.shipment_attributes.value) {
      caseFormData.shipments_attributes[0].ship_to_npi = data.shipment_attributes.value;
    }
  }
 

  if (data.doctor) {
    caseFormData.doctor_firstlastname = `${data.doctor.firstname} ${data.doctor.lastname}`;
    caseFormData.doctor_fullname = `${data.doctor.firstname} ${
      data.doctor.middlename || ""
    } ${data.doctor.lastname}`;

    caseFormData.doctor_attributes = {
      practice_id: data.doctor.practice_id,
      global_doctor_id: data.doctor.global_doctor_id,
    } as CaseDoctorProps;
  }

  if (data.practice || data.practice_address) {
    caseFormData.practice_name = `${data.practice.name} - ${data.practice_address.full_address}`;
  }

  if (data.patient) {
    const { patient } = data;

    caseFormData.patient_firstlastname = `${patient.firstname} ${patient.lastname}`;
    caseFormData.patient_fullname = `${patient.firstname} ${
      patient.middlename || ""
    } ${patient.lastname}`;
    caseFormData.patient_dob = patient.dob;

    caseFormData.patient_attributes = {
      global_patient_id: patient.global_patient_id,
      title: patient.title || "",
      firstname: patient.firstname,
      middlename: patient.middlename,
      lastname: patient.lastname,
      gender: patient.gender,
      dob: patient.dob,
      ssn: patient.ssn || "",
      best_time_to_contact: patient.best_time_to_contact || "",
      patient_email_id: null,
      patient_email: data.email_address ? data.email_address.email : "",
    } as PatientAttributes;
  }

  if (data.patient_phone_numbers && data.patient_phone_numbers.length > 0) {
    caseFormData.patient_attributes.phone_numbers = [];


    data.patient_phone_numbers.forEach((phone, index) => {
      caseFormData.patient_attributes.phone_numbers[index] =
        {} as PatientPhoneNumber;
      caseFormData.patient_attributes.phone_numbers[index].number =
        phone.number.toString();
      caseFormData.patient_attributes.phone_numbers[index].phone_type =
        phone.phone_type || "cell";
      caseFormData.patient_attributes.phone_numbers[
        index
      ].is_ok_to_leave_message = phone.is_ok_to_leave_message;
      caseFormData.patient_attributes.phone_numbers[index].is_preferred =
        phone.is_preferred;
      caseFormData.patient_attributes.phone_numbers[index].id = phone.id;
    });
  }

  caseFormData.patient_attributes.address = {
    address_name: "home",
  } as PatientAddress;
  if (data.patient_address) {
    caseFormData.patient_attributes.address.street =
      data.patient_address.street || "";
    caseFormData.patient_attributes.address.suite =
      data.patient_address.suite || "";
    caseFormData.patient_attributes.address.city =
      data.patient_address.city || "";
    caseFormData.patient_attributes.address.state =
      data.patient_address.state || "";
    caseFormData.patient_attributes.address.zip =
      data.patient_address.zip || "";
  }

  if (data.patient_entities_address) {
    caseFormData.patient_attributes.address.address_name =
      data.patient_entities_address.address_name || "";
  }

  caseFormData.insurances_attributes = [{} as InsuranceAttributesProps];
  if (data.insurances && data.insurances.length > 0) {
    data.insurances.forEach((insurance, index) => {
      if (caseFormData.insurances_attributes) {
        caseFormData.insurances_attributes[index] =
          {} as InsuranceAttributesProps;
        caseFormData.insurances_attributes[index].insurance_name =
          insurance.insurance_name;
        caseFormData.insurances_attributes[index].relation_to_patient =
          insurance.relation_to_patient;
        caseFormData.insurances_attributes[index].relation_firstname =
          insurance.relation_firstname || "";
        caseFormData.insurances_attributes[index].relation_middlename =
          insurance.relation_middlename || "";
        caseFormData.insurances_attributes[index].relation_lastname =
          insurance.relation_lastname || "";
        caseFormData.insurances_attributes[index].policy =
          insurance.policy || "";
        caseFormData.insurances_attributes[index].group_number =
          insurance.group_number || "";
        caseFormData.insurances_attributes[index].insurance_number =
          insurance.insurance_number || "";
        caseFormData.insurances_attributes[index].member_id =
          insurance.member_id || "";
        caseFormData.insurances_attributes[index].bin = insurance.bin || "";
        caseFormData.insurances_attributes[index].pcn = insurance.pcn || "";
        caseFormData.insurances_attributes[index].rx_group =
          insurance.rx_group || "";
        caseFormData.insurances_attributes[index].global_insurance_id =
          insurance.global_insurance_id
            ? insurance.global_insurance_id.toString()
            : null;
        caseFormData.insurances_attributes[index].is_primary_insurance =
          index === 0;
        caseFormData.insurances_attributes[index].insurance_priority = index;
      }
    });
  }

  return caseFormData;
}

export function populatingPrescriptionAttributes({ data }: CaseCloneResponseType): PrescriptionAttributesType {

  const prescription_attributes = {} as PrescriptionAttributesType;

  const drug_name = data.prescription.product.toLowerCase();
  if (data.prescription) {
    const diagnosis = data.prescription.diagnosis_code;
    prescription_attributes.diagnosis_code = diagnosis !== "" ? diagnosis.split(",") : [];
    prescription_attributes.note = data.prescription.note;
    prescription_attributes.direction = data.prescription.direction;
    prescription_attributes.is_specialty_pharmacy_only = data.prescription.is_specialty_pharmacy_only !== null ? data.prescription.is_specialty_pharmacy_only : false;
    prescription_attributes.drug_name = drug_name;
  }

  if (data.prescription_attributes) {
    data.prescription_attributes.forEach((attr) => {
      switch(attr.name) {
        case "scheduled_date_of_service":
          prescription_attributes.scheduled_date_of_service = attr.value || undefined;
          break;
        case "injection_site":
          prescription_attributes.injection_site = attr.value || "";
          break;
        case "last_date_of_service":
          console.log({ value: attr.value, label: drug_name === 'iovera' || drug_name === "iovera°" ? "last_surgical_date" : "last_date_of_service" })
          prescription_attributes[drug_name === 'iovera' || drug_name === "iovera°" ? "last_surgical_date" : "last_date_of_service"] =
            attr.value || "";
          break;
        case "last_surgical_date":
          prescription_attributes.last_surgical_date = attr.value || "";
          break;
        case "surgical_date":
          prescription_attributes.surgical_date = attr.value || "";
          break;
        case "treatment_date":
          prescription_attributes.treatment_date = attr.value || "";
          break;
        case "nerves_qty":
          prescription_attributes.nerves_qty = Number(attr.value);
          break;
        case "nerves":
          const splitValue = attr.value ? attr.value.split(",") : [];
          // Remove the last element from nerves array
          if (splitValue.length === 1) {
            prescription_attributes.nerves = splitValue;
            prescription_attributes.other_nerves = "";
          } else {
            // Remove the last element from the array
            const lastElement = splitValue.pop() || null;
            prescription_attributes.nerves = splitValue;
            prescription_attributes.other_nerves = lastElement || "";
          }
          break;
        default:
          break;
      }
    })
  }

  return prescription_attributes;
}
