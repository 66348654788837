import { FC} from "react";
import { StatisticsObjectType } from "../caseVolumeLineChart/types";
import { Link } from "react-router-dom";
import { topPayor } from "src/pages/manufacturer/types";

const CustomTopPayorList: FC<StatisticsObjectType> = ({ StatisticsObjectType }): JSX.Element => {

  return (
      <table className="table table-sm table-responsive overflow-y-auto">
        <thead className="table-light">
          <tr className="text-center">
            <th>Plan Name</th>
            <th>Cases</th>
            <th>% of Cases</th>
          </tr>
        </thead>
        <tbody>
        {StatisticsObjectType.top_payors_details && !StatisticsObjectType.top_payors_details.hasOwnProperty("cvg") && !StatisticsObjectType.top_payors_details.hasOwnProperty("pa_req") && (StatisticsObjectType.top_payors_details as topPayor[]).length > 0 &&
            (StatisticsObjectType.top_payors_details as topPayor[]).map((item, key) => (
                <tr className="text-center" key={key}>
                  <td>
                    <Link
                        to={{pathname: '/statistics', search: `?insurance_name=${encodeURIComponent(item?.name || '')}`}}
                        className="fw-normal fs-6 btn btn-link link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover p-0"
                    >
                      {item?.name}
                    </Link>
                  </td>
                  <td>{item?.value}</td>
                  <td>{item?.percentage}</td>
                </tr>
            ))
        }
        </tbody>
      </table>
  );
};

export default CustomTopPayorList;
