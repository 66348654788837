import { HCPUserSubHeaderProps, ManufacturerSubHeaderProps, UserType } from "../../types/users";

export type UserContextProps = {
  isUserAuthorized: boolean;
  isManufacturer: boolean;
  authToken: string;
  userData: UserType | null;
  sub_header: HCPUserSubHeaderProps[] | ManufacturerSubHeaderProps[] | null;
  isRestoreCalled: boolean;
  iovera_practice : boolean;
  createSession: (userData: UserType, sub_header: HCPUserSubHeaderProps[] | ManufacturerSubHeaderProps[], authToken: string,iovera_practice:boolean) => void;
  destroySession: () => void;
  restoreSession: () => boolean;
  updateUserToken: (userToken: string) => void;
  updateUserData : (userData: UserType) => void;
};

export type AuthProviderProps = {
  children: JSX.Element;
};



export type AuthStateProps = {
  isUserAuthorized: boolean;
  isManufacturer: boolean;
  authToken: string;
  userData: UserType | null;
  sub_header: HCPUserSubHeaderProps[] | ManufacturerSubHeaderProps[] | null;
  isRestoreCalled: boolean;
  iovera_practice:boolean;
};

export enum AuthStateActions {
  SET_SESSION = 'setSession',
  UPDATE_USER_DATA = 'updateUserData',
  UPDATE_USER_TOKEN = 'updateUserToken',
  CLEAR_SESSION = 'clearSession'
};

export type AuthStateAction = {
  type: AuthStateActions.SET_SESSION;
  payload: AuthStateProps;
} | {
  type: AuthStateActions.UPDATE_USER_DATA;
  payload: UserType;
} | {
  type: AuthStateActions.UPDATE_USER_TOKEN;
  payload: string;
} | {
  type: AuthStateActions.CLEAR_SESSION;
};
