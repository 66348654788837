import { FC, useContext, useEffect, useState } from "react";
import FooterText from "../../components/footerText";
import AuthSubHeader from "../../components/authSubHeader";
import { APIEndpoints } from "src/types/apiTypes";
import useFetch from "src/hooks/useFetch";
import { ScheduleRepVisitApiResponse, repVisitUpdateError } from "./types";
import { ToastContext } from "src/context/toastContext";
import { LoaderContext } from "src/context/loaderContext";
import { ToastTypes } from "src/types";
import { AuthContext } from "src/context/authContext";
import { useNavigate } from "react-router-dom";
import { HCPUserSubHeaderProps } from "src/types/users";



const NewRepVisit: FC = (): JSX.Element => {
  const [radioValue, setRadioValue] = useState("");
  const [drugValue, setdrugValue] = useState('ZILRETTA');

  const { showToast } = useContext(ToastContext);
  const { hideLoader } = useContext(LoaderContext);
  const { sub_header, destroySession } = useContext(AuthContext);
  // console.log("sub_heder.......", sub_header);
  const navigate = useNavigate();

  interface DrugOwner {
    rep_user_id: number;
    drug_name: string[];
  }

  interface DrugData {
    reason: string;
    rep_user_ids: number[];
    drugs_name: string[];
    drugs_owner: DrugOwner[];
  }

  const matchProductWithSubHeader = (

    productNames: string[], // Allow multiple products
    radioValue: string,
    sub_header: HCPUserSubHeaderProps[]
  ): DrugData => {
    const matchedReps = sub_header.filter(rep =>
      rep.Drugs.some(drug => productNames.includes(drug)) // Check if any product matches
    );
    const drugs_name = [
      ...new Set(
        matchedReps.flatMap(rep => rep.Drugs.filter(drug => productNames.includes(drug)))
      ),
    ]; // Get unique matched drugs

    return {
      reason: radioValue,
      rep_user_ids: [...new Set(matchedReps.map(rep => rep.Rep_id))], // Unique rep IDs
      drugs_name,
      drugs_owner: matchedReps.map(rep => ({
        rep_user_id: rep.Rep_id,
        drug_name: rep.Drugs.filter(drug => productNames.includes(drug)), // Assign matched drugs
      })),
    };
  };




  const { loading, data: repVisitData, mutate: repVisit, error: repVisitError } =
    useFetch<ScheduleRepVisitApiResponse>(APIEndpoints.SCHEDULE_REP_VISIT, {}, {}, false)


  const scheduleRepVisit = () => {
    let drugResult = drugValue === 'BOTH' ? ["iovera°", 'ZILRETTA'] : [drugValue]
    const result = matchProductWithSubHeader(drugResult, radioValue, sub_header as HCPUserSubHeaderProps[]);
    if (radioValue !== "") {
      repVisit(result)
    } else {
      showToast([`Please Select Reason First ... `], ToastTypes.WARNING);
    }
  }
  const onClickRadioButton = (value: string) => {
    setRadioValue(value)
  }
  const onClickProductButton = (value: string) => {
    setdrugValue(value)
  }
  useEffect(() => {
    if (repVisitError) {
      hideLoader();
      const { code, error } = repVisitError as unknown as repVisitUpdateError;
      showToast([error || `Can't Schedule Rep Visit right now. Please try again in a while.`], ToastTypes.ERROR);
      if (code === 401) {
        destroySession();
        showToast([`User session has expired`], ToastTypes.ERROR);
        navigate(`/users/sign_in`);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repVisitError]);

  useEffect(() => {
    if (repVisitData || repVisitError) {
      if (repVisitData) {
        if (repVisitData.status.code === 200) {
          showToast([`Rep Visit Scheduled Succesfully`], ToastTypes.SUCCESS);
          navigate('/cases');
        }
      }
      hideLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repVisitData, repVisitError]);

  return (
    <div className="container-fluid pt-2 body-bg overflow-auto" style={{ paddingBottom: `5rem` }}>
      <div className={"container"}>
        <div className="row justify-content-center">
          <div className="col-12">
            <AuthSubHeader />

            <div className="card mt-4">
              <div className="card-header">
                <h4 className="fw-normal"> Schedule a sales rep visit
                  {/* with {sub_header && (sub_header as HCPUserSubHeaderProps[])[0].Rep}  */}
                </h4>
              </div>

              <div className="card-body">
                <p>Fill out the form below and your rep will get in touch with you shortly</p>


                <div className="row mb-3">
                  <div className="col-12 col-md-2">
                    <div>Select a Product</div>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="drug" defaultChecked
                        id="zilretta" onClick={() => onClickProductButton('ZILRETTA')} />
                      <label className="form-check-label" htmlFor="supportOverview">
                        ZILRETTA
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="drug"
                        id="iovera" onClick={() => onClickProductButton('iovera°')} />
                      <label className="form-check-label" htmlFor="supportOverview">
                        iovera
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="drug"
                        id="both" onClick={() => onClickProductButton('BOTH')} />
                      <label className="form-check-label" htmlFor="supportOverview">
                        BOTH
                      </label>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h4 className="fw-normal"> I would like: </h4>
                  </div>

                  <div className="card-body">

                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="requestReason"
                        id="supportOverview" onClick={() => onClickRadioButton('Order Information')} />
                      <label className="form-check-label" htmlFor="supportOverview">
                        Order Information
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="requestReason"
                        id="supportOverview" onClick={() => onClickRadioButton(`How to obtain ${drugValue}`)} />
                      <label className="form-check-label" htmlFor="supportOverview">
                        How to obtain {drugValue === "BOTH" ? 'ZILRETTA , iovera ': drugValue === 'iovera°' ? "iovera" : drugValue }
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="requestReason" id="getJelmyto"
                        onClick={() => onClickRadioButton('Product Demonstration')} />
                      <label className="form-check-label" htmlFor="getJelmyto">
                        Product Demonstration
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="requestReason" id="jelmytoSupport"
                        onClick={() => onClickRadioButton('Billing Information')} />
                      <label className="form-check-label" htmlFor="jelmytoSupport">
                        Billing Information
                      </label>
                    </div>
                  </div>
                </div>
                <p className="mt-3">
                  By providing the information requested above, I authorize Pacira to enter the information I have
                  provided into their customer management system and contact me from time to time by telephone, mail, or
                  email to provide information about products or services that may be of interest to me. I understand
                  that the information will be treated confidentially and will be accessed only by authorized personnel
                  or third parties under contract with Pacira or its affiliates.
                </p>
                <button type="button" className="btn btn-primary mb-3" disabled={loading} onClick={scheduleRepVisit}>
                  Request Visit
                </button>
              </div>
            </div>
            <FooterText footerType="black" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewRepVisit;
