import { FC } from "react"
import { TextAlignProps } from "../../contactAndSafetyInfo/types";
import ManufacturerContactAndSafetyInfo from "../manufacturerContactAndSafetyInfo";


const AuthMaunfacturerSubHeader: FC = (): JSX.Element => {

  return (
      <ManufacturerContactAndSafetyInfo textColor="primary" alignText={TextAlignProps.TEXT_END} />
  );
};

export default AuthMaunfacturerSubHeader;
