import {FC, MouseEvent, useState} from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LogoBrand } from "../../assets";
import { PublicHeader } from "../../constants/headerLinks";
import { HeaderElementType } from "../../types";
import SSOLoginButton from "src/pages/login/SSOLoginButton";



const GuestHeader: FC = (): JSX.Element => {
  const navigate = useNavigate();

  const [openSubmenus, setOpenSubmenus] = useState<number[]>([]);


  const autoCollapseSubmenu = (event: MouseEvent<HTMLAnchorElement>, submenuIndex: number) => {
    // Check if clicked element is the dropdown toggle
    if ((event.target as HTMLAnchorElement).matches('a.dropdown-item.dropdown-toggle')) {
      event.stopPropagation(); // Prevent parent dropdown from collapsing
      setOpenSubmenus((prevState) => {
        const isAlreadyOpen = prevState.includes(submenuIndex);

        if (isAlreadyOpen) {
          // Close the submenu
          return prevState.filter((index) => index !== submenuIndex);
        } else {
          // Close all other submenus and open the clicked one
          return [submenuIndex];
        }
      });
    }
  };

  return (
    <nav className="navbar navbar-expand-lg px-3 py-2">
      <div className="container-fluid p-0">
        <Link className="navbar-brand p-0" to="/users/sign_in"><img src={LogoBrand} alt="Flexion Logo" /></Link>
        <button className="navbar-toggler bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#hubHeader">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-start" id="hubHeader">
          <ul className="navbar-nav">
            {PublicHeader.map((headerLink, index) => (
              <li className={`nav-item ${headerLink.actionType === HeaderElementType.DRPODOWN ? 'dropdown' : ''}`} key={index}>
                {headerLink.actionType === HeaderElementType.ROUTE ? (
                  <Link className="nav-link" aria-current="page" to={headerLink.redirectLink}>{headerLink.title}</Link>
                ) : null}
                {headerLink.actionType === HeaderElementType.BUTTON ? (
                  <button className="btn btn-localCoverage rounded-2 custom-text-purple" style={{fontStyle: 'italic', fontWeight: 'bold'}} onClick={() => { navigate(headerLink.redirectLink) }}>
                    {headerLink.title}
                  </button>
                ) : null}
                {headerLink.actionType === HeaderElementType.LINK ? (
                  <a className="nav-link" href={headerLink.redirectLink} target="_blank" rel="noreferrer">
                    {headerLink.title}
                  </a>
                ) : null}
                {headerLink.actionType === HeaderElementType.DRPODOWN ? (
                  <>
                    <a className="nav-link dropdown-toggle" href="." data-bs-toggle="dropdown" aria-expanded="false">
                      {headerLink.title}
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end" style={{ top: '53px' }}>
                     {headerLink.dropdown && headerLink.dropdown.map((header, index) => (
                         <li key={index}>
                         <a className="dropdown-item" href={header.redirectLink} target="_blank" rel="noreferrer">
                           {header.title}
                         </a>
                       </li>
                      ))}
                    </ul>
                    {/* <ul className="dropdown-menu dropdown-center">
                      {headerLink.dropdown && headerLink.dropdown.map((header, index) => {
                        return header.dropdown && header.dropdown.length > 0 ? (
                            <li className="submenu submenu-md dropend" key={index}>
                              <a className="dropdown-item dropdown-toggle" role="button"
                                 data-bs-toggle="dropdown" aria-expanded="false"
                                 onClick={(event) => autoCollapseSubmenu(event, index)}
                                 href="."
                              >
                                {header.title}
                              </a>
                              <ul className={`dropdown-menu py-2 ${openSubmenus.includes(index) ? 'show' : ''}`}>
                                {header.dropdown.map((dropdown, index) => (
                                    <li className="px-0" key={index}>
                                      <a className="dropdown-item" href={dropdown.redirectLink} target="_blank" rel="noreferrer">
                                        {dropdown.title}
                                      </a>
                                    </li>
                                ))}
                              </ul>
                            </li>
                        ) : (
                            <li key={index}>
                              <a className="dropdown-item" href={header.redirectLink} target="_blank" rel="noreferrer">
                                {header.title}
                              </a>
                            </li>
                        );
                      })}
                    </ul> */}
                  </>
                ) : null}
              </li>
            ))}
          </ul>
          <div className="ms-auto text-lg-end">
            <ul className="navbar-nav justify-content-end">
              <li className="nav-item">
                <SSOLoginButton />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default GuestHeader;
