import { Dispatch, SetStateAction } from "react";
import { CaseType } from "src/types/case";

export type precertType = {
  precert: boolean;
  precert_id: number;
  ins_name: string;
  precert_status: string;
  key: number;
};
export type reviewType = {
  review: boolean;
  precert_id: number;
  ins_name: string;
  precert_status: string;
  key: number;
};
export type updateType = {
  update: boolean;
  precert_id: number;
  ins_name: string;
  precert_status: string;
  key: number;
};
export type rxPAType = {
  rx_pa: boolean;
  key: number;
  prior_authorization_id: number;
  ins_name: string;
  prior_authorization_status: string;
};
export type reviewPAType = {
  review_pa: boolean;
  key: number;
  prior_authorization_id: number;
  ins_name: string;
  prior_authorization_status: string;
};
export type updatePAType = {
  update_pa: boolean;
  key: number;
  prior_authorization_id: number;
  ins_name: string;
  prior_authorization_status: string;
};
export type appealType = {
  appeal: boolean;
  key: number;
  precert_id: number;
  ins_name:string;
  precert_status: string;
};

export type CaseListingType = CaseType & {
  id: number;
  investigated_at: string | null;
  color_idx: 1 | 2 | 3 | 4 | 5;
  status_name: string;
  created_at: string | null;
  active_precert_id: number | null;
  precert_id: number | null;
  precert: boolean;
  update_pa: boolean;
  review_pa: boolean;
  appeal: boolean;
  rx_pa: boolean;
  update_rx_pa: boolean;
  review_rx_pa: boolean;
  iovera_practice: boolean;
  treatment_decision: boolean;
  no_action_required: boolean;
  prescription_id: number;
  drug_name: string;
  category: string;
  case_type: string;
  treated_button: boolean;
  prior_auth_id: number | null;
  prior_auth_status: string | null;
  soc_name: string | null;
  practice_name: string | null;
  patient: {
    id: number;
    external_id: string;
    firstlastname: string;
  };
  doctor: {
    id: number;
    firstlastname: string;
  };
  treatment?: {
    id: number;
    scheduled_at: string | null;
  };

  buy_and_bill: boolean;
  appeal_precert_id: number;
  date_of_treatment: boolean;
  pdp_xfer: boolean;
  precert_status: string;
  precert_buttons: precertType[];
  update_buttons: updateType[];
  review_buttons: reviewType[];
  rx_pa_buttons: rxPAType[];
  update_pa_buttons: updatePAType[];
  review_pa_buttons: reviewPAType[];
  appeal_buttons: appealType[];
};

export type CaseListErrorResponse = {
  code: number;
  error: string;
};

export type caseListAPIResponse = {
  code: number;
  message: string;
  data: {
    case: CaseType[];
  };
};

export type CaseListAPIResponseType = {
  status: number;
  cases: CaseListingType[];
  total_pages: number;
  investigated: number;
  pending: number;
  quarter_date: string;
  medical_reimbursement_rate: string;
};

export type QueryParamsState = {
  open: string | boolean;
  sort_by: string;
  direction: "asc" | "desc";
  search_query: string;
  date_from: string;
  date_to: string;
  page: number;
  category_type: string;
  drug_name: string;
};

export enum QueryStateActions {
  CHANGE_CASE_STATUS = "changeCaseStatus",
  CHANGE_SORT_PARAMS = "changeSortParams",
  CHANGE_SEARCH_QUERY = "changeSearchQuery",
  CHANGE_FROM_DATE = "changeFromDate",
  CHANGE_TO_DATE = "changeToDate",
  CLEAR_STATE = "clearState",
  CHANGE_PAGE = "changePage",
  CHANGE_CATEGORY_TYPE = "changeCategoryType",
  CHANGE_DRUG = "CHANGE_DRUG",
}

export type QueryStateActionType =
  | {
      type: QueryStateActions.CHANGE_CASE_STATUS;
      payload: boolean | string;
    }
    | {
      type: QueryStateActions.CHANGE_DRUG;
      payload: string;
    }
  | {
      type: QueryStateActions.CHANGE_CATEGORY_TYPE;
      payload: string;
    }
  | {
      type: QueryStateActions.CHANGE_SORT_PARAMS;
      payload: {
        sort_by: string;
        direction: "asc" | "desc";
      };
    }
  | {
      type: QueryStateActions.CHANGE_SEARCH_QUERY;
      payload: string;
    }
  | {
      type: QueryStateActions.CHANGE_FROM_DATE;
      payload: string;
    }
  | {
      type: QueryStateActions.CHANGE_TO_DATE;
      payload: string;
    }
  | {
      type: QueryStateActions.CHANGE_PAGE;
      payload: number;
    }
  | {
      type: QueryStateActions.CLEAR_STATE;
    };

export type DownloadZipResponseType = {
  response: string;
};
export type DownloadPDFResponseTypeApi = {
  code: number;
  message: string;
  response: string;
  report_name: string;
};

export type DownloadZipErrorType = {
  code: number;
  error: string;
};
export type statetype = {
  case_id: number;
  id: number;
  insurance_id?: number;
  insurance_name?: string;
};

export type RenderNextStepProps = {
  kase: CaseListingType;
  showHideModalForTreatment: Dispatch<
    SetStateAction<{
      isModalVisible: boolean;
      caseId: number;
      prescriptionId: number;
    }>
  >;

  setDataForCancelModal: Dispatch<
    SetStateAction<{
      isModalVisible: boolean;
      caseId: number;
      prescriptionId: number;
    }>
  >;
  setDataForTreatmentDecisionModal : Dispatch<
  SetStateAction<{
    isModalVisible: boolean;
    caseId: number;
    prescriptionId: number;
    prior_authorization_id:number;
    prior_auth_status: string | null;
    kase?:CaseListingType 
  }>
>;
  onTreatedOrNotTreatedButtonClicked: (
    value: string,
    treatment_id: number
  ) => void;
  setDataForTreatedOrNotTreatedTreatmentModal: Dispatch<
    SetStateAction<{
      isModalVisible: boolean;
      caseId: number;
      treatment_id: number;
    }>
  >;
  getCaseListing: <TBodyType, TPathParams, TQueryParams>(
    body?: TBodyType | undefined,
    pathFromData?: TPathParams | undefined,
    queryFromData?: TQueryParams | undefined
  ) => void;
};
