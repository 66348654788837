import React, { useContext } from "react";
import { ToastContext } from "src/context/toastContext";
import { ToastTypes } from "src/types";

// Helper functions for PKCE
const generateCodeVerifier = (): string => {
  const randomArray = new Uint8Array(32);
  window.crypto.getRandomValues(randomArray);
  return btoa(String.fromCharCode(...randomArray))
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
};

const generateCodeChallenge = async (codeVerifier: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest("SHA-256", data);
  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
};

const SSOLoginButton: React.FC = () => {
  const { showToast } = useContext(ToastContext);

  const handleLogin = async (): Promise<void> => {
    try {
      // Fetch environment variables
      const clientId = process.env.REACT_APP_AZURE_CLIENT_ID;
      const tenantId = process.env.REACT_APP_AZURE_TENANT_ID || "common";
      const tokenEndpointTemplate = process.env.REACT_APP_AZURE_TOKEN_ENDPOINT;
      const authUrlTemplate = process.env.REACT_APP_AZURE_AUTH_URL;
      const redirectUriTemplate = process.env.REACT_APP_AZURE_REDIRECT_URI;

      if (!clientId || !authUrlTemplate || !tokenEndpointTemplate || !redirectUriTemplate) {
        throw new Error("One or more required environment variables are missing.");
      }

      // Generate PKCE parameters
      const codeVerifier = generateCodeVerifier();
      const codeChallenge = await generateCodeChallenge(codeVerifier);

      // Save codeVerifier in sessionStorage and localStorage
      sessionStorage.setItem("codeVerifier", codeVerifier);
      localStorage.setItem("codeVerifier", codeVerifier);

      // Replace placeholders in the authorization URL
      const redirectUri = redirectUriTemplate.replace(
        "{window_location_origin}",
        encodeURIComponent(window.location.origin)
      );

      const authUrl = authUrlTemplate
        .replace("{tenantId}", tenantId)
        .replace("{clientId}", clientId)
        .replace("{redirectUri}", redirectUri)
        .replace("{scope}", encodeURIComponent("openid email profile offline_access"))
        .replace("{codeChallenge}", codeChallenge);

      // Redirect to Azure AD
      console.log("Redirecting to:", authUrl);
      window.location.href = authUrl;
    } catch (error) {
      console.error("Error during login:", error);

      if (error instanceof Error) {
        showToast([`Login failed: ${error.message}`], ToastTypes.ERROR);
      } else {
        showToast(["An unknown error occurred during login. Please try again."], ToastTypes.ERROR);
      }
    }
  };

  return <button className="nav-link text-white" onClick={handleLogin}>Rep Login</button>;
};

export default SSOLoginButton;








