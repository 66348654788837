import { FC, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import ContactAndSafetyInfo from "../../components/contactAndSafetyInfo";
import EnrollmentCard from "../../components/enrollmentCard";
import FooterText from "../../components/footerText";
import NPIInputField from "../../components/inputType";
import EnrollmentPracticeForm from "../../components/enrollmentComp/practiceForm";
import EnrollmentUserForm from "../../components/enrollmentComp/userForm";
import EnrollmentPrescriberForm from "../../components/enrollmentComp/prescriberForm";

import useFetch from "src/hooks/useFetch";
import { ToastContext } from "src/context/toastContext";

import { TextAlignProps } from "../../components/contactAndSafetyInfo/types";
import { AddEnrollmentErrorType, AddEnrollmentResponseType, EnrollmentPrescriber, EnrollmentUser, FieldName, NewEnrollmentType, NewEnrollmentTypeForAPI } from "./types";
import { APIEndpoints } from "src/types/apiTypes";
import { NPILookupResponse, ToastTypes } from "src/types";
import { NPISpeciality } from "../addProvider/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { NewEnrollmentSchema } from "src/validation/newEnrollment";

import { LoaderContext } from "src/context/loaderContext";
import { populateEnrollmentForm } from "src/helpers/enrollment";
import { useNavigate } from "react-router-dom";




const NewEnrollment: FC = (): JSX.Element => {
  const [preservedNpi, updatePreservedNpi] = useState<string>("");
  const navigate = useNavigate();
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const { control, formState, handleSubmit, getValues, setValue, watch, reset, trigger, resetField } = useForm<NewEnrollmentType>({
    mode: 'all',
    resolver: yupResolver(NewEnrollmentSchema),
    defaultValues: {
      enrollment_contacts_attributes: [
        {} as EnrollmentUser
      ],
      enrollment_doctors_attributes: [
        {} as EnrollmentPrescriber
      ],
    },
  });



  /**
   * Fetching doctor specialities to be rendered in prescriber's seciton
   * @First useFetch API integration
   * @Second useEffect to handle api response
   */
  const { data: specialityData } = useFetch<NPISpeciality[]>(APIEndpoints.GET_PROVIDER_SPECIALITIES);





  /**
   * @NPILookup calling npi lookup for details
   * @first useFetch hook for api call
   * @second useFetch error handler
   * @third useFetch success handler
   * @fourth npi lookup api trigger
   */
  const { data: npiLookupData, error: npiLookupError, mutate: lookupNpi } = useFetch<NPILookupResponse>(APIEndpoints.NPI_LOOKUP);

  const clearFields = () => {
    reset();
    setValue("npi", preservedNpi);
    setValue("enrollment_practices_attributes.0.npi", preservedNpi);
    setValue("enrollment_doctors_attributes.0.npi", preservedNpi);
    updatePreservedNpi("");
    showToast([`No Data Found`], ToastTypes.ERROR);
  }

  const populateFields = (npiLookupData: NPILookupResponse) => {

    if (npiLookupData.practice_data)
      showToast([`Practice Data Found`], ToastTypes.INFO);
    if (npiLookupData.doctor_data)
      showToast([`Doctor Data Found`], ToastTypes.INFO);

    populateEnrollmentForm(setValue, npiLookupData, getValues("npi") || "");

    trigger(["enrollment_practices_attributes", "enrollment_doctors_attributes"]);
  }

  const clearSpecificFields = (
    fields: string[],
    prefix: string,
    npiValue: string = '',
    errorMessage: string
  ) => {
    fields.forEach(field => setValue(`${prefix}.${field}` as FieldName, ''));
    setValue(`${prefix}.npi` as FieldName, npiValue);
    updatePreservedNpi("");
    showToast([errorMessage], ToastTypes.ERROR);
  };

  const clearPracticeField = () => {

    clearSpecificFields(
      [
        'practice_name',
        'phone_number',
        'street',
        'city',
        'zip',
        'tax_id',
        'fax_number',
        'suite',
        'state'
      ],
      'enrollment_practices_attributes.0',
      preservedNpi,
      'Practice Data Not Found'
    );
  }
  const clearDoctorField = () => {

    clearSpecificFields(
      [
        "firstname",
        "middlename",
        "lastname",
        "phone_number",
        "sln",
        "ptan",
        "tax_id",
        "ref_taxonomy_id",
        "fax_number",
        "dea",
        "email",
      ],
      'enrollment_doctors_attributes.0',
      preservedNpi,
      'Doctors Data Not Found'
    );

  }



  useEffect(() => {
    if (npiLookupData || npiLookupError) {
      hideLoader();
      if (npiLookupData) {
        const { doctor_data, practice_data } = npiLookupData;

        if (doctor_data !== null && practice_data !== null) {
          populateFields(npiLookupData);
        }
        if (doctor_data === null && practice_data === null) {
          clearFields();
        }
        if (doctor_data !== null && practice_data === null) {
          clearPracticeField();
        }
        if (doctor_data === null && practice_data !== null) {
          clearDoctorField()
        }
      }


    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [npiLookupData, npiLookupError]);

  const callNPILookup = () => {
    const npi = getValues("npi");

    if (!npiLookupData || npi !== preservedNpi) {
      if (!npi || (npi && npi.length === 0) || formState.errors.npi)
        showToast([`Please enter valid NPI for lookup`], ToastTypes.ERROR);
      else {
        setLoader();
        updatePreservedNpi(npi);
        const data = {
          'global_doctor[npi]': npi,
        };
        lookupNpi(null, null, data);
      }
    }
  };



  /**
   * @watchingChangesInFormElements Here we are adding new users/providers in the form
   */
  const userListing = watch("enrollment_contacts_attributes");
  const prescriberListing = watch("enrollment_doctors_attributes");

  const addNewUser = () => {
    const users: EnrollmentUser[] = getValues("enrollment_contacts_attributes");
    const newUser = {} as EnrollmentUser;
    users.push(newUser);

    setValue("enrollment_contacts_attributes", users);
  };

  const removeUser = (index: number) => {
    const users: EnrollmentUser[] = getValues("enrollment_contacts_attributes");
    users.splice(index, 1);

    setValue("enrollment_contacts_attributes", users);
  };

  const addNewPrescriber = () => {
    const prescribers: EnrollmentPrescriber[] = getValues("enrollment_doctors_attributes");
    const newPrescriber = {} as EnrollmentPrescriber;
    prescribers.push(newPrescriber);

    setValue("enrollment_doctors_attributes", prescribers);
  };

  const removePrescriber = (index: number) => {
    const prescribers: EnrollmentPrescriber[] = getValues("enrollment_doctors_attributes");
    prescribers.splice(index, 1);

    setValue("enrollment_doctors_attributes", prescribers);
  };




  /**
   * @AddingNewEnrollment Sending entered validated data to API
   * @first adding useFetch to call api
   * @second useFetch error handler
   * @third useFetch success handler
   * @fourth submit form button action
   */
  const {
    loading: enrollmentLoading,
    error: enrollmentError,
    data: enrollmentData,
    mutate: addEnrollment,
    removeErrorAndData,
  } = useFetch<AddEnrollmentResponseType>(APIEndpoints.ADD_ENROLLMENT);

  useEffect(() => {
    if (enrollmentError) {
      //console.log("enrollmentError.....",enrollmentError)
      const { message } = enrollmentError as unknown as AddEnrollmentErrorType;
      showToast([message], ToastTypes.ERROR);

      // if (message === 'An unexpected error occurred: Validation failed: Enrollment contacts email User email already in use'){
      //   showToast(['Enrollment contact email already exists.'], ToastTypes.ERROR);
      // }else{

      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollmentError]);

  useEffect(() => {
    if (enrollmentData) {
      const { status: { code, message } } = enrollmentData;
      if (code === 200) {
        showToast([message], ToastTypes.SUCCESS);
        reset();
      } else {
        showToast([message.replace("An unexpected error occurred: Validation failed:", "").trim()], ToastTypes.WARNING);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollmentData]);

  const submitHandler = async (values: NewEnrollmentType) => {
    if (enrollmentError || enrollmentData) removeErrorAndData();

    const data: NewEnrollmentTypeForAPI = values as unknown as NewEnrollmentTypeForAPI;
    data.enrollment_practices_attributes[0].program = process.env.CLIENT_DB || "";
    const doctorAttributes = values.enrollment_doctors_attributes.map((doc) => {
      return {
        ...doc,
        ref_taxonomy_id: (specialityData?.find((speciality) => speciality.code === doc.ref_taxonomy_id)?.id || 0).toString()
      };
    });

    addEnrollment({
      enrollment: {
        ...data,
        enrollment_doctors_attributes: doctorAttributes
      }
    });
  };
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault(); // Prevent the default anchor behavior (page reload)
    navigate('/pages/baa'); // Navigate to the desired page
  };

  return (
    <div className="overflow-auto pt-3">
      <div className="container">
        <ContactAndSafetyInfo alignText={TextAlignProps.TEXT_END} textColor="black" />
        <form onSubmit={handleSubmit(submitHandler)} className="d-flex flex-column flex-fill pb-3 pb-lg-4 pb-md-4">
          <EnrollmentCard isCrossBtnVisible={false} isTextCenter={true} cardHeader="Practice Registration">
            <>
              <p className="text-center">
                Welcome to the FlexForward enrollment page. To submit an enrollment request, please fill in as much
                detail as possible about your practice and providers. All information provided will be stored in your
                browser session and automatically deleted when your session ends or after 15 minutes of inactivity.
                FlexForward uses the information you submit on this form to process your enrollment request. By clicking
                on the "Submit Enrollment" button below, you agree that the information you provide will be governed as
                described here and as in
                <a href='https://www.pacira.com/privacy-cookies-policy/' target="_blank" rel="noreferrer"> Pacira's Privacy Policy</a>
                , and the Business Associate Agreement.
              </p>
            </>
          </EnrollmentCard>

          <EnrollmentCard isTextCenter={false} isCrossBtnVisible={false} cardHeader="NPI">
            <>
              <p className="text-start">Input a provider or practice NPI and we'll see if we can find the rest!</p>
              <Controller
                name="npi"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <NPIInputField
                    inputId="npi"
                    inputLabel="NPI"
                    isRequired={false}
                    isRowBased={true}
                    inputPlaceholder="NPI should be 10 digits"
                    field={field}
                    error={error}
                    onBlur={callNPILookup}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        callNPILookup();
                      }
                    }}
                  />
                )}
              />
            </>
          </EnrollmentCard>

          <EnrollmentCard isTextCenter={false} isCrossBtnVisible={false} cardHeader="Practice">
            <EnrollmentPracticeForm
              parentNpi={preservedNpi}
              control={control}
              formState={formState}
              getValues={getValues}
              setValue={setValue}
              resetField={resetField}
              trigger={trigger}
            />
          </EnrollmentCard>

          {userListing.map((_d, index) => (
            <EnrollmentUserForm key={index} removeUser={removeUser} control={control} userIndex={index} />
          ))}
          <div className="row justify-content-end mb-2">
            <div className="col-lg-3 text-end">
              <button className="btn btn-success " type="button" onClick={addNewUser}>
                <i className="fas fa-plus" style={{ marginRight: '8px' }}> </i>
                Add User</button>
            </div>
          </div>

          {prescriberListing.map((_d, index) => (
            <EnrollmentPrescriberForm
              key={index}
              parentNpi={preservedNpi}
              control={control}
              prescriberIndex={index}
              formState={formState}
              getValues={getValues}
              setValue={setValue}
              trigger={trigger}
              specialityData={specialityData}
              resetField={resetField}
              removePrescriber={removePrescriber}
            />
          ))}
          <div className="row justify-content-end mb-2">
            <div className="col-lg-3 text-end">
              <button className="btn btn-success " type="button" onClick={addNewPrescriber}>
                <i className="fas fa-plus" style={{ marginRight: '8px' }}> </i>
                Add Prescriber </button>
            </div>
          </div>
          <div className={"mt-2"}>
            <p className="text-center">
              By clicking submit enrollment below, you agree to the
              <a href="#" onClick={handleClick} className=""> Business Associate Agreement </a>
              and on behalf of the
              practice that is listed above. After clicking <b>Submit Enrollment</b> one of our representatives will reach
              out to your office in order to verify your account with you.
            </p>
          </div>
          <div className="row mb-4">
            <div className="col-lg-12 text-center">
              <button type="submit" disabled={enrollmentLoading} className="btn btn-primary">
                <div className="row align-items-center px-1">
                  {enrollmentLoading ? (
                    <div className="spinner-border text-light col-3" role="status">
                      <span className="sr-only"></span>
                    </div>
                  ) : null}
                  <p className="col-auto mb-0">Submit Enrollment</p>
                </div>
              </button>

            </div>
          </div>
        </form>
        <FooterText footerType="black" />
      </div>
    </div>
  );
};

export default NewEnrollment;
