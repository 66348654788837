import { FC } from "react";
import { StatisticsObjectType } from "../caseVolumeLineChart/types";
import { Link } from "react-router-dom";
import CustomPieChart from "../customPieChart";



const CustomComponentAreaCaseBySubmission: FC<StatisticsObjectType> = ({ StatisticsObjectType ,title}): JSX.Element => {

  const findPath = () => {
    if(title === "Area Manager") {
      return '/regions'
    } else if(title === "Regional Manager") {
      return '/districts'
    } else if(title === "Reps") {
      return '/territories'
    } else if(title === "Practices") {
      return '/practice_stats'
    } else if (title === "Doctors") {
      return "/doctor_stats"
    } else if (title === "Areas") {
      return "/regions"
    } else if (title === "Regions") {
      return "/districts"
    }else if (title === "Territories") {
      return "/territories"
    }
  }

  return (
    <div className="flex-fill row">
      <div className="col-12 col-md-6 col-sm-12 d-flex">
        <div className="flex-fill card">
          <div className="card-header">
            <h4 className="m-0 text-center">{title}</h4>
          </div>
            
          <div className="card-body p-0 overflow-y-auto"  style={{maxHeight:'434px'}}>
            <table className="table table-hover table-responsive" >
              <thead className="table-light">
                <tr className="text-center">
                  <th>Name</th>
                  <th>Cases</th>
                </tr>
              </thead>

              <tbody>
                {StatisticsObjectType.territories && StatisticsObjectType.territories.data && StatisticsObjectType.territories.data.length > 0 &&
                  StatisticsObjectType.territories.data.map((item, key) =>
                    <tr className="text-center" key={key}>
                      <td>
                        <Link
                          to={{
                            pathname: findPath(),
                            search: (() => {
                              const newParams = new URLSearchParams()
                              newParams.set("userId", item.id.toString());
                              newParams.set("role", item.role);
                              return newParams.toString();
                            })(),
                          }}
                          className="fs-6 fw-normal link-text"
                        >
                          {item.name}
                        </Link>
                      </td>
                      <td>{item?.cases}</td>
                    </tr>
                  )
                }
              </tbody>

              <tfoot>
                <tr className="text-center">
                  <td className="fw-bold">Total</td>
                  <td className="fw-bold">{ StatisticsObjectType.territories?.case_total}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <div className="col-12 col-md-6 col-sm-12">
        <CustomPieChart title={"Cases By Submission Mode"} pieChartObject={StatisticsObjectType.mos} />
      </div>
    </div>
  );
};

export default CustomComponentAreaCaseBySubmission;
