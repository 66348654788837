import { FC, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import FooterText from "../../components/footerText";
import AuthSubHeader from "src/components/authSubHeader";

import { ToastContext } from "src/context/toastContext";
import useFetch from "src/hooks/useFetch";

import { ToastTypes } from "src/types";
import { APIEndpoints } from "src/types/apiTypes";
import { SubmitPrecertResponse } from "./types";
import { ACCEPTED_PRECERT_FILE_EXTENSIONS } from "src/constants";
import { LoaderContext } from "src/context/loaderContext";
// import NewPrecertComp from "src/components/precertComp";


const Precert: FC = (): JSX.Element => {

  
  const [uploadFile, changeUploadFile] = useState<FileList | null>(null);
  const navigate = useNavigate();
  const ref = useRef<HTMLInputElement>(null);
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const { caseId, precertsId } = useParams();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search); // Parse query params




  const { loading: isFileUploading, error: submitPrecertError, data: submitPrecertData, mutate: submitPrecert } =
    useFetch<SubmitPrecertResponse>(APIEndpoints.SUBMIT_PRECERT, {}, { caseId, precertId: precertsId });

  useEffect(() => {
    if (submitPrecertData || submitPrecertError) {
      hideLoader()
     if(submitPrecertData) {
       showToast([`Precert Saved and Completed.`], ToastTypes.SUCCESS);
       navigate(-1);
     }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitPrecertData, submitPrecertError]);

  const completeMySelf = () => {
    const formData = new FormData();
    formData.append("precert[test]", "");
    if (uploadFile)
      formData.append("clinical_document", uploadFile[0]);
    formData.append("commit", "cancel");
    setLoader();
    submitPrecert(formData);
  };

  const completeOnMyBehalf = () => {
    if (uploadFile) {
      const formData = new FormData();
      formData.append("precert[test]", "");
      formData.append("clinical_document", uploadFile[0]);
      formData.append("commit", "submit_clinical_document");
      setLoader();
      submitPrecert(formData);
    }
  };





  const reset = () => {
    if (ref && ref.current) {
      ref.current.value = ""
    }
  };

  const clearDataAndForm = () => {
    changeUploadFile(null);
    reset();
  };

  const validateAndShowToast = (file: File) => {
    const caseFileExt = `.${file.name.split(".").pop()}`;
    const isExtAccepted = ACCEPTED_PRECERT_FILE_EXTENSIONS[caseFileExt];
    if (typeof isExtAccepted === "string") {
      const sizeInMBs = Number((file.size / (1024 * 1024)).toFixed(2));
      if (sizeInMBs <= 25) {
        return true;
      } else {
        showToast(["Document size should not exceed 25mb."], ToastTypes.WARNING);
      }
    } else {
      showToast(
        [`Not an acceptable format. The accepted formats are ${Object.keys(ACCEPTED_PRECERT_FILE_EXTENSIONS).toString()}`],
        ToastTypes.ERROR
      );
    }
    return false;
  };

  const handleConfirmAndExecute = (title: string, action: Function) => {
    const result = window.confirm(title);
    if (result) action();
  };

  const ConfirmAlert = (title: string, funcName: string) => {
    if (uploadFile) {
      if (validateAndShowToast(uploadFile[0])) {
        handleConfirmAndExecute(title, funcName === "completeMySelf" ? completeMySelf : completeOnMyBehalf);
      }
    } else {
      if (funcName === "completeMySelf") {
        handleConfirmAndExecute(title, completeMySelf);
      } else {
        showToast(["Document should be uploaded before submit."], ToastTypes.WARNING);
      }
    }
  };

  return (
    <div className="container-fluid d-flex flex-fill auth-page body-bg overflow-auto">
      <div className="container d-flex flex-column">
        <AuthSubHeader />
       <div className="flex-fill">
          <div className="card mt-4">
            <div className="card-header">
              <h4 className="mb-0">
                Precert Detected: Please Provide Clinical Information 
                {queryParam.get('insuranceName') && <p>(Insurance Name: {queryParam.get('insuranceName')})</p>}
              </h4>
            </div>
            

            <div className="card-body">
              <form>
                <p>
                We've detected the patient's insurance requires additional information to determine coverage for this product, but the form is not currently available. If you wish for us to complete the precert on your behalf, simply upload the patient's chart in the field below.
                </p>

                <div className="card">
                  <div className="card-header">
                    <h6 className="text-white" style={{fontFamily: 'sans-serif'}}>
                      <i className="fas fa-cloud-upload-alt me-2"></i>
                      Upload Clinical Information
                    </h6>
                  </div>

                  <div className="card-body">
                    <div className="input-group mb-2">
                      <input
                        type="file" className="form-control" id="fileUpload"
                        onChange={(e) => changeUploadFile(e.target.files)} ref={ref}
                      />
                      {uploadFile ? (
                        <button
                          className="btn btn-primary" onClick={clearDataAndForm} type="button"
                          id="fileUpload" disabled={isFileUploading}
                        >
                          Clear
                        </button>
                      ) : null}
                    </div>
                    <small className="d-block">
                      Click here to upload the patient's chart. Currently we accept .txt, .pdf, .tiff, .doc, .ccd, .cda,
                      .ccr, .ccda and image files.
                    </small>
                  </div>
                </div>

                <div className="mt-3 text-end">
                  <button
                    className="btn btn-danger mx-1 rounded-1" type="button" name="cancel"
                    onClick={() => ConfirmAlert("Are you sure you would like to i will complete myself?\n This action can not be undone.", "completeMySelf")}
                    disabled={isFileUploading}
                  >
                    <span
                      data-bs-toggle="tooltip" data-bs-placement="bottom"
                      title="This will remove the Precert Next Step from the cases screen and you will no longer be able to complete the precert below."
                    >
                      I Will Complete Myself
                    </span>
                  </button>
                  <button
                    className="btn btn-success rounded-1" type="button"
                    onClick={() => ConfirmAlert("Are you sure you would like to complete on my behalf?\n This action can not be undone.", "completeOnMyBehalf")}
                    disabled={!uploadFile || isFileUploading}
                  >
                    <span
                      data-bs-toggle="tooltip" data-bs-placement="bottom"
                      title="We can now complete on your behalf!"
                    >
                      Complete On My Behalf
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* <NewPrecertComp /> */}
        </div>
        <div className="mt-3"><FooterText footerType="black"/></div>
      </div>
    </div>
  );
};
export default Precert;
