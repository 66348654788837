import { PatientTitleProps } from "src/types/case";

export const DEFAULT_DRUG_NAME = "zilretta";

export const CPT_CODE_TO_HIDE_NERVES = "64624";

export const PATIENT_TITLE: readonly PatientTitleProps[] = [
  {
    label: 'Mr.',
    value: 'mr',
  },
  {
    label: 'Ms.',
    value: 'ms',
  },
  {
    label: 'Mrs.',
    value: 'mrs',
  },
  {
    label: 'Miss',
    value: 'miss',
  },
];

export const PATIENT_GENDER: PatientTitleProps[] = [
  {
    label: 'Male',
    value: 'M',
  },
  {
    label: 'Female',
    value: 'F',
  },
];

export const PATIENT_TIME_TO_CONTACT: PatientTitleProps[] = [
  {
    label: 'Morning',
    value: 'morning',
  },
  {
    label: 'Afternoon',
    value: 'afternoon',
  },
  {
    label: 'Evening',
    value: 'evening',
  },
];

export const PATIENT_PHONE_TYPES: PatientTitleProps[] = [
  {
    label: 'Cell',
    value: 'cell',
  },
  {
    label: 'Home',
    value: 'home',
  },
  {
    label: 'Work',
    value: 'work',
  },
];

export const PATIENT_ADDR_TYPES: PatientTitleProps[] = [
  {
    label: 'Home',
    value: 'home',
  },
  {
    label: 'Work',
    value: 'work',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const PATIENT_INSURANCE_TYPE: PatientTitleProps[] = [
  {
    label: 'Self',
    value: 'self',
  },
  {
    label: 'Spouse',
    value: 'spouse',
  },
  {
    label: 'Guardian',
    value: 'guardian',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const PATIENT_SITE_TYPE: PatientTitleProps[] = [
  {
    label: '11-Physician office/clinic',
    value: 'office',
  },
  {
    label: '24-Ambulatory Surgery Center',
    value: 'asc',
  },
  {
    label: '22-Hospital Outpatient Department (On-campus)',
    value: 'hospital',
  },
  {
    label: '19-Hospital Outpatient Department (Off-campus)',
    value: 'hospital-off',
  },
];

export const PATIENT_PHARMACY_INSTRUCTIONS: PatientTitleProps[] = [
  {
    label: 'Initial Course - 5 Refills',
    value: 'standard'
  },
  {
    label:'Maintenance Course - 10 Refills',
    value: 'maintenance'
  },
  {
    label: 'Initial Course Bilateral - 5 Refills',
    value: 'bilateral'
  },
  {
    label: 'Bilateral Maintenance Course - 10 Refills',
    value: 'bilateral_maintenance',
  }
];

export const CASE_STATUS_LISTING: { label: string; value: string | boolean; }[] = [
  {
    label: 'All Cases',
    value: '',
  },
  {
    label: 'Active Cases',
    value: true,
  },
  {
    label: 'Closed Cases',
    value: false,
  },
];
export const DRUG_OPTIONS: { label: string; value: string }[] = [
  {
    label: 'Select Drug',
    value: '',
  },
  {
    label: "iovera°",
    value: "iovera°",
  },
  {
    label: "ZILRETTA",
    value: "ZILRETTA",
  },
];

export const TREATMENT_STATUS_LISTING: { label: string; value: string | boolean; }[] = [
  {
    label: 'All Treatments',
    value: '',
  },
  {
    label: 'Active Treatments',
    value: true,
  },
  {
    label: 'Closed Treatments',
    value: false,
  },
];

export const ORDER_STATUS_LISTING: { label: string; value: string | boolean; }[] = [
  {
    label: 'All Orders',
    value: '',
  },
  {
    label: 'Active Orders',
    value: true,
  },
  {
    label: 'Closed Orders',
    value: false,
  },
];

export const CASE_HEADER_LISTING = [
  {
    value: 'id',
    label: 'ID',
  },
  {
    value: 'created_at',
    label: 'Submitted',
  },
  {
    value: 'patient_firstlastname',
    label: 'Patient',
  },
  {
    value: 'doctor_firstlastname',
    label: 'Provider',
  },
  {
    value: 'status_name',
    label: 'Status',
  },
  {
    value: '',
    label: 'Next Steps',
  },
  {
    value: '',
    label: 'Actions',
  },
];

export const CASE_HEADER_LISTING_IS_IOVERA_PRACTICE = [
  {
    value: 'id',
    label: 'ID',
  },
  {
    value: 'created_at',
    label: 'Submitted',
  },
  {
    value: 'drug_name',
    label: 'Product',
  },
  {
    value: 'practice_name',
    label: 'Submitting Practice',
  },
  {
    value: 'soc_name',
    label: 'Site of Care',
  },
  {
    value: 'patient_firstlastname',
    label: 'Patient',
  },
  {
    value: 'doctor_firstlastname',
    label: 'Physician',
  },
  {
    value: 'status_name',
    label: 'Status',
  },
  {
    value: '',
    label: 'Next Steps',
  },
  {
    value: '',
    label: 'Actions',
  },
];

export const TREATMENT_HEADER_LISTING = [
  {
    value: 'case_id',
    label: 'Case ID',
  },
  {
    value: '',
    label: 'Instillation',
  },
  {
    value: '',
    label: 'Ordered'
  },
  {
    value: '',
    label: 'Delivery ETA',
  },
  {
    value: '',
    label: 'Patient',
  },
  {
    value: '',
    label: 'Provider',
  },
  {
    value: 'status_name',
    label: 'Treatment Status',
  },
  {
    value: '',
    label: 'Next Steps',
  },
];

export const ORDER_HEADER_LISTING = [
  {
    value: 'id',
    label: 'ID',
  },
  {
    value: 'case_id',
    label: 'Case ID',
  },
  {
    value: 'po_number',
    label: 'Customer PO',
  },
  {
    value: 'user_name',
    label: 'Ordered By',
  },
  {
    value: 'created_at',
    label: 'Ordered Date',
  },
  {
    value: 'completed_at',
    label: 'Completed Date',
  },
  {
    value: '',
    label: 'Status',
  },
];

export const STATE_TREATMENT = [
  {
    value: 'Cost',
    label: 'Cost',
  }, {
    value: 'Off label',
    label: 'Off label',
  }, {
    value: 'PA denied',
    label: 'PA denied',
  }, {
    value: 'Patient refused',
    label: 'Patient refused',
  }, {
    value: 'Step-edits not met',
    label: 'Step-edits not met',
  }, {
    value: 'Unable to contact patient',
    label: 'Unable to contact patient',
  }, {
    value: 'Other',
    label: 'Other',
  }
];
export const STATUS_REASON = [
  
  {
    value: 'Change in Medical Condition',
    label: 'Change in Medical Condition',
  }, 
  {
    value: 'Cost',
    label: 'Cost',
  }, 
  {
    value: 'Duplicate Case',
    label: 'Duplicate Case',
  }, 
  {
    value: 'Not Covered By Insurance',
    label: 'Not Covered By Insurance',
  }, 
  {
    value: 'PA denied',
    label: 'PA denied',
  },
  {
    value: 'Patient refused',
    label: 'Patient refused',
  }, 
  {
    value: 'Unable to contact patient',
    label: 'Unable to contact patient',
  }, 
  {
    value: 'Other',
    label: 'Other',
  }
];

export const STATE_UPDATE_PA = [
  {
    value: 'approved',
    label: 'Approved',
    change_note: 'Rx PA Marked Approved by office.',
  }, 
  {
    value: 'denied',
    label: 'Denied',
    change_note: 'Rx PA Marked Denied by office.',
  },
   {
    value: 'cancelled',
    label: 'Cancelled',
    change_note: 'Rx PA Cancelled by office.',
  }
];

export const DRUG_QUANTITY = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
];

export const INJECTION_SITE = [
  {
    value: "left",
    label: "Left",
  },
  {
    value: "right",
    label: "Right",
  },
  {
    value: "bilateral",
    label: "Bilateral",
  },
];
export const NERVE_NAMES = [
  { value:"LFCN" ,
    label:'Lateral Femoral Cutaneous Nerve'
  } ,
  { value:"ILFCN" ,
    label:'Intermediate Lateral Femoral Cutaneous Nerve'
  } ,
  { value:"AFCN" ,
    label:'Anterior Femoral Cutaneous Nerve'
  } ,
  { value:"MFCN" ,
    label:'Medial Femoral Cutaneous Nerve'
  } ,
  { value:"IMFCN" ,
    label:'Intermediate Medial Femoral Cutaneous Nerve'
  } ,
  { value:"SPBSN" ,
    label:'Supra-Patellar Branch of the Saphenous Nerve'
  } ,
  { value:"SBIBSN" ,
    label:'Superior Branch of the Infrapatellar Branch of the Saphenous Nerve'
  } ,
  { value:"IBIBSN" ,
    label:'Inferior Branch of the Infrapatellar Branch of the Saphenous Nerve'
  } ,

]


export const TREATMENT_AREA = [
  {
    value: "left",
    label: "Left Knee",
  },
  {
    value: "right",
    label: "Right Knee",
  },
  {
    value: "bilateral",
    label: "Bilateral Knee",
  },
  {
    value: "other",
    label: "other",
  },
]

export const SHIP_TO = [
  {
    value: "provider",
    label: "Provider",
  },
  {
    value: "patient",
    label: "Patient",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const CASE_CATEGORY = {
  rx: "Pharmacy",
  med: "Medical",
  medrx: "Both"
};

export const RETREATMENT_TABLE_HEADER = [
  {
    value: 'id',
    label: 'ID',
  },
  {
    value: 'created_at',
    label: 'Submitted',
  },
  {
    value: 'patient_firstlastname',
    label: 'Patient',
  },
  {
    value: 'doctor_firstlastname',
    label: 'Physician',
  },
  {
    value: '',
    label: 'Patient Phone',
  },
  {
    value: '',
    label: 'Actions',
  },
];
export const COLOR_LIST = [
  "#7cb5ec","#434348","#90ed7d","#f7a35c","#8085e9","#f15c80","#e4d354","#2b908f","#f45b5b","#91e8e1"
]