import { FC, useCallback, useEffect, useState } from "react"
import HighCharts from "highcharts/highstock";
import HighchartsReact from 'highcharts-react-official'
import { COLUMN_CHART,  } from "src/constants/highCharts";
import { LineChartProps } from "./types";
import { format } from "date-fns";

const CustomLineChart: FC<LineChartProps> = ({ LineChartObject ,title }): JSX.Element => {

  const [columnChart, setColumnChart] = useState(COLUMN_CHART);

  // Populate line chart options with x-axis and series data
  const populateLineChart = useCallback(() => {
    const lineOptions = { ...columnChart };
    lineOptions.series = wrapperFunctionForLine();
    lineOptions.xAxis = {
      categories: wrapperFunctionForBaseAxis(),
    };
    setColumnChart(lineOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LineChartObject]);

  // Create a unified array of unique dates for the x-axis
  const wrapperFunctionForBaseAxis = () => {
    let allDates: any[] = [];
    LineChartObject.forEach((caseVolume: any) => {
      allDates = allDates.concat(Object.keys(caseVolume.data));
    });
    const updatedDates = allDates.map((item) => format(item, "MM-dd-yyyy"));
    return Array.from(new Set(updatedDates)).sort(); // Remove duplicates and sort the dates
  };

  // Map data to the line series, filling missing dates with null
  const wrapperFunctionForLine = () => {
    let lineSeriesArray: any = [];
    const xAxisDates = wrapperFunctionForBaseAxis(); // Get the unique x-axis dates

    LineChartObject.forEach((caseVolume) => {
      let seriesData: any = [];

      // Map over the xAxisDates, inserting data or null if missing
      xAxisDates.forEach((date) => {
        if (caseVolume.data) {
          if (caseVolume.data[format(date, "yyyy-MM-dd")] !== undefined) {
            seriesData.push(caseVolume.data[format(date, "yyyy-MM-dd")]);
          } else {
            seriesData.push(0); // Insert null for missing dates
          }
        }
      });

      lineSeriesArray.push({
        type: "column",
        name: caseVolume.name,
        data: seriesData,
        boostThreshold: 0,
      });
    });

    return lineSeriesArray;
  };

  useEffect(() => {
    populateLineChart();
  }, [populateLineChart]);




  return (
    <div className="card">
      <div className="card-header ">
        <h4 className="text-center m-0">{title}</h4>
      </div>
      <div className="card-body">
        <p className="text-center">Line convergence is positive, and higher numbers are better.</p>
        <HighchartsReact highcharts={HighCharts} options={columnChart} />
      </div>
    </div>

  )
}
export default CustomLineChart;
