import { FC, useContext, useEffect, useReducer, useRef, useState } from "react";
import HighCharts from "highcharts";
import HighchartsReact from 'highcharts-react-official'
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

import AuthSubHeader from "src/components/authSubHeader";
import FooterText from "src/components/footerText";
import TableFilters from "src/components/caseFilters";
import RenderNextStep from "./caseItemActions";
import RequestDateOfTreatment from "src/components/modals/requestDateOfTreatment";
import CancelTreatment from "src/components/modals/cancelTreatment";

import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { BAR_CHART } from "../../constants/highCharts";
import { ToastContext } from "src/context/toastContext";
import { ToastTypes } from "src/types";
import { AuthContext } from "src/context/authContext";
import { LoaderContext } from "src/context/loaderContext";

import { CaseListAPIResponseType, CaseListErrorResponse, CaseListingType, DownloadZipResponseType, QueryParamsState, QueryStateActionType, QueryStateActions } from "./types";
import {
  CASE_HEADER_LISTING_IS_IOVERA_PRACTICE,
  CASE_STATUS_LISTING,
} from "src/constants/cases";
import { initialState, queryParamReducer } from "./reducer";
import { TreatmentListErrorResponse, TreatmentStatusUpdateApiResponse } from "../treatmentListing/types";
import TreatedOrNotTreatedTreatment from "src/components/modals/treatedOrNotTreatedTreatment";
import { downloadZipFile } from "src/helpers/downloadFile";
// import UpdatePA from "src/components/modals/updatePA";
import TreatmentDecision from "src/components/modals/treatmentDecision";



const Dashboard: FC = (): JSX.Element => {
  const [chartOptions, setChartOptions] = useState(BAR_CHART);
  const [queryParams, dispatch] = useReducer(queryParamReducer, initialState);
  const [showRequestTreatmentModal, setDataForRequestModalTreatment] = useState<{ isModalVisible: boolean; caseId: number; prescriptionId :number }>({
    isModalVisible: false,
    caseId: 0,
    prescriptionId:0
    
  });
  const [cancelTreatmentModal, setDataForCancelModal] = useState<{ isModalVisible: boolean; caseId: number; prescriptionId :number }>({
    isModalVisible: false,
    caseId: 0,
    prescriptionId:0
  });
  const [treatmentDecisionModal, setTreatmentDecisionModal] = useState<{ isModalVisible: boolean; caseId: number; prescriptionId :number ,prior_authorization_id :number ,prior_auth_status : string | null ,kase?:CaseListingType }>({

    isModalVisible: false,
    caseId: 0,
    prescriptionId:0,
    prior_authorization_id:0,
    prior_auth_status:'',

  });
  const [treatedOrNotTreatedTreatmentModal, setDataForTreatedOrNotTreatedTreatmentModal] = useState<{ isModalVisible: boolean,caseId:number,treatment_id:number}>({
    isModalVisible: false,
    caseId : 0,
    treatment_id: 0
  });

  const { showToast } = useContext(ToastContext);
  const { destroySession } = useContext(AuthContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [selectedCaseStatus, setSelectedCaseStatus] = useState<string>("Active and Recently Completed Cases");

  // console.log("iovera_practice......in Case Listing",iovera_practice)



  const { data: caseObject, mutate: getCaseListing, error: caseError, removeErrorAndData: clearCaseState } =
    useFetch<CaseListAPIResponseType, QueryParamsState>(APIEndpoints.CASE_LIST, queryParams);

  useEffect(() => {
    if (caseError) {
      hideLoader();
      const { code, error } = caseError as unknown as CaseListErrorResponse;
      showToast([error || `Can't fetch cases details right now. Please try again in a while.`], ToastTypes.ERROR);
      if (code === 401) {
        destroySession();
        navigate(`/users/sign_in`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseError]);

  useEffect(() => {
    if (caseObject && caseObject.status) {
      hideLoader();
      if (caseObject.status === 500) {
        showToast(['No cases found against provided filters'], ToastTypes.ERROR);
      }

      const latestChartOptions = { ...chartOptions };
      latestChartOptions.series = [{
        type: 'bar',
        name: 'Value',
        dataLabels: {
          enabled: true,
        },
        data: [caseObject.pending, caseObject.investigated],
      }];

      setChartOptions(latestChartOptions);
      const chart = chartComponentRef.current?.chart;

      if (chart) {
        chart.redraw();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseObject]);

  const onClearButtonClicked = () => {
    if (caseError || caseObject) clearCaseState();
    setSelectedCaseStatus("Active and Recently Completed Cases");
    dispatch({
      type: QueryStateActions.CLEAR_STATE,
    });
    getCaseListing(null, null, initialState);
  };

  const handleSearchWithQueryParam = (updatedState?: QueryParamsState) => {
    if (caseError || caseObject) clearCaseState();
    setLoader();
    getCaseListing(null, null, updatedState ? updatedState : queryParams);
  };





  const changeIdOrder = (value: string) => {
    const order = queryParams.direction === 'desc' ? 'asc' : 'desc';

    const action: QueryStateActionType = {
      type: QueryStateActions.CHANGE_SORT_PARAMS,
      payload: {
        direction: queryParams.sort_by === value ? order : "desc",
        sort_by: value,
      },
    };
    dispatch(action);

    const updatedState = queryParamReducer(queryParams, action);
    handleSearchWithQueryParam(updatedState);
  };

  const changePageNumber = (page: { selected: number; }) => {
    const action: QueryStateActionType = {
      type: QueryStateActions.CHANGE_PAGE,
      payload: page.selected+1,
    };
    dispatch(action);

    const updatedState = queryParamReducer(queryParams, action);
    handleSearchWithQueryParam(updatedState);
  };





  const { data: treatmentUpdateData, mutate: updatetreatment, error: treatmentUpdateError } =
    useFetch<TreatmentStatusUpdateApiResponse>(APIEndpoints.TREATMENT_STATUS_UPDATE,{},{},false);

  useEffect(() => {
    if (treatmentUpdateError) {
      hideLoader();
      const { code, error } = treatmentUpdateError as unknown as TreatmentListErrorResponse;
      showToast([error || `Treatment can't update now. Please try again in a while.`], ToastTypes.ERROR);
      if (code === 401) {
        destroySession();
        navigate(`/users/sign_in`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentUpdateError]);

  useEffect(() => {
    const hasUpdateData = treatmentUpdateData?.code;

    if (hasUpdateData || treatmentUpdateError) {
      if (hasUpdateData) {
        getCaseListing(null, null, initialState);
      }
      hideLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentUpdateError, treatmentUpdateData]);

  const { data: downloadCaseData, loading: downloadCaseLoading, error: downloadCaseError, mutate: getDownloadLinkForZipFile, removeErrorAndData: clearZipFileData } =
    useFetch<DownloadZipResponseType>(APIEndpoints.DOWNLOAD_ZIP_FILE, {}, {}, false);

  useEffect(() => {
    if (downloadCaseData || downloadCaseError) {
      if(downloadCaseData){
        const { response } = downloadCaseData;
        downloadZipFile(response, `case_reports.zip`);
      }
      hideLoader();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadCaseData, downloadCaseError]);

  const downloadFileByCaseId = (caseId: number) => {
    if (downloadCaseData || downloadCaseError) clearZipFileData();
    setLoader();
    getDownloadLinkForZipFile({}, { caseId });
  };

  const onTreatedOrNotTreatedButtonClicked = (value: string, treatment_id: number) => {
    const requestBody = { status: 0 };

    if (value === "Not-Treated") {
      requestBody.status = 31;
    } else {
      requestBody.status = 22;
    }
    setLoader()
    updatetreatment(requestBody, { treatmentId: treatment_id });
  };




  return (
    <div className="cases-page body-bg overflow-y-auto overflow-x-hidden d-flex flex-column flex-fill">
      <div className="container-fluid px-3 pt-2">
        <AuthSubHeader />
        <div className="row" data-tour="fourth-step">
          <div className="col-12">
            {caseObject ? (
                <HighchartsReact
                    highcharts={HighCharts}
                    options={chartOptions}
                    immutable={true}
                    ref={chartComponentRef}
                />
            ) : null}
          </div>
        </div>
        <div className="card mt-2 flex-fill">
          <div className="card-header">
            <h4 className="m-0">{selectedCaseStatus}</h4>
          </div>
          <div className="card-body px-0">
            <div className="px-3">
              <p>The following is a list of all active and completed cases. Click on the Case ID to
                view details of the case. Yellow cases require user attention.</p>
              <TableFilters
                  dropdownData={CASE_STATUS_LISTING}
                  // drugOptions={DRUG_OPTIONS}
                  inputPlaceholder="Search by Patient, Physician, Case ID"
                  state={queryParams}
                  dispatch={dispatch}
                  handleSearchWithQueryParam={handleSearchWithQueryParam}
                  onClearButtonClicked={onClearButtonClicked}
                  onStatusChange={setSelectedCaseStatus}
              />
            </div>
            <div className="table-responsive-sm table-responsive-md table-responsive-lg">
              <table className="table table-striped table-sm mt-2" data-tour="second-step">
                <thead style={{backgroundColor:'#0067ac'}}>
                <tr className="text-center">
                  {CASE_HEADER_LISTING_IS_IOVERA_PRACTICE.map((tableHeader, index) => (
                    <th
                      key={index} className="bg-transparent text-white fs-6"
                      onClick={tableHeader.value !== '' ? () => changeIdOrder(tableHeader.value) : undefined}
                    >
                      <span
                        role={tableHeader.value === '' ? "textbox" : "button"}
                        className={tableHeader.value === '' ?
                          '' :
                          'btn text-white btn-link link-underline link-underline-opacity-0 p-0 fw-bold'
                        }
                      >
                        {tableHeader.label}
                        {queryParams.sort_by === tableHeader.value ? (
                          <i className={`bi ${queryParams.direction === 'desc' ? 'bi-caret-down-fill' : 'bi-caret-up-fill'}`}></i>
                        ) : null}
                      </span>
                    </th>
                  ))}
                </tr>
                </thead>
                <tbody>
                {caseObject && caseObject.cases && caseObject.cases.length > 0 ?
                  caseObject.cases.map(kase => (
                    <tr key={kase.id} className="align-middle text-center">
                      <td>
                        <Link
                          to={{pathname: `/cases/detail/${kase.id}`}}
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                          title={`Patient ID: ${kase.patient ? kase.patient.external_id : ''}`}
                          className="text-link link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover"
                        >
                          {kase.id}
                        </Link>
                      </td>
                      <td className="fs-6">{kase ? kase.created_at : ''}</td>
                      <td className="fs-6">{kase ? kase.drug_name : ''}</td>
                      <td className="fs-6">{kase ? kase.practice_name : ''}</td>
                      <td className="fs-6">{kase ? kase.soc_name : ''}</td>
                      <td className="fs-6">{kase && kase.patient ? kase.patient.firstlastname : ''}</td>
                      <td className="fs-6">{kase && kase.doctor ? kase.doctor.firstlastname : ''}</td>
                      <td className={`text-white align-middle fs-6 background-color-${kase && kase.color_idx ? kase.color_idx : 1}`}>
                        {kase ? kase.status_name : ''}
                      </td>
                      <td>
                        <RenderNextStep
                          kase={kase}
                          getCaseListing={getCaseListing}
                          showHideModalForTreatment={setDataForRequestModalTreatment}
                          setDataForCancelModal={setDataForCancelModal}
                          onTreatedOrNotTreatedButtonClicked={onTreatedOrNotTreatedButtonClicked}
                          setDataForTreatedOrNotTreatedTreatmentModal={setDataForTreatedOrNotTreatedTreatmentModal}
                          setDataForTreatmentDecisionModal={setTreatmentDecisionModal}
                        />
                      </td>
                      <td className={"action-btns"}>
                        <button type="button" onClick={() => downloadFileByCaseId(kase.id)} disabled={downloadCaseLoading} className="btn btn-outline-secondary me-2 rounded-0 d-inline-flex">
                          <i className="bi bi-file-earmark-arrow-down-fill me-1"></i> Download
                        </button>
                        <Link to={`/cases/${kase ? kase.id : 0}/clone`} className="btn btn-success my-2">
                          <i className="bi bi-arrow-repeat"></i> Re-run
                        </Link>
                      </td>
                    </tr>
                  )) : null
                }
                </tbody>
              </table>
            </div>

            {caseObject && caseObject.total_pages ? (
                <ul className="pagination pagination-sm pagination-lg justify-content-center flex-wrap">
                  {queryParams.page !== 1 ? (
                      <li className="page-item">
                        <button
                            className="btn btn-link page-link"
                            onClick={() => changePageNumber({selected: 0})}
                        >
                          <i className="bi bi-chevron-double-left"></i> First
                        </button>
                      </li>
                  ) : null}

                  <li>
                    <ReactPaginate
                        nextLabel={<>Next <i className="bi bi-chevron-right"></i></>}
                        onPageChange={changePageNumber}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={caseObject.total_pages}
                        previousLabel={<><i className="bi bi-chevron-left"></i> Previous</>}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination pagination-sm pagination-lg"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                        forcePage={queryParams.page - 1}
                    />
                  </li>

                  {queryParams.page !== caseObject.total_pages ? (
                      <li className={`page-item ${queryParams.page === caseObject.total_pages ? 'disabled' : ''}`}>
                        <button
                            className="btn btn-link page-link rounded-start-0"
                            onClick={() => changePageNumber({selected: caseObject.total_pages - 1})}
                        >
                          Last <i className="bi bi-chevron-double-right"></i>
                        </button>
                      </li>
                  ) : null}
                </ul>
            ) : null}
          </div>

          <RequestDateOfTreatment
              isModalVisible={showRequestTreatmentModal.isModalVisible}
              caseId={showRequestTreatmentModal.caseId}
              prescriptionId={showRequestTreatmentModal.prescriptionId}
              hideModal={() => {
                setDataForRequestModalTreatment({
                  isModalVisible: false,
                  caseId: 0,
                  prescriptionId: 0
                });
              }}
              refreshListing={() => {
                handleSearchWithQueryParam();
              }}
          />

          <CancelTreatment
              isModalVisible={cancelTreatmentModal.isModalVisible}
              caseId={cancelTreatmentModal.caseId}
              prescriptionId={cancelTreatmentModal.prescriptionId}
              hideModal={() => {
                setDataForCancelModal({
                  isModalVisible: false,
                  caseId: 0,
                  prescriptionId: 0
                });
              }}
              refreshListing={() => {
                handleSearchWithQueryParam();
              }}
          />

          <TreatedOrNotTreatedTreatment
              isModalVisible={treatedOrNotTreatedTreatmentModal.isModalVisible}
              dataTreatmentStates={treatedOrNotTreatedTreatmentModal}
              onTreatedOrNotTreatedButtonClick={onTreatedOrNotTreatedButtonClicked}
              hideModal={() => {
                setDataForTreatedOrNotTreatedTreatmentModal({
                  isModalVisible: false,
                  caseId: 0,
                  treatment_id: 0
                });
              }}
              refreshListing={() => {
                handleSearchWithQueryParam();
              }}
          />
          <TreatmentDecision
              isModalVisible={treatmentDecisionModal.isModalVisible}
              caseId={treatmentDecisionModal.caseId}
              prescriptionId={treatmentDecisionModal.prescriptionId}
              kase = {treatmentDecisionModal.kase}
              //prior_authorization_id={treatmentDecisionModal.prior_authorization_id}
              hideModal={() => {
                setTreatmentDecisionModal({
                  isModalVisible: false,
                  caseId: 0,
                  prescriptionId: 0,
                  prior_authorization_id:0,
                  prior_auth_status:'',
                  
                });
              }}
              refreshListing={() => {
                handleSearchWithQueryParam();
              }}
          />


        </div>
         <FooterText footerType="black"/>
      </div>
    </div>
  );
};

export default Dashboard;
