import { FC, useContext, useEffect, useRef, useState } from "react";

import FooterText from "../../components/footerText";
import AuthSubHeader from "src/components/authSubHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastTypes } from "src/types";
import { ACCEPTED_FILE_EXTENSIONS } from "src/constants";
import { ToastContext } from "src/context/toastContext";
import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { LoaderContext } from "src/context/loaderContext";
import { DocumentAPIResponse } from "src/components/documentUpload/types";



const Document: FC = (): JSX.Element => {
  const [caseFile, changeCaseFile] = useState<FileList | null>(null);

  const { caseId } = useParams();
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const ref = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search); // Parse query params

  const reset = () => {
    changeCaseFile(null);
    if (ref && ref.current) {
      ref.current.value = ""
    }
  };






  const { loading: uploadFileLoading, error: uploadFileError, data: uploadFileResponse, mutate: uploadCaseDocument } =
    useFetch<DocumentAPIResponse>(APIEndpoints.UPLOAD_CASE_FILE);

  useEffect(() => {
    if (uploadFileError) {
      hideLoader();
      changeCaseFile(null);
    }
    if (uploadFileResponse) {
      hideLoader();
      const { message } = uploadFileResponse;
      showToast([message], ToastTypes.SUCCESS);
      reset();
      navigate("/cases");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFileResponse, uploadFileError]);

  const uploadForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (caseFile) {
      const caseFileExt = `.${caseFile[0].name.split(".").pop()}`;
      const isExtAccepted = Object.keys(ACCEPTED_FILE_EXTENSIONS).find((ext) => caseFileExt === ext);
      if (typeof isExtAccepted === "string") {
        setLoader();
        const formData = new FormData();
        formData.append('file', caseFile[0]);
        uploadCaseDocument(formData, { caseId });
      } else {
        showToast(
          [`Not an acceptable format. The accepted formats are ${Object.keys(ACCEPTED_FILE_EXTENSIONS).toString()}`],
          ToastTypes.ERROR
        );
      }
    }
  };

  const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25 MB in bytes

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files;
    if (!selectedFile || selectedFile.length === 0) {
      reset();
      return;
    }
    if (selectedFile && selectedFile[0].size > MAX_FILE_SIZE) {
      showToast([`File size should not exceed 25 MB`], ToastTypes.ERROR);
      changeCaseFile(null);

      // Reset the file input field
      if (ref.current) {
        ref.current.value = "";
      }
    } else {
      changeCaseFile(selectedFile);
    }
  };





  return (
    <div className="cases-page body-bg overflow-y-auto overflow-x-hidden d-flex flex-column flex-fill">
      <div className="container px-4 pt-2">
        <AuthSubHeader />
        <div className="card m-2">
          <div className="card-header">
            <h4 className="m-0">Upload Clinical Document
              {queryParams.get('insuranceName') && <p>(Insurance Name: {queryParams.get('insuranceName')})</p>}
            </h4>
          </div>

          <div className="card-body">
            <p>If you wish for us to complete the precert on your behalf, simply upload the patient's chart in the field below.

            </p>
            <div className="card">
              <div className="card-header">
                <p className="text-white mb-0">
                  <i className="bi bi-cloud-arrow-up-fill fs-5 text-end align-middle"></i>{' '}
                  Upload New Document
                </p>
              </div>

              <div className="card-body">
                <form onSubmit={uploadForm}>
                  <div className="input-group">
                    <input type="file" className="form-control" name="caseDocument" onChange={handleFileChange} ref={ref} />

                    <button disabled={caseFile === null || uploadFileLoading} className="btn btn-primary" type="submit" id="inputGroupFileAddon04">
                      <div className="row align-items-center px-1">
                        {uploadFileLoading ? (
                          <div className="spinner-border text-light col-3" role="status">
                            <span className="sr-only"></span>
                          </div>
                        ) : null}
                        <p className="col-auto mb-0"> Upload </p>
                      </div>
                    </button>
                  </div>
                </form>
              </div>

            </div>

            <button type="button" onClick={() => navigate("/cases")} className="btn btn-primary float-end">
              Skip
            </button>
          </div>


        </div>
        <div className="mt-3"><FooterText footerType="black" /></div>
      </div>
    </div>
  );
};
export default Document;
