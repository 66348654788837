import {HeaderElement, HeaderElementType, subMenuHeaderElement } from "src/types";

const resourcesHeader: HeaderElement = {
  title: 'Resources',
  redirectLink: '',
  actionType: HeaderElementType.DRPODOWN,
  dropdown: [
    {
      title: 'Enrollment Form',
      redirectLink: 'https://zilrettapro.com/FlexForwardEnrollmentForm.pdf',
      actionType: HeaderElementType.LINK,
    },
    {
      title: 'Distributer Guide',
      redirectLink:'https://zilrettapro.com/getting-zilretta/flexforward-overview',
      actionType: HeaderElementType.LINK,
    },
    {
      title: 'Coding and Billing Guide ',
      redirectLink:'https://zilrettapro.com/getting-zilretta/coding-and-billing',
      actionType: HeaderElementType.LINK,
    },
    {
      title: 'Letter of Medical Necessity Template',
      redirectLink:'/assets/Letter-Medical-Necessity-Template.docx',
      actionType: HeaderElementType.LINK,
    },
    {
      title: 'Appeal Letter Template',
      redirectLink:'/assets/Sample-Appeals-Letter.docx',
      actionType: HeaderElementType.LINK,
    },
    {
      title:'Medicare One Pager',
      redirectLink:'/assets/Z-00675-Medicare-One-pager.pdf',
      actionType: HeaderElementType.LINK,

    },
    {
      title:'Schedule a Sales Rep Visit',
      redirectLink:'/rep_visits/new',
      actionType: HeaderElementType.LINK,
    },
    // {
    //   title:'Upload EOB',
    //   redirectLink:'https://www.euflexxa.com/'

    // },
  ]
};
const subMenuIoveraZilrettaHeaderPreLogin: HeaderElement = {
  title: 'Request Form',
  redirectLink: '',
  actionType: HeaderElementType.DRPODOWN,
  dropdown: [
    {
      title: 'iovera Enrollment Form',
      actionType: HeaderElementType.LINK,
      redirectLink: 'https://www.ioverapro.com/enrollment',
      // dropdown: [
      //   {
      //     title: 'Request Form',
      //     redirectLink: 'https://zilrettapro.com/FlexForwardEnrollmentForm.pdf',
      //     actionType: HeaderElementType.LINK,
      //   },
      // ],
    },
    {
      title: 'ZILRETTA Enrollment Form',
      actionType: HeaderElementType.LINK,
      redirectLink: 'https://zilrettapro.com/FlexForwardEnrollmentForm.pdf',
      // dropdown: [
      //   {
      //     title: 'Request Form',
      //     redirectLink: 'https://zilrettapro.com/FlexForwardEnrollmentForm.pdf',
      //     actionType: HeaderElementType.LINK,
      //   },
      // ],
    },
  ],
};



const subMenuIoveraZilrettaHeader: HeaderElement = {
  title: 'Resources',
  redirectLink: '',
  actionType: HeaderElementType.DRPODOWN,
  dropdown: [
    {
      title: 'iovera Resources',
      actionType: HeaderElementType.DRPODOWN,
      redirectLink: '',
      dropdown: [
        {
          title: 'Enrollment Form',
          redirectLink: 'https://ioverapro.com/enrollment',
          actionType: HeaderElementType.LINK,
        },
        {
          title: 'Coding and Reimbursement Guide',
          redirectLink:'https://www.ioverapro.com/iovera_coding_reimbursement_guide.pdf',
          actionType: HeaderElementType.LINK,
        },
        {
          title: 'Letter of Medical Necessity Template',
          redirectLink:'https://www.ioverapro.com/frontend/pdf/iovera_Medical_Necessity_Template_Letter.docx',
          actionType: HeaderElementType.LINK,
        },
        {
          title: 'Appeal Letter Template',
          redirectLink:'https://www.ioverapro.com/frontend/pdf/iovera_Denial_Appeal_Template_Letter.docx',
          actionType: HeaderElementType.LINK,
        },
        {
          title:'Additional Reimbursement Resources',
          redirectLink:'https://www.ioverapro.com/reimbursement#reimbursement-resources',
          actionType: HeaderElementType.LINK,
    
        },
        // {
        //   title:'Schedule a Sales Rep Visit',
        //   redirectLink:'/rep_visits/new',
        //   actionType: HeaderElementType.LINK,
        // },
      ],
    },
    {
      title: 'ZILRETTA Resources',
      actionType: HeaderElementType.DRPODOWN,
      redirectLink: '',
      dropdown: [
        {
          title: 'Enrollment Form',
          redirectLink: 'https://zilrettapro.com/FlexForwardEnrollmentForm.pdf',
          actionType: HeaderElementType.LINK,
        },
        {
          title: 'ZILRETTA Access Guide',
          redirectLink:'https://zilrettapro.com/buyandbill',
          actionType: HeaderElementType.LINK,
        },
        {
          title: 'Coding and Billing Guide ',
          redirectLink:'https://zilrettapro.com/getting-zilretta/coding-and-billing',
          actionType: HeaderElementType.LINK,
        },
        {
          title: 'Letter of Medical Necessity Template',
          redirectLink:'/assets/Letter-Medical-Necessity-Template.docx',
          actionType: HeaderElementType.LINK,
        },
        {
          title: 'Appeal Letter Template',
          redirectLink:'/assets/Sample-Appeals-Letter.docx',
          actionType: HeaderElementType.LINK,
        },
        {
          title:'Specialty Pharmacy Guide',
          redirectLink:'/assets/specialty-pharmacy-guide.pdf',
          actionType: HeaderElementType.LINK,
    
        },
        {
          title:'Additional Reimbursement Resources',
          redirectLink:'https://zilrettapro.com/getting-zilretta/tools-and-resources',
          actionType: HeaderElementType.LINK,
        },
      ],
    },
  ],
};


const hcpVideosHeader: HeaderElement = {
  title: 'Helpful Videos',
  redirectLink: '',
  actionType: HeaderElementType.DRPODOWN,
  dropdown: [
    {
      title: 'Getting Started',
      redirectLink: '/pages/signup-video',
      actionType: HeaderElementType.LINK,
    },
    {
      title: 'Navigating the Portal',
      redirectLink:'/pages/benefits-verification-video',
      actionType: HeaderElementType.LINK,
    },
  ]
};
const manufacturerVideosHeader: HeaderElement = {
  title: 'Helpful Videos',
  redirectLink: '',
  actionType: HeaderElementType.DRPODOWN,
  dropdown: [
    {
      title: 'Getting Started',
      redirectLink: '/pages/signup-video',
      actionType: HeaderElementType.LINK,
    },
    {
      title: 'Navigating the Portal',
      redirectLink:'/pages/benefits-verification-video',
      actionType: HeaderElementType.LINK,
    },
    {
      title: 'Reporting Portal Video',
      redirectLink:'/pages/reporting-portal-video',
      actionType: HeaderElementType.LINK,
    },
  ]
};

export const PublicHeader: HeaderElement[] = [
  {
    title: 'Welcome',
    redirectLink: '/users/sign_in',
    actionType: HeaderElementType.ROUTE,
  },
  {
    title: 'Practice Registration',
    redirectLink: '/enrollments/new',
    actionType: HeaderElementType.ROUTE,
  }, {
    ...subMenuIoveraZilrettaHeaderPreLogin
  },
  {
    title: 'ZILRETTA Local Coverage',
    redirectLink: '/formularies',
    actionType: HeaderElementType.BUTTON,
  },
  hcpVideosHeader,
];

export const AuthenticatedManufactureHeader: HeaderElement[] = [
  
  {
    title: 'Statistics',
    redirectLink: '/statistics',
    actionType: HeaderElementType.ROUTE,
  },
  {} as unknown as HeaderElement,
  {
    ...subMenuIoveraZilrettaHeader,
    dropdown: subMenuIoveraZilrettaHeader.dropdown?.filter(
        (item) => item.title !== 'Schedule a Sales Rep Visit'
    ),
  },
  manufacturerVideosHeader,
  {
    title: 'ZILRETTA Local Coverage',
    redirectLink: '/formularies',
    actionType: HeaderElementType.BUTTON,
  },
];
export const AuthenticatedHCPHeader: HeaderElement[] | subMenuHeaderElement [] = [
  
  {
    title: 'Cases',
    redirectLink: '/cases',
    actionType: HeaderElementType.ROUTE,
  },
  {
    title: 'Submit Request',
    redirectLink: '/cases/new',
    actionType: HeaderElementType.ROUTE,
  },
  // {
  //   title: 'Retreatment Requests',
  //   redirectLink: '/retreatments',
  //   actionType: HeaderElementType.ROUTE,
  // },
  subMenuIoveraZilrettaHeader,
  hcpVideosHeader,
  {
    title: 'ZILRETTA Local Coverage',
    redirectLink: '/formularies',
    actionType: HeaderElementType.BUTTON,
  },
  
];


