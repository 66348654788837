
import { FC, useContext, useEffect, useState } from "react"

import { AuthContext } from "src/context/authContext";
import { LoaderContext } from "src/context/loaderContext";
import useFetch from "src/hooks/useFetch";

import { downloadExcelFile } from "src/helpers/downloadFile";

import { APIEndpoints } from "src/types/apiTypes";
import { DownloadReportsResponseTypeApi, StatsReportProps } from "./types";
import { DISTRICT_MANAGER, FAM_AM, FAM_RM, MANUFACTURER, REGIONAL_MANAGER, REP } from "src/constants";

type Report = {
  reportTitle: string;
  reportName: string;
};


const StatsReports: FC<StatsReportProps> = ({ getValues }): JSX.Element => {
  const [enabledButton, setEnabledButton] = useState<boolean>(false);

  const { userData } = useContext(AuthContext)
  const { setLoader, hideLoader } = useContext(LoaderContext);
  const [report ,setReport ] = useState<string>('');

  const {
    loading: downloadReportsLoader,
    data: downloadReportsData, error: downloadReportsError, mutate: downloadRequestForm, removeErrorAndData: clearRequestFileData
  } = useFetch<DownloadReportsResponseTypeApi>(APIEndpoints.MANUFACTURE_STATISTICS_REPORT_DOWNLOAD);

  useEffect(() => {
    if (downloadReportsData || downloadReportsError) {
      if (downloadReportsData) {
        const { response } = downloadReportsData;
        downloadExcelFile(response, `${report === "mar_report" ? "frm_report" : report}.xlsx`);
      }
      hideLoader();
      setEnabledButton(false);

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadReportsData, downloadReportsError]);

  const DownloadReport = (reportName: string) => {
    setReport(reportName);
    if (getValues) {
      let params = {
        'start_date': getValues().start_date,
        'end_date': getValues().end_date,
        'report_name': reportName,
        ...(userData && userData.ref_type === "rep" ? { user_id: userData.id } : {})
      }
      if (downloadReportsData || downloadReportsError) clearRequestFileData();
      setLoader();
      setEnabledButton(true)
      downloadRequestForm({}, {}, params);
    }
  }

  const reportsOnRefType = () => {
    if (!userData) return null; // Handle cases where userData is not available

    let reports: Report[] = [];
    if (userData.ref_type === 'Manufacturer') {
      reports = MANUFACTURER;
    } else if (userData.ref_type === 'regional_manager') {
      reports = REGIONAL_MANAGER;
    } else if (userData.ref_type === 'district_manager') {
      reports = DISTRICT_MANAGER;
    } else if (userData.ref_type === 'fam_am') {
      reports = FAM_AM;
    } else if (userData.ref_type === 'fam_rm') {
      reports = FAM_RM;
    } else if (userData.ref_type === 'rep') {
      reports = REP;
    }

    return reports.map(({ reportTitle, reportName }, index) => (
      <button
        className="btn btn-success flex-fill"
        onClick={() => DownloadReport(reportName)}
        disabled={enabledButton}
      >
        {reportTitle}
      </button>
    ));
  };


  return !downloadReportsLoader ? (
    <div className="d-flex flex-wrap gap-2">
      {reportsOnRefType()}
    </div>
  ) : (
    <p className="text-dark h5">
      <span className="spinner-border spinner-border-sm" style={{ width: 20, height: 20 }} aria-hidden="true"></span>{' '}
      <i>Fetching Report</i>
    </p>
  );
};

export default StatsReports;
