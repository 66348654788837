import { FC, useCallback, useEffect, useState } from "react"
import HighCharts from "highcharts/highstock";
import HighchartsReact from 'highcharts-react-official'
import { LINE_CHART } from "src/constants/highCharts";
import { LineChartProps } from "./types";
import { format, parse } from "date-fns";
import { COLOR_LIST } from "src/constants/cases";

const CustomLineChart: FC<LineChartProps> = ({ LineChartObject ,title }): JSX.Element => {
  const [lineChart, setLineChart] = useState(LINE_CHART);
  const isNotStatsPage = window.location.href.includes("statistics") ? false : true;

  // Populate line chart options with x-axis and series data
  const populateLineChart = useCallback(() => {
    const lineOptions = { ...lineChart };
    lineOptions.series = wrapperFunctionForLine();
    lineOptions.xAxis = {
      categories: populateLineBase().map((item) => format(item, "dd. MMM")),
    };
    setLineChart(lineOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LineChartObject]);


  const populateLineBase = () => {
    let allDates: any[] = [];
    LineChartObject.forEach((caseVolume: any) => {
      allDates = allDates.concat(Object.keys(caseVolume.data));
    });

    return Array.from(new Set(allDates)).map((item) => parse(item, "yyyy-MM-dd", new Date())).sort((a: Date, b: Date) => (a < b) ? -1 : 1);
  }


  // Create a unified array of unique dates for the x-axis
  const wrapperFunctionForBaseAxis = () => {
    let allDates: any[] = [];
    LineChartObject.forEach((caseVolume: any) => {
      allDates = allDates.concat(Object.keys(caseVolume.data));
    });
    const updatedDates = allDates.map((item) => format(parse(item, "yyyy-MM-dd", new Date()), "MM-dd-yyyy"));
    return Array.from(new Set(updatedDates)).sort(); // Remove duplicates and sort the dates
  };

  // Map data to the line series, filling missing dates with null
  const wrapperFunctionForLine = () => {
    let lineSeriesArray: any = [];
    const xAxisDates = wrapperFunctionForBaseAxis(); // Get the unique x-axis dates

    LineChartObject.forEach((caseVolume,index) => {
      const seriesData: number[] = [];

      // Map over the xAxisDates, inserting data or null if missing
      xAxisDates.forEach((date) => {
        if (caseVolume.data) {
          const formattedDate = format(parse(date, "MM-dd-yyyy", new Date()), "yyyy-MM-dd");
          seriesData.push(caseVolume.data[formattedDate] || 0);
        }
      });

      lineSeriesArray.push({
        type: "spline",
        name: 
          isNotStatsPage && 
          (caseVolume.name.toLowerCase().includes("received") || caseVolume.name.toLowerCase().includes("completed")) 
            ? (!caseVolume.name.toLowerCase().includes("total") ? `Total ${caseVolume.name}` : caseVolume.name) :
            caseVolume.name,
        data: seriesData,
        color:COLOR_LIST[index],
        boostThreshold: 0,
      });
    });

    return lineSeriesArray;
  };

  useEffect(() => {
    populateLineChart();
  }, [populateLineChart]);




  return (
    <div className="card">
      <div className="card-header ">
        <h4 className="text-center m-0">{title}</h4>
      </div>
      <div className="card-body">
        <p className="text-center">Line convergence is positive, and higher numbers are better.</p>
        <HighchartsReact highcharts={HighCharts} options={lineChart} />
      </div>
    </div>

  )
}
export default CustomLineChart;
