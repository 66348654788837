import { FC, useContext } from "react";
import FooterText from "../../components/footerText";
import AuthSubHeader from "../../components/authSubHeader";
import { AuthContext } from "src/context/authContext";
import ContactAndSafetyInfo from "src/components/contactAndSafetyInfo";
import { TextAlignProps } from "src/components/contactAndSafetyInfo/types";
import ManufacturerContactAndSafetyInfo from "src/components/manufacturer/manufacturerContactAndSafetyInfo";


const HubPortalVideo: FC = (): JSX.Element => {
      const {isManufacturer} = useContext(AuthContext);

    return (
        <div className="container-fluid d-flex flex-fill flex-column body-bg overflow-auto pt-2">

            <div className="container">
            {isManufacturer ?  <ManufacturerContactAndSafetyInfo textColor="black" alignText={TextAlignProps.TEXT_END} /> : <AuthSubHeader /> }
                <div className="d-flex flex-column">
                   
                    <div className="mt-3">
                        <video controls className="w-100" src="/assets/benefits-verification.mp4" style={{maxHeight: "500px"}}>
                            Your browser does not support the video tag.
                        </video>
                    </div>

                    <div className="mt-3"><FooterText footerType="black"/></div>
                </div>
            </div>
        </div>
    );
};

export default HubPortalVideo;


