import { FC, useState } from "react"

import Modal from "../modal";
import { StatisticsObjectType } from "./types";
import { NewPracticeProps } from "src/pages/manufacturer/types";
import { Link } from "react-router-dom";

const CustomButtonRight: FC<StatisticsObjectType> = ({ StatisticsObjectType, startDate, endDate }): JSX.Element => {
    const [showingModal, setModal] = useState(false);
    const [title, setTitle] = useState("");

    const openModal = (title: string) => {
        setTitle(title);
        setModal(true);
        document.body.style.overflow = 'hidden';
    };

    return (
        <div>
            {showingModal ? <Modal startDate={startDate} endDate={endDate} title={title} data={(StatisticsObjectType && StatisticsObjectType.new_practices as NewPracticeProps)} onClick={() => setModal(false)}></Modal> : null}
            <div className="card" style={{ height: 150 }}>
                <div className="card-header text-center">
                    <h4 className="mb-0">Outside 72Hr
                    </h4>
                </div>
                <div className="card-body text-center">
                    <Link
                        to=""
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        className="mt-2 mb-0 fw-normal fs-6 text-secondary"
                        title={
                            StatisticsObjectType && StatisticsObjectType.outside_72hr
                                ? StatisticsObjectType.outside_72hr.cases_id.map(item => item).join(", ")
                                : "No cases available"
                        }
                    >
                        <h1 className="fw-bold text-center fw-bold m-0" >
                            {StatisticsObjectType ? StatisticsObjectType.outside_72hr.total_count : 0}
                        </h1>
                    </Link>


                </div>
            </div>
            <div className="card mt-3" style={{ height: 150 }}>
                <div className="card-header text-center">
                    <h4 className="mb-0">Avg TAT</h4>
                </div>
                <div className="card-body text-center">
                    <h1 className="text-black fw-bold text-center fw-bold m-0">
                        {StatisticsObjectType ? StatisticsObjectType.avg_tat : "00:00:00"}
                    </h1>
                </div>
            </div>
            <div className="card mt-3" style={{ height: 150 }}>
                <div className="card-header text-center">
                    <h4 className="mb-0">New Practices</h4>
                </div>
                <div className="card-body text-center" onClick={() => { openModal("New Practices") }}>
                    <h1 className="text-link fw-bold link-modal text-center fw-bold m-0">
                        {StatisticsObjectType.new_practices.count}
                    </h1>
                </div>
            </div>
        </div>
    )
}
export default CustomButtonRight;
