import { FC, useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

import {
  // DownloadZipErrorType,
  DownloadZipResponseType
} from "src/pages/dashboard/types";
import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { LoaderContext } from "src/context/loaderContext";
// import { ToastContext } from "src/context/toastContext";

import { CaseInformationProps, DownloadBenefitPDFResponseTypeApi } from "./types";
// import { AuthContext } from "src/context/authContext";
// import { ToastTypes } from "src/types";
import { downloadPdfFile } from "src/helpers/downloadFile";
import { format, parse } from "date-fns";



const CaseInformation: FC<CaseInformationProps> = ({ caseObject }): JSX.Element => {
  // const navigate = useNavigate();
  const { setLoader, hideLoader } = useContext(LoaderContext);
  // const { showToast } = useContext(ToastContext);
  // const { destroySession } = useContext(AuthContext);
  const [enabledButton, setEnabledButton] = useState<boolean>(false);





  const {
    data: downloadCaseData, error: downloadCaseError, mutate: downloadRequestForm, removeErrorAndData: clearRequestFileData
  } = useFetch<DownloadZipResponseType>(APIEndpoints.DOWNLOAD_REQUEST_FORM);

  useEffect(() => {
    if (downloadCaseData || downloadCaseError) {
      if (downloadCaseData) {
        const { response } = downloadCaseData;
        downloadPdfFile(response, `case_${caseObject.case_information.case_id}_reports.pdf`);
      }
      hideLoader();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadCaseData, downloadCaseError]);

  const downloadFileByCaseId = () => {
    // OpenForm Enrollment
    if (downloadCaseData || downloadCaseError) clearRequestFileData();
    setLoader();

    if (caseObject && caseObject.case_information) {
      // console.log({}, {}, { ref_id: caseObject.case_information.case_id, type: `${caseObject.case_information.product.toLowerCase()}-request`, ref_type: 'Case' });
      downloadRequestForm({}, {}, { ref_id: caseObject.case_information.case_id, type: `${caseObject.case_information.product.toLowerCase().replace("°", "")}-request`, ref_type: 'Case' });
    }
  };





  //////////////////////////////////////////////////Download PDF Benefits////////////////////////////////////////////
  const {
    data: downloadBenefitCaseData, error: downloadBenefitCaseError,
    removeErrorAndData: clearBenefitRequestFileData, mutate: downloadBenefitRequestForm,
  } = useFetch<DownloadBenefitPDFResponseTypeApi>(APIEndpoints.DOWNLOAD_REQUEST_FORM);

  useEffect(() => {
    if (downloadBenefitCaseData || downloadBenefitCaseError) {
      if (downloadBenefitCaseData) {
        const { response, report_name } = downloadBenefitCaseData;
        downloadPdfFile(response, `${report_name}`);
      }
      hideLoader();
      setEnabledButton(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadBenefitCaseData, downloadBenefitCaseError]);

  const downloadBenefitClickOnPdf = (refId: number, benefitType: string) => {
    if (downloadBenefitCaseData || downloadBenefitCaseError) clearBenefitRequestFileData();
    setLoader();
    setEnabledButton(true);
    downloadBenefitRequestForm({}, {}, { ref_id: refId, type: `${caseObject.case_information.product.toLowerCase().replace("°", "")}-investigation`, ref_type: 'Insurance', benefit_type: benefitType });
  };





  return (
    <div className="card">
      <div className="card-header">
        <h4 className="m-0">Case Information</h4>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Submitted by:</p>
              </div>
              <div className="col-6">
                <p>
                  {caseObject && caseObject.case_information ? caseObject.case_information.submitted : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6"></div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Created:</p>
              </div>
              <div className="col-6">
                <p>{caseObject && caseObject.case_information ? caseObject.case_information.created : "N/A"}</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Product:</p>
              </div>
              <div className="col-6">
                <p>
                  {caseObject && caseObject.case_information ? caseObject.case_information.product : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Shipment Date:</p>
              </div>
              <div className="col-6">
                <p>
                  {caseObject && caseObject.case_information ? caseObject.case_information.shipment_date : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Injection Site:</p>
              </div>
              <div className="col-6">
                <p>
                  {caseObject && caseObject.case_information ? caseObject.case_information.injection_site : "N/A"}
                </p>
              </div>
            </div>
          </div>

          {/* <div className="col-6"></div> */}

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end">
                <p>Benefits Type:</p>
              </div>
              <div className="col-6">
                <p>
                  {caseObject && caseObject.case_information ? caseObject.case_information.benefits_type : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>CPT Code:</p>
              </div>
              <div className="col-6">
                <p>
                  {caseObject && caseObject.case_information ? caseObject.case_information.show_cpt.join(", ") : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>{caseObject && caseObject.case_information && caseObject.case_information.product.toLowerCase() === "zilretta" ? "Last Injection Date:" : "Last Surgical Date:"}</p>
              </div>
              <div className="col-6">
                <p>
                  {
                    caseObject && caseObject.case_information && caseObject.case_information.product.toLowerCase() === "zilretta" ?
                      caseObject.case_information.last_date_of_service ?
                        format(parse(caseObject.case_information.last_date_of_service, "yyyy-MM-dd", new Date()), "MM/dd/yyyy")
                        : "N/A" 
                        : caseObject.case_information.last_surgical_date ?
                        format(parse(caseObject.case_information.last_surgical_date, "yyyy-MM-dd", new Date()), "MM/dd/yyyy") 
                        : "N/A"
                  }
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Treatment Date:</p>
              </div>
              <div className="col-6">
                <p>
                  {caseObject.case_information.product === "ZILRETTA" ?
                    caseObject && caseObject.case_information && caseObject.case_information.scheduled_date_of_service ?
                      format(parse(caseObject.case_information.scheduled_date_of_service, "yyyy-MM-dd", new Date()), "MM/dd/yyyy")
                      : "N/A"
                    :
                    caseObject && caseObject.case_information && caseObject.case_information.treatment_date ?
                      format(parse(caseObject.case_information.treatment_date, "yyyy-MM-dd", new Date()), "MM/dd/yyyy")
                      : "N/A"
                  }
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Quantity:</p>
              </div>
              <div className="col-6">
                <p>
                  {caseObject && caseObject.case_information ? caseObject.case_information.quantity : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Diagnosis Code:</p>
              </div>
              <div className="col-6">
                <p>
                  {caseObject && caseObject.case_information ? caseObject.case_information.diagnosis_code.split(",").join(", ") : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Note:</p>
              </div>
              <div className="col-6">
                <p>
                  {caseObject && caseObject.case_information ? caseObject.case_information.note : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Directions:</p>
              </div>
              <div className="col-6">
                <p>
                  {caseObject && caseObject.case_information ? caseObject.case_information.product === "zilretta" ? caseObject.case_information.directions : "N/A" : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Written Date:</p>
              </div>
              <div className="col-6">
                <p>{caseObject && caseObject.case_information ? caseObject.case_information.written_date : "N/A"}</p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p>Auto X-fer to Pharmacy:</p>
              </div>
              <div className="col-6">
                <p>
                  {caseObject && caseObject.case_information ? caseObject.case_information.auto_xfer : "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p className="mb-0">Request Form:</p>
              </div>
              <div className="col-6">
                <p>
                  <i className="bi bi-file-pdf text-danger fw-bold"></i>
                  <button
                    onClick={downloadFileByCaseId}
                    className="btn btn-link link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover p-0 align-baseline"
                  >
                    Download
                  </button>
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-6 text-end px-3">
                <p className="mb-0">Benefits Investigations:</p>
              </div>
              <div className="col-6">
                <div className="container-fluid">
                  {
                    caseObject && caseObject.case_information && typeof caseObject.case_information.benefit_investigation !== 'string' ?
                      caseObject.case_information.benefit_investigation.map((benefit) => (
                        benefit.completed_benefits.map((item, index) => (
                          <div className="row" key={index}>
                            <div className="col-1">
                              <i className="bi bi-file-pdf text-danger fw-bold"></i>
                            </div>
                            <button
                              className="btn btn-link link-underline link-underline-opacity-0 link-offset-0-hover link-underline-opacity-75-hover p-0 align-baseline col text-start ms-2"
                              onClick={() => downloadBenefitClickOnPdf(benefit.id, item.benefit_type)}
                              disabled={enabledButton}>
                              {item.download_title}

                            </button>
                          </div>
                        ))
                      ))
                      : 'N/A'
                  }
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default CaseInformation;
