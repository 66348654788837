import { FC, useEffect, useRef, useState } from "react";
import { StatisticsObjectType } from "../caseVolumeLineChart/types";
import { BAR_CHART_MEDICAL_AUTHS, BAR_CHART_PATIENT_DIRECT_PURCHASES, BAR_CHART_PRIOR_AUTHS } from "src/constants/highCharts";
import HighchartsReact from "highcharts-react-official";
import HighCharts from "highcharts";



const CustomComponentForPriorAuth: FC<StatisticsObjectType> = ({ StatisticsObjectType }): JSX.Element => {

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [barChartOptionPrior, setChartOptions] = useState(BAR_CHART_PRIOR_AUTHS);
  const [medicalBarChartOptionPrior, setMedicalBarChartOptions] = useState(BAR_CHART_MEDICAL_AUTHS);
  const [pdpsbarChartOptionPrior, setPdpsChartOptionsPrior] = useState(BAR_CHART_PATIENT_DIRECT_PURCHASES);




  useEffect(() => {
    let latestChartOptions = { ...barChartOptionPrior };
      latestChartOptions.xAxis = {
        categories: StatisticsObjectType.pharmacy_prior_auths && Object.keys(StatisticsObjectType.pharmacy_prior_auths)
      }
      latestChartOptions.series = [{
        type: 'bar',
        name: 'Value',
        data: StatisticsObjectType.pharmacy_prior_auths &&  Object.values(StatisticsObjectType.pharmacy_prior_auths).map(value => value ?? 0)
      }];

      setChartOptions(latestChartOptions);

      latestChartOptions = { ...medicalBarChartOptionPrior };
      latestChartOptions.xAxis = {
        categories:StatisticsObjectType.medical_prior_auths && Object.keys(StatisticsObjectType.medical_prior_auths)
      }
      latestChartOptions.series = [{
        type: 'bar',
        name: 'Value',
        data:StatisticsObjectType.medical_prior_auths && Object.values(StatisticsObjectType.medical_prior_auths).map(value => value ?? 0),
      }];

      setMedicalBarChartOptions(latestChartOptions);

     latestChartOptions = { ...pdpsbarChartOptionPrior };
     latestChartOptions.xAxis = {
      categories:StatisticsObjectType.pdps && Object.keys(StatisticsObjectType.pdps)
    }
      latestChartOptions.series = [{
        type: 'bar',
        name: 'Value',
        data:StatisticsObjectType.pdps && Object.values(StatisticsObjectType.pdps).map(value => value ?? 0),
      }];

      setPdpsChartOptionsPrior(latestChartOptions);




      const chart = chartComponentRef.current?.chart;

      if (chart) {
        chart.redraw();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);


  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-4">
        <div className="card mt-3">
          <div className="card-header">
            <h4 className="m-0 text-center text-primary">Medical Prior Auths</h4>
          </div>
          <div className="card-body">
            <HighchartsReact
              highcharts={HighCharts}
              options={medicalBarChartOptionPrior}
              immutable={true}
              ref={chartComponentRef}
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-4">
        <div className="card mt-3">
          <div className="card-header">
            <h4 className="m-0 text-center text-primary">Pharmacy Prior Auths</h4>
          </div>
          <div className="card-body">
            <HighchartsReact
                highcharts={HighCharts}
                options={barChartOptionPrior}
                immutable={true}
                ref={chartComponentRef}
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-4">
        <div className="card mt-3">
          <div className="card-header">
            <h4 className="m-0 text-center text-primary">Patient Direct Purchases</h4>
          </div>
          <div className="card-body">
            <HighchartsReact
                highcharts={HighCharts}
                options={pdpsbarChartOptionPrior}
                immutable={true}
                ref={chartComponentRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomComponentForPriorAuth;
