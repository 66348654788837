import { FC, useContext, useEffect, useState } from "react";

import { RequestDateOfTreatmentProps, RequestTreatmentNotTreatingApiResponse } from "./types";
import useFetch from "src/hooks/useFetch";
import { APIEndpoints } from "src/types/apiTypes";
import { ToastTypes } from "src/types";
// import { useNavigate } from "react-router-dom";
import { ToastContext } from "src/context/toastContext";
// import { AuthContext } from "src/context/authContext";

const RequestDateOfTreatment: FC<RequestDateOfTreatmentProps> = ({ isModalVisible, caseId, hideModal, prescriptionId ,refreshListing}): JSX.Element => {
  const [treatmentDate, changeTreatmentDate] = useState<string>("");
  const [isInvalid, setInvalidStatus] = useState<boolean>(false);

  const { showToast } = useContext(ToastContext);
  // const { destroySession } = useContext(AuthContext);
  // const navigate = useNavigate();

  const resetAndhideModal = () => {
    changeTreatmentDate("");
    hideModal();
  };





  const { loading, data: requestTreatmentData, mutate: updateRequestTreatment, error: treatmentUpdateError } = 
    useFetch<RequestTreatmentNotTreatingApiResponse>(APIEndpoints.REQUEST_TREATMENT_NOT_TREATING);

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (treatmentDate) {
      setInvalidStatus(false);
      const requestBody = {
        prescription: {
          bentype: "med",
          scheduled_date_of_service: treatmentDate
        }
      }
      // const queryParam = {
      //   buy_and_bill:true
      // }
      updateRequestTreatment(requestBody, { caseId, prescriptionId },{});
    } else if (treatmentDate === '') {
      setInvalidStatus(true);
    }
  }

  useEffect(() => {
    if(treatmentUpdateError){
      resetAndhideModal()
    }
    if (requestTreatmentData) {
      const { code } = requestTreatmentData;
      if (code === 200) {
        showToast([`Request Treatment Scheduled Successfully`], ToastTypes.SUCCESS);
        refreshListing()
        resetAndhideModal()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestTreatmentData, treatmentUpdateError]);





  return (
    <div
      className={`modal fade ${isModalVisible ? 'show show-modal' : ''}`}
      id="exampleModal"
      tabIndex={-1}
      data-bs-backdrop="true"
      aria-hidden="true"
      onClick={resetAndhideModal}
    >
      <div className="modal-dialog modal-lg" onClick={e => e.stopPropagation()}>
        <form onSubmit={submitForm}>
          <div className="modal-content">
            <div className="modal-header">
              <p className="modal-title fs-3 text-primary">
                Case {caseId} Requested Date of Treatment
              </p>
              <button type="button" className="btn-close" aria-label="Close" onClick={hideModal}></button>
            </div>

            <div className="modal-body">
              <p className="text-center my-3"> Please specify date of treatment... </p>

              <div className="row mx-3 align-items-center">
                <div className="col-3 text-end">
                  <label htmlFor="treatmentDate"> Date and Time* </label>
                </div>

                <div className="col-9">
                  <input
                    type="datetime-local"
                    value={treatmentDate}
                    onChange={(e) => {
                      changeTreatmentDate(e.target.value)
                      if (e.target.value.length > 0) setInvalidStatus(false);
                    }}
                    name="treatmentDate"
                    id="treatmentDate"
                    className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
                  />
                  {isInvalid ? (
                    <div className="invalid-feedback">
                      Please select a date first
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary rounded-0 px-4" disabled={loading} data-bs-dismiss="modal">
                <div className="row align-items-center px-1">
                  {loading ? (
                    <div className="spinner-border text-light col-3" role="status">
                      <span className="sr-only"></span>
                    </div>
                  ) : null}
                  <p className="col-auto mb-0"> Save </p>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RequestDateOfTreatment;
