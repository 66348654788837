import { FC } from "react";

const DropdownPossibleNextStep: FC = (): JSX.Element => {
  return (
    <li className="nav-item dropdown">
      <button className="btn btn-link nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
        Possible "Next Steps"
      </button>

      <ul className="colors-dropdown dropdown-menu dropdown-menu-end p-4">
        <p className="m-0">
          On the case screen, you can see a new column labeled "Next Steps" ...
          These new options allow you to update your cases without the need to contact FlexForward.
          The actions available are as follows:
        </p>
        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li>
          <div className="row">
            <div className="col-sm-4">
              <span className="btn background-color-5 my-1 text-white">
                <i className="fas fa-clipboard-list"></i> Precert
              </span>
            </div>
            <div className="col-sm-8">
              Clicking "Precert" allows you to upload clinicals and enter information for the precert as well as submit
              it to the plan or request it be completed on your behalf.
            </div>
          </div>
        </li>

        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li>
          <div className="row">
            <div className="col-sm-4">
              <span className="btn background-color-5 my-1 text-white">
                <i className="fas fa-clipboard-list"></i> Review PA
              </span>
            </div>
            <div className="col-sm-8">
              Clicking "Review PA" allows you to review the transcribed PA and request changes or, alternatively, submit
              the prior authorization form presented directly to the insurance.
            </div>
          </div>
        </li>

        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li>
          <div className="row">
            <div className="col-sm-4">
              <button className="btn btn-primary" type="button">
                <i className="fa fa-times"></i> Treatment Decision
              </button>
            </div>
            <div className="col-sm-8">
              Clicking "Treatment Decision" will allow you to specify whether or not you are treating the patient, as
              well as the date of treatment or the reason for not treating
            </div>
          </div>
        </li>

        <li>
          <hr className="dropdown-divider"/>
        </li>
        <li>
          <div className="row">
            <div className="col-sm-4">
              <span className="btn background-color-3 my-1 text-white">
                <i className="fas fa-prescription"></i> Xfer to SP
              </span>
            </div>
            <div className="col-sm-8">
              Clicking "Xfer to SP" Transfers the prescription through the Specialty pharmacy Channel.
            </div>
          </div>
        </li>
        <li>
          <hr className="dropdown-divider"/>
        </li>
        <li>
          <div className="row">
            <div className="col-sm-4">
              <span className="btn background-color-3 my-1 text-white">
                <i className="far fa-hospital"></i> Buy & Bill
              </span>
            </div>
            <div className="col-sm-8">
              Clicking "Buy & Bill" Changes the case status to "Office Buy & Bill"
            </div>
          </div>
        </li>

       
      </ul>
    </li>
  );
};

export default DropdownPossibleNextStep;
