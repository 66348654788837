export const PHONE_NUMBER = `1-844-353-9466`;
export const FAX_NUMBER = `1-866-558-7939`;
export const MANUFACTURER_NUMBER = `1-844-353-9466`;
export const dataProduct = [
  {
    name: "A2025-Miro3D",
    value: "A2025-Miro3D",
    ndc: "768435424"
  },
  {
    name: "A2029-MiroTract",
    value: "A2029-MiroTract",
    ndc: "768437424"
  },
  {
    name: "Q4175-MiroDerm",
    value: "Q4175-MiroDerm",
    ndc: "768737424"
  },
];
export const dataSitofCare = [
  {
    name: "Physician Office (POS 11)",
    value: "Physician Office (POS 11)",
  },
  {
    name: "Home (POS 12)",
    value: "Home (POS 12)",
  },
  {
    name: "In Patient (POS 21)",
    value: "In Patient (POS 21)",
  },
  {
    name: "Hospital Outpatient (POS 22)",
    value: "Hospital Outpatient (POS 22)",
  },
  {
    name: "Ambulatory Surgical Center (POS 24)",
    value: "Ambulatory Surgical Center (POS 24)",
  },
  {
    name: "Skilled Nursing Facility (POS 31)",
    value: "Skilled Nursing Facility (POS 31)",
  },
  {
    name: "Nursing Facility (POS 32)",
    value: "Nursing Facility (POS 32)",
  },
  {
    name: "Other",
    value: "other",
  },

];
