import { FC, useContext, useEffect, useState } from "react";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import Select,{ GroupBase, OptionsOrGroups, PropsValue, SingleValue } from "react-select";

import FooterText from "../../components/footerText";
import AuthSubHeader from "src/components/authSubHeader";

import { ToastContext } from "src/context/toastContext";
import useFetch from "src/hooks/useFetch";

import { ToastTypes } from "src/types";
import { APIEndpoints } from "src/types/apiTypes";
import { STATE_UPDATE_PA } from "src/constants/cases";
import { RequestTreatmentNotTreatingApiResponse } from "src/components/modals/requestDateOfTreatment/types";




const PriorAuthorization: FC = (): JSX.Element => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search); // Parse query params

  const [cancelReason, setCancelReason] = useState<string>('approved');
  const [patient] = useState((location.state as { patient: string }).patient)
  
  const { showToast } = useContext(ToastContext);
  const { caseId,prior_auth_id } = useParams();
  const navigate = useNavigate();




  const { loading, data: requestPriorAuthorization, mutate: updatePriorAuthorization, error: priorAuthorizationError } = 
    useFetch<RequestTreatmentNotTreatingApiResponse>(APIEndpoints.PRIOR_AUTHORIZATION);

  useEffect(() => {
    if (priorAuthorizationError) {}
    if (requestPriorAuthorization) {
      showToast([`Prior Authorization Saved and Completed`], ToastTypes.SUCCESS);
      navigate('/cases',{ replace: true });
      setCancelReason("Cost");
      const { status } = requestPriorAuthorization;
      if (status === 'ok') {
        showToast([`Prior Authorization Saved and Completed`], ToastTypes.SUCCESS);
        navigate("/cases");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestPriorAuthorization, priorAuthorizationError]);

  const updatePriorAuthorizationButton = () => {
    const queryParam = {
      status:cancelReason, 
      case_id: caseId, 
      id: prior_auth_id,
      change_note: STATE_UPDATE_PA.find((item) => item.value === cancelReason)?.change_note
    };
    updatePriorAuthorization(queryParam, { id:prior_auth_id});
  }





  const onChangeSelect = (value: SingleValue<string>) => {
    setCancelReason((value as unknown as { value: string; label: string; }).value);
  };

  const getValue = () => {
    const data = STATE_UPDATE_PA.find((option) => option.value === cancelReason);
    if (data) return data as unknown as PropsValue<string>;
  };

  return (
    <div className={"container-fluid body-bg overflow-auto d-flex flex-column flex-fill"}>
      <div className="container">
        <AuthSubHeader/>
        <div className="card">
          <div className="card-header">
            <p className="fs-2 text-white m-0">
              Update PA Status for Case {caseId} {'(' + patient + ")"}<p>(Insurance Name: {queryParams.get('insuranceName')})</p>
            </p>
          </div>

          <div className="card-body">
            <p className="text-center"> if you've received updated information or wish to cancel your PA, select a
              status from the dropdown below and the system will update this case for you. </p>

            <div className="row align-items-center">
              <div className="col-10">
                <Select
                  options={STATE_UPDATE_PA as unknown as OptionsOrGroups<string, GroupBase<string>>}
                  id="updatePA"
                  isSearchable={false}
                  noOptionsMessage={() => "Select Option..."}
                  value={getValue()}
                  onChange={(value) => onChangeSelect(value)}
                />
              </div>

              <div className="col-2">
                <button
                  type="button"
                  className="btn btn-success rounded-0 px-4"
                  onClick={updatePriorAuthorizationButton}
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3"><FooterText footerType="black"/></div>
      </div>
    </div>
  );
};

export default PriorAuthorization;
