import { format, parse } from "date-fns";
import { FC } from "react";
import { Controller } from "react-hook-form";

import HubInputField from "src/components/inputType";
import { INJECTION_SITE } from "src/constants/cases";

import { ZilrettaCompProps } from "./types";
import { GroupBase, OptionsOrGroups } from "react-select";
import AddCaseDiagnosisCode from "../addCaseDiagnosisCode";
import AddCaseProcedureSelect from "../addCaseProcedureSelect";
import DatePicker from "react-datepicker";
import ReactInputMask from "react-input-mask";



const ZilrettaComp: FC<ZilrettaCompProps> = ({ control, watch, setValue }): JSX.Element => {
  const injectionSite = watch("prescription_attributes.injection_site");

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <AddCaseProcedureSelect control={control} isIOvera={false} />
        </div>
        <div className="col-lg-6">
          <Controller
            name="prescription_attributes.scheduled_date_of_service"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <div className="row mb-2">
                <label htmlFor="prescription_attributes.last_date_of_service" className="form-label col-lg-4 col-md-4 col-12">
                  Date of Service
                </label>
                <div className="col-lg-8 col-md-8 col-12">
                  {/* <input type="date" className={`form-control ${error && error.message ? 'is-invalid' : ''}`}
                    id="prescription_attributes.last_date_of_service" {...field} min={new Date().toISOString().substring(0, 10)}
                    onKeyDown={(e) => e.preventDefault()} // Prevent manual input

                  /> */}
                  {/* <DatePicker
                    selected={field.value ? new Date(field.value) : null} // Convert string to Date
                    onChange={(date) => field.onChange(date?.toISOString().split("T")[0])} // Convert Date to YYYY-MM-DD string
                    className={`form-control ${error?.message ? 'is-invalid' : ''}`}
                    id="prescription_attributes.last_date_of_service"
                    minDate={new Date()} // Prevent past dates
                    placeholderText="mm/dd/yyyy"
                    dateFormat="MM/dd/yyyy"
                    wrapperClassName="w-100"
                    customInput={<ReactInputMask mask="99/99/9999">{(inputProps: {}) =>
                                          <input {...inputProps} />}</ReactInputMask>}
                  /> */}
                  <DatePicker
                    selected={
                      field.value
                        ? parse(field.value, "yyyy-MM-dd", new Date()) // Parse default yyyy-MM-dd to a Date
                        : null
                    }
                    onChange={(date) => {
                      field.onChange(date ? format(date, "yyyy-MM-dd") : ""); // Convert back to yyyy-MM-dd for consistency
                    }}
                    id="prescription_attributes.scheduled_date_of_service"
                    dateFormat="MM/dd/yyyy" // User-facing format
                    className={`form-control ${error?.message ? "is-invalid" : ""}`}
                    placeholderText="mm/dd/yyyy"
                    customInput={
                      <ReactInputMask mask="99/99/9999">
                        {(inputProps: {}) => <input {...inputProps} />}
                      </ReactInputMask>
                    }
                    minDate={new Date()} // Prevent past dates
                    wrapperClassName="w-100"
                  />
                  {error && error.message ? (
                    <div dangerouslySetInnerHTML={{ __html: error.message }} className="text-danger text-small mt-2 "></div>
                  ) : null}
                </div>
              </div>
            )}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <Controller
            name="prescription_attributes.injection_site"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                isRequired={true}
                isRowInsideAnother={true}
                isRowBased={true}
                inputId="prescription_attributes.injection_site"
                inputLabel="Knee(s)"
                inputPlaceholder="Specify Knee(s)..."
                field={field}
                error={error}
                isDropdown={true}
                isSearchable={false}
                dropdownData={
                  INJECTION_SITE as unknown as OptionsOrGroups<string, GroupBase<string>>
                }
              />
            )}
          />
        </div>

        <div className="col-lg-6">
          <Controller
            name="prescription_attributes.note"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                inputId="prescription_attributes.note"
                isRowBased={true}
                isRowInsideAnother={true}
                isRequired={false}
                field={field}
                inputLabel="Notes"
                inputPlaceholder="Not Shared With Patient"
                error={error}
              />
            )}
          />
        </div>
      </div>

      <div className={'row'}>
        <div className="col-lg-6">
          <Controller
            name="prescription_attributes.last_date_of_service"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <div className="row mb-2">
                <label htmlFor="prescription_attributes.last_date_of_service" className="form-label col-lg-4 col-md-4 col-12">
                  Date of Last ZILRETTA injection
                </label>
                <div className="col-lg-8 col-md-8 col-12">
                  {/* <input type="date" className={`form-control ${error && error.message ? 'is-invalid' : ''}`}
                    id="prescription_attributes.last_date_of_service" {...field} max={new Date().toISOString().substring(0, 10)}
                  /> */}
                  {/* <DatePicker
                    selected={field.value ? new Date(field.value) : null} // Convert string to Date
                    onChange={(date) => field.onChange(date?.toISOString().split("T")[0])} // Convert Date to YYYY-MM-DD string
                    className={`form-control ${error?.message ? 'is-invalid' : ''}`}
                    id="prescription_attributes.last_date_of_service"
                    maxDate={new Date()} // Prevent future dates
                    placeholderText="mm/dd/yyyy"
                    dateFormat="MM/dd/yyyy"
                    wrapperClassName="w-100"
                    customInput={<ReactInputMask mask="99/99/9999">{(inputProps: {}) =>
                      <input {...inputProps} />}</ReactInputMask>}
                  /> */}
                   <DatePicker
                    selected={
                      field.value
                        ? parse(field.value, "yyyy-MM-dd", new Date()) // Parse default yyyy-MM-dd to a Date
                        : null
                    }
                    onChange={(date) => {
                      field.onChange(date ? format(date, "yyyy-MM-dd") : ""); // Convert back to yyyy-MM-dd for consistency
                    }}
                    id="prescription_attributes.last_date_of_service"
                    dateFormat="MM/dd/yyyy" // User-facing format
                    className={`form-control ${error?.message ? "is-invalid" : ""}`}
                    placeholderText="mm/dd/yyyy"
                    customInput={
                      <ReactInputMask mask="99/99/9999">
                        {(inputProps: {}) => <input {...inputProps} />}
                      </ReactInputMask>
                    }
                    maxDate={new Date()} // Prevent past dates
                    wrapperClassName="w-100"
                  />
                  

                  {error && error.message ? (
                    <div dangerouslySetInnerHTML={{ __html: error.message }} className="text-danger text-small mt-2 "></div>
                  ) : null}
                </div>
              </div>
            )}
          />
        </div>
      </div>

      {injectionSite && (
        <div className="row">
          <div className="col-lg-6">
            <AddCaseDiagnosisCode injectionSite={injectionSite} control={control} watch={watch} setValue={setValue} />
          </div>

          <div className="row col-lg-6">
            <Controller
              name="prescription_attributes.direction"
              control={control}
              defaultValue="Administer as a single intra-articular injection for extended release"
              render={({ field: { value } }) => (
                <div className="row mb-3">
                  <div className="col-lg-4 col-md-4 col-12 fw-bold">
                    <label className={"form-label"}>Directions</label>
                  </div>
                  <div className="col-lg-8 col-md-8 col-12">Administer as a single intra-articular injection for extended release</div>
                </div>
              )}
            />
          </div>
        </div>
      )}

      <div className="row">
        <div className={"col-12 col-sm-2"}></div>
        <div className="col-12 col-sm-10">
          <Controller
            name="prescription_attributes.is_specialty_pharmacy_only"
            control={control}
            defaultValue={false}
            render={({ field: { value, onChange } }) => (
              <div className="form-check mt-3">
                <input
                  className="form-check-input" type="checkbox"
                  id="isSpecialityPharmacyOnlyCheckbox"
                  name="prescription_attributes.is_specialty_pharmacy_only"
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="isSpecialityPharmacyOnlyCheckbox">
                  Auto Transfer to Flexforward Speciality Pharmacy partner if RX coverage is available.
                </label>
              </div>
            )}
          />

          <Controller
            name="prescription_attributes.is_daw"
            control={control}
            defaultValue={format(new Date(), 'MM/dd/yyyy')}
            render={({ field: { value } }) => (
              <div className="form-check mt-3">
                <input
                  className="form-check-input" type="checkbox"
                  id="flexCheckIndeterminateDisabled"
                  disabled checked
                />
                <label className="form-check-label" htmlFor="flexCheckIndeterminateDisabled">
                  Dispense as Written (written date will be {value})
                </label>
              </div>
            )}
          />
        </div>
      </div>

      <Controller
        name="prescription_attributes.drug_name"
        control={control}
        render={() => <></>}
      />
    </>
  )
}

export default ZilrettaComp;
