import { FC, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FooterText from "../../components/footerText";
import HubInputField from "../../components/inputType";
import ContactAndSafetyInfo from "src/components/contactAndSafetyInfo";

import useFetch from "../../hooks/useFetch";
import { ToastContext } from "../../context/toastContext";
import { forgotpasswordSchema } from "../../validation/forgotPassword";
import { APIEndpoints } from "../../types/apiTypes";
import { ToastTypes } from "../../types";
import { ForgotPasswordResponseType } from "./types";
import { LoaderContext } from "../../context/loaderContext";
import EuflexxaBanner from "../../assets/images/euflexxa_banner.png";
import LoginHero from "../../assets/images/login_hero.png";
import IoveraLogo from "../../assets/images/iovera-logo.svg";
import { TextAlignProps } from "src/components/contactAndSafetyInfo/types";
import { Link } from "react-router-dom";

const HubForgotPassword: FC = (): JSX.Element => {
  const { control, handleSubmit, reset } = useForm({
    mode: 'all',
    resolver: yupResolver(forgotpasswordSchema),
  });
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);





  const { loading, error, data, mutate, removeErrorAndData } = useFetch<null, null, ForgotPasswordResponseType>(APIEndpoints.USER_FORGOT_PASSWORD);

  useEffect(() => {
    if (data || error) {
      hideLoader();
      if (data) {
        const { message } = data;
        showToast([message], ToastTypes.SUCCESS);
        reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  const submitForm = async (values: { email: string }) => {
    setLoader();
    if ((error && error.length > 0) || data) removeErrorAndData();

    mutate({ user: values });
  };




  return (
      <div className="login-sec pt-3 d-flex flex-fill flex-column justify-content-between overflow-auto">
        <div className="container d-flex flex-fill flex-column">
          <ContactAndSafetyInfo alignText={TextAlignProps.TEXT_END} textColor="black" />
          <div className="row flex-fill pt-2">
            <div className="col-12 col-md-7 d-flex d-md-block justify-content-center justify-content-md-center">
              <img className="login-hero-img" src={LoginHero} alt="HeroImage"/>
            </div>
            <div className="col-12 col-md-5">
              <form className="card mt-4" onSubmit={handleSubmit(submitForm)}>
                <div className="card-header">
                  <h3 className="mb-0 text-white">Input your email</h3>
                  <h1 className="mb-0 text-white">to reset your password</h1>
                </div>
                <div className="card-body">
                  <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      render={({field, fieldState: {error}}) => (
                        <>
                          <label htmlFor="email" className="form-label justify-content-start mb-2">Email *</label>
                          <HubInputField
                            inputId="email"
                            isRowBased={false}
                            isRequired={true}
                            field={field}
                            error={error}
                            inputType="email"
                          />
                        </>
                      )}
                  />

                  <div className="mb-0">
                    <button disabled={loading} className="btn btn-primary" type="submit">
                      {loading ? (<i className="spinner-border me-2"></i>) : null}
                      Send me reset password instruction
                    </button>
                    <p className="mt-2 mb-0">
                      <Link to="/users/sign_in" className="text-decoration-none">Log in</Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-12"><FooterText footerType="black"/></div>
          </div>
        </div>
      </div>
  );
};

export default HubForgotPassword;
