import { AppRoute, WithRequired } from "./types";

//:- All Application Pages
import HubLogin from "../pages/login";
import HubForgotPassword from  '../pages/forgotpassword';
import NewEnrollment from "../pages/newEnrollment";
import HubUploadPEF from "../pages/uploadPEF";
import HubResetPassword from "../pages/resetpassword";
import Dashboard from "../pages/dashboard";
import HubAddCase from "../pages/addCase";
import HubCreateUser from "../pages/createUser";
import HubCaseDetail from "src/pages/caseDetail";
import AddProvider from "../pages/addProvider";
import AddUser from "../pages/addUser";
import NewRepVisit from "../pages/newRepVisit";
import BaaAgreement from "../pages/baa";
import manufacturer from "src/pages/manufacturer";
import HubCaseClone from "src/pages/cloneCase";
import Territories from "src/pages/territories";
import TreatmentListing from "src/pages/treatmentListing";
import OrderListing from "src/pages/orderListing";
import Precert from "src/pages/precert";
import HubUpdateUser from "src/pages/updateuser";
import Regions from "src/pages/regions";
import District from "src/pages/districts";
import PracticeStats from "src/pages/practiceStats";
import DoctorStats from "src/pages/doctorStats";
import RetreatmentRequests from "src/pages/retreatmentRequests";
import AreaManager from "src/pages/areaManager";
import CoverageLookup from "src/pages/localCoverage";
import PriorAuthorization from "src/pages/priorAuthorization";
import DocumentUpload from "src/pages/document";
import ReviewMedPA from "src/pages/review";
// import UpdateRXPA from "src/pages/priorAuthorizationRXPA";
import UpdatePrecert from "src/pages/updatePrecert";
import ReviewRXPA from "src/pages/priorAuthorizationRXPA";
import AuthCallback from "src/pages/authCallback";
import FamRegions from "src/pages/famRegions";
import FamDistrict from "src/pages/famDistricts";
import HubPortalVideo from "../pages/portalVideo";
import HubGettingStartedVideo from "src/pages/startedVideo";
import HubPortalManufactureVideo from "src/pages/portalVideoManufacture";

const defaultRoute: WithRequired<WithRequired<Partial<AppRoute>, 'isIndex'>, 'removeHeaderandFooter'> = {
  isIndex: false,
  removeHeaderandFooter: false,
};

export const PublicRoutes: AppRoute[] = [
  { ...defaultRoute, route: '/users/sign_in', component: HubLogin, isIndex: true },
  { ...defaultRoute, route: '/users/forgotpassword', component: HubForgotPassword },
  { ...defaultRoute, route: '/users/password/new', component: HubForgotPassword },
  { ...defaultRoute, route: '/enrollments/new', component: NewEnrollment },
  { ...defaultRoute, route: '/users/uploadPEF', component: HubUploadPEF },
  { ...defaultRoute, route: '/set_new_password', component: HubResetPassword, removeHeaderandFooter: true },
  { ...defaultRoute, route: '/formularies', component: CoverageLookup },
  { ...defaultRoute, route: '/auth/entra_id/callback', component: AuthCallback, removeHeaderandFooter: true },
  { ...defaultRoute, route: '/pages/benefits-verification-video', component: HubPortalVideo },
  { ...defaultRoute, route: '/pages/signup-video', component: HubGettingStartedVideo },
  { ...defaultRoute, route: '/pages/baa', component: BaaAgreement },

];

export const AuthenticatedRoutes: AppRoute[] = [
  { ...defaultRoute, route: '/cases', component: Dashboard, isIndex: true },
  { ...defaultRoute, route: '/retreatments', component: RetreatmentRequests },
  { ...defaultRoute, route: '/cases/new', component: HubAddCase },
  { ...defaultRoute, route: '/cases/detail/:caseId', component: HubCaseDetail },
  { ...defaultRoute, route: '/users/createuser', component: HubCreateUser },
  { ...defaultRoute, route: '/users/updateuser', component: HubUpdateUser },
  { ...defaultRoute, route: '/doctors/new', component: AddProvider },
  { ...defaultRoute, route: '/users/new', component: AddUser },
  { ...defaultRoute, route: '/rep_visits/new', component: NewRepVisit },
  { ...defaultRoute, route: '/treatments', component: TreatmentListing },
  { ...defaultRoute, route: '/orders', component: OrderListing },
  { ...defaultRoute, route: '/pages/baa', component: BaaAgreement },
  { ...defaultRoute, route: '/cases/:caseId/clone', component: HubCaseClone },
  { ...defaultRoute, route: '/cases/:caseId/precerts/:precertsId/edit', component: Precert },
  { ...defaultRoute, route: '/cases/:caseId/insurances/:insuranceId/precerts/:precertsId/edit', component: Precert },
  { ...defaultRoute, route: '/cases/:caseId/prior_authorizations/:prior_auth_id/edit', component: PriorAuthorization },
  { ...defaultRoute, route: '/cases/:caseId/documents/new', component: DocumentUpload },
  { ...defaultRoute, route: '/formularies', component: CoverageLookup },
  { ...defaultRoute, route: '/cases/:caseId/precerts/:precertsId/review', component: ReviewMedPA },
  { ...defaultRoute, route: '/cases/:caseId/prior_authorizations/:prior_auth_id', component: ReviewRXPA },
  { ...defaultRoute, route: '/cases/:caseId/precert/:precertsId/edit', component: UpdatePrecert },
  { ...defaultRoute, route: '/pages/benefits-verification-video', component: HubPortalVideo },
  { ...defaultRoute, route: '/pages/signup-video', component: HubGettingStartedVideo },



];

export const ManufacturerRoutes: AppRoute[] = [
  { ...defaultRoute, route: '/statistics', component: manufacturer, isIndex: true },
  { ...defaultRoute, route: '/territories', component: Territories },
  { ...defaultRoute, route: '/regions', component: Regions },
  { ...defaultRoute, route: '/districts', component: District },
  { ...defaultRoute, route: '/practice_stats', component: PracticeStats },
  { ...defaultRoute, route: '/doctor_stats', component: DoctorStats },
  { ...defaultRoute, route: '/idp_territories', component: AreaManager },
  { ...defaultRoute, route: '/users/createuser', component: HubCreateUser },
  { ...defaultRoute, route: '/users/updateuser', component: HubUpdateUser },
  { ...defaultRoute, route: '/formularies', component: CoverageLookup },
  { ...defaultRoute, route: '/fam_regions', component: FamRegions },
  { ...defaultRoute, route: '/fam_districts', component: FamDistrict },
  { ...defaultRoute, route: '/pages/benefits-verification-video', component: HubPortalVideo },
  { ...defaultRoute, route: '/pages/signup-video', component: HubGettingStartedVideo },
  { ...defaultRoute, route: '/pages/reporting-portal-video', component: HubPortalManufactureVideo },
];
