import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import Select, { GroupBase, MultiValue, OptionsOrGroups } from "react-select";

import useFetch from "src/hooks/useFetch";

import { APIEndpoints } from "src/types/apiTypes";
import { DiagnosisCodeProps, DiagnosisResponseProps } from "./types";






const AddCaseDiagnosisCode: FC<DiagnosisCodeProps> = ({ injectionSite, control, watch,setValue }): JSX.Element => {
  const [selectedDiagnosisCodes, updateSelectedDiagnosisCodes] = useState<{ value: string; label: string; }[]>([])
  const diagnosisCode = watch("prescription_attributes.diagnosis_code");
  const drug_name = watch("prescription_attributes.drug_name");


  /**
   * @SearchDiagnosisBySearchValue search by API for diagnosis
   * @first initialize useFetch() with diagnosis
   * @second search diagnosis by search term
   */
  const { loading: diagnosisLoader, data: rfcListing, mutate: getDiagnosis, removeErrorAndData: clearDiagnosisData } =
    useFetch<DiagnosisResponseProps>(APIEndpoints.GET_DIAGNOSIS_DROPDOWN, { ...(injectionSite ? { search: "", drug_name } : {}) }, {}, injectionSite ? true : false);

  /** @second */
  const searchDiagnosis = (search: string) => {
    if (typeof search === "string" && search.length >= 2) {
      clearDiagnosisData();
      getDiagnosis(null, null, { search, drug_name });
    }
  };


  const debounced = useDebouncedCallback(searchDiagnosis, 1500);

  useEffect(() => {
    if (diagnosisCode || (rfcListing && rfcListing.data)) {

     // console.log("RFCListing",rfcListing,"    ", diagnosisCode)
      // getSiteOfCareListing()
      if (!injectionSite)
        setTimeout(() => clearDiagnosisData(), 150);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnosisCode]);




  return (
    <div className="row mb-3">
      <label htmlFor="prescription_attributes.diagnosis_code" className="form-label col-lg-4 col-md-4 col-12">
        Diagnosis Code *
      </label>

      <div className="col-lg-8 col-md-8 col-12">
        <Controller
          name="prescription_attributes.diagnosis_code"
          control={control}
          defaultValue={[]}
          render={({ field, fieldState: { error } }) => (
            <>
              <Select
                options={
                  (rfcListing && rfcListing.data && rfcListing.data.length > 0 ?
                    rfcListing.data.map((option) => ({ value: option.fullname, label: option.fullname }))
                    : []) as unknown as OptionsOrGroups<string, GroupBase<string>>
                }
                components={{
                  MultiValueLabel: (props) => (
                    <div {...props}
                      className="p-1" style={{ fontSize: 12 }}
                      title={selectedDiagnosisCodes.length > 0 ? selectedDiagnosisCodes.find((item: { value: string; label: string; }) => item.value === props.data.value)?.value : props.data.label}
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                    >
                      {selectedDiagnosisCodes.length > 0 ?
                       selectedDiagnosisCodes.find((item: { value: string; label: string; }) => item.value === props.data.value)?.value 
                       : props.data.label
                      }
                      
                    </div>
                  )
                }}
                id="prescription_attributes.diagnosis_code"
                isSearchable={true}
                isMulti={true}
                placeholder="Enter Diagnosis Code"
                {...field}
                noOptionsMessage={() => diagnosisLoader ? "Loading..." : "Please enter 2 or more characters"}
                value={ 
                  field &&
                  field.value.map((item) => ({
                    value: item,
                    label: item,
                  })) as unknown as MultiValue<unknown>
                }
                onChange={
                  (val) => {
                    updateSelectedDiagnosisCodes(val.map((item: unknown) => (item as { value: string; label: string; })))
                    field.onChange(
                      (val.map((item: unknown) => (item as { value: string; label: string; }).label))
                    )
                  }
                }
                onInputChange={!injectionSite ? debounced : () => {}}
              />

              {error && error.message ? (
                <div className="text-danger text-small mt-2">{error.message}</div>
              ) : null}
            </>
          )}
        />
      </div>
    </div>
  )
}

export default AddCaseDiagnosisCode;
