import { FC, useContext } from "react";

import { FooterContext } from "src/context/footerContext";

import { FAX_NUMBER, PHONE_NUMBER } from "../../constants/contact";
import { ContactAndSafetyInfoProps } from "./types";

const ContactAndSafetyInfo: FC<ContactAndSafetyInfoProps> = ({ alignText, textColor, isLogin = false }): JSX.Element => {
  const {changeAccordianVisibility} =  useContext(FooterContext)

  return (
    <>
    <div className="text-center text-md-end fw-bold" data-tour="sixth-step">
      <p className={"mb-2 mb-md-3"}>Phone: {PHONE_NUMBER}</p>
      <p className={"mb-2 mb-md-3"}>Fax: {FAX_NUMBER}</p>
    </div>
    </>
  );
};

export default ContactAndSafetyInfo;
