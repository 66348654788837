import { AuthAPIsType, APITypes, APIRouteType } from "../types/apiTypes";



export const apiConstants = {
  baseURL: process.env.REACT_APP_BASE_URL,

  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "ClientDb": process.env.REACT_APP_CLIENT_DB || "",
    "ENVTYPE": `${process.env.REACT_APP_ENV}-${process.env.REACT_APP_CLIENT_DB}`,
    "Accept":"*/*"
  },
};
const defaultProtectedRoute: Omit<Omit<APIRouteType, "url">, "method"> = {
  isBodyRequired: false,
  isPathParamsRequired: false,
  isQueryParamsRequired: false,
  isAuthHeaderRequired: true,
  isFileUpload: false,
};
export const authAPIs: AuthAPIsType = {
  signIn: {
    url: '/login',
    method: APITypes.POST,
    isBodyRequired: true,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: false,
    isFileUpload: false,
  },
  forgotPassword: {
    url: '/forgot_password',
    method: APITypes.POST,
    isBodyRequired: true,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: false,
    isFileUpload: false,
  },
  resetPassword: {
    url: '/reset_password',
    method: APITypes.PATCH,
    isBodyRequired: true,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: false,
    isFileUpload: false,
  },
  logout: {
    url: '/logout',
    method: APITypes.DELETE,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  getProviderSpecialities: {
    url: '/api/v1/global_doctors/taxonomy_lookup',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: false,
    isFileUpload: false,
  },
  userUpdate: {
    url: '/api/v1/users/:userId',
    method: APITypes.PATCH,
    isBodyRequired: true,
    isPathParamsRequired: true,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  caseList: {
    url: 'api/v1/cases',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  addUser: {
    url: '/api/v1/users',
    method: APITypes.POST,
    isBodyRequired: true,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  npiLookup: {
    url: '/api/v1/global_doctors/npi_lookup',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: false,
    isFileUpload: false,
  },
  addProvider: {
    url: '/api/v1/global_doctors',
    method: APITypes.POST,
    isBodyRequired: true,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  practiceDropdown: {
    url:'/api/v1/cases/practices_dropdown',
    method:APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  patientDropdown: {
    url:'/api/v1/cases/patients_dropdown',
    method:APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  providerDropdown: {
    url:'/api/v1/cases/providers_dropdown',
    method:APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  addEnrollment: {
    url: '/api/v1/enrollments',
    method: APITypes.POST,
    isBodyRequired: true,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: false,
    isFileUpload: false,
  },
  getProcedureDropdown: {
    url: '/api/v1/cases/procedure_cpt_dropdown',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  getDiagnosisDropdown: {
    url: '/api/v1/cases/diagnosis_code_dropdown',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  getSiteOfCareDropdown: {
    url: '/api/v1/cases/site_of_care_dropdown',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  addNewCase: {
    url: '/api/v1/cases',
    method: APITypes.POST,
    isAuthHeaderRequired: true,
    isBodyRequired: true,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isFileUpload: false,
  },
  getPatientDetailsById: {
    url: `/api/v1/cases/patient_data`,
    method: APITypes.GET,
    isAuthHeaderRequired: true,
    isQueryParamsRequired: true,
    isPathParamsRequired: false,
    isBodyRequired: false,
    isFileUpload: false,
  },
  caseDetail: {
    url: '/api/v1/cases/:caseId',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: true,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  addNote: {
    url: '/api/v1/notes',
    method: APITypes.POST,
    isBodyRequired: true,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  uploadCaseFile: {
    url: '/api/v1/cases/:caseId/upload_document',
    method: APITypes.POST,
    isBodyRequired: true,
    isPathParamsRequired: true,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: true,
  },
  downloadZipFile: {
    url: '/api/v1/cases/:caseId/zip_files',
    method: APITypes.GET,
    isBodyRequired: true,
    isPathParamsRequired: true,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  cloneCaseDetails: {
    url: '/api/v1/cases/:caseId/clone_case',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: true,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  treatmentListing: {
    url: '/api/v1/treatments',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  orderListing: {
    url: '/api/v1/orders',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  createOrder: {
    url: '/api/v1/orders',
    method: APITypes.POST,
    isBodyRequired: true,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  treatmentStatusUpdate: {
    url: '/api/v1/treatments/:treatmentId',
    method: APITypes.PUT,
    isBodyRequired: true,
    isPathParamsRequired: true,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  scheduleRepVisit: {
    url:'/api/v1/rep_visits',
    method: APITypes.POST,
    isBodyRequired: true,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  uploadPEF: {
    url:'/api/v1/faxes_ins',
    method: APITypes.POST,
    isBodyRequired: true,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: false,
    isFileUpload: true,
  },
  fetchDocument: {
    url:'/api/v1/cases/fetch_document',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  requestTreatmentNotTreating: {
    url:'/api/v1/cases/:caseId/prescriptions/:prescriptionId',
    method: APITypes.PUT,
    isBodyRequired: true,
    isPathParamsRequired: true,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  downloadRequestForm: {
    url:'/api/v1/downloads/get_file',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  manufactureStatistics: {
    url:'/api/v1/statistics',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  manufactureStatisticsPdfDownload: {
    url:'/api/v1/cases/:Id/fill_forms',
    method: APITypes.POST,
    isBodyRequired: false,
    isPathParamsRequired: true,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: true,
  },
  caseDetailPopup: {
    url:'/api/v1/statistics/case_popup',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  manufactureStatisticsReportsDownload: {
    url:'/api/v1/reports/download',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: true,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: true,
  },
  
  manufactureStatisticsSocAndDoctorsDropdown: {
    url:'/api/v1/statistics/practice_doctor_list',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  fetchPrecert: {
    url:'/api/v1/cases/:case_id/precerts/:id/edit',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: true,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  submiPrecert: {
    url:'/api/v1/cases/:caseId/precerts/:precertId',
    method: APITypes.PUT,
    isBodyRequired: true,
    isPathParamsRequired: true,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: true,
  },
  fetchPrecertCaseDetail: {
    url:'/api/v1/cases/:case_id/insurances/:insurance_id//precerts/:id/edit',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: true,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  transferRX: {
    url:'/api/v1/cases/:case_id/transfers',
    method: APITypes.POST,
    isBodyRequired: true,
    isPathParamsRequired: true,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  getProducts: {
    url:'/api/v1/cases/get_products',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  getUserForAccountManager: {
    url:'/api/v1/users/territory_users',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  appealCase: {
    url:'/api/v1/cases/:caseId/precerts/:precertId/appeal',
    method: APITypes.PUT,
    ...defaultProtectedRoute,
    isPathParamsRequired: true,
  },
  reviewPrecertApprove: {
    url:'/api/v1/cases/:caseId/precerts/:precertId/approve',
    method: APITypes.PUT,
    ...defaultProtectedRoute,
    isPathParamsRequired: true,
  },
  reviewPrecertDeny: {
    url:'/api/v1/cases/:caseId/precerts/:precertId/deny',
    method: APITypes.PUT,
    ...defaultProtectedRoute,
    isPathParamsRequired: true,
  },
  updateCases: {
    url: '/api/v1/cases/:caseId',
    method: APITypes.PUT,
    isBodyRequired: true,
    isPathParamsRequired: true,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  retreatmentListing: {
    url: '/api/v1/retreatments',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  deleteRetreatment: {
    url: '/api/v1/retreatments/:retreatment_id',
    method: APITypes.DELETE,
    isBodyRequired: false,
    isPathParamsRequired: true,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  localCoverage: {
    url: 'api/v1/formularies',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: false,
    isFileUpload: false,
  },
  priorAuthorization: {
    url: 'api/v1/prior_authorizations/:id ',
    method: APITypes.PUT,
    isBodyRequired: true,
    isPathParamsRequired: true,
    isQueryParamsRequired: false,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  downloadLocalRequestForm: {
    url:'/api/v1/downloads/get_local_file',
    method: APITypes.GET,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  getPrecertReviewFile: {
    url: '/api/v1/cases/:caseId/precerts/:precertId',
    method: APITypes.GET,
    ...defaultProtectedRoute,
    isPathParamsRequired: true,
  },
  updatePrecert: {
    url:'/api/v1/cases/:case_id/precerts/:id',
    method: APITypes.PUT,
    isBodyRequired: false,
    isPathParamsRequired: true,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  updatePriorAuth: {
    url:'/api/v1/prior_authorizations/:id',
    method: APITypes.PUT,
    isBodyRequired: false,
    isPathParamsRequired: true,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
  getPriorAuthReviewFile: {
    url: '/api/v1/prior_authorizations/:id',
    method: APITypes.GET,
    ...defaultProtectedRoute,
    isPathParamsRequired: true,
  },
  demoResetCases:{
    url:'/api/v1/cases/reset_demo',
    method: APITypes.PUT,
    ...defaultProtectedRoute,
  },
  resourcesAnalytics:{
    url:'/api/v1/analytics',
    method: APITypes.POST,
    isBodyRequired: false,
    isPathParamsRequired: false,
    isQueryParamsRequired: true,
    isAuthHeaderRequired: true,
    isFileUpload: false,
  },
};

/**
 * @APIErrors Default Errors based on static types
 */
export const ErrorMessages = {
  NetworkError: 'Something went wrong while connecting with services. Please try again in a while',
};
