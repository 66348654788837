import { FC } from "react";
import { MANUFACTURER_NUMBER } from "../../../constants/contact";
import { ContactAndSafetyInfoProps } from "../../contactAndSafetyInfo/types";

// import { AuthContext } from "src/context/authContext";
// import { FooterContext } from "src/context/footerContext";

const ManufacturerContactAndSafetyInfo: FC<ContactAndSafetyInfoProps> = ({ alignText, textColor }): JSX.Element => {
  // const { isUserAuthorized } = useContext(AuthContext);
  // const {changeAccordianVisibility} =  useContext(FooterContext)
  return (
    <>
      {/* <div className={`${alignText} my-3`}>
      <button
          type="button"
          onClick={changeAccordianVisibility}
          className={`btn btn-link ${!isUserAuthorized ? `text-${textColor}` : ''} ${!isUserAuthorized ? `link-${textColor}` : ''} link-offset-0 link-offset-0-hover link-underline-${isUserAuthorized ? 'primary' : textColor === 'black' ? 'dark' : 'light'} link-underline-opacity-0 link-underline-opacity-100-hover p-0 align-baseline`}
        >
          Important Safety Information
        </button>
        <span className={`text-${textColor}`}> | </span>
        <a
          href="/assets/EuflexxaPI.pdf"
          target="_blank"
          className={`${!isUserAuthorized ? `text-${textColor}` : ''} ${!isUserAuthorized ? `link-${textColor}` : ''} link-offset-0 link-offset-0-hover link-underline-${isUserAuthorized ? 'primary' : textColor === 'black' ? 'dark' : 'light'} link-underline-opacity-0 link-underline-opacity-75-hover`}
        >
          Full Prescribing Information
        </a>
      </div> */}

      <div className={`${alignText} fw-bold`}>
           {MANUFACTURER_NUMBER}
      </div>
    </>
  );
};

export default ManufacturerContactAndSafetyInfo;
