import { FC, useContext } from "react";

import AuthSubHeader from "../../components/authSubHeader";
import FooterText from "src/components/footerText";
import ContactAndSafetyInfo from "src/components/contactAndSafetyInfo";
import BAAContent from "src/components/baaContent";

import { AuthContext } from "src/context/authContext";
import { TextAlignProps } from "src/components/contactAndSafetyInfo/types";


const BAAAgreement: FC = (): JSX.Element => {
  const { isUserAuthorized } = useContext(AuthContext);

  return (
    <div className={!isUserAuthorized ? 'guest-page-bg overflow-auto' : 'overflow-auto body-bg'}>
      <div className="container-fluid pt-2 pb-3" style={{ paddingBottom: `5rem` }}>
        <div className={"container"}>
          <div className="row justify-content-center">
            <div className="col-12">
              {isUserAuthorized ? (
                  <AuthSubHeader/>
              ) : (
                  <ContactAndSafetyInfo alignText={TextAlignProps.TEXT_END} textColor="white"/>
              )}

              {/* <h2 className="mt-3 mb-4">Business Associate Agreement</h2> */}

              <div className="card bg-white">
                <div className="row">
                  <div className={"col-sm-12"}>
                    <BAAContent/>
                  </div>
                </div>
              </div>

              <FooterText footerType={isUserAuthorized ? "black" : "white"}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BAAAgreement;
