import { FC, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
// import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";

import { TreatmentStatusUpdateApiResponse } from "src/pages/treatmentListing/types";
import HubInputField from "src/components/inputType";

import useFetch from "src/hooks/useFetch";
import { ToastContext } from "src/context/toastContext";
// import { AuthContext } from "src/context/authContext";
import { TreatmentInstillationSchema } from "src/validation/treatmentInstillation";

import { ToastTypes } from "src/types";
import { APIEndpoints } from "src/types/apiTypes";
import { TreatmentInstillationModalProps, TreatmentInstillationProps, } from "./types";




const TreatmentInstillationModal: FC<TreatmentInstillationModalProps> = ({ isModalVisible, patientName, hideModal, treatmentId, instillation, treatedAt, refreshListing }): JSX.Element => {
  const { control, handleSubmit, reset, getValues } = useForm<TreatmentInstillationProps>({
    mode: 'all',
    resolver: yupResolver(TreatmentInstillationSchema),
  });

  const { showToast } = useContext(ToastContext);
  // const { destroySession } = useContext(AuthContext);
  // const navigate = useNavigate();

  useEffect(() => {
    reset({
      date: instillation ? `${format(instillation, "yyyy-MM-dd")}T${format(instillation, "HH:mm")}` : ""
    });
   // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [instillation]);

  const internalHideModal = () => {
    reset()
    hideModal()
  }





  const { loading, data:treatmentUpdateData, mutate: updatetreatment } =
    useFetch<TreatmentStatusUpdateApiResponse>(APIEndpoints.TREATMENT_STATUS_UPDATE,  {} , { treatmentId});

  useEffect(() => {
    if (treatmentUpdateData) {
      showToast([`Treatments Instillation Updated Successfully.`], ToastTypes.SUCCESS)
      refreshListing()
      internalHideModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentUpdateData]);

  const clickOntreatmentInstillation = (value: TreatmentInstillationProps) => {
    const requestBody = {
      scheduled_at: value.date,
      status: 21
    };
    updatetreatment(requestBody);
  }





  return (
    <div
      className={`modal fade ${isModalVisible ? 'show show-modal' : ''}`}
      id="exampleModal"
      tabIndex={-1}
      data-bs-backdrop="true"
      aria-hidden="true"
      onClick={internalHideModal}
    >
      <div className="modal-dialog modal-lg" onClick={e => e.stopPropagation()}>
        <div className="modal-content">

          <div className="modal-header">
            <h1 className="modal-title fs-3 mb-0" id="exampleModalLabel">
              Schedule Treatment {patientName ? `for ${patientName}` : ""}
            </h1>
            <button type="button" className="btn-close" aria-label="Close" onClick={hideModal}></button>
          </div>

          <form onSubmit={handleSubmit(clickOntreatmentInstillation)}>
            <div className="modal-body">
              <p className="text-center"> Please specify date of treatment... </p>
              <p className="text-center"> NOTE: The submitting office has requested an appointment for {getValues("date") ? getValues("date") : ""} </p>

              <div className="row align-items-center">
                <div className="col-3">
                  <p className="mb-0 text-end"> Date and Time </p>
                </div>

                <div className="col-7">
                  <Controller
                    name="date"
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <HubInputField
                        isRowBased={true}
                        isRequired={false}
                        isRowInsideAnother={true}
                        inputId="date"
                        field={field}
                        error={error}
                        inputType="datetime-local"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" disabled={loading}>
                <div className="row align-items-center px-1">
                  {loading ? (
                    <div className="spinner-border text-light col-3" role="status">
                      <span className="sr-only"></span>
                    </div>
                  ) : null}
                  <p className="col-auto mb-0"> Save </p>
                </div>
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  );
};

export default TreatmentInstillationModal;
