import { FC, useContext, useEffect} from "react";
import { DownloadPracticeResponseTypeApi, ModalType, NewDoctorType, PracticeProps } from "./types";
import { NewPracticeProps } from "src/pages/manufacturer/types";
import { formatPhoneNumber } from "src/helpers";
import { format, parse } from "date-fns";
import { APIEndpoints } from "src/types/apiTypes";
import useFetch from "src/hooks/useFetch";
import { downloadExcelFile } from "src/helpers/downloadFile";
import { LoaderContext } from "src/context/loaderContext";


const Modal: FC<ModalType> = ({ title, data, startDate, endDate, onClick }): JSX.Element => {
  const { setLoader, hideLoader } = useContext(LoaderContext)



  const {
    data: downloadReportsData, error: downloadReportsError, mutate: downloadRequestForm, removeErrorAndData: clearRequestFileData
  } = useFetch<DownloadPracticeResponseTypeApi>(APIEndpoints.MANUFACTURE_STATISTICS_REPORT_DOWNLOAD);

  useEffect(() => {
    if (downloadReportsData || downloadReportsError) {
      if (downloadReportsData){
        const { response, report_name } = downloadReportsData;
        downloadExcelFile(response, `${report_name}.xlsx`);
      }
      hideLoader();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadReportsData, downloadReportsError]);


  const downloadReport = () => {
    if (downloadReportsData || downloadReportsError) clearRequestFileData();
    setLoader();
    const params = {
      start_date: startDate,
      end_date: endDate,
      report_name: "practice_report",
    }
    downloadRequestForm({}, {}, params);
  }
  


  const overFlowBackground = () => {
    document.body.style.overflow = 'unset';
  }
  return (
    <div className= {`modal fade loader-index ${title && title.length > 0 ? 'show show-modal' : ''}`} id="typeModal" aria-labelledby="typeModalLabel" aria-hidden="true" >
      <div className="modal-dialog modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header align-items-md-center align-items-start">
            <div className={"d-block d-md-flex"}>
              <p className="h2 modal-title fw-normal" style={{color:'#008853'}}>{title}</p>
              <button type="button" className="btn btn-success ms-md-3 ms-0" onClick={() => downloadReport()} style={{ paddingInline: 80 }}>
                Download
              </button>
            </div>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={
              ()=>{
                onClick()
                overFlowBackground()
                }
               }>
               </button>
          </div>
          <div className="modal-body p-0">
            {title === "New Practices" ?
              <table className="table table-striped table-hover table-responsive position-relative m-0">
                <thead className="sticky-top">
                  <tr>
                    <th scope="col" className="text-center fs-6">Region</th>
                    <th scope="col" className="text-center fs-6">Territory</th>
                    <th scope="col" className="text-center fs-6">Practice Name</th>
                    <th scope="col" className="text-center fs-6">State</th>
                    <th scope="col" className="text-center fs-6 col-3">Phone</th>
                    <th scope="col" className="text-center fs-6 col-3">Added</th>
                  </tr>
                </thead>
                <tbody>
                  {data && (data as NewPracticeProps).data && (data as NewPracticeProps).data.length > 0 ?
                    (data as NewPracticeProps).data.map((item: PracticeProps, index) =>
                      <tr key={index}>
                        <td className="text-center text-wrap">{item.region_name || ""}</td>
                        <td className="text-center text-wrap">{item.territory_name || ""}</td>
                        <td className="text-center text-wrap">{item.name || ""}</td>
                        <td className="text-center">{item.state}</td>
                        <td className="text-center">{item.phone_number ? formatPhoneNumber(item.phone_number) : ""}</td>
                        <td className="text-center">{format(parse(item.created_at, "yyyy-MM-dd", new Date()), "MM/dd/yyyy")}</td>
                      </tr>
                    )
                    : null
                  }
                </tbody>
              </table>
              :
              <table className="table table-hover table-striped table-responsive m-0">
                <thead className="sticky-top">
                  <tr>
                    <th className="text-center fs-6">Name</th>
                    <th className="text-center fs-6">Practice</th>
                    <th className="text-center fs-6">State</th>
                    <th className="text-center fs-6">Phone</th>
                  </tr>
                </thead>
                <tbody>
                    {
                      data && (data as NewDoctorType[]).length > 0 ?
                      (data as NewDoctorType[]).map((item: NewDoctorType, index) =>
                        <tr key={index}>
                          <td className="text-center text-wrap text-break">{item.name} </td>
                          <td className="text-center"></td>
                          <td className="text-center">{item.state}</td>
                          <td className="text-center">{item?.phone}</td>
                        </tr>
                        )
                        : null
                    }

                </tbody>
              </table>
            }
          </div>
        </div>
      </div>
    </div>



  );
};

export default Modal;
