import { FC, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";

import  LoginHero  from "src/assets/images/login_hero.png";
import  IoveraLogo  from "src/assets/images/iovera-logo.svg";
import FooterText from "../../components/footerText";
import HubInputField from "../../components/inputType";
import ContactAndSafetyInfo from "src/components/contactAndSafetyInfo";

import { AuthContext } from "../../context/authContext";
import { ToastContext } from "../../context/toastContext";

import { TextAlignProps } from "src/components/contactAndSafetyInfo/types";
import { LoginSchema } from "../../validation/login";
import useFetch from "../../hooks/useFetch";
import { APIEndpoints } from "../../types/apiTypes";
import { ToastTypes } from "../../types";
import { UserType } from "src/types/users";
import { USER_TYPES } from "src/constants";
import { LoginFormType, LoginAPIResponseType } from "./types";




const HubLogin: FC = (): JSX.Element => {
  const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false);

  const { control, handleSubmit } = useForm({
    mode: 'all',
    resolver: yupResolver(LoginSchema),
  });
  const { userData: sessionSavedUser, createSession, restoreSession } = useContext(AuthContext);
  const { showToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const isSingleSignOn = localStorage.getItem("fromSingleSignOn");

  const routeToRelevantPaths = (userData: UserType) => {
    if (userData.ref_type === 'Manufacturer') {
      navigate("/statistics");
    } else if (userData.ref_type === 'regional_manager') {
      navigate("/regions");
    } else if (userData.ref_type === 'district_manager') {
      navigate("/districts");
    } else if (userData.ref_type === 'fam_am') {
      navigate("/fam_regions");
    } else if (userData.ref_type === 'fam_rm') {
      navigate("/fam_districts");
    } else if (userData.ref_type === 'rep') {
      navigate("/territories");
    } else if (USER_TYPES.HCP.includes(userData.ref_type)) {
      navigate("/cases");
    }
  }

  const reRouteRelevantUser = (userData: UserType) => {
    if (isSingleSignOn) {
      routeToRelevantPaths(userData)
    } else {
      if (!userData.is_password_changed) {
        navigate("/users/createuser");
      } else {
        routeToRelevantPaths(userData)
      }
    }
  };

  const { data: userData, loading, mutate, error, removeErrorAndData } = useFetch<LoginAPIResponseType>(APIEndpoints.USER_SIGNIN);

  useEffect(() => {
    if (userData && userData.status.code === 200) {
      const { status, token ,} = userData;
      createSession(
        {
          ...status.data.user,
          practice_created_date: status.data.address ? status.data.address.date : "",
          practice_name: status.data.address ? status.data.address.name : "",
          address: status.data.address ? status.data.address.ref_address : "",
          reset_demo_cases: status.data.navbar.reset_demo_cases,
          stats_view: status.data.navbar.stats_view,
          demo_user_view: status.data.navbar.demo_user_view,
        },
        status.data.sub_header,
        token,
        status.data.iovera_practice,

      );
      showToast([status.message], ToastTypes.INFO);

      reRouteRelevantUser(status.data.user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const submitForm = async (values: LoginFormType) => {
    if ((error && error.length > 0) || userData) removeErrorAndData();
    const isSessionExists = restoreSession();

    if (!isSessionExists) {
      mutate({ user: values });
    } else {
      showToast([`There is already an active user session`], ToastTypes.INFO);
      if (sessionSavedUser) reRouteRelevantUser(sessionSavedUser);
    }
  };

  return (
    <div className="login-sec pt-3 d-flex flex-fill flex-column justify-content-between overflow-auto">
      <div className="container d-flex flex-fill flex-column">
        <ContactAndSafetyInfo alignText={TextAlignProps.TEXT_END} textColor="black" />
        <div className="row flex-fill pt-2">
          <div className="col-12 col-md-7 d-flex flex-column justify-content-evenly align-items-center align-items-md-start">
            <img className="login-hero-img mb-3 mb-md-0" src={LoginHero} alt="HeroImage"/>
            <img className="login-hero-img" src={IoveraLogo} alt="HeroImage"/>
          </div>
          <div className="col-12 col-md-5">
            <form className="card mt-4" onSubmit={handleSubmit(submitForm)}>
              <div className="card-header">
                <h3 className="mb-0 text-white">Welcome to</h3>
                <h1 className="mb-0 text-white">FlexForward </h1>
              </div>
              <div className="card-body">
                <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({field, fieldState: {error}}) => (
                        <div className={"mb-3"}>
                          <label htmlFor="email" className="form-label justify-content-start mb-2">Email *</label>
                          <HubInputField
                            inputId="email"
                            inputPlaceholder="Email"
                            isRowBased={false}
                            isRequired={true}
                            field={field}
                            error={error}
                            inputType="email"
                          />
                        </div>
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({field, fieldState: {error}}) => (
                        <div className={"mb-3"}>
                          <label htmlFor="password" className="form-label justify-content-start mb-2">Password *</label>
                          <div className="input-group mb-3">
                            <input
                                type={isPasswordVisible ? "text" : "password"} placeholder="Password"
                                className="form-control" {...field}
                            />
                            <button
                                type="button"
                                className="btn btn-outline-dark"
                                onClick={() => setPasswordVisibility((isPasswordVisible) => !isPasswordVisible)}
                            >
                              {isPasswordVisible ? (
                                  <i className="bi bi-eye-fill"></i>
                              ) : (
                                  <i className="bi bi-eye-slash"></i>
                              )}
                            </button>
                          </div>
                          {error && (<p className="mb-0 text-danger">{error.message}</p>)}
                        </div>
                    )}
                />
                <p className="small m-0">By clicking sign in below, you agree to our&nbsp;
                  <a href="https://www.pacira.com/terms-of-use/" target="_blank" rel="noreferrer">
                    Terms of Use
                  </a>
                </p>

              </div>
              <div className={"card-footer"}>
                <div className="d-flex justify-content-between align-items-center">
                  <button disabled={loading} className="btn btn-primary" type="submit">
                    {loading ? (<i className="spinner-border"></i>) : null} Log in
                  </button>
                  &nbsp;
                  {/*<Link to="/enrollments/new" className="btn btn-success">Enroll</Link>*/}
                  <p className="m-0">
                    <Link to="/users/forgotpassword">Forgot your password?</Link>
                  </p>
                </div>
              </div>
            </form>
          </div>

          <div className="col-12"><FooterText footerType="black"/></div>
        </div>
      </div>
    </div>
  );
};

export default HubLogin;
