// import { driver } from "driver.js";

import Shepherd from "shepherd.js";

// export const euflexxaTour = driver({
//   showProgress: false,
//   allowClose: true,
//   nextBtnText: "Next >>",
//   prevBtnText: "<< Prev",
//   doneBtnText: "End Tour",
//   showButtons: [ 'close', 'next', 'previous' ],
//   // onHighlighted:function(element) {
//   //   if (element) {
//   //     const rect = element.getBoundingClientRect();
//   //     // Scroll to half of the element
//   //     window.scrollBy(0, rect.top / 2);
//   //   }
//   // },
//   steps: [
//     { element: "document", popover: {
//       title: 'Welcome to our landing page!',
//       description: `This tour will guide you through some of the features we'd like to point out.`,
//       side: "over", align: "center",
//     }},
//     { element: `[data-tour="second-step"]`, popover: {
//       title: "Case List",
//       description: "Here is where you'll find your submitted cases",
//       side: "top", align: "center",
//     },
//     onHighlighted: (element) => {
//       if (element) {
//         element.setAttribute("style", "max-height:10px !important;")
//         window.scrollTo(0, 0);
//       }
//     }},
//     { element: `[data-tour="third-step"]`, popover: {
//       title: "Case Search",
//       description: "If you can not find a Case, use the Search Toolbox",
//       side: "top", align: "center",
//     }},
//     { element: `[data-tour="fourth-step"]`, popover: {
//       title: "Light Dashboarding",
//       description: "Here's some information you might find useful!",
//       side: "bottom", align: "center",
//     }},
//     { element: `[data-tour="fifth-step"]`, popover: {
//       title: "Get Started",
//       description: "Click here to submit a new benefits request",
//       side: "bottom", align: "center",
//     }},
//     { element: `[data-tour="sixth-step"]`, popover: {
//       title: "Contact Us",
//       description: "If you have questions or need to send documentation, here is our contact information",
//       side: "bottom", align: "center",
//     }},
//   ],
// });

const tour = new Shepherd.Tour({
  useModalOverlay: true,
  defaultStepOptions: {
    classes: 'shepherd-theme-custom',
    scrollTo: false,
    arrow: false,
  },
});

// Add steps to the tour
tour.addStep({
  title: "Welcome to our landing page!",
  text: "This tour will guide you through some of the features we'd like to point out.",
  // attachTo: {
  //   element: "",
  //   on: "top"
  // },
  buttons: [
    {
      text: `<i class="bi bi-chevron-double-left"></i> Prev`,
      disabled: true,
    },
    {
      text: `Next <i class="bi bi-chevron-double-right"></i>`,
      action: tour.next,
    },
    {
      text: "End Tour",
      action: tour.complete,
    },
  ],
});

tour.addStep({
  title: "Case List",
  text: "Here is where you'll find your submitted cases",
  attachTo: {
    element: `[data-tour="second-step"]`,
    on: "top"
  },
  buttons: [
    {
      text: `<i class="bi bi-chevron-double-left"></i> Prev`,
      action: tour.back,
    },
    {
      text: `Next <i class="bi bi-chevron-double-right"></i>`,
      action: tour.next,
    },
    {
      text: "End Tour",
      action: tour.complete,
    },
  ],
});

tour.addStep({
  title: "Case Search",
  text: "If you can not find a Case, use the Search Toolbox",
  attachTo: {
    element: `[data-tour="third-step"]`,
    on: "top"
  },
  buttons: [
    {
      text: `<i class="bi bi-chevron-double-left"></i> Prev`,
      action: tour.back,
    },
    {
      text: `Next <i class="bi bi-chevron-double-right"></i>`,
      action: tour.next,
    },
    {
      text: "End Tour",
      action: tour.complete,
    },
  ],
});

tour.addStep({
  title: "Light Dashboarding",
  text: "Here's some information you might find useful!",
  attachTo: {
    element: `[data-tour="fourth-step"]`,
    on: "bottom"
  },
  buttons: [
    {
      text: `<i class="bi bi-chevron-double-left"></i> Prev`,
      action: tour.back,
    },
    {
      text: `Next <i class="bi bi-chevron-double-right"></i>`,
      action: tour.next,
    },
    {
      text: "End Tour",
      action: tour.complete,
    },
  ],
});

tour.addStep({
  title: "Get Started",
  text: "Click here to submit a new benefits request",
  attachTo: {
    element: `[data-tour="fifth-step"]`,
    on: "bottom"
  },
  buttons: [
    {
      text: `<i class="bi bi-chevron-double-left"></i> Prev`,
      action: tour.back,
    },
    {
      text: `Next <i class="bi bi-chevron-double-right"></i>`,
      action: tour.next,
    },
    {
      text: "End Tour",
      action: tour.complete,
    },
  ],
});

tour.addStep({
  title: "Contact Us",
  text: "If you have questions or need to send documentation, here is our contact information",
  attachTo: {
    element: `[data-tour="sixth-step"]`,
    on: "bottom"
  },
  buttons: [
    {
      text: `<i class="bi bi-chevron-double-left"></i> Prev`,
      action: tour.back,
    },
    {
      text: `Next <i class="bi bi-chevron-double-right"></i>`,
      disabled: true,
    },
    {
      text: "End Tour",
      action: tour.complete,
    },
  ],
});

export default tour;
