import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AuthSubHeader from 'src/components/authSubHeader';
import FooterText from 'src/components/footerText';
import useFetch from 'src/hooks/useFetch';
import { PrecertAPIResponse } from '../precert/types';
import { APIEndpoints } from 'src/types/apiTypes';
import { LoaderContext } from 'src/context/loaderContext';

const ReviewMedPA: React.FC = () => {
  const [note, setNote] = useState<string>("Input Changes Requested Here");
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [buttonDisabled,setButtonDisabled] = useState<boolean>(false);
  const { setLoader, hideLoader } = useContext(LoaderContext);
  

  const { caseId, precertsId} = useParams();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search); // Parse query params

  const navigate = useNavigate();
  // const [title] = useState((location.state as { title: string }).title)
  // const [patient,setPatient] = useState((location.state as { patient: string }).patient)


  const updateNote = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };
  const { data: reviewFile } =
  useFetch<{ precert_file: string }>(APIEndpoints.GET_PRECERT_REVIEW_FILE, {}, { caseId, precertId: precertsId ,button_type:"review PA"})


  // const { mutate: updatePriorMutate, data: updatePriorResponse } =
  //   useFetch<PrecertAPIResponse>(APIEndpoints.UPDATE_PRIOR_AUTH, {}, {}, false);

  // useEffect(() => {
  //   if (fetchPrecertResponse) {
  //     navigate(`/cases`);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [updatePriorResponse]);

  // const navigateToUpdatePrior = () => {
  //   const pathParams = {
  //     case_id: caseId,
  //     id: precertsId
  //   }
  //   const queryParams = {
  //     prior_authorization_approval: false,
  //     status: 14,
  //     case_id: caseId,
  //   }
  //   // setPrecertPathParameters(pathParams)
  //   updatePriorMutate({}, pathParams, queryParams)
  // }
  // const handleRequestChange = () => {
  //   const confirmDeny = window.confirm("Are you sure you wish to deny this precert request?");
  //   if (confirmDeny) {
  //     // Handle the logic to deny precert here
  //     if (title.includes("Med PA")){
  //       navigateToUpdatePrecert()

  //     }else{
  //       navigateToUpdatePrior()
  //     }

  //     console.log("Request denied with note:", note);
  //   }
  // };
  useEffect(()=>{
    if (reviewFile) {
      // Convert Base64 to binary data
      const byteCharacters = atob(reviewFile.precert_file);
      const byteNumbers = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      // Create a Blob and Object URL
      const blob = new Blob([byteNumbers], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);

      // Cleanup function to revoke the object URL
      return () => URL.revokeObjectURL(url);
    }
  },[reviewFile])



  const { mutate: fetchPrecertMutate, data: fetchPrecertResponse } =
    useFetch<PrecertAPIResponse>(APIEndpoints.UPDATE_PRECERT, {}, {}, false);

  useEffect(() => {
    if (fetchPrecertResponse) {
      setButtonDisabled(false);
      hideLoader();
      navigate(`/cases`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPrecertResponse]);



  const navigateToUpdatePrecert = () => {
    const pathParams = {
      case_id: caseId,
      id: precertsId
    }
    const queryParams = {
      precert_approval: false,
      status: "pending_transcription",
      case_id: caseId,
      change_note: note,
      system_note: "Precert Changes Requested by Office.",
    }
    // setPrecertPathParameters(pathParams)
    setLoader()
    setButtonDisabled(true)
    fetchPrecertMutate({}, pathParams, queryParams)
  }
  const handleRequestChanges = () => {
    const confirmDeny = window.confirm("Are you sure you wish to deny this precert request?");
    if (confirmDeny) {
      // Handle the logic to deny precert here
      // const title = queryParam.get('title');
      // if (title && title.includes("Med PA")) {
        navigateToUpdatePrecert()
      //} 

      console.log("Request denied with note:", note);
    }
  };
  const handleApproveAndSubmitToInsuranceChanges = () => {
    const confirmDeny = window.confirm("Are you sure wish to send this precert to the insurance company? This cannot be undone!");
    if (confirmDeny) {
      const pathParams = {
        case_id: caseId,
        id: precertsId
      }
      const queryParams = {
        precert_approval: false,
        status: "submitted",
        case_id: caseId,
        system_note: "Precert Submitted by office."
      }
      // setPrecertPathParameters(pathParams)
      setLoader()
      setButtonDisabled(true)
      fetchPrecertMutate({}, pathParams, queryParams)

      console.log("Request denied with note:", note);
    }
  };

  return (
    <div className="d-flex flex-column flex-fill body-bg cases-page overflow-x-hidden overflow-y-auto">
      <div className="container pt-2 px-4">
        <AuthSubHeader />

        <div className="card p-0">
          <h1 className="card-header text-white">{queryParam.get('title')} <p>(Insurance Name: {queryParam.get('insuranceName')})</p></h1>

          <div className="card-body">
            <p>
              Review the below information and select "Approve" to have the form sent to the insurance company.
              Alternatively, input any requested changes or updates in the notes field and click "Request Changes"
              below.
            </p>
            <div className="iframe-container">
              {/* {reviewFile && reviewFile.precert_file && reviewFile.precert_file.length > 0 ? (
                <iframe
                  title="Precert PDF"
                  src={`data:application/pdf;base64,${reviewFile.precert_file}#zoom=90`}
                  style={{ width: "100%", height: "70vh" }}
                />
              )
                :
                <>
                  <div>
                  <h2 className="text-center" style={{color: '#808285'}}>Form not uploaded, please contact support.</h2>
                  </div>
                </>
              } */}
              
                {pdfUrl && reviewFile && reviewFile.precert_file && reviewFile.precert_file.length > 0 ? (
                  <iframe  title="Precert PDF" src={pdfUrl} style={{ width: "100%", height: "70vh" }}></iframe>
                ) : (
                  <>
                  <div>
                  <h2 className="text-center" style={{color: '#808285'}}>Form not uploaded, please contact support.</h2>
                  </div>
                </>
                )}
              
            </div>

            <div className="row my-2">
              <label htmlFor="note" className="col-12 col-md-2 form-label">
                Notes
              </label>
              <div className="col-12 col-md-10">
                <textarea
                  name="note" id="note" className="form-control"
                  value={note} onChange={updateNote}
                  style={{ resize: "none" }} rows={4}
                ></textarea>
              </div>
            </div>
            <div className='row my-2'>
              <div className='d-flex justify-content-end gap-1'>
            <button
                  className="btn btn-danger btn-lg mt-2"
                  onClick={handleRequestChanges}
                  disabled={buttonDisabled}
                >
                  Request Changes
                </button>
                {reviewFile && reviewFile.precert_file && reviewFile.precert_file.length > 0 &&
                  <button
                    className="btn btn-lg btn-success mt-2"
                    onClick={handleApproveAndSubmitToInsuranceChanges}
                    disabled={buttonDisabled}
                  >
                    Approve and Submit to Insurance
                  </button>}
                  </div>
            </div>


          </div>
        </div>
        <div className="mt-3"><FooterText footerType="black" /></div>

      </div>
    </div>
  );
};

export default ReviewMedPA;
