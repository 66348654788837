import { FC } from "react";
import { Controller } from "react-hook-form";

import EnrollmentCard from "src/components/enrollmentCard";
import HubInputField from "src/components/inputType";

import { EnrollmentPageUserForm } from "./types";

const EnrollmentUserForm: FC<EnrollmentPageUserForm> = ({ userIndex, removeUser, control }): JSX.Element => {
  return (
    <EnrollmentCard isTextCenter={false} index={userIndex} isCrossBtnVisible={userIndex > 0 ? true : false} clickHandlerForCrossBtn={removeUser} cardHeader="User">
      <>
        <div className="row">
          <div className="col-lg-6">
            <Controller
              name={`enrollment_contacts_attributes.${userIndex}.firstname`}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId={`enrollment_contacts_attributes.${userIndex}.firstname`}
                  inputLabel="First Name"
                  isRequired={true}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  field={field}
                  error={error}
                />
              )}
            />
          </div>
          <div className="col-lg-6">
            <Controller
              name={`enrollment_contacts_attributes.${userIndex}.lastname`}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId={`enrollment_contacts_attributes.${userIndex}.lastname`}
                  inputLabel="Last Name"
                  isRequired={true}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  field={field}
                  error={error}
                />
              )}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <Controller
              name={`enrollment_contacts_attributes.${userIndex}.middlename`}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId={`enrollment_contacts_attributes.${userIndex}.middlename`}
                  inputLabel="Middle Name"
                  isRequired={false}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  field={field}
                  error={error}
                />
              )}
            />
          </div>
          <div className="col-lg-6">
            <Controller
              name={`enrollment_contacts_attributes.${userIndex}.email`}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId={`enrollment_contacts_attributes.${userIndex}.email`}
                  inputLabel="Email"
                  inputPlaceholder="e.g. example@domain.com"
                  isRequired={true}
                  isRowBased={true}
                  isRowInsideAnother={true}
                  field={field}
                  error={error}
                />
              )}
            />
          </div>
        </div>
      </>
    </EnrollmentCard>
  );
};

export default EnrollmentUserForm;
